<template>
  <orig class="markdown" v-bind="$attrs">
    <template>
      <slot name="default"></slot>
    </template>
  </orig>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import {VueShowdown} from "vue-showdown"

export default defineComponent({
    components: {
        orig: VueShowdown,
    },
})
</script>

<style lang="scss">
.markdown {
  img {
    max-width: 100%;
  }
}
</style>