<script lang="ts" setup>
import {computed, watch} from "vue"
import {Validator} from "@lib/common/Validator"
import {MandantStammdatenViewModel} from "@intranet/view/mandant/stammdaten/MandantStammdatenViewModel"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {AnschriftController} from "@intranet/view/anschrift/AnschriftController"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/rights/RightIdentifier"
import {Resource} from "@lib/model/Resource"
import {auth} from "@lib/common/Authentication"
import {AutomatischeRechnung} from "@generated/de/lohn24/model/mandant/AutomatischeRechnung"
import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import AnschriftForm from "@intranet/view/anschrift/AnschriftForm.vue"
import KooperationspartnerOptions from "@intranet/view/kooperationspartner/KooperationspartnerOptions.vue"
import {OrganisationStatus} from "@generated/de/lohn24/model/organisation/OrganisationStatus"
import Relation from "@lib/view/relation/Relation.vue"
import {RelationDefaultTemplateContext} from "@lib/view/relation/RelationDefaultTemplateContext"
import {Kuendigungsgrund} from "@generated/de/lohn24/model/mandant/Kuendigungsgrund"
import {capitalize} from "lodash"
import {MandantStatusPortal} from "@generated/de/lohn24/model/mandant/MandantStatusPortal"

export interface MandantStammdatenProps {
    mandant: Mandant,
    controllerAnschrift: AnschriftController<Mandant> | null,
    weitereOrganisationen?: Organisation[],
    currentName?: string,
    path?: string,
    pathAnschrift?: string,
    builder?: boolean
}

const props = withDefaults(
    defineProps<MandantStammdatenProps>(),
    {
        weitereOrganisationen: () => [],
        path: () => "mandant",
        pathAnschrift: () => "anschrift",
    },
)
const emits = defineEmits<{
    "update:modelValue": []
    "validate": [path?: string]
    "update:controllerAnschrift": [controller: AnschriftController<Mandant>]
    "update:organisation": [organisation?: Organisation]
}>()

const viewModel = computed(() => {
    return new MandantStammdatenViewModel(props.mandant)
})

watch(
    () => viewModel.value.sammelrechnung?.value,
    () => {
        emits("validate")
    },
    {
        immediate: false,
    },
)

watch(
    () => viewModel.value.keineRechnung,
    () => {
        emits("validate")
    },
    {
        immediate: false,
    },
)

const hasRechnungswesenEditAccess = computed(() => auth.hasAccess([RightAccess.editable(RightIdentifier.RECHNUNGSWESEN)]))
const hasKlassifizierunngAccess = computed(() => auth.hasAccess([RightAccess.editable(RightIdentifier.KLASSIFIZIERUNG)]))

const organisationError = computed(() => {
    const err1 = error(MandantStammdatenViewModel.props.organisation)
    const err2 = error(MandantStammdatenViewModel.props.organisation + ".name")

    const err = (err1 ?? "") + (err2 ?? "")
    return err.isNotEmpty() ? err : ""
})

function prop(name: string): string {
    return `${props.path}.${name}`
}

function error(name: string): string {
    return Validator.getErrorText(prop(name))
}


</script>

<template>
  <el-row :gutter="40" class="row-stammdaten-mandant">
    <el-col :span="12">
      <h3>Stammdaten</h3>
      <el-form-item
              :error="error(MandantStammdatenViewModel.props.name)"
              :label="MandantStammdatenViewModel.label.name"

      >
        <el-input
                v-model="viewModel.name"
                data-cy="mandant-stammdaten-name"
                name="name"
                @blur="emits('validate', undefined)"
        />
      </el-form-item>
      <el-form-item
              :error="error(MandantStammdatenViewModel.props.kennung)"
              :label="MandantStammdatenViewModel.label.kennung"
      >
        <el-input
                v-model="viewModel.kennung"
                data-cy="mandant-stammdaten-kennung"
                maxlength="6"
                name="kennung"
                :disabled="!(hasRechnungswesenEditAccess || props.builder)"
                @blur="emits('validate', undefined)"
        />
      </el-form-item>
      <el-form-item :error="error(MandantStammdatenViewModel.props.steuernummer)" :inline="true"
                    :label="MandantStammdatenViewModel.label.steuernummer"
      >
        <el-input
                v-model="viewModel.steuernummer"
                data-cy="mandant-stammdaten-steuernummer"
                label="Steuernummer"
                maxlength="13"
                name="steuernummer"
                @blur="emits('validate', undefined)"
        />
      </el-form-item>
      <el-form-item
              :error="organisationError"
              :label="MandantStammdatenViewModel.label.organisation"
      >
        <repository-options
                v-model="viewModel.organisation"
                :additional-items="props.weitereOrganisationen"
                :build-dialog="MandantStammdatenViewModel.label.organisationAnlegen"
                :edit-dialog="MandantStammdatenViewModel.label.organisationBearbeiten"
                :option-filter="(row) => row.status === OrganisationStatus.AKTIV"
                :repository="Resource.organisation"
                createable
                data-cy="mandant-stammdaten-organisation"
                editable
                model-key="name"
                model-label="Name"
                @change="emits('update:organisation', $event);emits('validate', undefined)"
        />
      </el-form-item>
      <el-form-item
              :error="error(MandantStammdatenViewModel.props.kooperationspartner)"
              :label="MandantStammdatenViewModel.label.kooperationspartner"
      >
        <kooperationspartner-options
                v-model="viewModel.kooperationspartner"
                :disabled="!hasRechnungswesenEditAccess"
                @change="emits('validate', undefined)"
        />
      </el-form-item>
      <el-form-item
              :error="error(MandantStammdatenViewModel.props.sammelrechnung)"
              :label="MandantStammdatenViewModel.label.sammelrechnung"
      >
        <kooperationspartner-options
                v-model="viewModel.sammelrechnung"
                :disabled="!hasRechnungswesenEditAccess"
                :keine-rechnung="false"
                @change="emits('validate', undefined)"
        />
      </el-form-item>
      <el-form-item
              :error="MandantStammdatenViewModel.errorCheckboxen(error)"
      >
        <el-checkbox
                v-model="viewModel.keineRechnung"
                :disabled="!hasRechnungswesenEditAccess"
                :label="MandantStammdatenViewModel.label.keineRechnung"
                data-cy='mandant-stammdaten-keineRechnung'
        />
        <el-checkbox
                v-model="viewModel.direktvertrag"
                :disabled="!hasRechnungswesenEditAccess"
                :label="MandantStammdatenViewModel.label.direktvertrag"
                data-cy='mandant-stammdaten-direktvertrag'
        />
        <el-checkbox
                v-model="viewModel.keinDirektkontakt"
                :disabled="!(viewModel.sammelrechnung?.value ?? viewModel.kooperationspartner?.value) && !viewModel.keinDirektkontakt"
                :label="MandantStammdatenViewModel.label.keinDirektkontakt"
                data-cy='mandant-stammdaten-keinDirektkontakt'
        >
        </el-checkbox>
      </el-form-item>
      <el-form-item
              :error="error(MandantStammdatenViewModel.props.automatischeRechnung)"
              :label="MandantStammdatenViewModel.label.automatischeRechnung"
              :prop="prop(MandantStammdatenViewModel.props.automatischeRechnung)"
      >
        <el-radio-group
                v-if="hasRechnungswesenEditAccess"
                v-model="viewModel.automatischeRechnung"
                @change="emits('validate', path)"
        >
          <el-radio-button style="flex: 1" :label="AutomatischeRechnung.JA">{{
              capitalize(AutomatischeRechnung.JA)
            }}
          </el-radio-button>
          <el-radio-button style="flex: 1" :label="AutomatischeRechnung.NEIN">{{
              capitalize(AutomatischeRechnung.NEIN)
            }}
          </el-radio-button>
          <el-radio-button style="flex: 1" :label="AutomatischeRechnung.NIE">{{ capitalize(AutomatischeRechnung.NIE) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item :error="error(Mandant.props.statusPortal)" :prop="prop(Mandant.props.statusPortal)">
        <template #label>
          <el-text>{{ MandantStammdatenViewModel.label.portalStatus }}</el-text>
        </template>
          <el-radio-group v-model="viewModel.portalStatus" style="width: 100%;" @change="emits('validate')">
            <el-radio-button
                    style="flex: 1"
                    v-for="option of MandantStatusPortal"
                    :label="option"
                    :key="option"
            >
              {{ capitalize(option) }}
            </el-radio-button>
          </el-radio-group>
      </el-form-item>
      <el-form-item
              v-if="hasKlassifizierunngAccess"
              :error="error(MandantStammdatenViewModel.props.klassifizierung)"
              :label="MandantStammdatenViewModel.label.klassifizierung"
              :prop="prop(MandantStammdatenViewModel.props.klassifizierung)"
      >
        <el-rate
                v-model="viewModel.klassifizierung"
                :max="3"
                size="small"
        />
      </el-form-item>
      <el-form-item
              :error="error(MandantStammdatenViewModel.props.kuendigungsdatum)"
              :label="MandantStammdatenViewModel.label.kuendigungsdatum"
              :prop="prop(MandantStammdatenViewModel.props.kuendigungsdatum)"
      >
        <el-date-picker
                v-model="viewModel.kuendigungsdatum"
                :disabled="!hasRechnungswesenEditAccess"
                data-cy="mandant-stammdaten-kuendigungsdatum"
                format="DD.MM.YYYY"
                type="date"
        />
      </el-form-item>
      <el-form-item
              v-if="viewModel.kuendigungsdatum"
              :error="error(MandantStammdatenViewModel.props.kuendigungsgrund)"
              :label="MandantStammdatenViewModel.label.kuendigungsgrund"
              :prop="prop(MandantStammdatenViewModel.props.kuendigungsgrund)"
      >
        <el-select v-model="viewModel.kuendigungsgrund" clearable @change="emits('validate')">
          <el-option :value="Kuendigungsgrund.ANBIETERWECHSEL" label="Anbieterwechsel"></el-option>
          <el-option :value="Kuendigungsgrund.BETRIEBSAUFGABE" label="Betriebsaufgabe"></el-option>
          <el-option :value="Kuendigungsgrund.INSOLVENZ" label="Insolvenz"></el-option>
          <el-option :value="Kuendigungsgrund.SONSTIGES" label="Sonstiges"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
              v-if="viewModel.kuendigungsgrund == Kuendigungsgrund.SONSTIGES"
              :label="MandantStammdatenViewModel.label.kuendigungsgrundNotiz"
              :prop="prop(MandantStammdatenViewModel.props.kuendigungsgrundNotiz)"
              :error="error(MandantStammdatenViewModel.props.kuendigungsgrundNotiz)"
      >
        <el-input v-model="viewModel.kuendigungsgrundNotiz" />
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <slot name="anschrift">
        <relation
                :build-controller-instance="AnschriftController.build"
                :controller="controllerAnschrift"
                :parent="viewModel.id"
                :relation="Resource.mandant.anschrift"
                @update:controller="emits('update:controllerAnschrift', $event)"
        >
          <template #default="{controller}: RelationDefaultTemplateContext<AnschriftController<Mandant>>">
            <anschrift-form
                    :controller="controller"
                    :path="pathAnschrift"
                    @validate="emits('validate', pathAnschrift)"
            />
          </template>
        </relation>
      </slot>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.row-stammdaten-mandant {
  .el-checkbox-group,
  .el-checkbox-group .el-checkbox-button,
  .el-checkbox-group .el-checkbox-button .el-checkbox-button__inner {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  .el-form-item__label {
    .el-text--small {
      line-height: 12px;
    }
  }
}
</style>
