import {computed, ref, Ref, watch, WatchStopHandle} from "vue"
import {ElTable} from "element-plus"
import {ViewModel} from "@lib/common/model/ViewModel"

export class Selection<T extends ViewModel<T>> {
    selection: Ref<T[]> = ref([])
    table = ref<InstanceType<typeof ElTable>>()
    empty = computed<boolean>(() => this.selection.value.isEmpty())

    get count() {
        return this.selection.value.length
    }

    isEmpty() {
        return this.selection.value.isEmpty()
    }

    private watched?: WatchStopHandle = undefined

    select(row: T) {
        this.table.value?.toggleRowSelection(row, true)
    }

    setSelectionsTrue() {
        this.selection.value.forEach(item => {
            this.table.value?.toggleRowSelection(item, true)
        })
    }

    clear(): T[] {
        const selectionClone = this.selection.value.splice(0)
        this.table.value?.clearSelection()
        return selectionClone
    }

    assignTable(table: InstanceType<typeof ElTable>) {
        this.table.value = table
    }

    watch() {
        if (!this.watched)
            this.watched = watch(
                () => this.selection.value,
                () => this.setSelectionsTrue(),
                {deep: true},
            )
    }

    unwatch() {
        this.watched && this.watched()
        this.watched = undefined
    }
}