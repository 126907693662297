import {ViewModel} from "@lib/common/model/ViewModel"
import {News} from "@generated/de/lohn24/model/news/News"
import {_props} from "@lib/common/_props"
import {NewsType} from "@generated/de/lohn24/model/news/NewsType"
import {NewsContentType} from "@generated/de/lohn24/model/news/NewsContentType"

export class NewsBuilderViewModel extends ViewModel<NewsBuilderViewModel> {
    static label = {
        title: "Titel",
    }
    static properties = _props<InstanceType<typeof this>>()

    news: News

    constructor(news: News) {
        super()
        this.news = this.registerShallowReactive(news)
    }

    get title() {
        return this.news.title
    }

    set title(value: string) {
        this.news.title = value
    }

    get teaser() {
        return this.news.teaser
    }

    set teaser(value: string) {
        this.news.teaser = value
    }

    get type(): NewsType {
        return this.news.type
    }

    set type(value: NewsType) {
        this.news.type = value
    }

    get contentType(): NewsContentType {
        return this.news.contentType
    }
}