<script generic="V extends ViewModel<V>" lang="ts" setup>
import {ListContentEmits} from "@lib/view/list/listcontent/ListContentEmits"
import {
    ListContentProps,
    ListContentType,
    SortOrderType,
    TableCellType,
} from "@lib/view/list/listcontent/ListContentProps"
import {Selection} from "@lib/common/controller/Selection"
import {CrudContextMenu} from "@lib/view/list/CrudContextMenu"
import {computed, ref, shallowRef, UnwrapRef, watch} from "vue"
import {ViewModel} from "@lib/common/model/ViewModel"
import Pagination from "@lib/common/pagination/Pagination"
import {Paginationlayout} from "@lib/common/pagination/PaginationLayout"
import {relativeScrollPositionYInPageHeight, scrollToTop} from "@lib/common/vue/plugin/ScrollDirection"
import CrudColumn from "@lib/view/list/components/CrudColumn.vue"
import {useRoute, useRouter} from "vue-router"
import {RowClassNameArgument} from "@lib/common/elementplus/types"
import {ElTable, TableColumnCtx} from "element-plus"
import {CSVContextMenu} from "@lib/view/list/CSVContextMenu"
import {ExpandableListController, ExpandableViewModel} from "@lib/common/controller/ExpandableListController"
import {ArrowUp} from "@element-plus/icons-vue"

const table = shallowRef<InstanceType<typeof ElTable>>()
const emits = defineEmits<ListContentEmits<V>>()
const props = withDefaults(
    defineProps<ListContentProps<V>>(),
    {
        type: () => ListContentType.TABLE,
        neu: () => "Neuen Eintrag erstellen",
        paginatable: () => true,
        showSummary: () => false,
        selection: () => new Selection(),
        searchables: () => [],
        data: (controller) => controller.data,
        settings: () => true,
        settingsBelow: () => false,
        beforePageChange: () => true,
        formatter: () => ({}),
        scrollPositionInPageHeight: 0.3,
        multiselectDropdown: () => false,
        multiselect: () => false,
        crudColumn: () => false,
        crudCellClass: () => "show-on-row-hover",
        rowClassName: () => "",
        csv: () => false,
    },
)
const route = useRoute()
const router = useRouter()
const rowRef = ref<HTMLElement | null>(null)
const currentRow = shallowRef<V | null>(null)
const popoverVisible = ref<boolean>(false)
const currentPathName = computed(() => router.currentRoute.value.name)
const searchInput = ref("")
const data = computed<V[]>(() => {
    return props.data(props.controller)
})
const sortOrder = ref<SortOrderType>(null)
const sortColumn = ref<TableColumnCtx<V> | null>(null)
const page = ref(1)
const limit = ref(50)
const sizes = ref([50, 100, 200, 500, 1000])
const layout = ref<Array<Paginationlayout>>(["total", "sizes", "prev", "pager", "next", "jumper"])
const size = computed(() => filteredData.value.length)

const showScrollUpButton = computed<boolean>(() => {
    return relativeScrollPositionYInPageHeight.value > props.scrollPositionInPageHeight
})

const filteredData = computed(() => {
    if (!props.searchables || props.searchables.length == 0) return data.value
    const searchItem = searchInput.value.toLowerCase()
    return data.value.filter((row: V) => {
        const formatted = applyFormatter(row)
        if (searchItem.length > 2) {
            return props.searchables.filter(key => {
                return formatted[key(row)].toLowerCase().includes(searchItem)
            }).length > 0
        }
        return true
    })
})

const selection_ = computed<V[]>({
    get: () => props.selection?.selection.value ?? [],
    set: value => {
        if (props.selection?.selection)
            // eslint-disable-next-line vue/no-mutating-props
            props.selection.selection.value = value
    },
})

const tableData = computed(() => {
    let data = filteredData.value.slice()

    if (props.remotePagination)
        return data

    if (props.localFilter)
        data = data.filter(props.localFilter())

    if (props.localSort)
        data = data.sort(props.localSort(sortColumn.value, sortOrder.value))

    if (!props.paginatable)
        return data

    const start = (localPagination.value.page.value - 1) * localPagination.value.limit.value
    const end = start + localPagination.value.limit.value
    return data.slice(start, end)
})

const externalPage = computed({
    get() {
        return props.remotePagination?.page.value
    },
    set: async value => {
        if (props.remotePagination && await props.beforePageChange()) {
            // eslint-disable-next-line vue/no-mutating-props
            props.remotePagination.page.value = value as number
        }
    },
})

const externalLimit = computed({
    get() {
        return props.remotePagination?.limit.value
    },
    set: value => {
        if (props.remotePagination)
            // eslint-disable-next-line vue/no-mutating-props
            props.remotePagination.limit.value = value as number
    },
})

const localPagination = computed<Pagination>(() => {
    return {
        size: size,
        page: computed({
            get: () => page.value,
            set: value => page.value = value,
        }),
        limit: computed({
            get: () => limit.value,
            set: value => limit.value = value,
        }),
        sizes: sizes,
        layout: layout,
    }
})

const effektiveRowKey = computed(() => {
    if (props.controller instanceof ExpandableListController)
        return (viewModel: ExpandableViewModel<V>) => viewModel.rowKey
    return props.rowKey
})

const expandedRows = computed(() => {
    return props.expandRowKeys
        ?? (props.controller as ExpandableListController<any>).expandedRows
        ?? undefined
})

function expandChange(row: V, expanded: unknown) {
    const context = {row, expanded}
    if (props.controller instanceof ExpandableListController) {
        props.controller.expandedChange(context)
    }
    emits("expand-change", context)
}

function sortChange(column: TableColumnCtx<V>, prop: keyof V, order: SortOrderType) {
    sortOrder.value = order
    sortColumn.value = column
}

function onContentContextMenu(event: MouseEvent) {
    if (props.createable || props.editable || props.deletable) {
        new CrudContextMenu({
            row: undefined,
            event,
            createable: props.createable ?? false,
            editable: props.editable ?? false,
            deletable: props.deletable ?? false,
            controller: props.controller,
        })
    }
    props.controller.contextMenu(event)
}

function applyFormatter(row: V): Record<keyof V, string> {
    return (props.searchables || [])
        .reduce((acc, key) => {
            const _key = key(row)
            acc[_key] = props.formatter[_key] ? props.formatter[_key](row[_key]) : row[_key] + ""
            return acc
        }, {} as Record<keyof V, string>)
}

function cellClass({column}: { column: { columnKey: TableCellType | string } }) {
    const classNames: string[] = []
    switch (column.columnKey) {
        case TableCellType.SYSTEM_ICON:
            classNames.push("system-icon-cell")
            break
        case TableCellType.SELECT:
        case TableCellType.BUTTON:
            classNames.push("no-pointer-cell")
    }
    return classNames.join(" ")
}

function onRowClick(row: UnwrapRef<ViewModel<any>>, column: {
    columnKey: string
} | undefined = undefined, event: PointerEvent) {
    switch (column?.columnKey) {
        case TableCellType.SELECT:
        case TableCellType.BUTTON:
            event.stopPropagation()
            event.preventDefault()
            break
        default:
            emits("row-click", {row: row, column, event})
    }
}

function rowClassNames(obj: RowClassNameArgument<V>): string {
    const classNames: string[] = props.userSelectNone ? ["user-select-none"] : []
    classNames.push(props.rowClassName(obj))
    return classNames.join(" ")
}

function onTableRowContextMenu(row: V, column: { columnKey: string }, event: PointerEvent) {
    emits("row-contextmenu", {
        row,
        event,
        selection: props.selection,
        column,
        controller: props.controller,
        router: router,
        route: route,
    })
    if (props.csv && table.value) {
        new CSVContextMenu({
            table: table.value,
            event: event,
            name: router.currentRoute.value.name?.toString() ?? undefined,
        })
    }
    if (props.createable || props.editable || props.deletable) {
        new CrudContextMenu({
            row,
            event,
            createable: props.createable ?? false,
            editable: props.editable ?? false,
            deletable: props.deletable ?? false,
            controller: props.controller,
        })
    }
}

function crudCellClass(row: V, index: number, name: string): string {
    const classNames: string[] = [props.crudCellClass(row, index, name)]
    return classNames.join(" ")
}

function updateCurrentRow(row: V | null, col, cell, event: Event) {
    rowRef.value = (event.target as HTMLElement | null) || rowRef.value
    popoverVisible.value = row !== null && col.property === "name"
    currentRow.value = row
    emits(
        "cell-hover",
        {
            row: currentRow.value,
            rowRef: rowRef.value,
            cell,
            event,
        },
    )
}

watch(
    () => table.value,
    (value) => {
        emits("table", value)
        if (value)
            props.selection?.assignTable(value)
    },
    {immediate: true},
)

props.controller.useController()

</script>

<template>
  <div class="list-content" @contextmenu="onContentContextMenu($event)">
    <div v-if="settings" class="settings">
      <slot name="settings">
        <el-row :gutter="40">
          <el-col :span="8" class="settings-left">
            <slot name="settings-left">
              <el-form size="small" @submit.prevent>
                <el-row>
                  <el-col>
                    <el-form-item>
                      <el-input
                              v-model="searchInput"
                              class="w-50 m-2"
                              clearable
                              name="suche"
                              placeholder="Suche"
                              prefix-icon="search"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </slot>
          </el-col>
          <el-col :span="8" class="settings-middle">
            <slot name="settings-middle">
            </slot>
          </el-col>
          <el-col :span="8" class="settings-right">
            <slot name="settings-right">
            </slot>
          </el-col>
        </el-row>
      </slot>
    </div>
    <div v-if="settingsBelow" class="settings">
      <slot name="settings-below">
      </slot>
    </div>
    <div :class="`content ${classprefix}-content`">
      <router-view v-if="type===ListContentType.ROUTER"
                   :key="currentPathName ?? 'null'"
                   :controller="controller"
                   :data="tableData"
                   :loading="controller.loading.value"
      />
      <slot v-else :data="tableData" :loading="controller.loading.value" name="content">
        <el-row v-if="type === ListContentType.LISTE">
          <el-col v-for="(row, index) in tableData" :key="props.rowKey ? props.rowKey(row) : index">
            <slot :row="row" :rowIndex="index" name="cols">

            </slot>
          </el-col>
        </el-row>
        <el-table
                v-else
                ref="table"
                v-loading="controller.loading.value"
                :cell-class-name="cellClass"
                :data="tableData"
                :empty-text="props.emptyText"
                :expand-row-keys="expandedRows"
                :row-class-name="rowClassNames"
                :row-key="effektiveRowKey"
                :show-summary="props.showSummary"
                :stripe="props.stripe"
                element-loading-text="Daten werden geladen..."
                size="small"
                @selection-change="selection_ = $event"
                @row-click="onRowClick"
                @row-contextmenu="(row, column, event) => onTableRowContextMenu(row, column, event)"
                @cell-mouse-enter="updateCurrentRow"
                @cell-mouse-leave="(row, column, cell, event) => updateCurrentRow(null, column, cell, event)"
                @filter-change="(event) => $emit('filter-change', event)"
                @expand-change="(row, expanded) => expandChange(row, expanded)"
                @sort-change="({column, prop, order}) => sortChange(column, prop, order)"
        >
          <el-table-column v-if="multiselect" column-key="select" reserve-selection type="selection"
                           width="30"
                           @mousedown.stop
          />
          <el-table-column v-if="props.controller instanceof ExpandableListController" type="expand">
            <template #default="context">
              <slot v-if="context.row.expanded((controller as ExpandableListController<any>).expandedRows)"
                    :row="context.row"
                    name="expand"
              >
              </slot>
            </template>
          </el-table-column>
          <slot :selection="selection" name="columns">
          </slot>
          <crud-column
                  v-if="(createable || editable || deletable || fixed) && crudColumn"
                  :controller="controller"
                  :createable="createable"
                  :deletable="deletable"
                  :editable="editable"
                  :fixed="fixed"
                  :neu="neu"
                  :row-class="crudCellClass"
                  size="small"
                  width="40"
          ></crud-column>
        </el-table>
      </slot>
    </div>

    <br>
    <div v-if="remotePagination || paginatable" class="pagination">
      <slot name="pagination">
        <el-row justify="space-between">
          <div class="el-col">
            <el-dropdown v-if="multiselect && multiselectDropdown" placement="top-start" trigger="click">
              <el-button type="primary">
                <el-icon>
                  <arrow-up></arrow-up>
                </el-icon>
                <span>Aktion</span>

              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <slot :selection="selection" name="multiselect-dropdown">
                  </slot>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <el-pagination
                  v-if="remotePagination"
                  v-model:current-page="externalPage"
                  v-model:page-size="externalLimit"
                  :layout="remotePagination.layout.value.join(', ')"
                  :page-sizes="remotePagination.sizes.value"
                  :total="remotePagination.size.value"
          ></el-pagination>
          <el-pagination
                  v-else-if="paginatable"
                  v-model:current-page="localPagination.page.value"
                  v-model:page-size="localPagination.limit.value"
                  :layout="localPagination.layout.value.join(', ')"
                  :page-sizes="localPagination.sizes.value"
                  :total="localPagination.size.value"
          ></el-pagination>
          <div v-if="showScrollUpButton" class="el-col">
            <el-affix
                    :offset="20"
                    class="transition-all-ease-out"
                    position="bottom"
            >
              <el-row justify="end">
                <el-col class="el-affix__content scroll-to-top">
                  <el-button text @click="scrollToTop">
                    <el-icon>
                      <fas-chevron-up />
                    </el-icon>
                  </el-button>
                </el-col>
              </el-row>
            </el-affix>
          </div>
          <div v-else class="el-col">
            <el-row justify="end">
              <el-col class="el-affix__content scroll-to-top">
                <el-button text @click="scrollToTop">
                  <el-icon>
                    <fas-chevron-up />
                  </el-icon>
                </el-button>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </slot>
    </div>
  </div>
</template>

<style lang="scss">
.list-content {
  .radio-group-system img {
    margin-right: 5px;
  }

  .settings {
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid lightgray;
  }

  .el-table__expanded-cell tr:last-child > td {
    border: 0 !important;
  }

  .el-table__inner-wrapper::before {
    display: none;
  }

  .content {
    .system-icon {
      --icon-size: 16px;

      .el-col {
        height: var(--icon-size);

        .el-icon {
          font-size: var(--icon-size);
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        img {
          margin: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .el-table {
      .el-table__row.user-select-none {
        user-select: none;
        cursor: pointer;

        .no-pointer-cell {
          cursor: default;
        }
      }

      .cell.highlight {
        color: var(--l24-color-brand)
      }

      .cell span.el-table__placeholder {
        display: none;
      }
    }

    .content-at-end {
      display: flex !important;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
    }

    .abrechnungssystem-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 35px;
    }

    .el-table__row .el-button {
      padding: 0;
      margin: 0;
      height: 10px;
    }

    button.el-button + button.el-button {
      padding-left: 15px;
    }

    .el-dropdown button.el-button + button.el-button {
      padding-left: 0;
    }

    .el-dialog__header button.el-button + button.el-button {
      padding-left: 5px;
    }

    .el-table.content .el-table__body-wrapper {
      min-height: 800px;
    }

    // xl
    @media (min-width: 1920px) {
      .el-scrollbar__bar.is-horizontal {
        display: none !important;
      }
    }

    .show-on-row-hover {
      display: none;
    }

    tr:hover .show-on-row-hover {
      display: inline-flex;
    }
  }

  .pagination {
    .el-affix__content.scroll-to-top {
      padding: 0;
    }
  }

  ul.el-dropdown-menu > li.el-dropdown-menu__item--divided:first-child {
    display: none;
    height: var(--el-cell)
  }
}
</style>