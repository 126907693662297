<template>
  <el-tooltip v-if="!gesperrt" :disabled="tooltipDisabled" :show-after="1000" placement="top">
    <template #content>
      <span>Mandant {{ viewModel.kennung }} für den Abrechnungszeitraum
        {{ abrechnungszeitraum }}
        abschließen</span>
    </template>
    <el-button :class="btnClass"
               :disabled="inaktiv"
               :size="size"
               name="abschluss"
               text
               @click.stop="onClick(viewModel)"
    >
      <icon-abschluss :size="size" />
    </el-button>
  </el-tooltip>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue"
import IconAbschluss from "@lib/view/icons/IconAbschluss.vue"
import {MandantViewModel} from "@intranet/view/mandant/MandantViewModel"
import {Formatter} from "@lib/common/Formatter"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {useRoute, useRouter} from "vue-router"
import {mandantAbschliessen} from "@intranet/view/mandant/MandantFunctions"
import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"
import _ from "lodash"

export default defineComponent({
    name: "ButtonMandantAbschluss",
    inheritAttrs: false,
    components: {
        IconAbschluss,
    },
    props: {
        mandant: {
            type: Mandant as PropType<Mandant>,
            required: true,
        },
        btnClass: {
            type: [String, Object],
            default: "",
        },
        size: {
            type: String,
            default: "default",
        },
        tooltipDisabled: Boolean,
    },
    emits: ["abschluss"],
    setup(props) {

        const viewModel = computed(() => new MandantViewModel(props.mandant))

        const gesperrt = computed(() => {
            return viewModel.value.gesperrt
        })

        const inaktiv = computed(() => {
            return viewModel.value.status === MandantStatus.INAKTIV
        })

        const abrechnungszeitraum = computed(() => Formatter.zeit.zeitraum(viewModel.value.abrechnungszeitraum))

        const router = useRouter()
        const route = useRoute()

        const debounced = _.debounce((viewModel: MandantViewModel) => {
                return mandantAbschliessen(() => ({
                    mandant: viewModel.mandant,
                    router: router,
                    route: route,
                }))
            },
            200, {
                leading: true,
                trailing: false,
            })

        async function onClick(viewModel: MandantViewModel) {
            return debounced(viewModel)
        }

        return {
            gesperrt,
            inaktiv,
            onClick,
            viewModel,
            abrechnungszeitraum,
        }
    }

    ,
})

</script>
