import {ViewModel} from "@lib/common/model/ViewModel"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {_props} from "@lib/common/_props"
import {Formatter} from "@lib/common/Formatter"

export class MandantOrganisationViewModel extends ViewModel<MandantOrganisationViewModel> {

    static properties = _props<InstanceType<typeof this>>()
    static label = {
        name: "Mandant",
        kennung: "Kennung",
        abrechnungszeitraum: "Abrechnungszeitraum",
    }
    mandant: Mandant

    constructor(mandant: Mandant) {
        super()
        this.mandant = this.registerShallowReactive(mandant)
    }

    get name() {
        return `${this.mandant.name}`
    }

    get kennung() {
        return this.mandant.kennung
    }

    get abrechnungszeitraum() {
        return Formatter.zeit.zeitraum(this.mandant.abrechnungszeitraum)
    }
}