import {ViewModel} from "@lib/common/model/ViewModel"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {_props} from "@lib/common/_props"
import {Formatter} from "@lib/common/Formatter"
import {Lohnsteueranmeldeperiode} from "@lib/model/abrechnung/Lohnsteueranmeldeperiode"
import {Resource} from "@lib/model/Resource"
import {DateTime} from "luxon"
import {Beitragsnachweis} from "@lib/model/abrechnung/Beitragsnachweis"
import {LocalMonth} from "@lib/common/LocalMonth"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {LocalDate} from "@lib/common/LocalDate"
import {
    BereitstellungOptionZahlungenArt,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungenArt"
import {BereitstellungOptionLohn} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionLohn"
import {
    BereitstellungOptionZahlungen,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungen"
import {
    BereitstellungOptionRechnung,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionRechnung"

export class MandantAbschlussdatenViewModel extends ViewModel<MandantAbschlussdatenViewModel> {
    static properties = _props<InstanceType<typeof this>>()
    static label = {
        abrechnungszeitraum: "Abrechnungszeitraum",
        sachbearbeiter: "Sachbearbeiter",
        lohnsteueranmeldeperiode: "Lohnsteuer",
        direktkontakt: "Direktkontakt",
        gekuendigtZum: "Gekündigt zum",
        bereitstellung: {
            zahlungenArt: "Bereitstellungsart Zahlungen",
            zahlungenOrt: "Bereitstellungsort Zahlungen",
            lohnOrt: "Bereitstellungsort Lohnabrechnungen",
            rechnungOrt: "Bereitstellungsort Rechnungen",
        },
        zahlungenArt: {
            datei: "Datei",
            ebics: "EBICS",
            traeger: "Überweisungsträger",
            liste: "Liste",
        },
        bereitstellungenZahlungen: {
            cloud: "Cloud",
            system: "System",
            fax: "Fax",
            post: "Post",
            pmc: "PMC"
        },
        bereitstellungenLohn: {
            cloud: "Cloud",
            system: "System",
            fax: "Fax",
            post: "Post",
            pmc: "PMC"
        },
        bereitstellungenRechnung: {
            cloud: "Cloud",
            email: "E-Mail",
            system: "System",
            fax: "Fax",
            post: "Post",
            pmc: "PMC"
        },
        zuletztAbgerechnetVon: "Zuletzt abgerechnet von",
    }
    mandant: Mandant

    get bereitstellungLohn(): BereitstellungOptionLohn[] {
        return this.mandant.bereitstellungLohn
    }

    get bereitstellungZahlungen(): BereitstellungOptionZahlungen[] {
        return this.mandant.bereitstellungZahlungen
    }

    get bereitstellungZahlungenArt(): BereitstellungOptionZahlungenArt[] {
        return this.mandant.bereitstellungZahlungenArt
    }

    get bereitstellungRechnung(): BereitstellungOptionRechnung[] {
        return this.debitor?.bereitstellungRechnung ?? []
    }

    constructor(mandant: Mandant, private readonly debitor?: Debitor | null) {
        super()
        this.mandant = this.registerShallowReactive(mandant)
    }

    get abrechnungssystem(): string {
        return Formatter.bereitstellungAbrechnungssystem(this.mandant.abrechnungssystem)
    }

    get abrechnungszeitraum(): string {
        return Formatter.zeit.zeitraum(this.mandant.abrechnungszeitraum)
    }

    get lohnsteueranmeldeperiode() {
        switch (this.mandant.lohnsteueranmeldeperiode) {
            case Lohnsteueranmeldeperiode.MONAT:
                return "monatlich"
            case Lohnsteueranmeldeperiode.QUARTAL:
                return "vierteljährlich"
            case Lohnsteueranmeldeperiode.JAHR:
                return "jährlich"
            default:
                return this.mandant.lohnsteueranmeldeperiode
        }
    }

    get sachbearbeiter() {
        return Resource.account.accountName(this.mandant.sachbearbeiter) ?? ""
    }

    get direktkontakt() {
        return !this.mandant.keinDirektkontakt
    }

    get kuendigungsdatum()
        :
        string {
        return Formatter.zeit.datum(this.mandant.kuendigungsdatum)
    }

    get effektiverAbrechnungszeitraum()
        :
        DateTime {
        const beitragsschaetzung = this.mandant.beitragsnachweis === Beitragsnachweis.SCHAETZUNG && this.mandant.abschlusstag === 10
        const abrechnungszeitraum = LocalMonth.fromString(this.mandant.abrechnungszeitraum!.YYYY_MM).dateTime
        if (beitragsschaetzung) {
            abrechnungszeitraum.plus({month: 1})
        }
        return abrechnungszeitraum
    }

    get isLastMonth() {
        const kuendigungsdatum = this.mandant.kuendigungsdatum
        if (kuendigungsdatum !== null) {

            return this.effektiverAbrechnungszeitraum.month === kuendigungsdatum.month &&
                this.effektiverAbrechnungszeitraum.year === kuendigungsdatum.year
        }
        return false
    }

    get gekuendigtInDerVergangenheit()
        :
        boolean {
        if (this.mandant.kuendigungsdatum !== null) {
            return this.mandant.kuendigungsdatum <= LocalDate.now()
        }
        return false
    }

    get zuletztAbgerechnetVon(): string {
        return Resource.account.accountName(this.mandant.vorherigerSachbearbeiter) ?? ""
    }
}