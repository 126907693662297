import {ModelController} from "@lib/common/controller/ModelController"
import {NewsViewModel} from "@intranet/view/news/NewsViewModel"
import {Resource} from "@lib/model/Resource"
import {from} from "@lib/common/Functions"
import {News} from "@generated/de/lohn24/model/news/News"
import {auth} from "@lib/common/Authentication"

export class NewsController extends ModelController<NewsViewModel> {
    dataSaveFailedMessage = `News - ${this.model.value.titel}`
    dataSavedSuccesfullyMessage = `News - ${this.model.value.titel}`

    protected async createModel(model: NewsViewModel): Promise<NewsViewModel> {
        model.author = auth.me.account
        const news = await Resource.news.insert(model.news)
        return new NewsViewModel(from(News, news))
    }

    protected async updateModel(model: NewsViewModel): Promise<NewsViewModel> {
        if (model.bearbeitungszustand.deleted) {
            await Resource.news.delete(model.news)
        } else {
            const news = await Resource.news.update(model.news)
            return new NewsViewModel(news)
        }
        return new NewsViewModel(model.news)
    }
}