<script setup lang="ts">
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"
import {Id} from "@lib/common/model/Id"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {RepositoryOptionsProps} from "@lib/view/formitem/RepositoryOptionsProps"
import {computed} from "vue"
import {Resource, ResourceCache} from "@lib/model/Resource"
import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"

const props = defineProps<
    {
        modelValue: Id<ArtikelkatalogArtikel> | null,
        artikelkatalog: Id<Artikelkatalog>
        optionFilter?: (artikel: Artikel) => boolean
    } & Omit<RepositoryOptionsProps<Artikel>, "repository" | "modelValue">
>()

const emits = defineEmits<{
    "change": [artikel: Artikel | null],
    "update:modelValue": [artikelkatalogArtikelId: Id<ArtikelkatalogArtikel> | null]
}>()

const artikelkatalogArtikel = computed(() => ResourceCache
    .artikelkatalogArtikel
    .map(it => it)
    .value
    .filter(it => it.artikelkatalog.value === props.artikelkatalog.value),
)

const artikelId = computed<Id<Artikel> | null>({
    get() {
        return ResourceCache
            .artikelkatalogArtikel
            .mapped(props.modelValue, it => it)
            .value
            ?.artikel ?? null
    },
    set: (id) => {
        if (id !== null) {
            const mapped = artikelkatalogArtikel
                .value
                .groupBy(it => it.artikel.value)

            const artikelkatalogArtikelId = mapped[id.value]
                .first()
                .id
            return emits("update:modelValue", artikelkatalogArtikelId)
        }

        return emits("update:modelValue", null)
    },
})
</script>

<template>
  <repository-options
          @change="emits('change', $event)"
          v-model="artikelId"
          :option-label="(artikel: Artikel) => `${artikel.name}`"
          :option-filter="artikel => artikelkatalogArtikel.any(it => it.artikel.value === artikel.id.value)"
          :repository="Resource.artikel"
  />
</template>