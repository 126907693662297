import {Id} from "@lib/common/model/Id"
import {Serializable} from "@lib/common/serializable/Serializable"

export abstract class ModelBuilder<B> extends Serializable<B> {
    id!: Id<B>
    // abstract modelType: Constructable<M>

    // abstract model(): M

    protected constructor(obj: object = {}) {
        super(obj)
    }
}