import { IModel, IRuntimeTypeInformation, Id, ModelBuilder } from "@lib/index"
import { IArtikelkatalogArtikel, ArtikelkatalogArtikel } from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"

export interface IArtikelkatalogBuilder extends IModel {
	artikelkatalogArtikel: Array<IArtikelkatalogArtikel>
	name: string
}

export class ArtikelkatalogBuilder extends ModelBuilder<ArtikelkatalogBuilder> {
	public artikelkatalogArtikel!: Array<ArtikelkatalogArtikel>
	public name!: string

	static _types: Record<keyof IArtikelkatalogBuilder, IRuntimeTypeInformation> = {
		id: { type: Id },
		artikelkatalogArtikel: { type: [ArtikelkatalogArtikel] },
		name: { type: String },
	}

	static props: Record<keyof IArtikelkatalogBuilder, string> = {
		id: "id",
		artikelkatalogArtikel: "artikelkatalogArtikel",
		name: "name",
	}

	constructor(_json: IArtikelkatalogBuilder) {
		super(_json)
	}
}
