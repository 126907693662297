import {Serializable} from "@lib/common/serializable/Serializable"
import {Model} from "@lib/common/model/Model"

export class ConfigurationModel<S extends Serializable<S>> extends Model<ConfigurationModel<S>> {

    configuration!: S

    constructor(model: Partial<ConfigurationModel<S>>) {
        super(model)
    }
}