import { IModel, IRuntimeTypeInformation, Id, LocalDate, LocalMonth, ModelBuilder } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { IKatalogArtikelPosition, KatalogArtikelPosition } from "@generated/de/lohn24/model/beleg/builder/KatalogArtikelPosition"
import { IFreitextArtikelPosition, FreitextArtikelPosition } from "@generated/de/lohn24/model/beleg/builder/FreitextArtikelPosition"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { Artikelkatalog } from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import { Account } from "@generated/dev/api/model/account/Account"
import { Abschluss } from "@generated/de/lohn24/model/abschluss/Abschluss"

export interface IBelegBuilder extends IModel {
	abschluss: string | null
	artikelkatalog: string | null
	debitor: string | null
	kooperationspartner: string | null
	mandant: string | null
	rechnungssteller: string | null
	sachbearbeiterAbschluss: string | null
	summeNetto: number | null
	manuell: boolean
	sammelrechnungFreigabe: boolean
	freitextArtikelPositionen: Array<IFreitextArtikelPosition>
	katalogArtikelPositionen: Array<IKatalogArtikelPosition>
	abschlussTag: LocalDate | null
	leistungsdatum: LocalDate | null
	abrechnungszeitraum: string | null
}

export class BelegBuilder extends ModelBuilder<BelegBuilder> {
	public abschluss!: Id<Abschluss> | null
	public artikelkatalog!: Id<Artikelkatalog> | null
	public debitor!: Id<Debitor> | null
	public kooperationspartner!: Id<Kooperationspartner> | null
	public mandant!: Id<Mandant> | null
	public rechnungssteller!: Id<Firma> | null
	public sachbearbeiterAbschluss!: Id<Account> | null
	public summeNetto!: number | null
	public manuell!: boolean
	public sammelrechnungFreigabe!: boolean
	public freitextArtikelPositionen!: Array<FreitextArtikelPosition>
	public katalogArtikelPositionen!: Array<KatalogArtikelPosition>
	public abschlussTag!: LocalDate | null
	public leistungsdatum!: LocalDate | null
	public abrechnungszeitraum!: LocalMonth | null

	static _types: Record<keyof IBelegBuilder, IRuntimeTypeInformation> = {
		id: { type: Id },
		abschluss: { type: Id, nullable: true },
		artikelkatalog: { type: Id, nullable: true },
		debitor: { type: Id, nullable: true },
		kooperationspartner: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		rechnungssteller: { type: Id, nullable: true },
		sachbearbeiterAbschluss: { type: Id, nullable: true },
		summeNetto: { type: Number, nullable: true },
		manuell: { type: Boolean },
		sammelrechnungFreigabe: { type: Boolean },
		freitextArtikelPositionen: { type: [FreitextArtikelPosition] },
		katalogArtikelPositionen: { type: [KatalogArtikelPosition] },
		abschlussTag: { type: LocalDate, nullable: true },
		leistungsdatum: { type: LocalDate, nullable: true },
		abrechnungszeitraum: { type: LocalMonth, nullable: true },
	}

	static props: Record<keyof IBelegBuilder, string> = {
		id: "id",
		abschluss: "abschluss",
		artikelkatalog: "artikelkatalog",
		debitor: "debitor",
		kooperationspartner: "kooperationspartner",
		mandant: "mandant",
		rechnungssteller: "rechnungssteller",
		sachbearbeiterAbschluss: "sachbearbeiterAbschluss",
		summeNetto: "summeNetto",
		manuell: "manuell",
		sammelrechnungFreigabe: "sammelrechnungFreigabe",
		freitextArtikelPositionen: "freitextArtikelPositionen",
		katalogArtikelPositionen: "katalogArtikelPositionen",
		abschlussTag: "abschlussTag",
		leistungsdatum: "leistungsdatum",
		abrechnungszeitraum: "abrechnungszeitraum",
	}

	constructor(_json: IBelegBuilder) {
		super(_json)
	}
}
