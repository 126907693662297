import {ViewModel} from "@lib/common/model/ViewModel"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {_props} from "@lib/common/_props"
import {BaseIdType, Id} from "@lib/common/model/Id"

export class KooperationspartnerViewModel extends ViewModel<KooperationspartnerViewModel> {

    static readonly props = _props<KooperationspartnerViewModel>()

    kooperationspartner: Kooperationspartner

    constructor(kooperationspartner: Kooperationspartner) {
        super()
        this.kooperationspartner = this.registerShallowReactive(kooperationspartner)
    }

    get needsDebitor() {
        if (this.kooperationspartner.keineRechnung)
            return false
        return !this.kooperationspartner.sammelrechnung?.value
    }

    get kennungName(): string {
        return `${this.kooperationspartner.kennung} // ${this.kooperationspartner.name}`
    }

    get name() {
        return this.kooperationspartner.name
    }

    set name(value: string) {
        this.kooperationspartner.name = value
    }

    get kennung() {
        return this.kooperationspartner.kennung
    }

    set kennung(value: string) {
        this.kooperationspartner.kennung = value
    }

    get sammelrechnungFestschreiben() {
        return this.kooperationspartner.sammelrechnungFestschreibenTag
    }

    set sammelrechnungFestschreiben(value: number) {
        this.kooperationspartner.sammelrechnungFestschreibenTag = value
    }

    get sammelrechnung() {
        return this.kooperationspartner.sammelrechnung?.value ?? null
    }

    set sammelrechnung(value: null | BaseIdType | Id<Kooperationspartner>) {
        if (value)
            this.kooperationspartner.sammelrechnung = new Id(value)
        else
            this.kooperationspartner.sammelrechnung = null
    }

    get sammelrechnungId() {
        return this.kooperationspartner.sammelrechnung
    }

    get keineRechnung() {
        return this.kooperationspartner.keineRechnung
    }

    set keineRechnung(value: boolean) {
        this.kooperationspartner.keineRechnung = value
    }

    get artikelkatalog() {
        return this.kooperationspartner.artikelkatalog?.value ?? null
    }

    set artikelkatalog(value: null | BaseIdType | Id<Artikelkatalog>) {
        this.kooperationspartner.artikelkatalog = value ? new Id(value) : null
    }

    get artikelkatalogId(): Id<Artikelkatalog> | null {
        return this.kooperationspartner.artikelkatalog
    }

    get debitorId(): Nullable<BaseIdType> {
        return this.kooperationspartner.debitor?.value ?? null
    }

    override equals(other: ViewModel<KooperationspartnerViewModel>): boolean {
        if (other instanceof KooperationspartnerViewModel) {
            return this.kooperationspartner.id.value === other.kooperationspartner.id.value
        }
        return false
    }
}