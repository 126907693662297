import {Bearbeitungszustand} from "@lib/common/model/Bearbeitungszustand"
import {isRef, ref, Ref, ShallowReactive, shallowReactive, toRaw, unref, watch} from "vue"
import {KeyOfWithType} from "@lib/types"
import {Serializable} from "@lib/common/serializable/Serializable"
import {_props} from "@lib/common/_props"
import {Modified} from "@lib/common/vue/Modified"

export abstract class ViewModel<M extends ViewModel<M>> {

    static label = {}
    static props = _props<InstanceType<typeof this>>() as any

    bearbeitungszustand: Bearbeitungszustand = new Bearbeitungszustand()
    private originals: {
        copy: Nullable<object | object[]>,
        reactive: Serializable<unknown> | Serializable<unknown>[]
    }[] = []

    flagDeleted() {
        this.bearbeitungszustand.deleted = true
        return this
    }

    unflagDeleted() {
        this.bearbeitungszustand.deleted = false
        return this
    }

    flagEdited() {
        this.bearbeitungszustand.edited = true
        return this
    }

    unflagEdit() {
        this.bearbeitungszustand.edited = false
        return this
    }

    flagCreated() {
        this.bearbeitungszustand.created = true
        return this
    }

    unflagCreated() {
        this.bearbeitungszustand.created = false
        return this
    }

    protected registerShallowReactive<T extends Serializable<any> | Serializable<unknown>[]>(obj: T): T {
        const copy = Array.isArray(obj) ? obj.map(it => it._original) : obj._original
        const reactiveInOriginals = this.inOriginalsOrNull(obj)
        if (reactiveInOriginals) {
            return reactiveInOriginals
        }
        const modifyWatched = Array.isArray(obj)
            ? obj.map(it => Modified.use(it).wrapped)
            : Modified.use(obj).wrapped
        const reactive = shallowReactive(modifyWatched) as ShallowReactive<T>
        this.originals.push({copy, reactive})
        return reactive as T
    }

    protected registerNestedValueShallowReactive<T extends Serializable<T>, A extends object>(obj: T, key: KeyOfWithType<T, A>) {
        const copy = obj.clone()
        const reactive_ = ref(unref(obj)) as Ref<T>
        if (this.inOriginalsOrNull(obj) === null) {
            this.originals.push({copy, reactive: reactive_.value})
        }
        const nestedReactive = shallowReactive((reactive_.value)[key] as unknown as A)
        watch(() => nestedReactive, (value) => {
            const raw = toRaw(value);
            //@ts-expect-error this line is correct
            (this.inOriginalsOrNull(obj) ?? reactive_.value)[key] = Array.isArray(raw) ? [...raw] : raw
        }, {deep: true})
        return nestedReactive
    }

    findOriginal<T extends Serializable<any> | Serializable<any>[], R>(reactive: T): Nullable<R> {
        const original = this.originals.find(it => it.reactive === reactive)
        return (original?.copy ?? null) as Nullable<R>
    }

    protected unwrapRefModel<T>(reactiveObj: Ref<T> | T): T {
        return isRef(reactiveObj) ? reactiveObj.value : reactiveObj
    }

    diffKeys(): string[] {
        return this.diffs().flatMap(it => Object.keys(it))
    }

    get changed(): boolean {
        return this.hasChanged()
    }

    /**
     * diff of editable and copy of original
     */
    protected diffs(): any {
        return this.originals.flatMap(pair => {
            if (Array.isArray(pair.reactive)) {
                return pair.reactive.map((it, index) => {
                    return it._diff(pair.copy && pair.copy[index])
                })
            }
            return pair.reactive._diff(pair.copy)
        })
    }

    private hasChanged() {
        return this.originals.any(pair => {
            if (Array.isArray(pair.reactive)) {
                return (pair.reactive.length !== (pair.copy as Array<unknown> | null)?.length) ||
                    pair.reactive.any((it) => {
                        return it[Modified.modified].value
                    })
            }
            return pair.reactive[Modified.modified].value
        })
    }

    /**
     *  Equals bezieht sich auf die Entität. Deren Werte können sich unterscheiden.
     */
    equals(other: ViewModel<M>): boolean {
        if (Object.getPrototypeOf(this) === Object.getPrototypeOf(other)) {
            return (this.originals === other.originals)
        }
        return false
    }

    protected getValueOrNull<T>(value: T) {
        return value ? value : null
    }

    private inOriginalsOrNull<T extends Serializable<T> | Serializable<unknown>[]>(obj: T): T | null {
        return (this.originals.find(it => toRaw(it.reactive) === toRaw(obj))?.reactive ?? null) as T | null
    }
}