import {Repository} from "@lib/common/repository/Repository"
import {TicketArtikel} from "@generated/de/lohn24/model/ticket/artikel/TicketArtikel"
import {Ticket} from "@generated/de/lohn24/model/ticket/Ticket"

export class TicketArtikelRepository extends Repository<TicketArtikel> {

    constructor() {
        super(TicketArtikel, "ticketartikel")
    }

    ticket = this.buildRelation(Ticket, "ticket")
}