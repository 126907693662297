import {DialogController} from "@lib/common/controller/DialogController"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {computed, shallowReactive, ShallowRef} from "vue"
import {MandantSummaryViewModel} from "@intranet/view/mandant/summary/MandantSummaryViewModel"
import {Resource} from "@lib/model/Resource"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {MandantEditDialog} from "@intranet/view/mandant/edit/MandantEditDialog"
import {Logger} from "@lib/common/logger/Logger"

export class MandantSummaryDialogController extends DialogController {
    title = computed(() => this.viewModel.titel)
    readonly mandant: Mandant

    constructor(mandant: Mandant) {
        super()
        this.mandant = shallowReactive(mandant)
    }

    get viewModel() {
        return new MandantSummaryViewModel(this.mandant)
    }

    async anschrift(ref: ShallowRef<Anschrift | null>): Promise<Anschrift> {
        const result = await Resource.mandant.anschrift.first(this.mandant.id)
        ref.value = result
        return result
    }

    async kontakte(ref: ShallowRef<Kontakt[] | null>): Promise<Kontakt[]> {
        const result = await Resource.organisation.kontakt.all(this.mandant.organisation)
        ref.value = result

        return result
    }

    async debitor(ref: ShallowRef<Debitor | null>): Promise<Debitor | null> {
        const result = await Resource.mandant.debitor.firstOrNull(this.mandant.id)
        ref.value = result
        return result
    }

    async organisationMandanten(ref: ShallowRef<Mandant[] | null>): Promise<Mandant[] | null> {
        const result = (await Resource.organisation.mandant.all(this.mandant.organisation))
            .filter(it => it.id.value !== this.mandant.id.value)
        ref.value = result
        return result
    }

    mergeModel(mandant: Mandant) {
        this.mandant.merge(mandant)
    }

    async edit() {
        return await MandantEditDialog.show(this.mandant.id)
    }

    protected override async onClose(): Promise<void> {
        return super.onClose()
    }
}