import {auth} from "@lib/common/Authentication"
import {Id} from "@lib/common/model/Id"
import {Account} from "@generated/dev/api/model/account/Account"
import {newsUngelesenListController} from "@intranet/view/news/ungelesen/NewsUngelesenListController"
import {IntervalPlugin} from "@lib/common/vue/plugin/IntervalPlugin"
import {Plugin} from "@lib/common/vue/plugin/Plugin"
import {App} from "vue"

export class PollNewsPlugin implements Plugin<{ interval: number }> {
    private intervalPlugin = new IntervalPlugin<Id<Account>>()

    install(app: App, options: { interval: number }) {
        this.intervalPlugin.install(app, {
            uniqueName: "PollNewsPlugin",
            interval: options.interval,
            methods: [
                async () => {
                    await newsUngelesenListController.pollNews()
                },
            ],
            startWithWatcher: {
                source: () => auth.mitarbeiter.value.id,
                triggerRule: (id?: Id<Account> | null) => id !== null && id !== undefined,
            },
        })
    }
}