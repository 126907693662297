import { IRuntimeTypeInformation, Id, LocalMonth, Serializable } from "@lib/index"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { IEinzelrechnungListElement, EinzelrechnungListElement } from "@generated/de/lohn24/model/beleg/builder/EinzelrechnungListElement"

export interface ISammelrechnungListElement {
	rechnungssteller: string
	sammelrechnung: string
	belegBuilder: Array<IEinzelrechnungListElement>
	leistungszeitraum: string
	aktiveMandanten: number
	kennung: string
	name: string
}

export class SammelrechnungListElement extends Serializable<SammelrechnungListElement> {
	public rechnungssteller!: Id<Firma>
	public sammelrechnung!: Id<Kooperationspartner>
	public belegBuilder!: Array<EinzelrechnungListElement>
	public leistungszeitraum!: LocalMonth
	public aktiveMandanten!: number
	public kennung!: string
	public name!: string

	static _types: Record<keyof ISammelrechnungListElement, IRuntimeTypeInformation> = {
		rechnungssteller: { type: Id },
		sammelrechnung: { type: Id },
		belegBuilder: { type: [EinzelrechnungListElement] },
		leistungszeitraum: { type: LocalMonth },
		aktiveMandanten: { type: Number },
		kennung: { type: String },
		name: { type: String },
	}

	static props: Record<keyof ISammelrechnungListElement, string> = {
		rechnungssteller: "rechnungssteller",
		sammelrechnung: "sammelrechnung",
		belegBuilder: "belegBuilder",
		leistungszeitraum: "leistungszeitraum",
		aktiveMandanten: "aktiveMandanten",
		kennung: "kennung",
		name: "name",
	}

	constructor(_json: ISammelrechnungListElement) {
		super(_json)
	}
}
