import {DialogController} from "@lib/common/controller/DialogController"
import {nextTick, ref, Ref, watch, WatchStopHandle} from "vue"
import {Resource} from "@lib/model/Resource"
import {debounce} from "lodash"
import {SearchResult, SearchResultTopic} from "@lib/view/search/SearchResult"
import {MandantSummaryDialog} from "@intranet/view/mandant/summary/MandantSummaryDialog"
import {
    KooperationspartnerSummaryDialog,
} from "@intranet/view/kooperationspartner/summary/KooperationspartnerSummaryDialog"
import {DebitorSummaryDialog} from "@intranet/view/debitor/summary/DebitorSummaryDialog"
import {raf} from "@lib/common/Functions"

export class SearchDialogController extends DialogController {
    title: Ref<string> = ref("Suche")

    suchtext: Ref<string> = ref("")
    topic: Ref<string | null> = ref(SearchResultTopic.Mandant)
    topics = ref([
        SearchResultTopic.Mandant,
        SearchResultTopic.Kontakt,
        SearchResultTopic.Debitor,
        SearchResultTopic.Arbeitnehmer,
        SearchResultTopic.Anschrift,
    ])
    loading: Ref<boolean> = ref(false)
    results: Ref<{ type: string, name: string, description: string }[]> = ref([])
    private watching: WatchStopHandle | null = null

    protected onClose(): Promise<void> {
        return super.onClose()
    }

    override useController(): void {
        super.useController()
        this.watch()
    }

    private watch() {
        this.unwatch()
        this.watching = watch([this.suchtext, this.topic], (current, before) => this.watchDebounced(current, before))
    }

    private unwatch() {
        if (this.watching !== null)
            this.watching()
    }

    private debitorTriggerConstraint(test: string) {
        return test.length >= 3 + (2 * +(/^18.*/.test(test)))
    }

    private passesTopicTriggerConstraint(): boolean {
        switch (this.topic.value) {
            case SearchResultTopic.Debitor:
                return this.debitorTriggerConstraint(this.suchtext.value)
            case null:
                return this.debitorTriggerConstraint(this.suchtext.value)
            default:
                return this.suchtext.value.length >= 3
        }
    }

    async fetch() {
        if (this.suchtext.value.isEmpty())
            return this.results.value = []
        const results = await Resource.mandant.search(this.suchtext.value, this.topic.value)
        return this.results.value = results
    }

    private async watchHandler([text, topic], [textBefore, topicBefore]) {
        if (text != textBefore && text.isEmpty()) {
            this.results.value = []
            return
        }

        if (!this.passesTopicTriggerConstraint())
            return
        if (topic != topicBefore) {
            this.results.value = []
        }
        this.loading.value = true
        await nextTick()
        await raf()
        await raf()
        if (text.isEmpty())
            this.results.value = []
        else
            await this.fetch()
        this.loading.value = false
    }

    watchDebounced = debounce(
        (current: [string, string | null], before: [string, string | null]) => this.watchHandler(current, before),
        1000,
        {
            leading: false,
            trailing: true,
        },
    )

    async open(item: Exclude<SearchResult<SearchResultTopic>, "model">) {
        switch (item.topic) {

            case SearchResultTopic.Arbeitnehmer: {
                break
            }

            case SearchResultTopic.Mandant: {
                const result = item as SearchResult<SearchResultTopic.Mandant>
                await MandantSummaryDialog.show(result.model.mandant!.id)
                break
            }

            case SearchResultTopic.Kooperationspartner: {
                const result = item as SearchResult<SearchResultTopic.Kooperationspartner>
                await KooperationspartnerSummaryDialog.show(result.model.Kooperationspartner.id)
                break
            }

            case SearchResultTopic.Debitor: {
                const result = item as SearchResult<SearchResultTopic.Debitor>
                await DebitorSummaryDialog.show(result.model.debitor.id)
                break
            }

            case SearchResultTopic.Kontakt: {
                const result = item as SearchResult<SearchResultTopic.Kontakt>
                if (result.model.kooperationspartner) {
                    await KooperationspartnerSummaryDialog.show(result.model.kooperationspartner.id)
                    break
                }
                const mandant = await Resource.organisation.mandant.firstOrNull(result.model.organisation.id)
                if (mandant)
                    await MandantSummaryDialog.show(mandant.id)
                break
            }
        }
    }
}