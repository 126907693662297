import { IRuntimeTypeInformation, Id, LocalDateTime, LocalMonth, Serializable } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { IBelegausgangListSachbearbeiterAbschlussMandant, BelegausgangListSachbearbeiterAbschlussMandant } from "@generated/de/lohn24/model/beleg/BelegausgangListSachbearbeiterAbschlussMandant"
import { BelegArt } from "@generated/de/lohn24/model/beleg/BelegArt"
import { Beleg } from "@generated/de/lohn24/model/beleg/Beleg"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IBelegausgangListElement {
	id: string
	debitor: string
	firma: string
	kooperationspartner: string | null
	mandant: string | null
	rechnungsbearbeiter: string
	art: BelegArt
	korrigiert: boolean
	sammelrechnung: boolean
	sepa: boolean
	mailCc: Array<string>
	mailTo: Array<string>
	sachbearbeiterAbschlussMandant: Array<IBelegausgangListSachbearbeiterAbschlussMandant>
	festgeschrieben: LocalDateTime
	mailSent: LocalDateTime | null
	leistungszeitraum: string
	summeNetto: number
	summeUmsatzsteuer: number
	belegnummer: string
	dateiname: string
	kennung: string
	konto: string
	name: string
}

export class BelegausgangListElement extends Serializable<BelegausgangListElement> {
	public id!: Id<Beleg>
	public debitor!: Id<Debitor>
	public firma!: Id<Firma>
	public kooperationspartner!: Id<Kooperationspartner> | null
	public mandant!: Id<Mandant> | null
	public rechnungsbearbeiter!: Id<Account>
	public art!: BelegArt
	public korrigiert!: boolean
	public sammelrechnung!: boolean
	public sepa!: boolean
	public mailCc!: Array<string>
	public mailTo!: Array<string>
	public sachbearbeiterAbschlussMandant!: Array<BelegausgangListSachbearbeiterAbschlussMandant>
	public festgeschrieben!: LocalDateTime
	public mailSent!: LocalDateTime | null
	public leistungszeitraum!: LocalMonth
	public summeNetto!: number
	public summeUmsatzsteuer!: number
	public belegnummer!: string
	public dateiname!: string
	public kennung!: string
	public konto!: string
	public name!: string

	static _types: Record<keyof IBelegausgangListElement, IRuntimeTypeInformation> = {
		id: { type: Id },
		debitor: { type: Id },
		firma: { type: Id },
		kooperationspartner: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		rechnungsbearbeiter: { type: Id },
		art: { type: BelegArt },
		korrigiert: { type: Boolean },
		sammelrechnung: { type: Boolean },
		sepa: { type: Boolean },
		mailCc: { type: [String] },
		mailTo: { type: [String] },
		sachbearbeiterAbschlussMandant: { type: [BelegausgangListSachbearbeiterAbschlussMandant] },
		festgeschrieben: { type: LocalDateTime },
		mailSent: { type: LocalDateTime, nullable: true },
		leistungszeitraum: { type: LocalMonth },
		summeNetto: { type: Number },
		summeUmsatzsteuer: { type: Number },
		belegnummer: { type: String },
		dateiname: { type: String },
		kennung: { type: String },
		konto: { type: String },
		name: { type: String },
	}

	static props: Record<keyof IBelegausgangListElement, string> = {
		id: "id",
		debitor: "debitor",
		firma: "firma",
		kooperationspartner: "kooperationspartner",
		mandant: "mandant",
		rechnungsbearbeiter: "rechnungsbearbeiter",
		art: "art",
		korrigiert: "korrigiert",
		sammelrechnung: "sammelrechnung",
		sepa: "sepa",
		mailCc: "mailCc",
		mailTo: "mailTo",
		sachbearbeiterAbschlussMandant: "sachbearbeiterAbschlussMandant",
		festgeschrieben: "festgeschrieben",
		mailSent: "mailSent",
		leistungszeitraum: "leistungszeitraum",
		summeNetto: "summeNetto",
		summeUmsatzsteuer: "summeUmsatzsteuer",
		belegnummer: "belegnummer",
		dateiname: "dateiname",
		kennung: "kennung",
		konto: "konto",
		name: "name",
	}

	constructor(_json: IBelegausgangListElement) {
		super(_json)
	}
}
