import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {DebitorSummaryDialogController} from "@intranet/view/debitor/summary/DebitorSummaryDialogController"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Id} from "@lib/common/model/Id"
import {Resource} from "@lib/model/Resource"
import DebitorSummaryDialogView from "@intranet/view/debitor/summary/DebitorSummaryDialogView.vue"

export class DebitorSummaryDialog extends ComponentRenderer<typeof DebitorSummaryDialogView> {
    constructor(controller: DebitorSummaryDialogController, done: () => void) {
        super(DebitorSummaryDialogView, {
            controller: controller,
            done: done,
        })
    }

    static async show(id: Id<Debitor>): Promise<void> {
        const debitor = await Resource.debitor.find(id)
        const controller = new DebitorSummaryDialogController(debitor)
        return new Promise(resolve => {
            new DebitorSummaryDialog(controller, () => resolve())
        })
    }
}