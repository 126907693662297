import {ViewModel} from "@lib/common/model/ViewModel"
import {BelegBuilder} from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import {MandantArtikel} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikel"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {Resource, ResourceCache} from "@lib/model/Resource"
import {KatalogPositionViewModel} from "@intranet/view/rechnung/builder/KatalogPositionViewModel"
import {LocalMonth} from "@lib/common/LocalMonth"
import {assertNotNull} from "@lib/common/Functions"
import {FreitextBelegPositionViewModel} from "@intranet/view/rechnung/builder/FreitextBelegPositionViewModel"
import {Formatter} from "@lib/common/Formatter"
import {Id} from "@lib/common/model/Id"
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"
import {Pair} from "@lib/common/repository/RepositoryHasManyWithRelation"

export class BelegBuilderViewModel extends ViewModel<BelegBuilderViewModel> {

    readonly builder: BelegBuilder
    readonly artikel: Pair<ArtikelkatalogArtikel, Artikel>[]
    readonly mandantArtikel: MandantArtikel[]

    constructor(builder: BelegBuilder, artikel: Pair<ArtikelkatalogArtikel, Artikel>[], mandantArtikel: MandantArtikel[]) {
        super()
        this.builder = this.registerShallowReactive(builder)
        this.artikel = artikel
        this.mandantArtikel = mandantArtikel
    }

    get freitextArtikelPositionen() {
        return this.builder.freitextArtikelPositionen.map(it => new FreitextBelegPositionViewModel(it))
    }

    set freitextArtikelPositionen(value) {
        this.builder.freitextArtikelPositionen = value.map(it => it.freitextArtikelPosition)
    }

    get katalogArtikelPositionen(): Array<KatalogPositionViewModel> {
        const builder = this.builder
        const katalogArtikelPositionen = builder.katalogArtikelPositionen
        return katalogArtikelPositionen.map(it => {
            return new KatalogPositionViewModel(
                it,
                assertNotNull(this.artikel.find(artikel => {
                    return it.artikel.value === artikel.first.id.value
                })),
                this.mandantArtikel.find(mandantArtikel => it.artikel.value === mandantArtikel.artikel?.value),
            )
        })
    }

    set katalogArtikelPositionen(value: KatalogPositionViewModel[]) {
        this.builder.katalogArtikelPositionen = value.map(it => it.katalogArtikelPosition)
    }

    get zeitraum() {
        return this.builder.abrechnungszeitraum ?? this.leistungszeitraum
    }

    get leistungszeitraum() {
        return LocalMonth.fromLocalDate(assertNotNull(this.builder.leistungsdatum))
    }

    get zeitraumMMYYYY() {
        return Formatter.zeit.zeitraum(this.builder.abrechnungszeitraum ?? this.builder.leistungsdatum)
    }

    get isSammelrechnung() {
        const mandant = this.builder.mandant?.value
        return mandant
            ? ResourceCache.mandant.record(it => it.sammelrechnung).value[mandant] !== null
            : false
    }

    async freigeben(value: boolean = true) {
        this.builder.sammelrechnungFreigabe = value
        return Resource.beleg.builder.update(this.builder)
    }

    async festschreiben() {
        if (this.isSammelrechnung) {
            return Resource.beleg.sammelrechnungFestschreiben(
                Id.required(this.builder.kooperationspartner),
                LocalMonth.fromLocalDate(assertNotNull(this.builder.leistungsdatum)),
            )
        } else {
            return Resource.beleg.einzelrechnungFestschreiben(
                this.builder.id,
            )
        }
    }

    async verwerfen() {
        return Resource.beleg.builder.delete(this.builder.id)
    }
}