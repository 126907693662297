<script lang="ts" setup>
import {computed} from "vue"
import IBAN from "iban"

const props = defineProps<{ modelValue: string }>()
const emits = defineEmits<{ "update:modelValue": [value: string] }>()

const iban = computed<string>({
    get() {
        return IBAN.printFormat(props.modelValue || "")
    },
    set(value) {
        const unformatted = value.replace(RegExp(" ", "g"), "")
        emits("update:modelValue", unformatted)
    },
})
</script>

<template>
  <el-input v-model="iban" v-bind="$attrs" />
</template>

<style scoped>

</style>