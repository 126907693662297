import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"
import { KontaktFunktion } from "@generated/de/lohn24/model/kontakt/KontaktFunktion"
import { KontaktAnrede } from "@generated/de/lohn24/model/kontakt/KontaktAnrede"

export interface IKontakt extends IModel {
	zammadExport: LocalDateTime | null
	anrede: KontaktAnrede | null
	funktion: KontaktFunktion
	order: number
	zammadId: number | null
	email: string | null
	name: string | null
	telefon: string | null
	telefonMobil: string | null
	titel: string | null
	vorname: string | null
}

export class Kontakt extends Model<Kontakt> {
	public zammadExport!: LocalDateTime | null
	public anrede!: KontaktAnrede | null
	public funktion!: KontaktFunktion
	public order!: number
	public zammadId!: number | null
	public email!: string | null
	public name!: string | null
	public telefon!: string | null
	public telefonMobil!: string | null
	public titel!: string | null
	public vorname!: string | null

	static _types: Record<keyof IKontakt, IRuntimeTypeInformation> = {
		id: { type: Id },
		zammadExport: { type: LocalDateTime, nullable: true },
		anrede: { type: KontaktAnrede, nullable: true },
		funktion: { type: KontaktFunktion },
		order: { type: Number },
		zammadId: { type: Number, nullable: true },
		email: { type: String, nullable: true },
		name: { type: String, nullable: true },
		telefon: { type: String, nullable: true },
		telefonMobil: { type: String, nullable: true },
		titel: { type: String, nullable: true },
		vorname: { type: String, nullable: true },
	}

	static props: Record<keyof IKontakt, string> = {
		id: "id",
		zammadExport: "zammadExport",
		anrede: "anrede",
		funktion: "funktion",
		order: "order",
		zammadId: "zammadId",
		email: "email",
		name: "name",
		telefon: "telefon",
		telefonMobil: "telefonMobil",
		titel: "titel",
		vorname: "vorname",
	}

	constructor(_json: IKontakt) {
		super(_json)
	}
}
