import {ViewModel} from "@lib/common/model/ViewModel"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {_props} from "@lib/common/_props"

export class OrganisationViewModel extends ViewModel<OrganisationViewModel> {

    static properties = _props<InstanceType<typeof this>>()
    static label = {
        name: "Organisation",
        domain: "Domain",
    }
    organisation: Organisation

    constructor(organisation: Organisation) {
        super()
        this.organisation = this.registerShallowReactive(organisation)
    }

    get name(): string {
        return this.organisation.name
    }

    get domain() {
        return this.organisation.domain ?? ""
    }

    set domain(value: string) {
        this.organisation.domain = value ? value : null
    }
}