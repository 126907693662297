<script lang="ts" setup>

import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {computed, nextTick, watch} from "vue"
import {Validator} from "@lib/common/Validator"
import {raf} from "@lib/common/Functions"
import {Lohnsteueranmeldeperiode} from "@lib/model/abrechnung/Lohnsteueranmeldeperiode"
import {Beitragsnachweis} from "@lib/model/abrechnung/Beitragsnachweis"
import AccountMitarbeiterOptions from "@intranet/view/mitarbeiter/AccountMitarbeiterOptions.vue"
import {
    BereitstellungOptionZahlungen,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungen"
import {MandantLohnabrechnungViewModel} from "@intranet/view/mandant/component/MandantLohnabrechnungViewModel"
import {
    BereitstellungOptionZahlungenArt,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungenArt"
import {BereitstellungOptionLohn} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionLohn"
import EnumCheckboxButtonGroup from "@lib/view/formitem/EnumCheckboxButtonGroup.vue"

export interface MandantLohnabrechnungProps {
    mandant: Mandant
    path?: string
    abrechnungszeitraum?: boolean
}

const emits = defineEmits<{
    "update:modelValue": []
    "validate": [path?: string]
}>()
const props = withDefaults(
    defineProps<MandantLohnabrechnungProps>(),
    {
        path: "mandant",
    },
)

const viewModel = computed(() => new MandantLohnabrechnungViewModel(props.mandant))

watch(
    () => viewModel.value.abschlusstag,
    () => {
        emits("validate")
    },
    {
        immediate: false,
    },
)

function prop(path: string) {
    return `${props.path}.${path}`
}

function error(propName: string) {
    return Validator.getErrorText(prop(propName))
}

async function validate(path?: string) {
    // watcher in ViewModel did not trigger reactive jet, wait for it
    await nextTick()
    await raf()
    emits("validate", path)
}

</script>

<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <el-row>
        <el-col :span="24">
          <h3 class="heading">Abrechnung</h3>
          <el-form-item
                  :error="error(MandantLohnabrechnungViewModel.properties.sachbearbeiter)"
                  :label="MandantLohnabrechnungViewModel.label.sachbearbeiter"
                  :prop="prop(MandantLohnabrechnungViewModel.properties.sachbearbeiter)"
          >
            <account-mitarbeiter-options
                    v-model="viewModel.sachbearbeiter"
                    clearable
                    @change="() => validate()"
            />
          </el-form-item>
          <el-form-item
                  :label="MandantLohnabrechnungViewModel.label.abrechnungssystem"
                  :error="error(MandantLohnabrechnungViewModel.properties.abrechnungssystem)"
          >
            <el-radio-group
                    v-model="viewModel.abrechnungssystem"
                    :prop="prop(MandantLohnabrechnungViewModel.properties.abrechnungssystem)"
                    data-cy="mandant-lohnabrechnung-abrechnungssystem"
                    @change="validate"
            >
              <el-radio-button label="ADDISON">ADDISON</el-radio-button>
              <el-radio-button label="DATEV">DATEV</el-radio-button>
              <el-radio-button label="AGENDA">Agenda</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
                  :error="error(MandantLohnabrechnungViewModel.properties.abschlusstag)"
                  :label="MandantLohnabrechnungViewModel.label.abschlusstag"
          >
            <el-radio-group
                    v-model="viewModel.abschlusstag"
                    :class="MandantLohnabrechnungViewModel.properties.abschlusstag"
                    data-cy="mandant-lohnabrechnung-abschlusstag"
                    @change="validate"
            >
              <el-radio-button :label="10">10.</el-radio-button>
              <el-radio-button :label="15">15.</el-radio-button>
              <el-radio-button :label="20">20.</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
                  :error="error(MandantLohnabrechnungViewModel.properties.lohnsteueranmeldeperiode)"
                  :label="MandantLohnabrechnungViewModel.label.lohnsteueranmeldeperiode"
          >
            <el-radio-group
                    v-model="viewModel.lohnsteueranmeldeperiode"
                    data-cy="mandant-lohnabrechnung-lohnsteueranmeldeperiode"
                    @change="validate"
            >
              <el-radio-button :label="Lohnsteueranmeldeperiode.MONAT">monatlich</el-radio-button>
              <el-radio-button :label="Lohnsteueranmeldeperiode.QUARTAL">quartalsweise</el-radio-button>
              <el-radio-button :label="Lohnsteueranmeldeperiode.JAHR">jährlich</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item
                  :error="error(MandantLohnabrechnungViewModel.properties.beitragsnachweis)"
                  :label="MandantLohnabrechnungViewModel.label.beitragsnachweis"
                  :prop="prop(MandantLohnabrechnungViewModel.properties.beitragsnachweis)"
          >
            <el-radio-group
                    v-model="viewModel.beitragsnachweis"
                    data-cy="mandant-lohnabrechnung-beitragsnachweis"
            >
              <el-radio-button :label="Beitragsnachweis.ECHTABRECHNUNG">Echtabrechnung</el-radio-button>
              <el-radio-button :label="Beitragsnachweis.SCHAETZUNG">Schätzung</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item>
            <el-checkbox
                    v-model="viewModel.beitragAbbuchung"
                    :error="error(MandantLohnabrechnungViewModel.properties.beitragAbbuchung)"
                    :label="MandantLohnabrechnungViewModel.label.beitragAbbuchung"
                    :prop="prop(MandantLohnabrechnungViewModel.properties.beitragAbbuchung)"
                    data-cy="mandant-lohnabrechnung-beitragAbbuchung"
            >

            </el-checkbox>
            <el-checkbox
                    v-model="viewModel.meldetSichVorAbschluss"
                    :error="error(MandantLohnabrechnungViewModel.properties.meldetSichVorAbschluss)"
                    :label="MandantLohnabrechnungViewModel.label.meldetSichVorAbschluss"
                    :prop="prop(MandantLohnabrechnungViewModel.properties.meldetSichVorAbschluss)"
                    data-cy="mandant-lohnabrechnung-meldetSichVorAbschluss"
            ></el-checkbox>
          </el-form-item>
          <el-form-item
                  :error="error(MandantLohnabrechnungViewModel.properties.abrechnungszeitraum)"
                  :label="MandantLohnabrechnungViewModel.label.abrechnungszeitraum"
                  :prop="prop(MandantLohnabrechnungViewModel.properties.abrechnungszeitraum)"
                  data-cy="mandant-lohnabrechnung-abrechnungszeitraum"
          >
            <el-date-picker
                    v-model="viewModel.abrechnungszeitraum"
                    :disabled="!props.abrechnungszeitraum"
                    format="MM / YYYY"
                    type="month"
                    @change="validate(props.path)"
            >
            </el-date-picker>
          </el-form-item>

        </el-col>
      </el-row>
    </el-col>
    <el-col :lg="12" :span="24">
      <h3 class="heading">Datenbereitstellung</h3>
      <el-form-item
              :error="error(MandantLohnabrechnungViewModel.properties.bereitstellungZahlungenArt)"
              :prop="MandantLohnabrechnungViewModel.properties.bereitstellungZahlungenArt"
              label="Zahlungsdaten / Art"
      >
        <enum-checkbox-button-group
                v-model="viewModel.bereitstellungZahlungenArt"
                :label="{
                    [BereitstellungOptionZahlungenArt.TRAEGER]: 'Überweisungsträger',
                    [BereitstellungOptionZahlungenArt.EBICS]: 'Ebics',
                    [BereitstellungOptionZahlungenArt.LISTE]: 'Zahlungsliste',
                    [BereitstellungOptionZahlungenArt.DATEI]: 'Zahlungsdatei',
                }"
                :options="Object.values(BereitstellungOptionZahlungenArt)"
                @change="validate(props.path)"
        />
      </el-form-item>
      <el-form-item
              :error="error(MandantLohnabrechnungViewModel.properties.bereitstellungZahlungen)"
              :prop="MandantLohnabrechnungViewModel.properties.bereitstellungZahlungen"
              label="Zahlungsdaten / Ort"
      >
        <enum-checkbox-button-group
                v-model="viewModel.bereitstellungZahlungen"
                :label="{
                    [BereitstellungOptionZahlungen.CLOUD]: 'LOHN24 Cloud',
                    [BereitstellungOptionZahlungen.POST]: 'Post',
                    [BereitstellungOptionZahlungen.FAX]: 'Fax',
                    [BereitstellungOptionZahlungen.SYSTEM]: viewModel.abrechnungssystemLabel,
                    [BereitstellungOptionZahlungen.PMC]: 'PMC',
                }"
                :options="Object.values(BereitstellungOptionZahlungen)"
                @change="validate(props.path)"
        />
      </el-form-item>
      <el-form-item
              :error="error(MandantLohnabrechnungViewModel.properties.bereitstellungLohn)"
              :prop="MandantLohnabrechnungViewModel.properties.bereitstellungLohn"
              label="Lohnabrechnungen"
      >
        <enum-checkbox-button-group
                v-model="viewModel.bereitstellungLohn"
                :label="{
                    [BereitstellungOptionLohn.CLOUD]: 'LOHN24 Cloud',
                    [BereitstellungOptionLohn.POST]: 'Post',
                    [BereitstellungOptionLohn.FAX]: 'Fax',
                    [BereitstellungOptionLohn.SYSTEM]: viewModel.abrechnungssystemLabel,
                    [BereitstellungOptionLohn.PMC]: 'PMC',
                }"
                :options="Object.values(BereitstellungOptionLohn)"
                @change="validate(props.path)"
        />
      </el-form-item>
    </el-col>
  </el-row>
  <br>
  <el-row :gutter="40">
    <el-col :md="12" :span="12">
      <h3>Bearbeitungshinweise</h3>
      <el-form-item label-width="0">
        <el-input
                v-model="viewModel.bearbeitungshinweis"
                rows="12"
                type="textarea"
        />
      </el-form-item>
    </el-col>
    <el-col :md="12" :span="12">
      <h3>Versandhinweise</h3>
      <el-form-item label-width="0">
        <el-input v-model="viewModel.versandhinweis" rows="12" type="textarea" />
      </el-form-item>
    </el-col>
  </el-row>
  <br>
  <el-row :gutter="40">
    <el-col :md="12" :span="12">
      <h3>Abschlusshinweise</h3>
      <el-form-item label-width="0">
        <el-input
                v-model="viewModel.abschlusshinweis"
                rows="12"
                type="textarea"
        />
      </el-form-item>
    </el-col>
  </el-row>
</template>