export class Bearbeitungszustand {
    constructor(
        public deleted: boolean = false,
        public edited: boolean = false,
        public created: boolean = false,
    ) {
    }

    clone(): Bearbeitungszustand {
        return new Bearbeitungszustand(
            this.deleted,
            this.edited,
            this.created,
        )
    }

    flagDeleted() {
        this.deleted = true
        return this
    }

    unflagDeleted() {
        this.deleted = false
        return this
    }

    flagEdited() {
        this.edited = true
        return this
    }

    unflagEdit() {
        this.edited = false
        return this
    }

    flagCreated() {
        this.created = true
        return this
    }

    unflagCreated() {
        this.created = false
        return this
    }
}