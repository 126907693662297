<script setup lang="ts">
import {computed} from "vue"

const props = withDefaults(
    defineProps<{
        letters: string
        color?: string
        radius?: number
        background?: string
    }>(),
    {
        color: "#fff",
        background: "#000",
        radius: 20,
    },
)

const style = computed(() => {
    return {
        color: props.color,
        background: `color-mix(in srgb, var(--el-color-primary) 60%, ${props.background})`,
        width: `${2 * props.radius}px`,
        height: `${2 * props.radius}px`,
        'font-size': `${props.radius * 0.6}px`
    }
})

</script>

<template>
  <div :style="style" class="circle">
    {{ props.letters.toLocaleUpperCase() }}
  </div>
</template>

<style scoped lang="scss">
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99999px;
  opacity: 0.9;

}
</style>