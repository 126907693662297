import {ViewModel} from "@lib/common/model/ViewModel"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"

export class KooperationspartnerEditDialogViewModel extends ViewModel<KooperationspartnerEditDialogViewModel> {

    kooperationspartner: Kooperationspartner

    constructor(kooperationspatner: Kooperationspartner) {
        super()
        this.kooperationspartner = this.registerShallowReactive(kooperationspatner)
    }

    get needsDebitor() {
        if (this.kooperationspartner.keineRechnung)
            return false

        if (!this.kooperationspartner.sammelrechnung?.value)
            return true

        return this.kooperationspartner.sammelrechnung.value === this.kooperationspartner.id.value
    }

    get kooperationspartnerId() {
        return this.kooperationspartner.id
    }

    get titel() {
        return `${this.kooperationspartner.kennung} // ${this.kooperationspartner.name}`
    }

}