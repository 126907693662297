import {NavigationGuardNext, RouteLocationNormalized, Router} from "vue-router"

export interface MiddlewareContext {
    router: Router;
    from: RouteLocationNormalized;
    to: RouteLocationNormalized;
    next: NavigationGuardNext;
}

export type Middleware = (context: MiddlewareContext) => void;

export function nextMiddlewareFactory(
    context: MiddlewareContext,
    middleware: Array<Middleware>,
    index = 1,
): NavigationGuardNext {
    const currentMiddleware = middleware[index]
    if (!currentMiddleware) return context.next

    return ((args): void => {
        if (args !== undefined) {
            // routing ends chain
            context.next(args)
        } else {
            const nextMiddleware = nextMiddlewareFactory(context, middleware, index + 1)
            currentMiddleware({...context, next: nextMiddleware})
        }
    }) as NavigationGuardNext
}
