<script generic="M extends Model<M>, B extends ModelBuilder<B>" lang="ts" setup>
import {computed, shallowRef} from "vue"
import {Id} from "@lib/common/model/Id"
import {BuilderRepository} from "@lib/common/repository/BuilderRepository"
import {ModelBuilder} from "@lib/common/model/ModelBuilder"
import {Model} from "@lib/common/model/Model"

export interface BuilderOptionsProps {
    modelValue?: Id<M> | null,
    repository: BuilderRepository<B, M>,
    disabled?: boolean,
    placeholder?: string,
    width?: string,
    optionLabel?: (m: B) => string,
    optionFilter?: (m: B) => boolean,
    optionSort?: (a: B, b: B) => 1 | 0 | -1,
    selected?: Id<M> | Id<M>[],
}

const props = withDefaults(
    defineProps<BuilderOptionsProps>(),
    {
        modelValue: () => OptionalId.none(),
        placeholder: () => "Neu",
        default: () => "100%",

    },
)
const emits = defineEmits<{
    "update:modelValue": [id: Id<M> | null],
    "change": [builder: B | null | undefined],
    "create": [],
    "update:selected": [items: Id<M>[]],
}>()
const id = computed<number | null>({
    get: () => {
        return props.modelValue?.valueOrNull ?? null
    },
    set: (value: number | null | "") => {
        const id_ = value === "" ? null : value
        const id = id_ ? new Id(id_) : null
        updateSelectedList(id)
        emits("update:modelValue", id)
        emitChange(id)
    },
})

const builderItems = shallowRef<B[]>([])

async function pullBuilderItems() {
    builderItems.value = await props.repository.all()
}

function emitChange(id: Id<M> | null) {
    const result = id !== null ? builderItems.value.find(it => it.id.value === id.value) : null
    emits("change", result)
}

function updateSelectedList(value: Id<M> | null) {
    if (!Array.isArray(props.selected)) return
    const newSelected = props.selected.filter((i) => i !== props.modelValue)
    if (value !== null) newSelected.push(value)
    emits("update:selected", newSelected)
}

function disableOption(item: { label: string; value: number }): boolean {
    return Array.isArray(props.selected)
        ? props.selected.findIndex(it => it.value === item.value) >= 0
        : false
}

const options = computed(() => {
        return builderItems
            .value
            .filter(it => {
                return props.optionFilter ? props.optionFilter(it) : true
            })
            .sort((a, b) => props.optionSort ? props.optionSort(a, b) : 0)
            .map(it => {
                return {value: it.id.value, label: props.optionLabel ? props.optionLabel(it) : (it as any).name}
            })
            .sort((a, b) => props.optionSort ? 0 : (a.label > b.label ? 1 : a.label == b.label ? 0 : -1))
    },
)

pullBuilderItems()

</script>

<template>
  <el-button-group :style="`width: ${width}`" class="options" @contextmenu.prevent.stop>
    <el-select
            v-model="id"
            :disabled="disabled"
            :placeholder="placeholder"
            clearable filterable @contextmenu.prevent
    >
      <el-option
              v-for="item in options"
              :key="item.value"
              :disabled="disableOption(item)"
              :label="item.label"
              :value="item.value"
              @contextmenu.prevent
      />
    </el-select>
  </el-button-group>
</template>

<style lang="scss">
.options.el-button-group > .button-grouped {

  float: left;
  position: relative;

  &:first-child .el-input__wrapper {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right-width: 0 !important;
  }

  &:last-child .el-input__wrapper {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.options.dialog-footer button:first-child {
  margin-right: 10px;
}

</style>