import {OptionalModelController} from "@lib/common/controller/OptionalModelController"
import {
    KooperationspartnerBriefabsenderViewModel,
} from "@intranet/view/kooperationspartner/briefabsender/KooperationspartnerBriefabsenderViewModel"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Id} from "@lib/common/model/Id"
import {Resource} from "@lib/model/Resource"

export class KooperationspartnerBriefabsenderController extends OptionalModelController<KooperationspartnerBriefabsenderViewModel> {

    constructor(viewModel: KooperationspartnerBriefabsenderViewModel) {
        super(viewModel, () => true)
    }

    override async update(id: Id<Kooperationspartner>): Promise<KooperationspartnerBriefabsenderViewModel> {
        if (this.model.value?.briefabsender.id !== null) {
            this.model.value?.flagCreated()
        }
        return super.update(id)
    }

    protected async createModel(model: KooperationspartnerBriefabsenderViewModel, kooperationspartner: Id<Kooperationspartner>): Promise<KooperationspartnerBriefabsenderViewModel> {
        const result = await Resource.kooperationspartner.briefabsender.create(kooperationspartner, model.briefabsender)
        return new KooperationspartnerBriefabsenderViewModel(result)
    }

    protected async updateModel(model: KooperationspartnerBriefabsenderViewModel, kooperationspartner: Id<Kooperationspartner>): Promise<KooperationspartnerBriefabsenderViewModel> {
        const result = await Resource.kooperationspartner.briefabsender.update(kooperationspartner, model.briefabsender)
        return new KooperationspartnerBriefabsenderViewModel(result)
    }

}