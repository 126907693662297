import {ViewModel} from "@lib/common/model/ViewModel"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"

export class KooperationspartnerSummaryDialogViewModel extends ViewModel<KooperationspartnerSummaryDialogViewModel> {

    kooperationspartner: Kooperationspartner

    constructor(kooperationspartner: Kooperationspartner) {
        super()
        this.kooperationspartner = this.registerShallowReactive(kooperationspartner)
    }

    get titel() {
        return `${this.kooperationspartner.kennung} // ${this.kooperationspartner.name}
        `
    }
}