import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"

export interface IMail extends IModel {
	bcc: Array<string>
	cc: Array<string>
	to: Array<string>
	sendAfter: LocalDateTime | null
	sent: LocalDateTime | null
	header: Record<string,string>
	body: string
	from: string
	subject: string
}

export class Mail extends Model<Mail> {
	public bcc!: Array<string>
	public cc!: Array<string>
	public to!: Array<string>
	public sendAfter!: LocalDateTime | null
	public sent!: LocalDateTime | null
	public header!: Record<string,string>
	public body!: string
	public from!: string
	public subject!: string

	static _types: Record<keyof IMail, IRuntimeTypeInformation> = {
		id: { type: Id },
		bcc: { type: [String] },
		cc: { type: [String] },
		to: { type: [String] },
		sendAfter: { type: LocalDateTime, nullable: true },
		sent: { type: LocalDateTime, nullable: true },
		header: { type: [String, String] },
		body: { type: String },
		from: { type: String },
		subject: { type: String },
	}

	static props: Record<keyof IMail, string> = {
		id: "id",
		bcc: "bcc",
		cc: "cc",
		to: "to",
		sendAfter: "sendAfter",
		sent: "sent",
		header: "header",
		body: "body",
		from: "from",
		subject: "subject",
	}

	constructor(_json: IMail) {
		super(_json)
	}
}
