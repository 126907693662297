import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {Repository} from "@lib/common/repository/Repository"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"
import {ArtikelkatalogBuilder} from "@generated/de/lohn24/model/artikelkatalog/ArtikelkatalogBuilder"

export class ArtikelkatalogRepository extends Repository<Artikelkatalog> {
    artikel = this.buildHasManyWithRelation(ArtikelkatalogArtikel, Artikel, "artikel")
    builder = this.buildBuilder(ArtikelkatalogBuilder)

    constructor() {
        super(Artikelkatalog, "artikelkatalog")
    }
}

