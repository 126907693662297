import {Repository} from "@lib/common/repository/Repository"
import {MandantExport} from "@generated/de/lohn24/model/mandant/export/MandantExport"
import {File} from "@generated/dev/api/model/file/File"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"

export class MandantExportRepository extends Repository<MandantExport> {

    constructor() {
        super(MandantExport, "mandantexport")
    }

    files = this.buildRelation(File, "files")
    mandant = this.buildRelation(Mandant, "mandant")
}