import {ContextMenuBuilder} from "@lib/common/contextmenu/ContextMenuBuilder"
import {ContextMenuItemOption, ContextSubMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import {ListController} from "@lib/common/controller/ListController"
import {ViewModel} from "@lib/common/model/ViewModel"
import IconEdit from "@lib/view/icons/IconEdit.vue"
import IconAdd from "@lib/view/icons/IconAdd.vue"
import IconDelete from "@lib/view/icons/IconDelete.vue"

export type CrudContextMenuRowContext<R extends ViewModel<R>> = {
    createable: boolean;
    editable: boolean;
    deletable: boolean;
    row?: R;
    event: MouseEvent,
    controller: ListController<R>
}

export class CrudContextMenu<R extends ViewModel<R>> extends ContextMenuBuilder {

    constructor(private context: CrudContextMenuRowContext<R>) {
        super(context.event)
        this.build()
    }

    edit = (context: CrudContextMenuRowContext<R>) => new ContextMenuItemOption(
        "Bearbeiten",
        async () => context.row && context.controller.editRow(context.row),
        IconEdit,
        () => context.row != undefined && !context.row.bearbeitungszustand.edited && context.editable,
    )

    stopEdit = (context: CrudContextMenuRowContext<R>) => new ContextMenuItemOption(
        "Speichern",
        async () => context.row && context.controller.stopEditRow(context.row),
        IconEdit,
        () => context.row != undefined && context.row.bearbeitungszustand.edited && context.editable,
    )

    create = (context: CrudContextMenuRowContext<R>) => new ContextMenuItemOption(
        "Neu",
        () => context.controller.create(),
        IconAdd,
        () => context.row == undefined && context.createable,
    )

    delete = (context: CrudContextMenuRowContext<R>) => new ContextMenuItemOption(
        "Löschen",
        async () => context.row && context.controller.deleteRow(context.row),
        IconDelete,
        () => context.row != undefined && context.deletable,
    )

    override buildOptions(): (ContextMenuItemOption | ContextSubMenuItemOption)[] {
        return super.buildOptions()
            .concat(
                this.create(this.context),
                this.edit(this.context),
                this.stopEdit(this.context),
                this.delete(this.context),
            )
    }
}