import {Repository} from "@lib/common/repository/Repository"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Benachrichtigungsoption} from "@generated/de/lohn24/model/benachrichtigung/Benachrichtigungsoption"

export class KontaktRepository extends Repository<Kontakt> {

    benachrichtigung = this.buildRelation(Benachrichtigungsoption, "benachrichtigung")

    constructor() {
        super(Kontakt, "kontakt")
    }
}