<script lang="ts" setup>
import {computed, onMounted, ref} from "vue"
import {BaseIdType, Id} from "@lib/common/model/Id"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {ResourceCache} from "@lib/model/Resource"

const props = withDefaults(
    defineProps<{
        modelValue: Nullable<Id<Kooperationspartner>>
        keineRechnung?: boolean
        mitArtikelKatalog?: boolean
        keineSammelrechnung?: boolean
        disabled?: boolean
        tooltip?: string
    }>(),
    {
        keineRechnung: false,
    },
)

const emits = defineEmits<{
    "update:modelValue": [id: Nullable<Id<Kooperationspartner>>]
    "change:kooperationspartner": [kooperationspartner: Nullable<Kooperationspartner>]
}>()

const kooperationspartner_ = computed<BaseIdType | null>({
    get: () => {
        return props.modelValue?.value ?? null
    },
    set: (value: BaseIdType | null | "") => {
        const id = value === "" ? null : value
        const optionalId = id ? new Id(id) : null
        emits("update:modelValue", optionalId)
        emitChangeKooperationspartner(optionalId)
    },
})

function emitChangeKooperationspartner(id: Id<Kooperationspartner> | null) {
    const result = kooperationspartner.value?.find(it => it.id.value === id?.value) ?? null
    emits("change:kooperationspartner", result)
}

const kooperationspartner = ref<Kooperationspartner[]>()
const kooperationspartnerOptions = ref<{ value: BaseIdType; label: string }[]>([])
onMounted(async () => {
    kooperationspartner.value = await ResourceCache.kooperationspartner.all()
    kooperationspartnerOptions.value = kooperationspartner.value
        .filter(it => {
            return (props.keineRechnung ? it.keineRechnung === false : true) &&
                (props.mitArtikelKatalog ? it.artikelkatalog !== null : true) &&
                (props.keineSammelrechnung ? it.sammelrechnung === null : true)
        })
        .sort((a, b) => (a.name > b.name ? 1 : a.name == b.name ? 0 : -1))
        .map((it) => ({
            // eslint-disable-next-line
            value: it.id.value,
            label: `${it.kennung} // ${it.name}`,
        }))
})

</script>

<template>
  <el-tooltip :content="tooltip" :disabled="!tooltip">
    <el-select v-model="kooperationspartner_" :disabled="disabled" clearable filterable
               placeholder="Kein Kooperationspartner"
    >
      <el-option v-for="item in kooperationspartnerOptions" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </el-tooltip>
</template>
