import {PdfMenuItem} from "@intranet/view/pdf/PdfMenuItem"
import {Resource} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"
import {MandantAnschriftentraegerQuery} from "@lib/model/mandant/MandantRepository"

export const MandantAnschriftentraegerPdf =
    new PdfMenuItem<{ mandant: string }, MandantAnschriftentraegerQuery>({
        path: "anschriftentraeger/mandant/:mandant",
        cb({mandant}, query) {
            return Resource.mandant.anschriftentraeger(
                new Id(mandant),
                query,
            )
        },
    })