import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"

export interface IFile extends IModel {
	parent: string | null
	thumbnail: string | null
	duplicate: boolean
	encrypted: boolean
	private: boolean
	temporary: boolean
	versioned: boolean
	antivirusReport: Array<string> | null
	antivirusScan: LocalDateTime | null
	associated: LocalDateTime | null
	created: LocalDateTime
	deleted: LocalDateTime | null
	fileCreated: LocalDateTime
	fileModified: LocalDateTime
	periodEnd: LocalDateTime | null
	periodStart: LocalDateTime | null
	processed: LocalDateTime | null
	reprocess: LocalDateTime | null
	softDeleted: LocalDateTime | null
	timeout: LocalDateTime | null
	updated: LocalDateTime
	size: number | null
	metadata: string | null
	metadataClassName: string | null
	mimeType: string
	name: string
	objectId: string
	origin: string
	originMetadata: string | null
	originMetadataClassName: string | null
	subtype: string | null
	timeoutReason: string | null
	type: string | null
	usertype: string | null
	version: string | null
}

export class File extends Model<File> {
	public parent!: Id<File> | null
	public thumbnail!: Id<File> | null
	public duplicate!: boolean
	public encrypted!: boolean
	public private!: boolean
	public temporary!: boolean
	public versioned!: boolean
	public antivirusReport!: Array<string> | null
	public antivirusScan!: LocalDateTime | null
	public associated!: LocalDateTime | null
	public created!: LocalDateTime
	public deleted!: LocalDateTime | null
	public fileCreated!: LocalDateTime
	public fileModified!: LocalDateTime
	public periodEnd!: LocalDateTime | null
	public periodStart!: LocalDateTime | null
	public processed!: LocalDateTime | null
	public reprocess!: LocalDateTime | null
	public softDeleted!: LocalDateTime | null
	public timeout!: LocalDateTime | null
	public updated!: LocalDateTime
	public size!: number | null
	public metadata!: string | null
	public metadataClassName!: string | null
	public mimeType!: string
	public name!: string
	public objectId!: string
	public origin!: string
	public originMetadata!: string | null
	public originMetadataClassName!: string | null
	public subtype!: string | null
	public timeoutReason!: string | null
	public type!: string | null
	public usertype!: string | null
	public version!: string | null

	static _types: Record<keyof IFile, IRuntimeTypeInformation> = {
		id: { type: Id },
		parent: { type: Id, nullable: true },
		thumbnail: { type: Id, nullable: true },
		duplicate: { type: Boolean },
		encrypted: { type: Boolean },
		private: { type: Boolean },
		temporary: { type: Boolean },
		versioned: { type: Boolean },
		antivirusReport: { type: [String], nullable: true },
		antivirusScan: { type: LocalDateTime, nullable: true },
		associated: { type: LocalDateTime, nullable: true },
		created: { type: LocalDateTime },
		deleted: { type: LocalDateTime, nullable: true },
		fileCreated: { type: LocalDateTime },
		fileModified: { type: LocalDateTime },
		periodEnd: { type: LocalDateTime, nullable: true },
		periodStart: { type: LocalDateTime, nullable: true },
		processed: { type: LocalDateTime, nullable: true },
		reprocess: { type: LocalDateTime, nullable: true },
		softDeleted: { type: LocalDateTime, nullable: true },
		timeout: { type: LocalDateTime, nullable: true },
		updated: { type: LocalDateTime },
		size: { type: Number, nullable: true },
		metadata: { type: String, nullable: true },
		metadataClassName: { type: String, nullable: true },
		mimeType: { type: String },
		name: { type: String },
		objectId: { type: String },
		origin: { type: String },
		originMetadata: { type: String, nullable: true },
		originMetadataClassName: { type: String, nullable: true },
		subtype: { type: String, nullable: true },
		timeoutReason: { type: String, nullable: true },
		type: { type: String, nullable: true },
		usertype: { type: String, nullable: true },
		version: { type: String, nullable: true },
	}

	static props: Record<keyof IFile, string> = {
		id: "id",
		parent: "parent",
		thumbnail: "thumbnail",
		duplicate: "duplicate",
		encrypted: "encrypted",
		private: "private",
		temporary: "temporary",
		versioned: "versioned",
		antivirusReport: "antivirusReport",
		antivirusScan: "antivirusScan",
		associated: "associated",
		created: "created",
		deleted: "deleted",
		fileCreated: "fileCreated",
		fileModified: "fileModified",
		periodEnd: "periodEnd",
		periodStart: "periodStart",
		processed: "processed",
		reprocess: "reprocess",
		softDeleted: "softDeleted",
		timeout: "timeout",
		updated: "updated",
		size: "size",
		metadata: "metadata",
		metadataClassName: "metadataClassName",
		mimeType: "mimeType",
		name: "name",
		objectId: "objectId",
		origin: "origin",
		originMetadata: "originMetadata",
		originMetadataClassName: "originMetadataClassName",
		subtype: "subtype",
		timeoutReason: "timeoutReason",
		type: "type",
		usertype: "usertype",
		version: "version",
	}

	constructor(_json: IFile) {
		super(_json)
	}
}
