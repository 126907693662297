import {LocationAsRelativeRaw} from "vue-router"
import {MiddlewareContext} from "./Middleware"
import {auth} from "@lib/common/Authentication"
import {NotFoundMenuItem} from "@lib/common/menu/404MenuItem"
import {LoginMenuItem} from "@lib/common/menu/LoginMenuItem"
import {unwrapped} from "@lib/common/Functions"

export function authMiddleware(
    options: {
        afterLoginRoute: LocationAsRelativeRaw
    },
) {
    return async ({to, next, router}: MiddlewareContext): Promise<void> => {
        const {uri} = to.query
        if (uri != null && uri != "/") {
            next(false)
            const name = router.getRoutes().find(it => it.path == uri)?.name
            if (name) {
                await router.push({name: name})
            } else {
                await router.push(uri.toString())
            }
        }

        await auth.initialise()
        if (!auth.valid()) {
            if (to.name === unwrapped(LoginMenuItem.route).name) {
                return next()
            } else if (to.name === NotFoundMenuItem.routeRecordName) {
                return next(unwrapped(LoginMenuItem.route))
            } else {
                return next({...unwrapped(LoginMenuItem.route), query: {from: to.fullPath}})
            }
        }

        if (to.name === unwrapped(LoginMenuItem.route).name && auth.valid()) {
            return next(options.afterLoginRoute)
        }

        const name = router.getRoutes().find(it => it.path === to.path)?.name
        if (!to.name && name) {
            return next({name})
        }

        return next()
    }
}
