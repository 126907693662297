import {LocalStorage} from "@lib/common/LocalStorage"
import {computed, isRef, nextTick, ref, Ref, toRaw} from "vue"
import {Config} from "@lib/Config"
import {LocationQueryRaw, RouteLocationNormalizedLoaded, Router} from "vue-router"
import {MimeType} from "@lib/common/Enums"
import {UploadUserFile} from "element-plus"
import {Serializable} from "@lib/common/serializable/Serializable"
import {Partially} from "@lib/types"
import {LohnabrechnungSystem} from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"

export async function uploadFileToBase64(uploadFile: UploadUserFile) {
    assertIsDefined(uploadFile.raw)
    return `data:${uploadFile.raw.type};base64, ` + btoa(
        new Uint8Array(await uploadFile.raw.arrayBuffer())
            .reduce((data, byte) => data + String.fromCharCode(byte), ""),
    )
}

export function propertyNameOf<T, K extends any[]>(ctor: new (...args: K) => T, ...args: K): Record<keyof T, keyof T> {
    const instance = new ctor(...args) as unknown as T
    const record = {} as Record<keyof typeof instance, keyof typeof instance>
    for (const key of getAllProperties(instance)) {
        record[key] = key
    }
    return record
}

export function logStack(limit: number = 100) {
    if (Config.development) {
        const lim = Error.stackTraceLimit
        Error.stackTraceLimit = limit
        console.log(new Error().stack)
        Error.stackTraceLimit = lim
    }
}

export function getAllProperties(obj) {
    const allProps: string[] = []
    let curr = obj
    do {
        const props = Object.getOwnPropertyNames(curr)
        props.forEach(function (prop) {
            if (allProps.indexOf(prop) === -1)
                allProps.push(prop)
        })
        curr = Object.getPrototypeOf(curr)
    } while (curr)
    return allProps
}

export function from<D extends NonNullable<Serializable<D>>, T extends any[]>(ctor: new (...obj: T) => D, obj: Partial<D>): D
export function from<D extends NonNullable<Serializable<D>>, T extends any[]>(ctor: new (...obj: T) => D, ...obj: Partially<T>): D
export function from<D extends NonNullable<unknown>, T extends any[]>(ctor: new (...obj: T) => D, ...obj: T): D {
    if (Serializable.isAncestorOf(ctor)) {
        return new ctor(...[JSON.parse(JSON.stringify(obj.first()))] as T)
    }
    //@ts-expect-error check
    return new ctor(...obj.map(it => toRaw(it)))
}

export async function raf(): Promise<void> {
    return new Promise<void>((resolve) => requestAnimationFrame(() => resolve()))
}

export async function skipFrame(): Promise<void> {
    await raf()
    return raf()
}

export async function nextTickLogged() {
    const logger = "next-tick-" + (Math.random() * 100000000 | 0).toFixed()
    console.time(logger)
    console.timeLog(logger, "pre next tick")
    await nextTick()
    console.timeLog(logger, "post next tick")
    console.timeEnd(logger)
}

export function random(length: number) {
    return Math.random() * Math.pow(10, length) | 0
}

export function komplementAohneB<T, S>(a: Array<T>, b: Array<T>, accessor: (item: T) => S): Array<T> {
    return a.filter(aItem => b.findIndex((bItem) => accessor(aItem) === accessor(bItem)) < 0)
}

export function formatterAbrechnungssystem(name: LohnabrechnungSystem, height = 16): string {
    return `<img src="/icons/${name.toLowerCase()}.png" width="${height}" alt="${name}">`
}

function getValue(_value: Ref<any>, storageKey?: string, type?: "number" | "string" | "boolean" | "object") {
    if (storageKey) {
        switch (type) {
            case "number":
                return _value.value = LocalStorage.Instance.number(storageKey, _value.value as number)
            case "string":
                return _value.value = LocalStorage.Instance.string(storageKey, _value.value as string)
            case "boolean":
                return _value.value = LocalStorage.Instance.boolean(storageKey, _value.value as boolean)
            case "object":
                if (Array.isArray(_value.value))
                    return _value.value = LocalStorage.Instance.parse(storageKey, _value.value as object)
                break
            default:
                // eslint-disable-next-line prefer-rest-params
                throw new Error(`Arguments not expected: ${arguments}`)
        }
    } else {
        return _value.value
    }
}

export function reactiveLocalStorageProxy<T>(
    defaultValue: T,
    storageKey?: string,
    modified?: Ref<boolean>,
    type?: "number" | "string" | "boolean" | "object",
) {
    const _value = ref(defaultValue)
    return computed<T>({
        get: () => {
            return _value.value ?
                getValue(_value, storageKey, type || typeof defaultValue as "string" | "number" | "boolean" | "object") as unknown as T :
                getValue(_value, storageKey, type || typeof defaultValue as "string" | "number" | "boolean" | "object") as unknown as T
        },
        set: (value: T) => {
            if (storageKey)
                if (Array.isArray(value))
                    LocalStorage.Instance.stringify(storageKey, value)
                else
                    LocalStorage.Instance.set(storageKey, `${value}`);
            (_value.value as T) = value
            if (modified)
                modified.value = true
        },
    })
}

export function readFileAsync(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            const result = reader.result as string
            resolve(result.trim())
        }
        reader.onerror = reject
        reader.readAsBinaryString(file)
    })
}

export function downloadAsFile(filename: string, content: string, mimetype = MimeType.PLAIN, node = document.body) {
    const link = document.createElement("a")
    link.download = filename
    link.innerHTML = "Download File"
    link.href = window.URL.createObjectURL(new Blob([content], {type: mimetype}))
    node.appendChild(link)
    link.click()
    link.remove()
}

export function downloadFile(blob: Blob, filename: string, node = document.body) {
    const link = document.createElement("a")
    link.download = filename
    link.innerHTML = "Download File"
    link.href = URL.createObjectURL(blob)
    node.appendChild(link)
    link.click()
    link.remove()
}

export function openPDF(blob: Blob, name?: string) {
    const urlObj = URL.createObjectURL(blob)
    const aNode = document.createElement("a")
    aNode.setAttribute("href", urlObj)
    aNode.setAttribute("target", "_blank")
    if (name)
        aNode.setAttribute("download", `${name}`)
    document.body.appendChild(aNode)
    aNode.click()
    aNode.remove()
}

export function withinTimeRange(start: Date, end: Date, value: Date) {
    return start <= value && value <= end
}

/**
 *
 * @param duration HH:mm:ss
 */
export function durationStringToSeconds(duration: string): number {
    const [hours, minutes, seconds] = duration.split(":").map((x) => +x)
    return (hours * 60 + minutes) * 60 + seconds
}

export function secondsToDurationString(duration: number): string {
    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor((duration - hours * 3600) / 60)
    const seconds = duration - minutes * 60 - hours * 3600
    const result = [hours, minutes, seconds]
    return result
        .reduce((acc, t) => {
            return acc + String(t).padStart(2, "0") + ":"
        }, "")
        .slice(0, -1)
}

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
    if (val === undefined || val === null) {
        throw new Error(
            `Expected 'val' to be defined, but received ${val}`,
        )
    }
}

export function assertNotNull<T>(value: Nullable<T> | undefined): T {
    assertIsDefined(value)
    return value as T
}

export function shallowUnwrap<T>(value: Ref<T> | T): T {
    if (isRef(value)) {
        return value.value
    } else {
        return value
    }
}

export async function replaceRouteParams<P extends Record<string, any>, Q extends LocationQueryRaw | undefined>(router: Router, route: RouteLocationNormalizedLoaded, params?: P, query?: Q) {
    if (route?.name) {
        await router.replace({name: route.name, params: params, query: query})
    }
}

function getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop)
}

export function getCanvasFont(el = document.body) {
    const fontWeight = getCssStyle(el, "font-weight") || "normal"
    const fontSize = getCssStyle(el, "font-size") || "16px"
    const fontFamily = getCssStyle(el, "font-family") || "Times New Roman"

    return `${fontWeight} ${fontSize} ${fontFamily}`
}

export function getTextWidth(text, font) {
    //@ts-expect-error re-use canvas object for better performance
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"))
    const context = canvas.getContext("2d")
    context.font = font
    const metrics = context.measureText(text)
    return metrics.width
}

export function dotted(text: string, maxWidth: number = 235) {
    let _text = text
    let dots = ""
    while (getTextWidth(_text, getCanvasFont()) >= maxWidth) {
        _text = _text.slice(0, -1)
        dots = "..."
        if (_text.length <= 3)
            return _text + "..."
    }
    return _text + dots
}

export function wait(ms: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(resolve, ms)
    })
}

export async function poll<R>(fn: () => Promise<R>, condition: (R) => boolean, ms: number) {
    let result = await fn()
    while (condition(result)) {
        await wait(ms)
        result = await fn()
    }
    return result
}

export function blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = (e) => reject(e)
        reader.readAsDataURL(blob)
    })
}

export function unwrapped<T>(value: Ref<T> | T): T {
    return isRef(value) ? value.value : value
}

export function hashCode(text: string): number { // java String#hashCode
    let hash = 0
    for (let i = 0; i < text.length; i++) {
        hash = text.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
}

export function numberToRGB(number: number) {
    const colour = (number & 0x00FFFFFF)
        .toString(16)
        .toUpperCase()

    return "00000".substring(0, 6 - colour.length) + colour
}

export function textToRGB(text: string): string {
    return "#" + numberToRGB(hashCode(text))
}

export function enumValues<T extends { [key: number]: string | number }>(e: T): (keyof T)[] {
    return Object.keys(e).map(it => e[it])
}

export async function copyToClipboard(text: string) {
    const clipboard = navigator.clipboard
    await clipboard.writeText(text)
}

export async function readAsText(blob: Blob, charset: string): Promise<string> {
    return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result as string)
        reader.readAsText(blob, charset)
    })
}