import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Rechnungsmodus } from "@generated/de/lohn24/model/firma/Rechnungsmodus"
import { BriefabsenderArt } from "@generated/de/lohn24/model/brief/BriefabsenderArt"
import { Artikelkatalog } from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"

export interface IFirma extends IModel {
	artikelkatalog: string
	briefabsenderArt: BriefabsenderArt
	logoSkalierung: number | null
	datevSachkontenlaengeBuchungen: number
	datevSachkontenlaengeDebitoren: number
	datevSepaBank: number
	rechnungsmodus: Rechnungsmodus
	bic: string
	datevBeraternummer: string
	datevErtragskonto: string
	datevSepaGlaeubigerId: string | null
	datevSepaGlaeubigerName: string | null
	datevUnternehmensnummer: string
	email: string
	fusszeile: string | null
	geschaeftsfuehrung: string
	iban: string
	internet: string
	kurzname: string
	logo: string | null
	mailGrussformel: string | null
	mailLogo: string
	mailSignatur: string
	name: string
	ort: string
	postleitzahl: string
	registergericht: string
	registernummer: string
	ruecksendeangabe: string
	strasse: string
	telefax: string
	telefon: string
	umsatzsteuerid: string
}

export class Firma extends Model<Firma> {
	public artikelkatalog!: Id<Artikelkatalog>
	public briefabsenderArt!: BriefabsenderArt
	public logoSkalierung!: number | null
	public datevSachkontenlaengeBuchungen!: number
	public datevSachkontenlaengeDebitoren!: number
	public datevSepaBank!: number
	public rechnungsmodus!: Rechnungsmodus
	public bic!: string
	public datevBeraternummer!: string
	public datevErtragskonto!: string
	public datevSepaGlaeubigerId!: string | null
	public datevSepaGlaeubigerName!: string | null
	public datevUnternehmensnummer!: string
	public email!: string
	public fusszeile!: string | null
	public geschaeftsfuehrung!: string
	public iban!: string
	public internet!: string
	public kurzname!: string
	public logo!: string | null
	public mailGrussformel!: string | null
	public mailLogo!: string
	public mailSignatur!: string
	public name!: string
	public ort!: string
	public postleitzahl!: string
	public registergericht!: string
	public registernummer!: string
	public ruecksendeangabe!: string
	public strasse!: string
	public telefax!: string
	public telefon!: string
	public umsatzsteuerid!: string

	static _types: Record<keyof IFirma, IRuntimeTypeInformation> = {
		id: { type: Id },
		artikelkatalog: { type: Id },
		briefabsenderArt: { type: BriefabsenderArt },
		logoSkalierung: { type: Number, nullable: true },
		datevSachkontenlaengeBuchungen: { type: Number },
		datevSachkontenlaengeDebitoren: { type: Number },
		datevSepaBank: { type: Number },
		rechnungsmodus: { type: Rechnungsmodus },
		bic: { type: String },
		datevBeraternummer: { type: String },
		datevErtragskonto: { type: String },
		datevSepaGlaeubigerId: { type: String, nullable: true },
		datevSepaGlaeubigerName: { type: String, nullable: true },
		datevUnternehmensnummer: { type: String },
		email: { type: String },
		fusszeile: { type: String, nullable: true },
		geschaeftsfuehrung: { type: String },
		iban: { type: String },
		internet: { type: String },
		kurzname: { type: String },
		logo: { type: String, nullable: true },
		mailGrussformel: { type: String, nullable: true },
		mailLogo: { type: String },
		mailSignatur: { type: String },
		name: { type: String },
		ort: { type: String },
		postleitzahl: { type: String },
		registergericht: { type: String },
		registernummer: { type: String },
		ruecksendeangabe: { type: String },
		strasse: { type: String },
		telefax: { type: String },
		telefon: { type: String },
		umsatzsteuerid: { type: String },
	}

	static props: Record<keyof IFirma, string> = {
		id: "id",
		artikelkatalog: "artikelkatalog",
		briefabsenderArt: "briefabsenderArt",
		logoSkalierung: "logoSkalierung",
		datevSachkontenlaengeBuchungen: "datevSachkontenlaengeBuchungen",
		datevSachkontenlaengeDebitoren: "datevSachkontenlaengeDebitoren",
		datevSepaBank: "datevSepaBank",
		rechnungsmodus: "rechnungsmodus",
		bic: "bic",
		datevBeraternummer: "datevBeraternummer",
		datevErtragskonto: "datevErtragskonto",
		datevSepaGlaeubigerId: "datevSepaGlaeubigerId",
		datevSepaGlaeubigerName: "datevSepaGlaeubigerName",
		datevUnternehmensnummer: "datevUnternehmensnummer",
		email: "email",
		fusszeile: "fusszeile",
		geschaeftsfuehrung: "geschaeftsfuehrung",
		iban: "iban",
		internet: "internet",
		kurzname: "kurzname",
		logo: "logo",
		mailGrussformel: "mailGrussformel",
		mailLogo: "mailLogo",
		mailSignatur: "mailSignatur",
		name: "name",
		ort: "ort",
		postleitzahl: "postleitzahl",
		registergericht: "registergericht",
		registernummer: "registernummer",
		ruecksendeangabe: "ruecksendeangabe",
		strasse: "strasse",
		telefax: "telefax",
		telefon: "telefon",
		umsatzsteuerid: "umsatzsteuerid",
	}

	constructor(_json: IFirma) {
		super(_json)
	}
}
