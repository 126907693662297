import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'overlay': _ctx.overlay}),
    style: _normalizeStyle(`height: ${_ctx.overlay ? _ctx.screenHeight - 1 : 0}px`),
    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.doClose && _ctx.doClose(...args))),
    onMousedown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.doClose && _ctx.doClose(...args)))
  }, [
    _createElementVNode("div", {
      ref: "root",
      style: _normalizeStyle(`position: absolute; left: ${_ctx.mouse.x - ((_ctx.left || _ctx.openLeft) && _ctx.overlay ? _ctx.rect.width :0)}px; top: ${_ctx.mouse.y - (_ctx.openTop ? _ctx.rect.height : 0)}px;`),
      class: "contextmenu",
      role: "menu",
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
      onMousedown: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("ul", null, [
        _renderSlot(_ctx.$slots, "default", {
          active: _ctx.activeChild,
          dx: -((_ctx.left || _ctx.openLeft) && _ctx.overlay ? _ctx.rect.width :0),
          enabled: true,
          left: (_ctx.left || _ctx.openLeft),
          onClose: () =>_ctx.doClose(),
          "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeChild=$event))
        })
      ])
    ], 36)
  ], 38))
}