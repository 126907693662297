import Api from "@lib/common/axios/Api"
import {Resource} from "@lib/model/Resource"
import {ZammadTaskbar, ZammadTaskbarBuilder} from "@lib/common/zammad/ZammadTaskbar"
import {ContentType, MimeType} from "@lib/common/Enums"
import {Ticket} from "@generated/de/lohn24/model/ticket/Ticket"

export interface ZammadUser {
    id: number
}

export class ZammadApi {

    private api: Api

    static setToken(zammadUrl: string, token: string) {
        Api.Instance(`${zammadUrl}/api/v1`).setBearerToken(token)
    }

    constructor(zammadUrl: string, token: string | null = null) {
        this.api = Api.Instance(`${zammadUrl}/api/v1`)
        if (token)
            this.api.setBearerToken(token)
    }

    private route(path: string): string {
        const sanitzedPath = path[0] === "/" ? path.slice(1) : path
        const cleanupPath = sanitzedPath.startsWith("api/v1/") ? sanitzedPath.slice("api/v1/".length) : sanitzedPath
        return `${this.api.baseUrl}/${cleanupPath}`
    }

    public async updateTicket(ticket: Ticket) {
        const mandantennummer = ticket.mandant !== null ? (await Resource.mandant.find(ticket.mandant)).kennung : ""
        const besitzer = ticket.besitzer !== null ? (await Resource.account.mitarbeiter.first(ticket.besitzer)).zammadId : null
        const ticketgruppe = ticket.ticketgruppe !== null ? (await Resource.ticketgruppe.find(ticket.ticketgruppe)).zammadId : null
        await this.api.put(this.route(`tickets/${ticket.zammadId}`), {
            mandantennummer: mandantennummer,
            owner_id: besitzer,
            ticket: ticket.id.value,
            organization_id: await this.ticketOrganisation(ticket),
            group_id: ticketgruppe,
        })
    }

    private async ticketOrganisation(ticket: Ticket) {
        if (ticket.organisation)
            return (await Resource.organisation.find(ticket.organisation)).zammadId

        if (ticket.kooperationspartner)
            return (await Resource.kooperationspartner.find(ticket.kooperationspartner)).zammadId

        return null
    }

    async attachment(path: string): Promise<ArrayBufferLike> {
        return await this.api.get(this.route(path), null, ContentType.JPEG, {
            responseType: "arraybuffer",
        })
    }

    async ticketCreate(builder: ZammadTaskbarBuilder): Promise<ZammadTaskbar> {
        const result = await this.api.post<ZammadTaskbar>(this.route(`taskbar`), builder)
        // es reicht nicht den state im post mitzugeben, man muss die Antwort nochmal einbrennen o0'
        return await this.api.put<ZammadTaskbar>(this.route(`taskbar/${result.id}`), result)
    }

    async createEmailUser(email: string): Promise<ZammadUser | null> {
        try {
            return await this.api.post<ZammadUser>(this.route("users"), {
                email: email,
            })
        } catch (e) {
            return null
        }

    }

    async pullBase64(path: string) {
        const blob = await this.attachment(path)
        return `data:${MimeType.PNG};base64, ` + btoa(
            new Uint8Array(blob as ArrayBufferLike)
                .reduce((data, byte) => data + String.fromCharCode(byte), ""),
        )
    }

}