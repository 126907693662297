<script lang="ts" setup>
import {computed, ref} from "vue"
import AnschriftSummary from "@intranet/view/anschrift/AnschriftSummary.vue"
import IconEdit from "@lib/view/icons/IconEdit.vue"
import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router"
import {DialogController} from "@lib/common/controller/DialogController"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import CustomDialogV2 from "@lib/view/dialog/CustomDialogV2.vue"

const emits = defineEmits<{ edit: [context: { route: RouteLocationNormalizedLoaded, router: Router }] }>()
const props = withDefaults(
    defineProps<{
        controller: DialogController,
        anschrift: Anschrift | null,
        edit?: boolean
    }>(),
    {
        edit: true,
    },
)
const route = useRoute()
const router = useRouter()
const clearTooltip = ref(false)
const visible = computed<boolean>({
    get: () => props.controller.visible.value,
    // eslint-disable-next-line vue/no-mutating-props
    set: value => props.controller.visible.value = value,
})

props.controller.useController()

</script>

<template>
  <custom-dialog-v2
          v-model="props.controller.visible.value"
          :close="() => props.controller.close()"
          :open-delay="0"
          :title="props.controller.title.value"
          intent="summary"
  >
    <template #header-right="{btnClassName}">
      <slot :btnClassName="btnClassName" name="header-right"></slot>
      <slot v-if="props.edit" :btnClassName="btnClassName" name="header-edit">
        <el-button
                name="edit"
                text
                @click.stop="clearTooltip=!clearTooltip;$emit('edit', {route, router})"
        >
          <el-tooltip
                  :disabled="clearTooltip"
                  :show-after="1000"
                  content="Bearbeiten"
                  placement="top"
          >
            <icon-edit />
          </el-tooltip>
        </el-button>
      </slot>
    </template>
    <template #content-left>
      <slot name="content-left-prepend"></slot>
      <el-row>
        <el-col>
          <anschrift-summary v-if="anschrift" :anschrift="anschrift">
            <template #header-right>
              <slot name="anschrift-button-group"></slot>
            </template>
          </anschrift-summary>
        </el-col>
      </el-row>
      <slot name="content-left-append"></slot>
    </template>
    <template #content-right>
      <slot name="content-right"></slot>
    </template>
    <template #content-append>
      <slot></slot>
    </template>
    <template #footer>
      <slot name="footer"></slot>
    </template>
  </custom-dialog-v2>
</template>

<style lang="scss">
.summary-dialog__wrapper {
  .summary-dialog__content > .el-row {
    margin-bottom: 30px;
  }

  .content-left, .content-right {
    & > div:not(:last-child) {
      margin-bottom: 26px;
    }
  }
}

</style>