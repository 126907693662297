import {Repository} from "@lib/common/repository/Repository"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {File} from "@generated/dev/api/model/file/File"
import {VersandListDistinct} from "@lib/model/versand/VersandListDistinct"
import {Versand} from "@generated/de/lohn24/model/versand/Versand"
import {Id} from "@lib/common/model/Id"
import {VersandListElement} from "@generated/de/lohn24/model/versand/VersandListElement"

export class VersandRepository extends Repository<Versand> {
    datei = this.buildRelation(File, "datei")

    constructor() {
        super(Versand, "versand")
    }

    async versandliste(filter: Record<string, unknown> = {}): Promise<PartitionedResultSet<VersandListElement, VersandListDistinct>> {
        const result = (await this.api.get<Partial<PartitionedResultSet<VersandListElement, VersandListDistinct>>>(this.route("versandliste"), filter))
        return new PartitionedResultSet(VersandListElement, VersandListDistinct, result)
    }

    async versandt(id: Id<Versand>): Promise<void> {
        await this.api.post(this.route(`${id}/versandt`), {})
    }

    async pdf(id: Id<Versand>): Promise<Blob> {
        return await this.api.getBlob(this.route(`${id}/pdf`))
    }
}