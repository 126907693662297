import {ContextMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import IconTicket from "@lib/view/icons/IconTicket.vue"

export function mandantOtrsSuche(context: () => { kennung: string }) {
    return new ContextMenuItemOption(
        "Offene Tickets im OTRS suchen",
        async () => {
            const linkToTicketSystem = `https://otrs.lohn24.de/otrs/index.pl?Action=AgentTicketSearch&Subaction=Search&CustomerID=${+context().kennung}&StateType=Open`
            window.open(linkToTicketSystem, "_blank")
        },
        IconTicket,
    )
}