import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Ticketgruppe } from "@generated/de/lohn24/model/ticketgruppe/Ticketgruppe"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"

export interface ITicketgruppeMapping extends IModel {
	kooperationspartner: string | null
	mandant: string | null
	sammelrechnung: string | null
	ticketgruppe: string
}

export class TicketgruppeMapping extends Model<TicketgruppeMapping> {
	public kooperationspartner!: Id<Kooperationspartner> | null
	public mandant!: Id<Mandant> | null
	public sammelrechnung!: Id<Kooperationspartner> | null
	public ticketgruppe!: Id<Ticketgruppe>

	static _types: Record<keyof ITicketgruppeMapping, IRuntimeTypeInformation> = {
		id: { type: Id },
		kooperationspartner: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		sammelrechnung: { type: Id, nullable: true },
		ticketgruppe: { type: Id },
	}

	static props: Record<keyof ITicketgruppeMapping, string> = {
		id: "id",
		kooperationspartner: "kooperationspartner",
		mandant: "mandant",
		sammelrechnung: "sammelrechnung",
		ticketgruppe: "ticketgruppe",
	}

	constructor(_json: ITicketgruppeMapping) {
		super(_json)
	}
}
