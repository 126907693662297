import {ViewModel} from "@lib/common/model/ViewModel"
import {_props} from "@lib/common/_props"
import {ValidateableListItem} from "@lib/common/ValidateableListItem"
import {FreitextArtikelPosition} from "@generated/de/lohn24/model/beleg/builder/FreitextArtikelPosition"

export class FreitextBelegPositionViewModel extends ViewModel<FreitextBelegPositionViewModel> implements ValidateableListItem {
    static properties = _props<InstanceType<typeof this>>()
    static label = {
        [this.properties.text]: "Text",
        [this.properties.menge]: "Menge",
        [this.properties.notiz]: "Notiz",
        [this.properties.netto]: "Netto",
        [this.properties.summe]: "Summe",
    }
    validationIndex: number = 0
    freitextArtikelPosition: FreitextArtikelPosition

    constructor(freitextArtikelPosition: FreitextArtikelPosition) {
        super()

        this.freitextArtikelPosition = this.registerShallowReactive(freitextArtikelPosition)
    }

    get menge() {
        return this.freitextArtikelPosition.menge
    }

    set menge(value: number) {
        this.freitextArtikelPosition.menge = value
    }

    get text() {
        return this.freitextArtikelPosition.text ?? ""
    }

    set text(value: string) {
        this.freitextArtikelPosition.text = value
    }

    get notiz() {
        return this.freitextArtikelPosition.notiz ?? ""
    }

    set notiz(value: string) {
        this.freitextArtikelPosition.notiz = value ? value : null
    }

    get netto() {
        return this.freitextArtikelPosition.netto
    }

    set netto(value: number) {
        this.freitextArtikelPosition.netto = value
    }

    get summe() {
        return (this.freitextArtikelPosition.netto * this.freitextArtikelPosition.menge).toFixed(2)
    }

    textIsBlanc() {
        return this.freitextArtikelPosition.text === ""
    }
}