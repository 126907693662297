import {Constructable} from "@lib/types"
import {Config} from "@lib/Config"
import {Serializable} from "@lib/common/serializable/Serializable"
import {from} from "@lib/common/Functions"

type Constructor<C> = new (...args: any[]) => C

export class ModelWorker {
    private static instance: ModelWorker
    private worker!: SharedWorker
    private listeners = new Map<Constructor<any>, Array<{ (model: any): void }>>()

    private constructor() {
        this.worker = new SharedWorker(new URL("@lib/common/worker/SharedModelWorker.ts", import.meta.url))
        this.worker.port.onmessage = (ev) => {
            let ctor: Constructor<any> | undefined = undefined
            for (const key of this.listeners.keys())
                if (ev.data.typeName === key.name) {
                    ctor = key
                    break
                }
            if (ctor === undefined) {
                if (Config.development) {
                    console.error(`not listening on ${ev.data.typeName}`)
                }
                return
            }
            const model = ev.data.model ? new ctor(JSON.parse(ev.data.model)) : null
            this.listeners.get(ctor)?.forEach(it => it(model))
        }
    }

    public static get Instance() {
        return this.instance = this.instance || new ModelWorker()
    }

    delete<M extends abstract new (...args: any) => any>(ctor: M) {
        const typeName = ctor.name
        this.worker.port.postMessage({typeName, method: "DELETE"})
    }

    post<M extends Serializable<M>>(model: M | M[], ctor?: Constructable<M>) {
        const typeName = ctor?.name || Array.isArray(model) ? model[0].constructor.name : model.constructor.name
        this.worker.port.postMessage({items: JSON.stringify(model), typeName, method: "POST"})
    }

    async get<M extends Serializable<M>>(ctor: Constructor<M>): Promise<M | null> {
        return new Promise(resolve => {
            const cb = (model) => {
                this.removeListener(ctor, cb)
                resolve(model)
            }
            this.addListener(ctor, cb)
            this.worker.port.postMessage({
                typeName: ctor.name,
                method: "GET",
            })
        })
    }

    addListener<T extends Serializable<T>>(type: Constructor<T>, cb: (model: T) => void): void {
        const current = this.listeners.get(type) || []
        this.listeners.set(type, current.concat(cb))
    }

    removeListener<T extends Serializable<T>>(type: Constructor<T>, cb: (model: T) => void): void {
        const current = this.listeners.get(type) || []
        const index = current.findIndex(it => it === cb)
        if (index >= 0)
            current.splice(index, 1)
        this.listeners.set(type, current)
    }
}