<script lang="ts" setup>

import Validateable from "@lib/view/validateable/Validateable.vue"
import {
    InputType,
    ValidateableInputProps,
    ValidateableProps,
    ValidateableTrigger,
} from "@lib/view/validateable/ValidateableProps"
import {FontSize} from "@lib/common/Enums"
import {computed} from "vue"

defineOptions({
    inheritAttrs: false,
})

const emits = defineEmits<{
    "update:modelValue": [text: string | number],
    "validate": [],
}>()
const props = withDefaults(
    defineProps<ValidateableProps & ValidateableInputProps>(),
    {
        type: InputType.Input,
        noMargin: () => true,
        trigger: () => ValidateableTrigger.blur,
        size: () => FontSize.default,
    },
)
const model = computed({
    get: () => props.modelValue ?? "",
    set: value => {
        emits("update:modelValue", value)
    },
})

function triggerValidate(type: ValidateableTrigger) {
    if (props.trigger === type) {
        emits("validate")
    }
}

if (props.immediate)
    emits("validate")

function classNames() {
    const names: string[] = []
    if (props.type === InputType.InputNumber) names.push("no-justify")
    return names.join(" ")
}
</script>

<template>
  <validateable
          :class="classNames()"
          :error="error"
          :label="label"
          :no-margin="noMargin"
          :prop="prop"
          :size="size"
          :warning="warning"
  >
    <component
            :is="`el-${type}`"
            v-model="model"
            :size="size"
            autocomplete="off"
            v-bind="$attrs"
            @blur="triggerValidate(ValidateableTrigger.blur)"
            @change="triggerValidate(ValidateableTrigger.change)"
    >
      <slot></slot>
    </component>
  </validateable>
</template>

<style lang="scss">
.validatable_input_wrapper.no-margin-important {
  margin-bottom: 0 !important;
  word-break: break-word;

  .el-form-item__content {
    margin-left: 0 !important;
  }

  &.is-warning {
    .el-input__wrapper {
      box-shadow: 0 0 0 1px var(--el-color-warning) inset;
    }

    .el-form-item__error {
      color: var(--el-color-warning);
    }
  }


  &.is-error.hide-error {
    .el-form-item__error {
      display: none;
    }
  }
}

.validatable_input_wrapper.no-margin-important.no-justify {
  .el-form-item__content {
    justify-content: start;
  }
}
</style>