import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, LocalMonth, Model } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"

export interface IMandantExport extends IModel {
	mandant: string
	exists: boolean
	global: boolean
	export: LocalDateTime | null
	mutating: LocalDateTime | null
	timeout: LocalDateTime | null
	zeitraum: string | null
	zeitraumMax: string | null
	zeitraumMin: string | null
}

export class MandantExport extends Model<MandantExport> {
	public mandant!: Id<Mandant>
	public exists!: boolean
	public global!: boolean
	public export!: LocalDateTime | null
	public mutating!: LocalDateTime | null
	public timeout!: LocalDateTime | null
	public zeitraum!: LocalMonth | null
	public zeitraumMax!: LocalMonth | null
	public zeitraumMin!: LocalMonth | null

	static _types: Record<keyof IMandantExport, IRuntimeTypeInformation> = {
		id: { type: Id },
		mandant: { type: Id },
		exists: { type: Boolean },
		global: { type: Boolean },
		export: { type: LocalDateTime, nullable: true },
		mutating: { type: LocalDateTime, nullable: true },
		timeout: { type: LocalDateTime, nullable: true },
		zeitraum: { type: LocalMonth, nullable: true },
		zeitraumMax: { type: LocalMonth, nullable: true },
		zeitraumMin: { type: LocalMonth, nullable: true },
	}

	static props: Record<keyof IMandantExport, string> = {
		id: "id",
		mandant: "mandant",
		exists: "exists",
		global: "global",
		export: "export",
		mutating: "mutating",
		timeout: "timeout",
		zeitraum: "zeitraum",
		zeitraumMax: "zeitraumMax",
		zeitraumMin: "zeitraumMin",
	}

	constructor(_json: IMandantExport) {
		super(_json)
	}
}
