import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_postcard = _resolveComponent("icon-postcard")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    "show-after": 1000,
    content: "Anschriftenetikett",
    placement: "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        text: "",
        onClick: _ctx.download
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon_postcard)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}