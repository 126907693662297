import {ViewModel} from "@lib/common/model/ViewModel"
import {News} from "@generated/de/lohn24/model/news/News"
import {DateTime} from "luxon"
import {Formatter} from "@lib/common/Formatter"
import {Resource} from "@lib/model/Resource"
import {_props} from "@lib/common/_props"

export class NewsUngelesenViewModel extends ViewModel<NewsUngelesenViewModel> {

    static props = _props<InstanceType<typeof this>>()
    news: News

    constructor(news: News) {
        super()
        this.news = this.registerShallowReactive(news)
    }

    get titel() {
        return this.news.title
    }

    set titel(value: string) {
        this.news.title = value
    }

    get teaser() {
        return this.news.teaser
    }

    set teaser(value: string) {
        this.news.teaser = value
    }

    get authorName() {
        return Resource.account.accountName(this.news.author)
    }

    get released() {
        return this.news.released
    }

    set released(value: Nullable<DateTime>) {
        this.news.released = value
    }

    get veroeffentlichtVon() {
        return `Veröffentlicht von ${this.authorName} am ${Formatter.zeit.datumMonthShort(this.released)}`
    }

    isReleased(): boolean {
        return this.released && this.released <= DateTime.now() || false
    }
}