import "@lib/common/extension"
import "@lib/Config"
import "@intranet/style/element/index.scss"

import {router} from "@intranet/plugin/router/Router"
import ElementPlus, {ElCollapseTransition} from "element-plus"

import de from "element-plus/es/locale/lang/de"

import {ScrollDirectionPlugin} from "@lib/common/vue/plugin/ScrollDirection"
import {ContextMenuClass} from "@lib/view"
import {FontAwesomePlugin} from "@lib/common/vue/plugin/FontAwesomePlugin"
import {ElementPlusIconsPlugin} from "@lib/common/vue/plugin/ElementPlusIconsPlugin"
import {VueShowdownWrappedPlugin} from "@intranet/plugin/vueshowdown/VueShowdown"
import {vueShowdownConfig} from "@intranet/plugin/vueshowdown/VueShowdownConfig"
import {Time} from "@lib/common/LocalMonth"
import {DevPlugin} from "@intranet/plugin/DevPlugin"
import {LogoPlugin} from "@intranet/plugin/LogoPlugin"
import {createApp} from "vue"
import App from "@intranet/App.vue"
import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {ReloadToUpdatePlugin} from "@intranet/plugin/ReloadToUpdatePlugin"
import {PollNewsPlugin} from "@intranet/plugin/PollNewsPlugin"
import {HeartbeatPlugin} from "@intranet/plugin/HeartbeatPlugin"
import {KeyboardEventlistener} from "@lib/common/dialog/KeyboardEventlistener"
import {SearchDialog} from "@lib/view/search/SearchDialog"

createApp(App)
    .use(DevPlugin)
    .use(router)
    .use(ElementPlus, {locale: de})
    .use(ElementPlusIconsPlugin)
    .use(VueShowdownWrappedPlugin, vueShowdownConfig)
    .use(FontAwesomePlugin)
    .use(ContextMenuClass)
    // for dialog
    .use(ComponentRenderer)
    .use(new ReloadToUpdatePlugin(), {interval: Time.MIN})
    .use(new PollNewsPlugin(), {interval: Time.MIN * 5})
    .use(new HeartbeatPlugin(), {interval: Time.MIN * 5})
    .use(ScrollDirectionPlugin, {sensitivity: 50})
    .use(LogoPlugin)
    .use(new KeyboardEventlistener(),
        SearchDialog.config,
    )
    .component(ElCollapseTransition.name, ElCollapseTransition)
    .mount("#app")

