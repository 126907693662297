import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { OrganisationStatus } from "@generated/de/lohn24/model/organisation/OrganisationStatus"

export interface IOrganisation extends IModel {
	zammadId: number | null
	status: OrganisationStatus
	domain: string | null
	name: string
}

export class Organisation extends Model<Organisation> {
	public zammadId!: number | null
	public status!: OrganisationStatus
	public domain!: string | null
	public name!: string

	static _types: Record<keyof IOrganisation, IRuntimeTypeInformation> = {
		id: { type: Id },
		zammadId: { type: Number, nullable: true },
		status: { type: OrganisationStatus },
		domain: { type: String, nullable: true },
		name: { type: String },
	}

	static props: Record<keyof IOrganisation, string> = {
		id: "id",
		zammadId: "zammadId",
		status: "status",
		domain: "domain",
		name: "name",
	}

	constructor(_json: IOrganisation) {
		super(_json)
	}
}
