<script lang="ts" setup>
import L24Header from "@intranet/view/header/L24Header.vue"
import {computed, onErrorCaptured, toRaw} from "vue"
import {useZIndex} from "element-plus/es"
import {menu} from "@intranet/plugin/router/Router"
import {auth} from "@lib/common/Authentication"
import UserSubMenu from "@lib/view/menuitem/UserSubMenu.vue"
import {LogoutMenuItem} from "@lib/common/menu/LogoutMenuItem"
import {MenuItem} from "@lib/common/menu/MenuItem"
import {isDevelopment} from "@intranet/plugin/DevPlugin"
import {assertNotNull} from "@lib/common/Functions"
import {MitarbeiterStatus} from "@generated/de/lohn24/model/mitarbeiter/MitarbeiterStatus"
import {showError} from "@intranet/view/error/ErrorMenuItem"

const AppHeader = L24Header
const avatarClass = computed(() => {
    return {
        "menu-avatar": true,
        "mitarbeiter-status": true,
        "mitarbeiter-status-pause": auth.mitarbeiter.value.mitarbeiterStatus === MitarbeiterStatus.PAUSE,
        "mitarbeiter-status-abwesend": auth.mitarbeiter.value.mitarbeiterStatus === MitarbeiterStatus.ABWESEND,
        "mitarbeiter-status-online": auth.mitarbeiter.value.mitarbeiterStatus === MitarbeiterStatus.ONLINE,
    }
})
// zIndex erhöhen, um den vom Framework für Dialoge benutzten initialen zIndex über den in den CSS Styles benutzten zIndex zu setzen.
const {currentZIndex, nextZIndex} = useZIndex()
while (currentZIndex.value < 2030) {
    nextZIndex()
}

if (isDevelopment()) {
    document.documentElement.style.setProperty("--l24-color-main-background", "#8EB19D")
}

const menuItems = computed(() => menu.headerMenuItems.value)

function getLogoutMenuItem(item: MenuItem): LogoutMenuItem {
    return assertNotNull(item.children?.find(it => toRaw(it) instanceof LogoutMenuItem) as LogoutMenuItem | undefined ?? null)
}

onErrorCaptured((error, instance) => {
    showError(error)
})

</script>

<template>
  <app-header :menu-items="menuItems">
    <template #user_title>
      <el-avatar :class="avatarClass" class="blink" icon="avatar"></el-avatar>
    </template>
    <template #user_submenu="{item}">
      <user-sub-menu
              :logout-menu-item="getLogoutMenuItem(item)"
              :name="item.name"
      />
    </template>
  </app-header>
  <el-main>
    <router-view />
  </el-main>
</template>

<style lang="scss">
@import "intranet/src/style/index";

:root {
  --el-header-height: var(--l24-dyn-header-height) !important;
  --l24-dyn-header-transistion: 0.3s all ease-out;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, sans-serif;
  color: $color-text;

  .el-button.is-text {
    &:hover {
      background-color: rgba(0, 0, 0, 0) !important;
      color: var(--l24-color-brand);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0) !important;
    }
  }

  input:disabled {
    // firefox does not bubble events on disabled inputs
    pointer-events: none
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.el-main {
  position: absolute;
  top: var(--l24-dyn-header-height);
  width: 100%;
  min-height: calc(100% - var(--l24-dyn-header-height));
  background-color: var(--l24-color-main-background);
  transition: var(--l24-dyn-header-transistion);
  overflow: hidden;
}

.el-card__header {
  display: flex;
  font-weight: bold;
  letter-spacing: 0.4px;
  font-size: 14px;
  color: $color-primary;
}

.el-card .el-card__header {
  font-size: 13px;
  padding: 10px 10px 10px 20px;
}

hr {
  border-top: 1px solid $color-brand;
  color: $color-brand;
  margin: 30px 0;
}

.el-step__title.is-process,
.el-step__head.is-process {
  color: $color-brand !important;
  border-color: $color-gray-light-2 !important;
}

.el-dialog {
  .el-dialog__title {
    color: $color-primary;
    font-weight: bold;
    letter-spacing: 0.4px;
    font-size: 14px;
    margin: 0;
    padding: 0 0 5px;
  }

  .el-dialog__header {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid $color-gray-light-8;
    margin: 0;
  }

  .el-dialog__body {
    padding: 40px;
  }
}

// ElForm Restyling
.el-dialog__body h3 {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 20px;
  color: $color-brand;
}

.el-form-item--small.is-error {
  margin-bottom: 3px;
}

.el-form-item {
  .el-form-item__content,
  .el-input,
  .el-checkbox,
  .el-select,
  .select-trigger,
  .el-select-v2,
  .el-select-v2__wrapper,
  .el-checkbox-group,
  .el-checkbox-group .el-checkbox-button,
  .el-checkbox-group .el-checkbox-button .el-checkbox-button__inner,
  .el-radio-group,
  .el-radio-group .el-radio-button,
  .el-radio-group .el-radio-button .el-radio-button__inner {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  .el-form-item__content .el-checkbox-group {
    width: 100%;
  }

  .el-checkbox {
    justify-content: left;
  }

  &.is-error {
    margin-bottom: 3px;

    .el-radio-group {
      .el-radio-button__inner {
        box-shadow: 0 0 0 1px var(--el-color-danger) !important;
      }
    }
  }

  .el-date-editor {
    width: 100%;
  }

  .el-form-item__content {
    justify-content: left;
    width: 100%;
  }

  .el-form-item__error {
    display: contents;
  }
}

div.el-message-box__header span {
  color: $color-brand;
  font-weight: bold;
  font-size: 16px;
}

button.el-button {
  height: auto;
}

.el-message-box__message {
  strong {
    color: var(--l24-color-brand);
  }
}

.transition-all-ease-out {
  .el-affix--fixed {
    transition: 0.1s all ease-out;
  }

  .el-affix--fixed {
    .el-affix__content.mandantenliste {
      padding-top: 20px;
      padding-bottom: 2px;
      background-color: var(--el-fill-color-blank);
      box-shadow: var(--el-box-shadow-light);
    }

    .el-affix__content.anruferstatistik {
      padding-top: 10px;
      padding-bottom: 2px;
      background-color: var(--el-fill-color-blank);
      box-shadow: var(--el-box-shadow-light);
    }

    .el-affix__content.scroll-to-top {
      padding-top: 2px;
      padding-bottom: 10px;
      background-color: var(--el-fill-color-blank);
      box-shadow: var(--el-box-shadow-light);

      .el-button--text {
        padding: 3px 10px;
        height: 40px;
      }
    }
  }
}

.el-table__row .el-button {
  padding: 0;
  margin: 0;
  height: 10px;
}

@media print {
  #header {
    display: none;
  }

  .settings {
    display: none !important;
  }

  .cell {
    display: inline-block;
    inline-size: max-content;
  }

  .el-table__cell > .cell {
    display: inline-block;
    inline-size: max-content;
  }

  .el-table-column--selection {
    display: none;
  }

  .el-table__row {
    display: block;
    width: max-content;
  }

  .el-table {
    display: block;
  }

  .el-affix__content {
    display: none;
  }
}

tr.attention:nth-child(4n+0) {
  --child-no: 0;
  --attention-color: var(--l24-color-brand-light-80);
}

tr.attention:nth-child(4n+1) {
  --child-no: 1;
  --attention-color: var(--l24-color-brand-light-80);
}

tr.attention:nth-child(4n+2) {
  --child-no: 2;
  --attention-color: var(--l24-color-brand-light-80);
}

tr.attention:nth-child(4n+3) {
  --child-no: 3;
  --attention-color: var(--l24-color-brand-light-80);
}

tr.attention {
  --width: 25%;
  --step: 37.5%;

  background: repeating-linear-gradient(
                  -45deg,
                  var(--attention-color) calc((var(--step) * var(--child-no))),
                  var(--attention-color) calc((var(--step) * var(--child-no)) + var(--width)),
                  #fff calc((var(--step) * var(--child-no)) + var(--width)),
                  #fff calc((var(--step) * var(--child-no)) + var(--width) + var(--width))
  );
  background-size: 44px 45px;
}
</style>
