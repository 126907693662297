import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio_button = _resolveComponent("el-radio-button")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createBlock(_component_el_radio_group, {
    modelValue: _ctx.id,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.id) = $event)),
    "data-cy": _ctx.dataCy
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createBlock(_component_el_radio_button, {
          key: option.value,
          label: option.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(option.label), 1)
          ]),
          _: 2
        }, 1032, ["label"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "data-cy"]))
}