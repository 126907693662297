import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"

export interface IKooperationspartnerListElement extends IModel {
	keineRechnung: boolean
	sammelrechnungFestschreiben: number
	aktiveMandanten: number
	kennung: string
	name: string
	anschrift: string
	debitor: string | null
	sammelrechnung: string | null
}

export class KooperationspartnerListElement extends Model<KooperationspartnerListElement> {
	public keineRechnung!: boolean
	public sammelrechnungFestschreiben!: number
	public aktiveMandanten!: number
	public kennung!: string
	public name!: string
	public anschrift!: string
	public debitor!: string | null
	public sammelrechnung!: string | null

	static _types: Record<keyof IKooperationspartnerListElement, IRuntimeTypeInformation> = {
		id: { type: Id },
		keineRechnung: { type: Boolean },
		sammelrechnungFestschreiben: { type: Number },
		aktiveMandanten: { type: Number },
		kennung: { type: String },
		name: { type: String },
		anschrift: { type: String },
		debitor: { type: String, nullable: true },
		sammelrechnung: { type: String, nullable: true },
	}

	static props: Record<keyof IKooperationspartnerListElement, string> = {
		id: "id",
		keineRechnung: "keineRechnung",
		sammelrechnungFestschreiben: "sammelrechnungFestschreiben",
		aktiveMandanten: "aktiveMandanten",
		kennung: "kennung",
		name: "name",
		anschrift: "anschrift",
		debitor: "debitor",
		sammelrechnung: "sammelrechnung",
	}

	constructor(_json: IKooperationspartnerListElement) {
		super(_json)
	}
}
