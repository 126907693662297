import {ContextMenuBuilder, ContextMenuRowContext} from "@lib/common/contextmenu/ContextMenuBuilder"
import {ContextMenuItemOption, ContextSubMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import {MandantSummaryDialog} from "@intranet/view/mandant/summary/MandantSummaryDialog"
import IconLink from "@lib/view/icons/IconLink.vue"
import {zammadTicketByMandantennummer} from "@intranet/view/mandant/ticket/ZammadTicketByMandantennummer"
import {mandantZammadTicket} from "@intranet/view/mandant/ticket/MandantZammadTicket"
import IconTicket from "@lib/view/icons/IconTicket.vue"
import {pmcMandantContextMenuItemOption} from "@intranet/view/mandant/pmc/PmcMandantContextMenuItemOption"
import {
    KooperationspartnerMandant
} from "@generated/de/lohn24/model/kooperationspartner/KooperationspartnerMandanten/KooperationspartnerMandant"
import IconZammadTicket from "@lib/view/icons/IconZammadTicket.vue"

export class MandantenContextMenu extends ContextMenuBuilder {

    summary = () => new ContextMenuItemOption(
        this.context.row.name,
        async () => {
            await MandantSummaryDialog.show(this.context.row.id)
        },
        IconLink,
    )

    zammadTicket = () => new ContextSubMenuItemOption(
        "Tickets (Zammad)",
        [
            zammadTicketByMandantennummer(() => ({kennung: this.context.row.kennung})),
            mandantZammadTicket(async () => {
                const mandant = this.context.row.id
                return {mandant}
            }),
        ],
        IconZammadTicket,
    )

    pmc = () => pmcMandantContextMenuItemOption(this.context.row.id)

    constructor(private context: ContextMenuRowContext<KooperationspartnerMandant>) {
        super(context.event)
        this.build()
    }

    override buildOptions(): (ContextMenuItemOption | ContextSubMenuItemOption)[] {
        return super.buildOptions()
            .concat(
                this.summary(),
                this.zammadTicket(),
                this.pmc()
            )
    }
}