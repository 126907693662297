import { IRuntimeTypeInformation, Serializable } from "@lib/index"

export interface IAddisonExportMetrics {
	errors: number
	mandanten: number
	processed: number
	processing: number
	timeouts: number
	unprocessed: number
	zeitraeume: Record<string,number>
}

export class AddisonExportMetrics extends Serializable<AddisonExportMetrics> {
	public errors!: number
	public mandanten!: number
	public processed!: number
	public processing!: number
	public timeouts!: number
	public unprocessed!: number
	public zeitraeume!: Record<string,number>

	static _types: Record<keyof IAddisonExportMetrics, IRuntimeTypeInformation> = {
		errors: { type: Number },
		mandanten: { type: Number },
		processed: { type: Number },
		processing: { type: Number },
		timeouts: { type: Number },
		unprocessed: { type: Number },
		zeitraeume: { type: [String, Number] },
	}

	static props: Record<keyof IAddisonExportMetrics, string> = {
		errors: "errors",
		mandanten: "mandanten",
		processed: "processed",
		processing: "processing",
		timeouts: "timeouts",
		unprocessed: "unprocessed",
		zeitraeume: "zeitraeume",
	}

	constructor(_json: IAddisonExportMetrics) {
		super(_json)
	}
}
