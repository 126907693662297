<script lang="ts" setup>
import {mandantZammadTicket} from "@intranet/view/mandant/ticket/MandantZammadTicket"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Id} from "@lib/common/model/Id"

interface MandantZammadTicketContext {
    kontakt?: Kontakt,
    mandant: Id<Mandant>,
    btnClass?: string
}

const props = withDefaults(
    defineProps<MandantZammadTicketContext>(),
    {btnClass: ""},
)

const options = mandantZammadTicket(() => (props))
</script>

<template>
  <el-tooltip :content="options.label" :show-after="1000" placement="top">
    <el-button :class="btnClass" text v-bind="$attrs" @click.stop="(event)=>options.action(event)">
      <component :is="options.icon" />
    </el-button>
  </el-tooltip>
</template>