import {Repository} from "@lib/common/repository/Repository"
import {Role} from "@generated/dev/api/model/role/Role"
import {RoleRight} from "@generated/dev/api/model/role/RoleRight"

export class RoleRepository extends Repository<Role> {
    right = this.buildRelation(RoleRight, "right")

    constructor() {
        super(Role, "role")
    }
}