import {App, AppContext, ComponentPublicInstance, createVNode, render, VNode} from "vue"
import ContextMenuItemConstructor from "./ContextMenuItem.vue"
import ContextSubMenuItemConstructor from "./ContextSubMenuItem.vue"
import ContextMenuConstructor from "./ContextMenu.vue"
import {assertNotNull} from "@lib/common/Functions"

export class ContextMenuItemOption implements Record<string, unknown> {
    constructor(
        public label: string,
        public action: (event: MouseEvent) => Promise<unknown>,
        public icon?: ComponentPublicInstance<any>,
        public render: () => boolean = () => true,
        public enabled: () => boolean = () => true,
    ) {
    }

    [key: string]: unknown
}

export class ContextSubMenuItemOption implements Record<string, unknown> {
    constructor(
        public label: string,
        public children: (ContextMenuItemOption | ContextSubMenuItemOption)[],
        public icon?: ComponentPublicInstance<any>,
        public render: () => boolean = () => true,
        public enabled: () => boolean = () => true,
    ) {
    }

    [key: string]: unknown
}

export class ContextMenuItemMultiSelectOption implements Record<string, unknown> {
    constructor(
        public label: string,
        public children: (ContextMenuItemOption)[],
        public icon?: ComponentPublicInstance<any>,
        public render: () => boolean = () => true,
        public enabled: () => boolean = () => true,
    ) {
    }

    [key: string]: unknown
}

export interface ContextMenuOptions extends Record<string, unknown> {
    appendTo?: HTMLElement
    visible: boolean
}

type ContextMenuItemGroup = (ContextMenuItemOption | ContextSubMenuItemOption | ContextMenuItemMultiSelectOption)[]

export class ContextMenuClass {

    private static _context: AppContext | null = null
    private static instance: ContextMenuClass | null = null
    private static groups: ContextMenuItemGroup[] = []
    private static timeout = 0
    private vm: ComponentPublicInstance<{ doClose: () => void }> | null = null

    constructor(
        private event: MouseEvent,
        private groups: ContextMenuItemGroup[],
        private options: ContextMenuOptions,
    ) {
        this.vm = this.render(options.appendTo || document.body)
    }

    //invoked by Vue.use
    static install(app: App): App {
        ContextMenuClass._context = app._context
        app.config.globalProperties.$contextMenu = ContextMenuClass
        return app
    }

    static buildContextMenu(context: {
        options: (ContextMenuItemOption | ContextSubMenuItemOption)[],
        event: MouseEvent,
    }): void {
        if (context.event.ctrlKey && context.event.altKey)
            return
        context.event.preventDefault()

        ContextMenuClass.instance?.vm?.doClose()

        clearTimeout(ContextMenuClass.timeout)
        ContextMenuClass.timeout = 0

        ContextMenuClass.groups.push(context.options)
        this.timeout = setTimeout(() => {

            if (ContextMenuClass.groups.flat().length)
                ContextMenuClass.instance = new ContextMenuClass(
                    context.event,
                    ContextMenuClass.groups.reverse(),
                    {
                        visible: true,
                    },
                )
            ContextMenuClass.groups = []
        }, 20) as unknown as number
    }

    buildProps(container) {
        return {
            x: this.event.pageX,
            y: this.event.pageY,
            ...this.options,
            onVanish: () => {
                render(null, container)
            },
        }
    }

    render(appendTo: HTMLElement, appContext: AppContext | null = ContextMenuClass._context) {
        const container = document.createElement("div")

        const contextmenuVNode = createVNode(
            ContextMenuConstructor,
            this.buildProps(container),
            {
                default: (slotProps) => [
                    this.groups.flatMap(it => {
                        return it.mapNotNull(item => {
                            return item.render()
                                ? this.vNodeItem(item, appContext, slotProps)
                                : null
                        })
                    }),
                ],
            },
        )

        contextmenuVNode.appContext = appContext

        render(contextmenuVNode, container)

        appendTo.appendChild(assertNotNull(container.firstChild))

        return assertNotNull(contextmenuVNode.component).proxy as ComponentPublicInstance<{
            doClose: () => void
        }>
    }

    private vNodeMenuItem(
        item: ContextMenuItemOption,
        appContext: AppContext | null = null,
        slotProps: Record<string, unknown> = {},
    ): VNode {
        const vNode = createVNode(
            ContextMenuItemConstructor,
            {label: item.label, icon: item.icon, action: item.action, ...slotProps},
        )
        vNode.appContext = appContext
        return vNode
    }

    private vNodeSubMenuItem(
        item: ContextSubMenuItemOption,
        appContext: AppContext | null = null,
        slotProps: Record<string, unknown> = {},
    ): VNode {
        const vnode = createVNode(
            ContextSubMenuItemConstructor,
            {label: item.label, icon: item.icon, ...slotProps, enabled: item.enabled()},
            {
                default: (_slotProps) => {
                    return item.children.mapNotNull(it =>
                        it.render() ? this.vNodeItem(it, appContext, _slotProps) : null,
                    )
                },
            },
        )
        vnode.appContext = appContext
        return vnode
    }

    private vNodeItem(
        item: ContextMenuItemOption | ContextSubMenuItemOption,
        appContext: AppContext | null = null,
        slotProps: Record<string, unknown> = {},
    ): VNode {
        if (this.isSubMenuItem(item)) {
            return this.vNodeSubMenuItem(item, appContext, slotProps)
        } else if (this.isMultiSelectMenuItem(item)) {
            return this.vNodeMenuItem(item, appContext, slotProps)
        } else {
            return this.vNodeMenuItem(item, appContext, slotProps)
        }
    }

    private isSubMenuItem(item: unknown): item is ContextSubMenuItemOption {
        return item instanceof ContextSubMenuItemOption
    }

    private isMultiSelectMenuItem(item: unknown): item is ContextMenuItemMultiSelectOption {
        return item instanceof ContextMenuItemMultiSelectOption
    }
}