import {Repository} from "@lib/common/repository/Repository"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {DateTime} from "luxon"
import {ResourceCache} from "@lib/model/Resource"
import {assertNotNull} from "@lib/common/Functions"
import {auth} from "@lib/common/Authentication"
import {Serializable} from "@lib/common/serializable/Serializable"
import {TicketListElement} from "@generated/de/lohn24/model/ticket/TicketListElement"
import {TicketTag} from "@generated/de/lohn24/model/ticket/tag/TicketTag"
import {TicketTagFunktion} from "@generated/de/lohn24/model/ticket/tag/TicketTagFunktion"
import {Ticket} from "@generated/de/lohn24/model/ticket/Ticket"
import {TicketStatus} from "@generated/de/lohn24/model/ticket/TicketStatus"
import {Config} from "@lib/Config"
import {Id} from "@lib/common/model/Id"
import {MimeType} from "@lib/common/Enums"
import {TicketAnhang} from "@generated/de/lohn24/model/ticket/anhang/TicketAnhang"
import {TicketArtikel} from "@generated/de/lohn24/model/ticket/artikel/TicketArtikel"

export class TicketListDistinct extends Serializable<TicketListDistinct> {
    static _types = {}
}

export class TicketRepository extends Repository<Ticket> {

    artikel = this.buildRelation(TicketArtikel, "artikel")
    tag = this.buildRelation(TicketTag, "tag")

    constructor() {
        super(Ticket, "ticket")
    }

    async ticketliste<K>(params: K): Promise<PartitionedResultSet<TicketListElement, TicketListDistinct>> {
        const result = await this.api.get<PartitionedResultSet<TicketListElement, TicketListDistinct>>(this.route("ticketliste"), params)
        return new PartitionedResultSet(TicketListElement, TicketListDistinct, result)
    }

    async alsSpamSchliessen(ticket: Ticket) {
        // TODO spam und lautlos müssen als Konfiguration in die DB
        const spam = assertNotNull((await ResourceCache.tags.all()).find(it => it.funktion === TicketTagFunktion.SPAM))
        const lautlos = assertNotNull((await ResourceCache.tags.all()).find(it => it.funktion === TicketTagFunktion.LAUTLOS))
        const tags = await this.tag.all(ticket.id)
        if (!tags.any(it => it.id.value === lautlos.id.value)) {
            await this.tag.create(ticket.id, lautlos)
        }
        if (!tags.any(it => it.id.value === spam.id.value)) {
            await this.tag.create(ticket.id, spam)
        }
        const copy = ticket.clone()
        copy.status = TicketStatus.GESCHLOSSEN
        copy.besitzer = auth.mitarbeiter.value.account
        await this.update(copy)
    }

    override async update(entity: Ticket): Promise<Ticket> {
        const copy = entity.clone()
        copy.imAuftragVon = auth.mitarbeiter.value.zammadId
        return super.update(copy)
    }

    async loeschen(ticket: Ticket) {
        ticket.deleted = DateTime.now()
        return this.update(ticket)
    }

    async openTicketInZammad(zammadId: string | number) {
        window.open(
            `${Config.ZAMMAD_BASE_URL}/#ticket/zoom/${zammadId}`,
            Config.ZAMMAD_WINDOW_TARGET,
        )
    }

    async anhang(ticket: Id<Ticket>, anhang: Id<TicketAnhang>): Promise<Blob> {
        return await this.api.getBlob(this.route(`${ticket}/anhang/${anhang}`))
    }

    async anhangBase64(ticket: Id<Ticket>, anhang: Id<TicketAnhang>): Promise<string> {
        const blob = await this.anhang(ticket, anhang)
        return `data:${MimeType.PNG};base64, ` + btoa(
            new Uint8Array(await blob.arrayBuffer())
                .reduce((data, byte) => data + String.fromCharCode(byte), ""),
        )
    }
}