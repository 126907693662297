<template>
  <svg height="609" viewBox="0 0 1314 609" width="1314">
    <g transform="matrix(1.3333333,0,0,1.3333333,0,641.20467)">
      <g id="g12" transform="matrix(16.226715,0,0,16.226715,-1111.7091,-480.79109)">
        <g id="g14">
          <g id="g16">
            <path
                    d="m 68.578,0.262 v 27.847 h 17.211 v -2.66 H 71.55 V 0.262 Z"
                    style="fill: #545051; fill-opacity: 1; fill-rule: nonzero; stroke: none"
            />
          </g>
          <g id="g68">
            <path
                    d="m 108.824,26.93 c 0,0.554 -0.515,1.179 -1.179,1.179 H 91.418 c -0.656,0 -1.078,-0.625 -1.078,-1.179 l -0.031,-6.395 c 0,-1.84 0.101,-2.57 2.152,-3.855 l 9.695,-7.508 c 0.559,-0.449 0.938,-0.762 1.114,-0.938 0.726,-0.796 1.043,-1.597 0.871,-2.468 -0.176,-0.832 -0.84,-1.25 -1.985,-1.25 h -3.718 c -2.223,0 -3.024,0.418 -3.407,2.121 -0.035,0.453 -0.066,0.765 -0.066,0.937 v 0.141 c 0,0.867 -0.211,1.215 -1.149,1.215 h -2.328 c -0.972,0 -1.109,-0.348 -1.109,-1.215 V 7.09 c 0,-0.801 0.137,-2.539 0.797,-3.891 0.347,-0.664 1.012,-1.394 1.98,-2.121 C 94.094,0.348 95.867,0 98.438,0 h 3.824 c 7.922,0 8.301,8.441 3.402,12.199 l -10.769,8.164 v 2.985 h 12.75 c 0.664,0 1.179,0.625 1.179,1.218 z"
                    style="fill: #ed1539; fill-opacity: 1; fill-rule: nonzero; stroke: none"
            />
            <path
                    d="m 125.758,17.094 h 2.152 c 1.219,0 1.356,0.176 1.356,1.394 v 1.703 c 0,0.692 -0.071,1.387 -1.149,1.387 h -2.359 v 5.18 c 0,1.074 -0.418,1.351 -1.356,1.351 h -1.879 c -0.867,0 -1.386,-0.171 -1.386,-1.109 v -5.422 h -11.953 c -1.012,0 -1.079,-0.695 -1.079,-1.387 v -1.496 c 0,-0.386 0.106,-0.629 0.243,-0.871 L 121.137,0.66 121.203,0.59 c 0.242,-0.176 0.594,-0.277 1.043,-0.277 h 2.399 c 1.078,0 1.113,0.66 1.113,1.355 z m -4.621,0 v -9.52 l -6.989,9.52 z"
                    style="fill: #ed1539; fill-opacity: 1; fill-rule: nonzero; stroke: none"
            />
          </g>
        </g>
        <g id="g108">
          <g id="g110"></g>
        </g>
        <g id="g142">
          <g id="g144"></g>
        </g>
      </g>
    </g>

    <rect
            height="180.37238"
            style="
        opacity: 1;
        fill: none;
        fill-opacity: 1;
        fill-rule: nonzero;
        stroke: none;
        stroke-width: 17.25;
        stroke-linejoin: round;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-dashoffset: 2.11465836;
        stroke-opacity: 0.99428555;
      "
            width="598.25989"
            x="2062.6394"
            y="-431.42383"
    />
    <rect
            height="183.13057"
            style="
        opacity: 1;
        fill: none;
        fill-opacity: 1;
        fill-rule: nonzero;
        stroke: none;
        stroke-width: 17.25;
        stroke-linejoin: round;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-dashoffset: 2.11465836;
        stroke-opacity: 0.99428555;
      "
            width="465.76453"
            x="2062.6394"
            y="-431.42383"
    />
    <rect
            height="45.589947"
            style="
        opacity: 1;
        fill: none;
        fill-opacity: 1;
        fill-rule: nonzero;
        stroke: none;
        stroke-width: 17.25;
        stroke-linejoin: round;
        stroke-miterlimit: 4;
        stroke-dasharray: none;
        stroke-dashoffset: 2.11465836;
        stroke-opacity: 0.99428555;
      "
            width="109.2599"
            x="2528.4038"
            y="-248.29326"
    />
  </svg>
</template>
