import {UuidService} from "@lib/common/uuid/UuidService"

export type BaseIdType = string

export interface IId {
    id: BaseIdType
}

export class Id<T> {

    static map: Record<BaseIdType, BaseIdType> = {}
    private readonly id: BaseIdType

    constructor(id: BaseIdType | Id<T> | IId) {
        if (id instanceof Id) {
            this.id = id.value
        } else if (this.isIId(id)) {
            this.id = this.assertUuidV7(id.id)
        } else {
            this.id = this.assertUuidV7(id)
        }
    }
    get value(): BaseIdType {
        return this.id
    }

    static required<M>(id: Nullable<Id<M>>): Id<M> {
        return assertNotNull(id)
    }

    private assertUuidV7(id: BaseIdType): BaseIdType {
        if (UuidService.isV7(id))
            return id
        else
            throw Error(`Invalid UUID: ${id}`)
    }

    toString(): string {
        return `${this.value}`
    }

    toJSON() {
        return this.value
    }

    private isIId(value: unknown): value is IId {
        return !!(value && value["id"])
    }
}

function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
    if (val === undefined || val === null) {
        throw new Error(
            `Expected 'val' to be defined, but received ${val}`,
        )
    }
}

function assertNotNull<T>(value: Nullable<T> | undefined): T {
    assertIsDefined(value)
    return value as T
}
