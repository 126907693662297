import {MenuItem} from "@lib/common/menu/MenuItem"

export class TabMenuItem extends MenuItem {
    override children: [MenuItem]

    constructor(menuItem: Partial<MenuItem>) {
        super(menuItem)
        this.children = [
            new MenuItem({
                name: menuItem.name as string + "-tab-view",
                visible: false,
                rights: menuItem.rights?.slice(),
                view: () => import("@lib/view/TabView.vue"),
                children: menuItem.children,
            }),
        ]
    }

    get nestedChildren() {
        if (!Array.isArray(this.children[0].children))
            this.children[0].children = []
        return this.children[0].children
    }

    add(child: MenuItem): this {
        child.param = "dialog"
        this.nestedChildren.push(child)
        return this
    }

    addIf(child: MenuItem, constraint: boolean) {
        if (constraint)
            return this.add(child)
        return this
    }

    target(child: MenuItem) {
        this.route = {name: child.routeRecordName}
        return this
    }
}