import {Repository} from "@lib/common/repository/Repository"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {Serializable} from "@lib/common/serializable/Serializable"

export class OrganisationDistinct extends Serializable<OrganisationDistinct> {}

export class OrganisationRepository extends Repository<Organisation> {

    kontakt = this.buildRelation(Kontakt, "kontakt")
    mandant = this.buildRelation(Mandant, "mandant")

    constructor() {
        super(Organisation, "organisation")
    }

    async liste<K>(params: K): Promise<PartitionedResultSet<Organisation, OrganisationDistinct>> {
        const result = await this.api.get<PartitionedResultSet<Organisation, OrganisationDistinct>>(this.route("organisationliste"), params)
        return new PartitionedResultSet(Organisation, OrganisationDistinct, result)
    }
}
