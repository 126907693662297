import {Repository} from "@lib/common/repository/Repository"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {ArtikelEinheit} from "@generated/de/lohn24/model/artikel/ArtikelEinheit"

export class ArtikelRepository extends Repository<Artikel> {
    constructor() {
        super(Artikel, "artikel")
    }

    artikelEinheitValues(): ArtikelEinheit[] {
        return Object.keys(ArtikelEinheit).map(it => ArtikelEinheit[it])
    }
}