import { IRuntimeTypeInformation, Serializable } from "@lib/index"
import { IBelegausgangAuswertungElement, BelegausgangAuswertungElement } from "@generated/de/lohn24/model/beleg/auswertung/BelegausgangAuswertungElement"

export interface IBelegausgangAuswertung {
	items: Array<IBelegausgangAuswertungElement>
	size: number
	distincts: Record<string,Array<any>>
}

export class BelegausgangAuswertung extends Serializable<BelegausgangAuswertung> {
	public items!: Array<BelegausgangAuswertungElement>
	public size!: number
	public distincts!: Record<string,Array<any>>

	static _types: Record<keyof IBelegausgangAuswertung, IRuntimeTypeInformation> = {
		items: { type: [BelegausgangAuswertungElement] },
		size: { type: Number },
		distincts: { type: [String, Array] },
	}

	static props: Record<keyof IBelegausgangAuswertung, string> = {
		items: "items",
		size: "size",
		distincts: "distincts",
	}

	constructor(_json: IBelegausgangAuswertung) {
		super(_json)
	}
}
