<template>
  <el-icon v-bind="$attrs">
    <rag-svg />
  </el-icon>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import RagSvg from "./RagSVG.vue"

export default defineComponent({
    components: {
        RagSvg,
    },
})
</script>
