import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, Suspense as _Suspense, withCtx as _withCtx, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "el-card__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createBlock(_component_el_tabs, {
    key: _ctx.currentPathName,
    modelValue: _ctx.activeTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event)),
    class: "tab-view",
    type: "border-card"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createBlock(_component_el_tab_pane, {
          key: tab.label + index,
          label: tab.label,
          name: tab.dataKey,
          lazy: ""
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(tab.label), 1)
          ]),
          default: _withCtx(() => [
            (_ctx.tabIsRoute(tab))
              ? (_openBlock(), _createBlock(_component_router_view, { key: _ctx.currentPathName }, {
                  default: _withCtx(({Component}) => [
                    Component
                      ? (_openBlock(), _createBlock(_Suspense, { key: 0 }, {
                          fallback: _withCtx(() => [
                            _createTextVNode(" Loading... ")
                          ]),
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["label", "name"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue"]))
}