import {computed, ref, Ref} from "vue"
import {ViewModel} from "@lib/common/model/ViewModel"
import {Config} from "@lib/Config"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {Resource, ResourceCache} from "@lib/model/Resource"

export abstract class OptionalModelController<T extends ViewModel<T>> {
    public resource = Resource
    public resourceCache = ResourceCache
    dataSavedSuccesfullyMessage: string = ""
    dataSaveFailedMessage: string = ""
    public editableValue!: Ref<T>
    model = computed<T | null>(
        () => this.optional(this) ? this.editableValue.value : null,
    )
    protected showSuccessMessage = false

    protected constructor(model: T, private optional: (instance: OptionalModelController<T>) => boolean) {
        this.editableValue = ref(model) as Ref<T>
    }

    get modified(): boolean {
        return this.model.value?.changed ?? false
    }

    async create(...args: any[]): Promise<T> {
        try {
            const result = await this.createModel(this.editableValue.value, ...args)
            this.editableValue.value = result
            return this.updateValues(result)
        } catch (e) {
            if (e instanceof ValidationError) {
                await Config.showValidationErrorMessage(e.response?.data || [])
            } else {
                await Config.showDataSaveFailedMessage(e, this.dataSaveFailedMessage)
            }
            throw e
        }
    }

    async update(...args: any[]): Promise<T> {
        try {
            if (this.modified || this.editableValue.value.bearbeitungszustand.created) {
                const result = (this.editableValue.value.bearbeitungszustand.created && !this.editableValue.value.bearbeitungszustand.deleted)
                    ? await this.createModel(this.editableValue.value, ...args)
                    : await this.updateModel(this.editableValue.value, ...args)
                if (this.showSuccessMessage)
                    await Config.showDataSavedSuccessfullyMessage(this.dataSavedSuccesfullyMessage)
                this.editableValue.value = result
                return this.updateValues(result)
            }

            return this.updateValues(this.editableValue.value)
        } catch (e) {
            if (e instanceof ValidationError) {
                await Config.showValidationErrorMessage(e.response?.data || [])
            } else {
                await Config.showDataSaveFailedMessage(e, this.dataSaveFailedMessage)
            }
            throw e
        }
    }

    updateValues(model: T): T {
        this.editableValue.value = model
        return model
    }

    protected abstract createModel(model: T, ...args: any[]): Promise<T>

    protected abstract updateModel(model: T, ...args: any[]): Promise<T>
}