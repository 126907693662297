<script lang="ts" setup>
import {shallowRef} from "vue"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {ComponentRenderer, ComponentRendererProps} from "@lib/common/dialog/ComponentRenderer"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {DebitorSummaryDialogController} from "@intranet/view/debitor/summary/DebitorSummaryDialogController"
import SummaryDialog from "@intranet/view/SummaryDialog.vue"
import IconEmail from "@lib/view/icons/IconEmail.vue"
import {DebitorSummaryDialogViewModel} from "@intranet/view/debitor/summary/DebitorSummaryDialogViewModel"
import TooltipButton from "@lib/view/TooltipButton.vue"

const props = withDefaults(
    defineProps<ComponentRendererProps<Debitor> & { controller: DebitorSummaryDialogController }>(),
    {
        __loader: ComponentRenderer.loaderDefault,
        onVanish: ComponentRenderer.onVanishDefault,
    },
)

const anschrift = shallowRef<Anschrift | null>(null)
props.controller.useController()
props.controller.anschrift(anschrift)

</script>

<template>
  <summary-dialog
          :anschrift="anschrift"
          :controller="props.controller"
          class="debitor-summary"
          @edit="() => props.controller.edit()"
  >
    <template #content-left-append>
      <el-table
              v-if="props.controller.viewModel.rechnungsemail.isNotEmpty()"
              :data="props.controller.viewModel.rechnungsemail"
              size="small"
              stripe
      >
        <el-table-column>
          <template #header>
            <span><icon-email /></span>
            <span>{{ DebitorSummaryDialogViewModel.label.rechnung }}</span>
          </template>
          <template #default="{row}">
            <slot v-if="row.email"
                  :email="row.email"
                  name="email-prefix"
            >
              <tooltip-button :options="() => props.controller.viewModel.debitorTicketOptions()" />
            </slot>
            <span>{{ row.email }}</span>
          </template>
        </el-table-column>
      </el-table>
    </template>
  </summary-dialog>
</template>

<style lang="scss">
.debitor-summary {
  .cell {
    button {
      display: inline-block;
    }

    span {
      position: relative;

      &:nth-child(2) {
        left: 16px;
      }

      i.el-icon {
        position: absolute;
        bottom: 1px;

        svg {
          height: 12px;
          width: 12px;
        }
      }
    }
  }
}
</style>