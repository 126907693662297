import {Repository} from "@lib/common/repository/Repository"
import {TicketTag} from "@generated/de/lohn24/model/ticket/tag/TicketTag"
import {ISummary, Summary} from "@generated/de/lohn24/endpoint/TicketTagSynchronizeCommand/Summary"
import {from} from "@lib/common/Functions"

export class TicketTagRepository extends Repository<TicketTag> {
    constructor() {
        super(TicketTag, "tickettag")
    }

    async synchronize(): Promise<Summary> {
        const result = await this.api.post<ISummary>(this.route("synchronize"), {})
        return from(Summary, result)
    }
}