<template>
  <el-tooltip
          :show-after="1000"
          content="Anschriftenträger"
          placement="top"
  >
    <el-button
            text
            @click="download"
    >
      <icon-location />
    </el-button>
  </el-tooltip>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Id} from "@lib/common/model/Id"
import {PdfMenuItem} from "@intranet/view/pdf/PdfMenuItem"
import {MandantAnschriftentraegerPdf} from "@intranet/view/mandant/anschriftentraeger/MandantAnschriftentraegerPdf"
import IconLocation from "@lib/view/icons/IconRZL/IconLocation.vue"

export default defineComponent({
    name: "MandantAnschriftentraegrButton",
    components: {IconLocation},
    props: {
        id: {
            type: Id as PropType<Id<Mandant>>,
            required: true,
        },
        kooperationspartner: Boolean,
    },
    setup(props) {
        async function download() {
            PdfMenuItem.open(
                MandantAnschriftentraegerPdf,
                {mandant: props.id.toString()},
                props.kooperationspartner ? {kooperationspartner: "true"} : undefined,
            )
        }

        return {
            download,
        }
    },
})
</script>

<style scoped>

</style>