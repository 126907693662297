import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"
import { ZammadArticleType } from "@generated/de/lohn24/zammad/model/article/ZammadArticleType"
import { Ticket } from "@generated/de/lohn24/model/ticket/Ticket"

export interface ITicketArtikel extends IModel {
	ticket: string
	autoReply: boolean | null
	erstellt: LocalDateTime
	zammadId: number
	an: string | null
	betreff: string | null
	cc: string | null
	contentType: string
	inhalt: string
	von: string
	type: ZammadArticleType
}

export class TicketArtikel extends Model<TicketArtikel> {
	public ticket!: Id<Ticket>
	public autoReply!: boolean | null
	public erstellt!: LocalDateTime
	public zammadId!: number
	public an!: string | null
	public betreff!: string | null
	public cc!: string | null
	public contentType!: string
	public inhalt!: string
	public von!: string
	public type!: ZammadArticleType

	static _types: Record<keyof ITicketArtikel, IRuntimeTypeInformation> = {
		id: { type: Id },
		ticket: { type: Id },
		autoReply: { type: Boolean, nullable: true },
		erstellt: { type: LocalDateTime },
		zammadId: { type: Number },
		an: { type: String, nullable: true },
		betreff: { type: String, nullable: true },
		cc: { type: String, nullable: true },
		contentType: { type: String },
		inhalt: { type: String },
		von: { type: String },
		type: { type: ZammadArticleType },
	}

	static props: Record<keyof ITicketArtikel, string> = {
		id: "id",
		ticket: "ticket",
		autoReply: "autoReply",
		erstellt: "erstellt",
		zammadId: "zammadId",
		an: "an",
		betreff: "betreff",
		cc: "cc",
		contentType: "contentType",
		inhalt: "inhalt",
		von: "von",
		type: "type",
	}

	constructor(_json: ITicketArtikel) {
		super(_json)
	}
}
