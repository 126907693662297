import {Menu} from "@lib/common/menu/Menu"
import {LoginMenuItem} from "@lib/common/menu/LoginMenuItem"
import {NotFoundMenuItem} from "@lib/common/menu/404MenuItem"
import {BearbeitungListMenuItem} from "@intranet/plugin/router/BearbeitungListMenuItem"
import {MandantMenuItem} from "@intranet/view/mandant/MandantMenuItem"
import {UserMenuItem} from "@intranet/plugin/router/UserMenuItem"
import {LogoutMenuItem} from "@lib/common/menu/LogoutMenuItem"
import {auth} from "@lib/common/Authentication"
import {MitarbeiterMenuItem} from "@intranet/view/mitarbeiter/MitarbeiterMenuItem"
import {VersandDokumentPdf} from "@intranet/view/pdf/VersandDokumentPdf"
import {EinzelrechnungVorschauPdf} from "@intranet/view/pdf/EinzelrechnungVorschauPdf"
import {SammelrechnungVorschauPdf} from "@intranet/view/pdf/SammelrechnungVorschauPdf"
import {RuecklastschriftPdf} from "@intranet/view/pdf/RuecklastschriftPdf"
import {DebitorAnschriftentraegerPdf} from "@intranet/view/pdf/DebitorAnschriftentraegerPdf"
import {BelegPdf} from "@intranet/view/pdf/BelegPdf"
import {MandantAnschriftentraegerPdf} from "@intranet/view/mandant/anschriftentraeger/MandantAnschriftentraegerPdf"
import {RechnungswesenMenuItem} from "@intranet/plugin/router/RechnungswesenMenuItem"
import {EinzelrechnungMenuItem} from "@intranet/view/rechnung/einzelrechnung/EinzelrechnungMenuItem"
import {SammelrechnungMenuItem} from "@intranet/view/rechnung/sammelrechnung/SammelrechnungMenuItem"
import {BelegausgangMenuItem} from "@intranet/view/rechnung/belegausgang/BelegausgangMenuItem"
import {ArtikelkatalogMenuItem} from "@intranet/view/rechnung/artikelkatalog/ArtikelkatalogMenuItem"
import {KooperationspartnerMenuItem} from "@intranet/view/kooperationspartner/KooperationspartnerMenuItem"
import {DebitorMenuItem} from "@intranet/view/debitor/DebitorMenuItem"
import {NewsMenuItem} from "@intranet/plugin/router/NewsMenuItem"
import {NewsUngelesenMenuItem} from "@intranet/view/news/ungelesen/NewsUngelesenMenuItem"
import {NewsGesamtMenuItem} from "@intranet/view/news/gesamt/NewsGesamtMenuItem"
import {NewsArbeitsanweisungMenuItem} from "@intranet/view/news/arbeitsanweisung/NewsArbeitsanweisungMenuItem"
import {NewsVerwaltenMenuItem} from "@intranet/view/news/verwaltung/NewsVerwaltenMenuItem"
import {LinkMenuItem} from "@intranet/plugin/router/LinkMenuItem"
import {ToolsMenuItem} from "@intranet/plugin/router/ToolsMenuItem"
import {KonverterLohnbewegungMenuItem} from "@intranet/view/konverterlohnbewegung/KonverterLohnbewegungMenuItem"
import {AuswertungMenuItem} from "@intranet/plugin/router/AuswertungMenuItem"
import {BelegausgangAuswertungMenuItem} from "@intranet/view/auswertung/belegausgang/BelegausgangAuswertungMenuItem"
import {LeistungsdatenMenuItem} from "@intranet/view/auswertung/leistungsdaten/LeistungsdatenMenuItem"
import {AdministrationMenuItem} from "@intranet/plugin/router/AdministrationMenuItem"
import {MitarbeiterverwaltungMenuItem} from "@intranet/view/mitarbeiterverwaltung/MitarbeiterverwaltungMenuItem"
import {AnruferstatistikMenuItem} from "@intranet/view/anruferstatistik/AnruferstatistikMenuItem"
import {RollenverwaltungMenuItem} from "@intranet/view/rollenverwaltung/RollenverwaltungMenuItem"
import {
    TicketgruppeMappingVerwaltungMenuItem,
} from "@intranet/view/ticketgruppemappingverwaltung/TicketgruppeMappingVerwaltungMenuItem"
import {VersandMenuItem} from "@intranet/view/versand/VersandMenuItem"
import {TicketMenuItem} from "@intranet/view/ticket/TicketMenuItem"
import {ErrorMenuItem} from "@intranet/view/error/ErrorMenuItem"
import {MenuItem} from "@lib/common/menu/MenuItem"
import {TechnikMenuItem} from "@intranet/plugin/router/TechnikMenuItem"
import {CommandMenuItem} from "@intranet/view/development/commands/CommandMenuItem"
import {FailureMenuItem} from "@intranet/view/development/failure/FailureMenuItem"
import {AddisonMenuItem} from "@intranet/view/development/addison/AddisonMenuItem"
import {ArtikelMenuItem} from "@intranet/view/rechnung/artikel/ArtikelMenuItem"
import {ConfigurationMenuItem} from "@intranet/view/development/configuration/ConfigurationMenuItem"

const menu = new Menu(...[
    LoginMenuItem,
    NotFoundMenuItem,
    TechnikMenuItem
        .target(CommandMenuItem)
        .add(CommandMenuItem)
        .add(FailureMenuItem)
        .add(AddisonMenuItem)
        .add(ConfigurationMenuItem)
    ,
    AdministrationMenuItem
        .target(MitarbeiterverwaltungMenuItem)
        .add(MitarbeiterverwaltungMenuItem)
        .add(RollenverwaltungMenuItem)
        .add(TicketgruppeMappingVerwaltungMenuItem)
    ,
    AuswertungMenuItem
        .target(BelegausgangAuswertungMenuItem)
        .add(BelegausgangAuswertungMenuItem)
        .add(LeistungsdatenMenuItem)
        .add(AnruferstatistikMenuItem)

    ,
    ToolsMenuItem
        .target(KonverterLohnbewegungMenuItem)
        .add(KonverterLohnbewegungMenuItem),
    LinkMenuItem,
    NewsMenuItem
        .add(NewsUngelesenMenuItem)
        .add(NewsGesamtMenuItem)
        .add(NewsArbeitsanweisungMenuItem)
        .add(NewsVerwaltenMenuItem),
    RechnungswesenMenuItem
        .target(EinzelrechnungMenuItem)
        .add(EinzelrechnungMenuItem)
        .add(SammelrechnungMenuItem)
        .add(BelegausgangMenuItem)
        .add(ArtikelkatalogMenuItem)
        .add(ArtikelMenuItem)
        .add(KooperationspartnerMenuItem)
        .add(DebitorMenuItem)
    ,
    BearbeitungListMenuItem
        .target(MandantMenuItem)
        .add(MandantMenuItem)
        .add(VersandMenuItem)
        .add(TicketMenuItem)
    ,
    MitarbeiterMenuItem,
    UserMenuItem
        .add(new LogoutMenuItem(
                (async (to, from, next, loginRoute) => {
                    await auth.logout()
                    auth.clearSession()
                    next(loginRoute)
                }),
            ),
        ),
    EinzelrechnungVorschauPdf,
    SammelrechnungVorschauPdf,
    VersandDokumentPdf,
    RuecklastschriftPdf,
    DebitorAnschriftentraegerPdf,
    MandantAnschriftentraegerPdf,
    BelegPdf,
    ErrorMenuItem,
])

const router = menu.createRouter()

export async function routeTo(menuItem: MenuItem) {
    return router.push({name: menuItem.routeRecordName})
}

export {
    menu,
    router,
}
