import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {NewsDialogController} from "@intranet/view/news/NewsDialogController"
import {NewsViewModel} from "@intranet/view/news/NewsViewModel"
import NewsEditDialogView from "@intranet/view/news/edit/NewsEditDialogView.vue"
import {News} from "@generated/de/lohn24/model/news/News"
import {Resource} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"
import {from} from "@lib/common/Functions"

export class NewsEditDialog extends ComponentRenderer<typeof NewsEditDialogView> {

    constructor(news: News, done: (news: News) => void) {
        super(
            NewsEditDialogView,
            {
                controller: new NewsDialogController(new NewsViewModel(news)),
                done: done,
            },
        )
    }

    static async show(id: Id<News> | null): Promise<News> {
        if (id === null)
            return from(News, {})

        const news = await Resource.news.find(id)
        return new Promise(resolve => {
            new NewsEditDialog(news, resolve)
        })
    }
}