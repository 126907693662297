import {
    ContextMenuClass,
    ContextMenuItemOption,
    ContextSubMenuItemOption,
} from "@lib/view/contextmenu/ContextMenuClass"
import {RouteLocationNormalizedLoaded, Router} from "vue-router"

export interface ContextMenuContext {
    event: PointerEvent,
    router: Router,
    route: RouteLocationNormalizedLoaded,
}

export interface ContextMenuRowContext<M> extends ContextMenuContext {
    row: M,
    column: any,
}

export class ContextMenuBuilder {

    protected constructor(private event: MouseEvent) {
    }

    buildOptions(): (ContextMenuItemOption | ContextSubMenuItemOption)[] {
        return []
    }

    protected build() {
        ContextMenuClass.buildContextMenu({
            event: this.event,
            options: this.buildOptions(),
        })
    }
}