<template>
  <el-row>
    <el-col v-if="!dialogTitle" :span="23">
      <h3>{{ dialogTitle }}</h3>
    </el-col>
    <el-col v-if="editable" :span="1">
      <el-button size="small" text @click="$emit('click')">
        <icon-edit size="large" />
      </el-button>
    </el-col>
    <el-col class="readable-text content">
      <vue-showdown :markdown="modelValue"></vue-showdown>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import IconEdit from "@lib/view/icons/IconEdit.vue"

export default defineComponent({
    name: "Bearbeitungshinweis",
    components: {
        IconEdit,
    },
    props: {
        modelValue: {
            type: String,
            required: true,
        },
        editable: Boolean,
        dialogTitle: String,
    },
})
</script>

<style scoped>
.readable-text {
    text-align: left;
}
</style>
