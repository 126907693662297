import { IRuntimeTypeInformation, Serializable } from "@lib/index"

export interface IRabbitMqResetBody {
	relations: Array<string>
	resources: Array<string>
}

export class RabbitMqResetBody extends Serializable<RabbitMqResetBody> {
	public relations!: Array<string>
	public resources!: Array<string>

	static _types: Record<keyof IRabbitMqResetBody, IRuntimeTypeInformation> = {
		relations: { type: [String] },
		resources: { type: [String] },
	}

	static props: Record<keyof IRabbitMqResetBody, string> = {
		relations: "relations",
		resources: "resources",
	}

	constructor(_json: IRabbitMqResetBody) {
		super(_json)
	}
}
