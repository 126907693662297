import { IModel, IRuntimeTypeInformation, Id, ModelBuilder } from "@lib/index"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { IDebitor, Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { Artikelkatalog } from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import { IAnschrift, Anschrift } from "@generated/de/lohn24/model/anschrift/Anschrift"

export interface IKooperationspartnerBuilder extends IModel {
	artikelkatalog: string | null
	sammelrechnung: string | null
	anschrift: IAnschrift
	postanschrift: IAnschrift | null
	keineRechnung: boolean
	debitor: IDebitor | null
	sammelrechnungFestschreiben: number
	kennung: string
	name: string
}

export class KooperationspartnerBuilder extends ModelBuilder<KooperationspartnerBuilder> {
	public artikelkatalog!: Id<Artikelkatalog> | null
	public sammelrechnung!: Id<Kooperationspartner> | null
	public anschrift!: Anschrift
	public postanschrift!: Anschrift | null
	public keineRechnung!: boolean
	public debitor!: Debitor | null
	public sammelrechnungFestschreiben!: number
	public kennung!: string
	public name!: string

	static _types: Record<keyof IKooperationspartnerBuilder, IRuntimeTypeInformation> = {
		id: { type: Id },
		artikelkatalog: { type: Id, nullable: true },
		sammelrechnung: { type: Id, nullable: true },
		anschrift: { type: Anschrift },
		postanschrift: { type: Anschrift, nullable: true },
		keineRechnung: { type: Boolean },
		debitor: { type: Debitor, nullable: true },
		sammelrechnungFestschreiben: { type: Number },
		kennung: { type: String },
		name: { type: String },
	}

	static props: Record<keyof IKooperationspartnerBuilder, string> = {
		id: "id",
		artikelkatalog: "artikelkatalog",
		sammelrechnung: "sammelrechnung",
		anschrift: "anschrift",
		postanschrift: "postanschrift",
		keineRechnung: "keineRechnung",
		debitor: "debitor",
		sammelrechnungFestschreiben: "sammelrechnungFestschreiben",
		kennung: "kennung",
		name: "name",
	}

	constructor(_json: IKooperationspartnerBuilder) {
		super(_json)
	}
}
