import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"
import { NewsType } from "@generated/de/lohn24/model/news/NewsType"
import { NewsContentType } from "@generated/de/lohn24/model/news/NewsContentType"
import { Account } from "@generated/dev/api/model/account/Account"

export interface INews extends IModel {
	author: string
	created: LocalDateTime
	deleted: LocalDateTime | null
	released: LocalDateTime | null
	updated: LocalDateTime
	contentType: NewsContentType
	type: NewsType
	content: string | null
	teaser: string
	title: string
}

export class News extends Model<News> {
	public author!: Id<Account>
	public created!: LocalDateTime
	public deleted!: LocalDateTime | null
	public released!: LocalDateTime | null
	public updated!: LocalDateTime
	public contentType!: NewsContentType
	public type!: NewsType
	public content!: string | null
	public teaser!: string
	public title!: string

	static _types: Record<keyof INews, IRuntimeTypeInformation> = {
		id: { type: Id },
		author: { type: Id },
		created: { type: LocalDateTime },
		deleted: { type: LocalDateTime, nullable: true },
		released: { type: LocalDateTime, nullable: true },
		updated: { type: LocalDateTime },
		contentType: { type: NewsContentType },
		type: { type: NewsType },
		content: { type: String, nullable: true },
		teaser: { type: String },
		title: { type: String },
	}

	static props: Record<keyof INews, string> = {
		id: "id",
		author: "author",
		created: "created",
		deleted: "deleted",
		released: "released",
		updated: "updated",
		contentType: "contentType",
		type: "type",
		content: "content",
		teaser: "teaser",
		title: "title",
	}

	constructor(_json: INews) {
		super(_json)
	}
}
