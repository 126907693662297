import {LocalMonth} from "@lib/common/LocalMonth"
import {Id} from "@lib/common/model/Id"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Serializable} from "@lib/common/serializable/Serializable"
import {Account} from "@generated/dev/api/model/account/Account"

export interface IMandantListDistinct {
    abrechnungszeitraum: Array<LocalMonth>
    kooperationspartner: Array<Id<Kooperationspartner>>
    sachbearbeiter: Array<Id<Account>>
    effektiverSachbearbeiter: Array<Id<Account>>
}

export class MandantListDistinct extends Serializable<MandantListDistinct> {

    static _types = {
        abrechnungszeitraum: {type: [LocalMonth]},
        kooperationspartner: {type: [Id]},
        sachbearbeiter: {type: [Id]},
        effektiverSachbearbeiter: {type: [Id]},
    }
    abrechnungszeitraum!: Array<LocalMonth>
    kooperationspartner!: Array<Id<Kooperationspartner>>
    sachbearbeiter!: Array<Id<Account>>
    effektiverSachbearbeiter!: Array<Id<Account>>

    constructor(obj: IMandantListDistinct = {
        abrechnungszeitraum: [],
        kooperationspartner: [],
        sachbearbeiter: [],
        effektiverSachbearbeiter: []
    }) {
        super(obj)
    }
}