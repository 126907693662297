import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { TicketTagFunktion } from "@generated/de/lohn24/model/ticket/tag/TicketTagFunktion"

export interface ITicketTag extends IModel {
	zammadId: number
	name: string
	funktion: TicketTagFunktion | null
}

export class TicketTag extends Model<TicketTag> {
	public zammadId!: number
	public name!: string
	public funktion!: TicketTagFunktion | null

	static _types: Record<keyof ITicketTag, IRuntimeTypeInformation> = {
		id: { type: Id },
		zammadId: { type: Number },
		name: { type: String },
		funktion: { type: TicketTagFunktion, nullable: true },
	}

	static props: Record<keyof ITicketTag, string> = {
		id: "id",
		zammadId: "zammadId",
		name: "name",
		funktion: "funktion",
	}

	constructor(_json: ITicketTag) {
		super(_json)
	}
}
