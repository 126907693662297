<template>
  <svg height="609" viewBox="0 0 1260 609" width="1260">
    <g id="g10" inkscape:groupmode="layer" transform="matrix(1.3333333,0,0,1.3333333,0,641.20467)">
      <rect
              id="rect910"
              height="180.37238"
              style="
          opacity: 1;
          fill: none;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 17.25;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-dashoffset: 2.11465836;
          stroke-opacity: 0.99428555;
          paint-order: normal;
        "
              width="598.25989"
              x="2062.6394"
              y="-431.42383"
      />
      <rect
              id="rect912"
              height="183.13057"
              style="
          opacity: 1;
          fill: none;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 17.25;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-dashoffset: 2.11465836;
          stroke-opacity: 0.99428555;
          paint-order: normal;
        "
              width="465.76453"
              x="2062.6394"
              y="-431.42383"
      />
      <rect
              id="rect914"
              height="45.589947"
              style="
          opacity: 1;
          fill: none;
          fill-opacity: 1;
          fill-rule: nonzero;
          stroke: none;
          stroke-width: 17.25;
          stroke-linejoin: round;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-dashoffset: 2.11465836;
          stroke-opacity: 0.99428555;
          paint-order: normal;
        "
              width="109.2599"
              x="2528.4038"
              y="-248.29326"
      />
      <text
              id="text26046"
              style="
          font-style: normal;
          font-weight: normal;
          font-size: 40px;
          line-height: 1.25;
          font-family: sans-serif;
          white-space: pre;
          fill: #545051;
          fill-opacity: 1;
          stroke: none;
        "
              transform="matrix(13.155936,0,0,15.544145,-16930.54,-679.6443)"
              xml:space="preserve"
      >
        <tspan id="tspan40140" x="1309.0098" y="42.118213">Z</tspan>
      </text>
      <text
              id="text26046-3"
              style="
          font-style: normal;
          font-weight: normal;
          font-size: 40px;
          line-height: 1.25;
          font-family: sans-serif;
          white-space: pre;
          fill: #545051;
          fill-opacity: 1;
          stroke: none;
        "
              transform="matrix(16.136352,0,0,15.544145,-20537.523,-680.29134)"
              xml:space="preserve"
      >
        <tspan id="tspan40142" x="1309.0098" y="42.118213">L</tspan>
      </text>
      <text
              id="text26046-2"
              style="
          font-style: normal;
          font-weight: normal;
          font-size: 40px;
          line-height: 1.25;
          font-family: sans-serif;
          white-space: pre;
          fill: #545051;
          fill-opacity: 1;
          stroke: none;
        "
              transform="matrix(13.155936,0,0,15.544145,-17269.836,-679.51874)"
              xml:space="preserve"
      >
        <tspan id="tspan40144" x="1309.0098" y="42.118213">R</tspan>
      </text>
    </g>
  </svg>
</template>
