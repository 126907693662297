<template>
  <el-row class="mandant-kooperationspartner">
    <el-col>
      <el-row justify="space-between">
        <div class="el-col ellipsis">
          <h3>{{ viewModel.titel }} </h3>
        </div>
        <div class="el-col">
          <el-button-group class="mandant-kooperationspartner-button-group">
            <slot name="header-right" />
          </el-button-group>
        </div>
      </el-row>
      <el-row>
        <el-col>
          <slot name="anschrift">
            <anschrift-summary
                    :anschrift="viewModel.anschrift"
                    :headline="false"
            />
          </slot>
        </el-col>
      </el-row>
      <br>
      <br>
      <slot
              :kontakte="viewModel.kontakte"
              :name="MandantKooperationspartnerViewModel.slots.kontakt"
      >
        <kontakt-summary
                v-if="viewModel.kontakte.isNotEmpty()"
                :data="viewModel.kontakte"
                :headline="false"
        >
          <template #email-prefix="{kontakt}">
            <mandant-zammad-ticket
                    v-if="mandant"
                    :kontakt="kontakt"
                    :mandant="mandant.id"
            >
            </mandant-zammad-ticket>
          </template>
        </kontakt-summary>
      </slot>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref, watch} from "vue"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Id} from "@lib/common/model/Id"
import {Resource} from "@lib/model/Resource"
import KontaktSummary from "@intranet/view/kontakt/KontaktSummary.vue"
import {
    MandantKooperationspartnerViewModel,
} from "@intranet/view/mandant/kooperationspartner/MandantKooperationspartnerViewModel"
import AnschriftSummary from "@intranet/view/anschrift/AnschriftSummary.vue"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import MandantZammadTicket from "@intranet/view/mandant/ticket/MandantZammadTicket.vue"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {from} from "@lib/common/Functions"

export default defineComponent({
    name: "MandantKooperationspartner",
    components: {
        MandantZammadTicket,
        AnschriftSummary,
        KontaktSummary,
    },
    props: {
        modelValue: {
            type: Id as PropType<Id<Kooperationspartner> | null>,
            required: true,
        },
        mandant: {
            type: Mandant as PropType<Mandant>,
            required: false,
        },
    },
    setup(props) {
        const kooperationspartner = ref()
        const kontakte = ref()
        const anschrift = ref()

        async function pullKooperationspartner(id: Id<Kooperationspartner>) {
            kooperationspartner.value = await Resource.kooperationspartner.find(id)
        }

        async function pullAnschrift(id: Id<Kooperationspartner>) {
            anschrift.value = await Resource.kooperationspartner.anschrift.first(id)
        }

        async function pullKontakte(id: Id<Kooperationspartner>) {
            kontakte.value = await Resource.kooperationspartner.kontakt.all(id)
        }

        watch(() => props.modelValue, _ => {
            if (props.modelValue !== null) {
                // no await -> can run "parallel"
                pullKooperationspartner(props.modelValue)
                pullAnschrift(props.modelValue)
                pullKontakte(props.modelValue)
            }
        }, {immediate: true})

        const viewModel = computed(() => new MandantKooperationspartnerViewModel(
            kooperationspartner.value ?? from(Kooperationspartner, {}),
            anschrift.value ?? from(Anschrift, {}),
            kontakte.value ?? [],
        ))

        return {
            viewModel,
            MandantKooperationspartnerViewModel,
        }
    },
})
</script>

<style lang="scss">
.mandant-kooperationspartner {
  .ellipsis {
    display: inline-block;
    max-width: calc(90%);

    h3 {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .mandant-kooperationspartner-button-group {
    .el-button {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
</style>