import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import MandantEditDialogView from "@intranet/view/mandant/edit/MandantEditDialogView.vue"
import {Resource} from "@lib/model/Resource"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {MandantEditDialogController} from "@intranet/view/mandant/edit/MandantEditDialogController"
import {Id} from "@lib/common/model/Id"

export class MandantEditDialog extends ComponentRenderer<typeof MandantEditDialogView> {

    constructor(controller: MandantEditDialogController, done: (model: Mandant | null) => void) {
        super(
            MandantEditDialogView,
            {
                controller: controller,
                done: done,
                closes: controller.closes,
            },
        )
    }

    static async show(id: Id<Mandant>) {
        const mandant = await Resource.mandant.find(id)
        if (!mandant) throw "Kein Mandant gefunden"
        return new Promise<Mandant | null>((resolve) => {
            new MandantEditDialog(
                new MandantEditDialogController(mandant),
                it => resolve(it),
            )
        })
    }
}