<script lang="ts" setup>
import {Component} from "vue"

const emits = defineEmits<{
    "close": []
}>()

const props = defineProps<{
    label: string
    action: (event: MouseEvent) => void
    icon: string | Component
}>()

function onClick(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (props.action)
        props.action(event)
    emits("close")
}

</script>

<template>
  <li class="context-menu-item" @click="onClick" @mousedown.prevent @mouseup.prevent>
    <span class="icon-prepend">
      <slot name="icon">
        <component :is="icon" v-if="icon" />
      </slot>
    </span>
    <span class="context-menu-item__label">{{ label }}</span>
  </li>
</template>

<style lang="scss">
.context-menu-item {
  span.icon-prepend {
    margin-left: calc(0.5 * var(--context-menu-item-padding) - var(--context-menu-item-padding));
    margin-right: calc(var(--context-menu-item-padding) - 0.5 * var(--context-menu-item-padding));
    width: var(--font-size);
  }

  span.context-menu-item__label {
    width: calc(90%);
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>