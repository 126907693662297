import {Nullable} from "@lib/types"
import {Serializable} from "@lib/common/serializable/Serializable"

export class ValidationResult extends Serializable<ValidationResult> {
    message!: string
    propertyName!: string
    propertyValue: Nullable<string> = null

    static isValidationResult(e: any): e is ValidationResult {
        return e instanceof ValidationResult || ("message" in e && "propertyName" in e && "propertyValue" in e)
    }
}