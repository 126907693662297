import {ViewModel} from "@lib/common/model/ViewModel"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"

export class MandantKooperationspartnerViewModel extends ViewModel<MandantKooperationspartnerViewModel> {

    static slots = {
        kontakt: "kontakt",
    }
    static label = {}

    kooperationspartner: Kooperationspartner
    anschrift: Anschrift
    kontakte: Kontakt[]

    constructor(kooperationsaprtner: Kooperationspartner, anschrift: Anschrift, kontakte: Kontakt[]) {
        super()
        this.kooperationspartner = this.registerShallowReactive(kooperationsaprtner)
        this.anschrift = this.registerShallowReactive(anschrift)
        this.kontakte = this.registerShallowReactive(kontakte)
    }

    get titel() {
        return `Kooperationspartner ${this.kooperationspartner.name} (${this.kooperationspartner.kennung})`
    }
}