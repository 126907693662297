import {ContextMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import {Resource} from "@lib/model/Resource"
import {buildCallbackPath, ZammadFormSenderType, ZammadTaskbarBuilder} from "@lib/common/zammad/ZammadTaskbar"
import {auth} from "@lib/common/Authentication"
import {Config} from "@lib/Config"
import IconEmail from "@lib/view/icons/IconEmail.vue"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {assertNotNull} from "@lib/common/Functions"

export interface DebitorZammadTicketContext {
    debitor: Debitor
}

export function debitorZammadTicket(
    context: () => Promise<DebitorZammadTicketContext> | DebitorZammadTicketContext,
): ContextMenuItemOption {
    return new ContextMenuItemOption(
        "E-Mail-Ticket erfassen",
        async () => {
            const client = await Resource.zammad()
            const {debitor} = await context()
            const email = assertNotNull(debitor.mail)
            const zammadUser = await client.createEmailUser(email)
            const taskbar = await client.ticketCreate(new ZammadTaskbarBuilder(
                {
                    formSenderType: ZammadFormSenderType.EMAIL_OUT,
                    customer_id: zammadUser?.id ?? `${debitor.mail}`,
                    group_id: `${(await Resource.debitor.ticketgruppe(debitor.id)).zammadId}`,
                    owner_id: `${auth.me.zammadId}`,
                },
            ))
            window.open(
                buildCallbackPath(Config.ZAMMAD_BASE_URL, taskbar),
                `${Config.ZAMMAD_WINDOW_TARGET}-${taskbar.key}`,
            )
        },
        IconEmail,
    )
}