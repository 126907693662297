import {computed, nextTick, onUnmounted, Ref, ref} from "vue"
import {Config} from "@lib/Config"
import {ViewModel} from "@lib/common/model/ViewModel"
import {Resource, ResourceCache} from "@lib/model/Resource"
import {skipFrame} from "@lib/common/Functions"
import {IdRequiredException} from "@lib/common/Error"
import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router"
import _ from "lodash"

export abstract class ListController<T extends ViewModel<T>> {
    resource = Resource
    resourceCache = ResourceCache
    loading = ref(false)
    errors = ref<Record<number, Record<string, string>>>({})
    protected route!: RouteLocationNormalizedLoaded
    protected router!: Router
    protected dataSavedSuccessfullyMessage: string = ""
    protected dataSaveFailedMessage: string = ""
    protected pullFailedMessage?: string

    protected newValues: Ref<T[]> = ref([])
    protected editableValues: Ref<T[]> = ref([])

    protected modifiedValues = computed(() => this.allValues.filter(it => it.changed))

    get data() {
        return [...this.newValues.value, ...this.editableValues.value].filter(item => {
            return !item.bearbeitungszustand.deleted
        })
    }

    get modified(): boolean {
        return this.allValues.any(it => it.changed)
    }

    private _isFetching: boolean = false

    get isFetching(): boolean {
        return this._isFetching
    }

    protected get allValues() {
        return [...this.newValues.value, ...this.editableValues.value]
    }

    contextMenu(event: MouseEvent) {
        // return new ListContextMenu({
        //     controller: this as ListController<any>,
        //     event: event,
        // })
    }

    async toggleLoading(value?: boolean): Promise<void> {
        if (value !== undefined)
            this.loading.value = value
        else
            this.loading.value = !this.loading.value
        await nextTick()
    }

    updateDebounced = _.debounce(this.update, 300, {
        trailing: true,
        leading: false,
    })

    async update(...args: any[]): Promise<T[]> {
        try {
            if (this.modified) {
                const result = await this.updateData(this.modifiedValues.value, ...args)
                if (Config.development) {
                    await Config.showDataSavedSuccessfullyMessage(this.dataSavedSuccessfullyMessage)
                }
                return result
            }
            return this.data
        } catch (e) {
            if (Config.development) {
                await Config.showDataSaveFailedMessage(this.dataSaveFailedMessage)
            }
            throw e
        }
    }

    async fetch(...args: any[]): Promise<T[]> {

        this._isFetching = true
        this.loading.value = true
        try {
            const pulledData = await this.fetchData(...args)
            await skipFrame()
            this.assignValues(pulledData)
            await skipFrame()
            return this.editableValues.value
        } catch (e) {
            if (e instanceof IdRequiredException)
                throw e
            if (Config.development) {
                throw e
            }
            await Config.showDataPullFailedMessage(e, this.pullFailedMessage)
        } finally {
            this.loading.value = false
            this._isFetching = false
        }
        return []
    }

    abstract create(): Promise<void>

    addRow(row: T) {
        this.newValues.value = this.newValues.value.concat(row).slice()
    }

    editRow(row: T) {
        row.bearbeitungszustand.flagEdited()
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    stopEditRow(row: T, ...args: any[]) {
        row.bearbeitungszustand.unflagEdit()
    }

    stopEditAll() {
        this.allValues.forEach(it => it.bearbeitungszustand.unflagEdit())
    }

    deleteRow(row: T) {
        const newValues = this.newValues.value
        if (newValues.find(item => item.equals(row))) {
            newValues.splice(newValues.findIndex(item => item.equals(row)), 1)
            this.newValues.value = newValues
        } else {
            row.bearbeitungszustand.flagDeleted()
        }
    }

    protected useRouter() {
        this.route = this.route ?? useRoute()
        this.router = this.router ?? useRouter()
        return {route: this.route, router: this.router}
    }

    protected abstract fetchData(...args: any[]): Promise<T[]>

    protected abstract updateData(data: T[], ...args: any[])

    protected assignValues(values: T[]) {
        this.newValues.value.length = 0
        this.editableValues.value = values
    }

    protected updateValue(value: T): boolean {
        const index = this.editableValues.value.findIndex(item => item.equals(value))
        if (index >= 0) {
            this.editableValues.value.splice(index, 1, value)
            return true
        }
        return false
    }

    protected insertValue(value: T): void {
        this.editableValues.value.push(value)
        this.newValues.value.remove(it => it.equals(value))
    }

    protected updateOrInsertValue(value: T) {

        const index = this.editableValues.value.findIndex(item => item.equals(value))
        if (index >= 0)
            this.editableValues.value.splice(index, 1, value)
        else
            this.insertValue(value)
    }

    protected removeValue(value: T): void {
        this.editableValues.value.remove(it => it.equals(value))
    }

    protected async startLoading() {
        this.loading.value = true
        await nextTick()
    }

    protected async stopLoading() {
        this.loading.value = false
        await nextTick()
    }

    protected async refreshList() {
        const viewModels = await this.fetchData()
        viewModels.forEach(it => {
            this.updateOrInsertValue(it)
        })
        this.data.forEach(it => {
            if (viewModels.any(vm => vm.equals(it))) {
                return
            } else {
                this.removeValue(it)
            }
        })
    }

    protected subscribe() {
        //
    }

    protected unsubscribe() {
        //
    }

    private useCounter = 0

    useController() {
        this.useCounter++
        if (this.useCounter > 1) return
        this.useRouter()
        this.subscribe()
        onUnmounted(() => {
            this.useCounter--
            if (this.useCounter < 1) {
                this.unsubscribe()
            }
        })
    }

    protected toDelete(): T[] {
        return this.data.filter(it => it.bearbeitungszustand.deleted)
    }

    protected toUpdate(): T[] {
        return this.data.filter(it => it.changed && !it.bearbeitungszustand.deleted && !it.bearbeitungszustand.created)
    }

    protected toCreate(): T[] {
        return this.data.filter(it => it.bearbeitungszustand.created)
    }
}
