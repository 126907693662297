import Api from "@lib/common/axios/Api"
import RepositoryConfig, {repositoryConfig} from "@lib/common/repository/RepositoryConfig"
import {IRabbitMqResetBody, RabbitMqResetBody} from "@generated/de/lohn24/endpoint/RabbitMqResetQuery/RabbitMqResetBody"
import {from} from "@lib/common/Functions"

export class CommandRepository {
    api: Api

    constructor(config: RepositoryConfig = repositoryConfig) {
        this.api = Api.Instance(config.baseURL)
    }

    async rabbitMqResetBody(): Promise<RabbitMqResetBody> {
        const result = await this.api.get<IRabbitMqResetBody>("/rabbitmq/reset")
        return from(RabbitMqResetBody, result)
    }

    async rabbitMqReset(body: RabbitMqResetBody): Promise<void> {
        await this.api.post<void>("/rabbitmq/reset", body)
    }

    async activateInactiveMandantCommand(kennung: string): Promise<void> {
        await this.api.post<void>("/mandant/activate", {kennung})
    }

    async resetMandantExport(kennung: string): Promise<void> {
        await this.api.post<void>("/mandantexport/reset", {kennung})
    }
}