<script lang="ts" setup>
import {computed} from "vue"
import {RouteLocationNormalizedLoaded, RouteRecordName} from "vue-router"

const props = withDefaults(
    defineProps<{
        name: string | symbol
        badge?: number
        currentActive?: string | symbol | null
        route: RouteLocationNormalizedLoaded | { name: RouteRecordName }
        shortcuts?: string[]
        uuid: string
    }>(),
    {
        badge: 0,
        shortcuts: () => [],
    },
)
const emits = defineEmits<{
    activate: [name: string | symbol]
}>()

const className = computed(() => ({
    "is-active": props.name === props.currentActive,
    "is-key-shortcut": props.shortcuts.includes(props.uuid),
}))

function emitActivate() {
    emits("activate", props.name)
}
</script>

<template>
  <el-menu-item :class="className" :index="name" :route="route" v-bind="$attrs" @click="emitActivate">
    <template #title>
      <slot :badge="badge" :title="name" name="title">
        {{ name }}
      </slot>
    </template>
  </el-menu-item>
</template>

<style lang="scss">
.el-menu--horizontal > .el-menu-item,
.el-menu--horizontal > .el-menu-item.is-active,
.el-menu--horizontal > .el-menu-item.is-active:hover,
.el-menu--horizontal > .el-menu-item:visited {
  border-bottom: none !important;
  user-select: none;
}

.el-menu--horizontal > .el-menu-item.is-active {
  background-color: var(--el-menu-hover-bg-color);
}
.el-menu--horizontal > .el-menu-item.is-key-shortcut {
  color: yellow;
}
</style>
