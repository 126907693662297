<script setup lang="ts">
import {ComponentRenderer, ComponentRendererProps} from "@lib/common/dialog/ComponentRenderer"
import {SearchDialogController} from "@lib/view/search/SearchDialogController"
import {SearchResult, SearchResultTopic} from "@lib/view/search/SearchResult"
import {MandantSummaryDialog} from "@intranet/view/mandant/summary/MandantSummaryDialog"
import {Resource} from "@lib/model/Resource"
import IconTicket from "@lib/view/icons/IconTicket.vue"
import SearchDialog from "@lib/view/search/SearchDialog.vue"

const props = withDefaults(
    defineProps<ComponentRendererProps<any, SearchDialogController>>(),
    {
        onVanish: ComponentRenderer.onVanishDefault,
        __loader: ComponentRenderer.loaderDefault,
    },
)
props.controller.useController()
</script>

<template>
  <search-dialog
          v-if="SearchResultTopic"
          :types="props.controller.topics.value"
          v-model="props.controller.suchtext.value"
          v-model:type="props.controller.topic.value"
          :loading="props.controller.loading.value"
          :results="props.controller.results.value"
          @beforeClose="() => props.controller.close()"
          @open="(item) => props.controller.open(item)"
  >
    <template #[`action-${SearchResultTopic.Mandant}`]="{result, label, value}">
      <el-button v-if="label=== 'Kennung'"
                 link
                 icon="folder"
                 @click="MandantSummaryDialog.show((result as SearchResult<SearchResultTopic.Mandant>).model.mandant.id)"
      />
    </template>
    <template #[`action-${SearchResultTopic.Arbeitnehmer}`]="{result, label, value}">
      <el-button v-if="label=== 'Mandant'"
                 link
                 icon="folder"
                 @click="MandantSummaryDialog.show((result as SearchResult<SearchResultTopic.Arbeitnehmer>).model.mandant.id)"
      />
    </template>
    <template #[`action-${SearchResultTopic.Anschrift}`]="{result, label, value}">
      <el-button v-if="label=== 'Mandant'"
                 link
                 icon="folder"
                 @click="MandantSummaryDialog.show((result as SearchResult<SearchResultTopic.Anschrift>).model.mandant!.id)"
      />
    </template>
    <template #[`action-${SearchResultTopic.Ticket}`]="{result, label, value}">
      <el-button v-if="label=== 'Mandant'"
                 link
                 icon="folder"
                 @click="MandantSummaryDialog.show((result as SearchResult<SearchResultTopic.Ticket>).model.mandant!.id)"
      />
      <el-button v-if="label=== 'Nummer'"
                 link
                 @click="Resource.ticket.openTicketInZammad((result as SearchResult<SearchResultTopic.Ticket>).model.ticket.zammadId)"
      >
        <icon-ticket />
      </el-button>
    </template>
  </search-dialog>
</template>

<style scoped lang="scss">

</style>