import {Config} from "@lib/Config"

export default interface RepositoryConfig {
    baseURL: string
}

export const repositoryConfig: RepositoryConfig = {
    get baseURL() {
        return Config.API_BASE_URL
    },
}