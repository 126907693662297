import {VueShowdownPlugin} from "vue-showdown"
import {App, Plugin} from "vue"
import VueShowdownWrapped from "@intranet/plugin/vueshowdown/VueShowdownWrapped.vue"

export const VueShowdownWrappedPlugin: Plugin = {
    install: (app: App, options) => {
        app.use(VueShowdownPlugin, options)
        app.component("VueShowdown", VueShowdownWrapped)
    },
}
