import {AnschriftRepository} from "@lib/model/anschrift/AnschriftRepository"
import {ArtikelRepository} from "@lib/model/artikel/ArtikelRepository"
import {ArtikelkatalogRepository} from "@lib/model/artikelkatalog/ArtikelkatalogRepository"
import {NewsRepository} from "@lib/model/news/NewsRepository"
import {AccountRepository} from "@lib/model/account/AccountRepository"
import {FirmaRepository} from "@lib/model/firma/FirmaRepository"
import {SessionRepository} from "@lib/model/session/SessionRepository"
import {MandantRepository} from "@lib/model/mandant/MandantRepository"
import {KooperationspartnerRepository} from "@lib/model/kooperationspartner/KooperationspartnerRepository"
import {StaatRepository} from "@lib/model/staat/StaatRepository"
import {DebitorRepository} from "@lib/model/debitor/DebitorRepository"
import {BelegRepository} from "@lib/model/beleg/BelegRepository"
import {LeistungsmerkmalRepository} from "@lib/model/leistungsmerkmal/LeistungsmerkmalRepository"
import {PbxCallRepository} from "@lib/model/pbx/PbxCallRepository"
import {VersandRepository} from "@lib/model/versand/VersandRepository"
import {RoleRepository} from "@lib/model/role/RoleRepository"
import {RightRepository} from "@lib/model/right/RightRepository"
import {KontaktRepository} from "@lib/model/kontakt/KontaktRepository"
import {AbschlussRepository} from "@lib/model/abschluss/AbschlussRepository"
import {OrganisationRepository} from "@lib/model/mandantengruppe/OrganisationRepository"
import {TicketRepository} from "@lib/model/ticket/TicketRepository"
import {TicketgruppeRepository} from "@lib/model/ticketgruppe/TicketgruppeRepository"

import {TicketTagRepository} from "@lib/model/tickettag/TicketTagRepository"
import {ZammadApi} from "@lib/common/zammad/ZammadApi"
import {Config} from "@lib/Config"
import {TicketgruppeMappingRepository} from "@lib/model/TicketgruppeMapping/TicketgruppeMappingRepository"
import {MitarbeiterRepository} from "@lib/model/mitarbeiter/MitarbeiterRepository"
import {debounce} from "lodash"
import {FileRepository} from "@lib/model/file/FileReporsitory"
import {CommandRepository} from "@intranet/view/development/commands/CommandRepository"
import {ProcessingRepository} from "@intranet/view/development/processing/ProcessingRepository"
import {AddisonExportRepository} from "@intranet/view/development/addisonexport/AddisonExportRepository"
import {FailureRepository} from "@lib/model/failure/FailureRepository"
import {Repository} from "@lib/common/repository/Repository"
import {MandantExportRepository} from "@lib/model/mandantexport/MandantExportRepository"
import {TicketArtikelRepository} from "@lib/model/ticketartikel/TicketArtikelRepository"
import {TicketAnhangRepository} from "@lib/model/ticketanhang/TicketAnhangRepository"
import {MailRepository} from "@lib/model/mail/MailRepository"
import {LohnabrechnungExportRepository} from "@lib/model/lohnabrechnungexport/LohnabrechnungExportRepository"
import {ArtikelkatalogArtikelRepository} from "@lib/model/artikelkatalog/ArtikelkatalogArtikelRepository"

let zammadApi: ZammadApi | null = null

export const Resource = {
    abschluss: new AbschlussRepository(),

    account: new AccountRepository(),
    anschrift: new AnschriftRepository(),
    artikel: new ArtikelRepository(),
    artikelkatalog: new ArtikelkatalogRepository(),
    artikelkatalogArtikel: new ArtikelkatalogArtikelRepository(),
    beleg: new BelegRepository(),
    debitor: new DebitorRepository(),
    file: new FileRepository(),
    firma: new FirmaRepository(),
    kontakt: new KontaktRepository(),
    kooperationspartner: new KooperationspartnerRepository(),
    land: new StaatRepository(),
    leistungsmerkmal: new LeistungsmerkmalRepository(),
    mandant: new MandantRepository(),
    mitarbeiter: new MitarbeiterRepository(),
    news: new NewsRepository(),
    pbxCall: new PbxCallRepository(),
    right: new RightRepository(),
    role: new RoleRepository(),
    session: new SessionRepository(),
    versand: new VersandRepository(),
    organisation: new OrganisationRepository(),
    tags: new TicketTagRepository(),
    ticket: new TicketRepository(),
    ticketgruppe: new TicketgruppeRepository(),
    ticketgruppeMapping: new TicketgruppeMappingRepository(),
    zammad: debounce(async () => {
        return zammadApi = zammadApi ?? new ZammadApi(
            Config.ZAMMAD_BASE_URL,
            (await Resource.mitarbeiter.createZammadAccessToken()).zammadUserAccessToken,
        )
    }, 10000, {leading: true}),
    command: new CommandRepository(),
    failure: new FailureRepository(),
    processing: new ProcessingRepository(),
    addisonExport: new AddisonExportRepository(),
    mandantExport: new MandantExportRepository(),
    ticketArtikel: new TicketArtikelRepository(),
    ticketAnhang: new TicketAnhangRepository(),
    mail: new MailRepository(),
    lohnabrechnungExport: new LohnabrechnungExportRepository(),
    byModelClassName(className: string): Repository<any> {
        return endpoint[className.toLowerCase()]
    },
} as const

const endpoint = Object
    .values(Resource)
    .reduce<Record<string, Repository<any>>>((acc, value) => {
            if (value instanceof Repository) {
                acc[value.endpoint] = value
                return acc
            }
            return acc
        },
        {} as Record<string, Repository<any>>,
    )

export const ResourceCache = {
    abschluss: Resource.abschluss.cache,
    ticketgruppe: Resource.ticketgruppe.cache,
    anschrift: Resource.anschrift.cache,
    artikel: Resource.artikel.cache,
    artikelkatalog: Resource.artikelkatalog.cache,
    artikelkatalogArtikel: Resource.artikelkatalogArtikel.cache,
    debitor: Resource.debitor.cache,
    firma: Resource.firma.cache,
    kooperationspartner: Resource.kooperationspartner.cache,
    mandant: Resource.mandant.cache,
    account: Resource.account.cache,
    news: Resource.news.cache,
    pbxCall: Resource.pbxCall.cache,
    right: Resource.right.cache,
    role: Resource.role.cache,
    versand: Resource.versand.cache,
    organisation: Resource.organisation.cache,
    mitarbeiter: Resource.mitarbeiter.cache,
    tags: Resource.tags.cache,
} as const