import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {Resource} from "@lib/model/Resource"
import MandantSummaryDialogView from "@intranet/view/mandant/summary/MandantSummaryDialogView.vue"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {MandantSummaryDialogController} from "@intranet/view/mandant/summary/MandantSummaryDialogController"
import {Id} from "@lib/common/model/Id"

export class MandantSummaryDialog extends ComponentRenderer<typeof MandantSummaryDialogView> {

    constructor(dialogController: MandantSummaryDialogController) {
        super(
            MandantSummaryDialogView,
            {
                controller: dialogController,
                edit: true,
                abschliessen: true,
            },
        )
    }

    static async show(id: Id<Mandant>): Promise<MandantSummaryDialog> {
        const mandant = await Resource.mandant.find(id)
        if (!mandant) throw "Keinen Mandanten gefunden"
        return new MandantSummaryDialog(new MandantSummaryDialogController(mandant))
    }

}