import {MenuItem} from "@lib/common/menu/MenuItem"

export const LINK_MAP = {
    TICKETSYSTEM_OTRS: {
        name: "Ticketsystem (OTRS)",
        link: "https://otrs.lohn24.de/otrs/index.pl",
    },
    TICKETSYSTEM: {
        name: "Ticketsystem",
        link: "https://tickets.lohn24.de/",
    },
    WIKI: {
        name: "Wiki",
        link: "https://wiki.lohn24.de/",
    },
    LOHN24DE: {
        name: "Lohn24.de",
        link: "https://lohn24.de/online-test/index.php",
    },
    LOHN24_CLOUD: {
        name: "Lohn24 Cloud",
        link: "https://cloud.lohn24.de/",
    },
    BMF_STUERRECHNER: {
        name: "BMF Steuerrechner",
        link: "https://www.bmf-steuerrechner.de/",
    },
    SOKA_BERLIN: {
        name: "SOKA-Berlin",
        link: "https://dta.sozialkasse-berlin.de/dapi/login.php",
    },
    PMC: {
        name: "Payroll Management Center",
        link: "https://pmc.lohn24.de/"
    }
}

export const LinkMenuItem = new MenuItem({
    label: "Links",
    name: "links",
    children: Object.keys(LINK_MAP).map(key => {
        return new MenuItem({
            label: LINK_MAP[key].name,
            name: key,
            link: LINK_MAP[key].link,
        })
    }),
})
