export enum FontSize {
    "large" = "large",
    "default" = "default",
    "small" = "small"
}

export enum MimeType {
    PLAIN = "text/plain",
    CSV = "text/csv",
    PNG = "image/png",
}

export enum ContentType {
    JSON = "application/json",
    XML = "application/xml",
    HTML = "text/html",
    PLAIN_TEXT = "text/plain",
    JPEG = "image/jpeg",
}