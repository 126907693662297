import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import BelegBuilderDialogView from "@intranet/view/rechnung/builder/BelegBuilderDialogView.vue"
import {BelegBuilderDialogController} from "@intranet/view/rechnung/builder/BelegBuilderDialogController"
import {BelegBuilderViewModel} from "@intranet/view/rechnung/builder/BelegBuilderViewModel"
import {MandantViewModel} from "@intranet/view/mandant/MandantViewModel"
import {BelegBuilder} from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import {Resource} from "@lib/model/Resource"
import {BelegBuilderModus} from "@intranet/view/rechnung/builder/BelegBuilderModus"
import {from} from "@lib/common/Functions"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Id} from "@lib/common/model/Id"

export class MandantBelegBuilderDialog extends ComponentRenderer<typeof BelegBuilderDialogView> {

    constructor(
        name: string,
        kennung: string,
        viewModel: BelegBuilderViewModel,
        done: () => void,
    ) {
        const controller = new BelegBuilderDialogController(
            name,
            kennung,
            viewModel,
            BelegBuilderModus.SACHBEARBEITERABSCHLUSS,
        )
        super(
            BelegBuilderDialogView,
            {
                controller: controller,
                done: done,
                closes: controller.closes,
            },
        )
    }

    static async show(id: Id<Mandant>): Promise<void> {
        if (!(await ComponentRenderer.activeInstanceCloses()))
            return

        const mandant = await Resource.mandant.find(id)
        const viewModel = new MandantViewModel(mandant)
        const firma = await viewModel.firma()
        const artikelkatalog = await viewModel.artikelkatalogId()
        const name = viewModel.name
        const kennung = viewModel.kennung
        const belegBuilderInDB = await Resource.beleg.builder.all()
        const existingBuilder = belegBuilderInDB.find(it => {
            return it.mandant?.value === viewModel.mandant.id.value
                && it.abrechnungszeitraum?.valueOf() === viewModel.mandant.abrechnungszeitraum!.valueOf()
        })
        const newBuilder = from(BelegBuilder, {
            mandant: viewModel.mandant.id?.value,
            artikelkatalog: artikelkatalog.value,
            abrechnungszeitraum: viewModel.abrechnungszeitraum.toJSON(),
            rechnungssteller: firma.value,
        })

        const artikel = await Resource.artikelkatalog.artikel.all(artikelkatalog)
        const mandantArtikel = await Resource.mandant.mandantartikel.all(viewModel.mandant.id)

        const builderViewModel = new BelegBuilderViewModel(
            existingBuilder ?? newBuilder,
            artikel,
            mandantArtikel.map(it => it.first),
        )

        if (!existingBuilder) {
            builderViewModel.flagCreated()
        }

        return new Promise((resolve) => {
            new MandantBelegBuilderDialog(
                name,
                kennung,
                builderViewModel,
                () => resolve(),
            )
        })
    }
}