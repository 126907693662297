import {ValidationResult} from "@lib/common/ValidationResult"
import {BaseIdType, Id} from "@lib/common/model/Id"

export type RepostioryEvents = "create" | "update" | "validate" | "get" | "delete"

export type RepositoryEventMap<M> = {
    "get": (evt: M) => void
    "delete": (evt: BaseIdType) => void
    "create": (evt: M) => void
    "update": (evt: M) => void
    "validate": (evt: ValidationResult[]) => void
}

export type RepositoryDispatchMap<M> = {
    "get": M
    "delete": Id<M>
    "create": M
    "update": M
    "validate": ValidationResult[]
}

export class RepositoryEvent<R> extends CustomEvent<R> {
    constructor(type: string, data: R) {
        super(type, {detail: data})
    }
}

export class RepositoryEventTarget<M> {
    registeredTypes: Map<any, string[]> = new Map()
    registered: Map<any, any> = new Map<any, any>()
    private eventTarget = new EventTarget()

    addEventListener<L extends RepostioryEvents>(
        type: string,
        callback: RepositoryEventMap<M>[L],
        options?: AddEventListenerOptions | boolean,
    ) {
        const typeList = this.registeredTypes.get(callback)
        if (typeList) typeList.push(type)
        else this.registeredTypes.set(callback, [type])
        if (!this.registered.has(callback))
            this.registered.set(callback, (event: any) => callback(event.detail))
        this.eventTarget.addEventListener(type, this.registered.get(callback), options)
    }

    removeEventListener<L extends RepostioryEvents>(
        type: string,
        callback: RepositoryEventMap<M>[L],
        options?: EventListenerOptions | boolean,
    ) {
        this.eventTarget.removeEventListener(type, this.registered.get(callback), options)
        const typelist = this.registeredTypes.get(callback)
        if (typelist) {
            const index = typelist.indexOf(type)
            if (index > -1)
                typelist.splice(index, 1)
            if (typelist.length === 0) {
                this.registeredTypes.delete(callback)
                this.registered.delete(callback)
            }
        }
    }

    dispatchEvent<R>(event: RepositoryEvent<R>) {
        this.eventTarget.dispatchEvent(event)
    }

    remove<L extends RepostioryEvents>(callback: RepositoryEventMap<M>[L]) {
        const typelist = this.registeredTypes.get(callback)
        if (typelist)
            for (const type of typelist) {
                this.eventTarget.removeEventListener(type, this.registered.get(callback))
                this.registered.delete(callback)
            }
    }
}