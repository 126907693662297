<template>
  <el-table-column
          :fixed="fixed ? 'right' : false"
          align="right"
          class-name="crud-column"
          column-key="button"
  >
    <template #header>
      <el-tooltip
              v-if="createable"
              :content="neu"
              :show-after="1000"
              placement="top"
      >
        <el-button
                :size="size"
                name="create"
                plain
                text
                @click.stop="controller.create()"
        >
          <icon-add size="large" />
        </el-button>
      </el-tooltip>
    </template>
    <template #default="{ row, $index }">
      <template v-if="!row.bearbeitungszustand.deleted">
        <el-tooltip v-if="deletable && (row?.bearbeitungszustand?.edited || !editable)"
                    :show-after="1000"
                    content="Löschen"
                    placement="top"
        >
          <el-button
                  :class="rowClass(row, $index, 'delete')"
                  :size="size"
                  name="delete"
                  text
                  @click.stop="() => {controller.deleteRow(row);$emit('delete', row)}"
          >
            <icon-delete size="large" />
          </el-button>
        </el-tooltip>
        <el-tooltip v-if="editable" :show-after="1000" content="Bearbeiten" placement="top">
          <el-button
                  v-if="row?.bearbeitungszustand?.edited"
                  :class="rowClass(row, $index, 'save')"
                  :size="size"
                  name="save"
                  text
                  @click.stop="() => controller.stopEditRow(row, $index)"
          >
            <icon-checked size="large" />
          </el-button>
          <el-button
                  v-else
                  :class="rowClass(row, $index, 'edit')"
                  :size="size"
                  name="edit"
                  text
                  @click.stop="() => controller.editRow(row)"
          >
            <icon-edit size="large" />
          </el-button>
        </el-tooltip>
        <slot :index="$index" :row="row"></slot>
      </template>

      <template v-else>
        <el-tooltip :show-after="1000" content="Nicht löschen" placement="top">
          <el-button
                  :class="rowClass(row, $index, 'undo')"
                  :size="size"
                  name="undo"
                  text
                  @click.stop="() => row.unflagDeleted()"
          >
            <icon-undo size="large" />
          </el-button>
        </el-tooltip>
      </template>
    </template>
  </el-table-column>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue"
import IconDelete from "@lib/view/icons/IconDelete.vue"
import IconChecked from "@lib/view/icons/IconChecked.vue"
import IconEdit from "@lib/view/icons/IconEdit.vue"
import IconUndo from "@lib/view/icons/IconUndo.vue"
import IconAdd from "@lib/view/icons/IconAdd.vue"
import {crudActionColumnProps} from "@lib/view/list/components/CrudColumnProps"

export default defineComponent({
    name: "CrudColumn",
    components: {IconAdd, IconUndo, IconEdit, IconChecked, IconDelete},
    emits: ["delete"],
    props: {
        ...crudActionColumnProps,
        size: {
            type: String as PropType<"default" | "small" | "large">,
            default: "default",
        },
        rowClass: {
            type: Function as PropType<(row: any, index: number, name: "undo" | "edit" | "delete" | "save") => string>,
            default: () => "",
        },
    },
})
</script>

<style lang="scss">
.crud-column {
  .cell {
    padding: 0;

    .el-button {
      padding: 0 2px;
    }
  }

}
</style>