import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_add = _resolveComponent("icon-add")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_icon_delete = _resolveComponent("icon-delete")!
  const _component_icon_checked = _resolveComponent("icon-checked")!
  const _component_icon_edit = _resolveComponent("icon-edit")!
  const _component_icon_undo = _resolveComponent("icon-undo")!
  const _component_el_table_column = _resolveComponent("el-table-column")!

  return (_openBlock(), _createBlock(_component_el_table_column, {
    fixed: _ctx.fixed ? 'right' : false,
    align: "right",
    "class-name": "crud-column",
    "column-key": "button"
  }, {
    header: _withCtx(() => [
      (_ctx.createable)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            content: _ctx.neu,
            "show-after": 1000,
            placement: "top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: _ctx.size,
                name: "create",
                plain: "",
                text: "",
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.controller.create()), ["stop"]))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_add, { size: "large" })
                ]),
                _: 1
              }, 8, ["size"])
            ]),
            _: 1
          }, 8, ["content"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(({ row, $index }) => [
      (!row.bearbeitungszustand.deleted)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.deletable && (row?.bearbeitungszustand?.edited || !_ctx.editable))
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 0,
                  "show-after": 1000,
                  content: "Löschen",
                  placement: "top"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      class: _normalizeClass(_ctx.rowClass(row, $index, 'delete')),
                      size: _ctx.size,
                      name: "delete",
                      text: "",
                      onClick: _withModifiers(() => {_ctx.controller.deleteRow(row);_ctx.$emit('delete', row)}, ["stop"])
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_icon_delete, { size: "large" })
                      ]),
                      _: 2
                    }, 1032, ["class", "size", "onClick"])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            (_ctx.editable)
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 1,
                  "show-after": 1000,
                  content: "Bearbeiten",
                  placement: "top"
                }, {
                  default: _withCtx(() => [
                    (row?.bearbeitungszustand?.edited)
                      ? (_openBlock(), _createBlock(_component_el_button, {
                          key: 0,
                          class: _normalizeClass(_ctx.rowClass(row, $index, 'save')),
                          size: _ctx.size,
                          name: "save",
                          text: "",
                          onClick: _withModifiers(() => _ctx.controller.stopEditRow(row, $index), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icon_checked, { size: "large" })
                          ]),
                          _: 2
                        }, 1032, ["class", "size", "onClick"]))
                      : (_openBlock(), _createBlock(_component_el_button, {
                          key: 1,
                          class: _normalizeClass(_ctx.rowClass(row, $index, 'edit')),
                          size: _ctx.size,
                          name: "edit",
                          text: "",
                          onClick: _withModifiers(() => _ctx.controller.editRow(row), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_icon_edit, { size: "large" })
                          ]),
                          _: 2
                        }, 1032, ["class", "size", "onClick"]))
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "default", {
              index: $index,
              row: row
            })
          ], 64))
        : (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 1,
            "show-after": 1000,
            content: "Nicht löschen",
            placement: "top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                class: _normalizeClass(_ctx.rowClass(row, $index, 'undo')),
                size: _ctx.size,
                name: "undo",
                text: "",
                onClick: _withModifiers(() => row.unflagDeleted(), ["stop"])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_undo, { size: "large" })
                ]),
                _: 2
              }, 1032, ["class", "size", "onClick"])
            ]),
            _: 2
          }, 1024))
    ]),
    _: 3
  }, 8, ["fixed"]))
}