import {MenuItem} from "@lib/common/menu/MenuItem"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/rights/RightIdentifier"

export const SammelrechnungMenuItem = new MenuItem({
    label: "Sammelrechnung",
    name: "sammelrechnung",
    rights: [RightAccess.readable(RightIdentifier.RECHNUNGSWESEN)],
    view: () => import("@intranet/view/rechnung/sammelrechnung/SammelrechnungList.vue"),
    "view-rights": [RightAccess.readable(RightIdentifier.RECHNUNGSWESEN)],
})