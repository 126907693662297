import {Nullable} from "@lib/types"

declare global {
    interface String {
        toNullIfEmpty(): Nullable<string>

        isEmpty(): boolean

        isNotEmpty(): boolean

        substringBeforeLast(match: string): string
    }
}

String.prototype.toNullIfEmpty = function () {
    return this.length === 0 ? null : this as string
}

String.prototype.isEmpty = function () {
    return this.trim().length === 0
}

String.prototype.isNotEmpty = function () {
    return !this.isEmpty()
}

String.prototype.substringBeforeLast = function (match) {
    const index = this.lastIndexOf(match)
    return this.substring(0, index)
}

