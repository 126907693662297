import { IModel, IRuntimeTypeInformation, Id, LocalDate, Model } from "@lib/index"
import { Kontakt } from "@generated/de/lohn24/model/kontakt/Kontakt"

export interface IBenachrichtigungsoption extends IModel {
	kontakt: string
	nachAbschluss: boolean
	news: boolean
	rechnung: boolean
	vorAbschluss: boolean
	nachAbschlussErzeugt: LocalDate | null
	vorAbschlussErzeugt: LocalDate | null
}

export class Benachrichtigungsoption extends Model<Benachrichtigungsoption> {
	public kontakt!: Id<Kontakt>
	public nachAbschluss!: boolean
	public news!: boolean
	public rechnung!: boolean
	public vorAbschluss!: boolean
	public nachAbschlussErzeugt!: LocalDate | null
	public vorAbschlussErzeugt!: LocalDate | null

	static _types: Record<keyof IBenachrichtigungsoption, IRuntimeTypeInformation> = {
		id: { type: Id },
		kontakt: { type: Id },
		nachAbschluss: { type: Boolean },
		news: { type: Boolean },
		rechnung: { type: Boolean },
		vorAbschluss: { type: Boolean },
		nachAbschlussErzeugt: { type: LocalDate, nullable: true },
		vorAbschlussErzeugt: { type: LocalDate, nullable: true },
	}

	static props: Record<keyof IBenachrichtigungsoption, string> = {
		id: "id",
		kontakt: "kontakt",
		nachAbschluss: "nachAbschluss",
		news: "news",
		rechnung: "rechnung",
		vorAbschluss: "vorAbschluss",
		nachAbschlussErzeugt: "nachAbschlussErzeugt",
		vorAbschlussErzeugt: "vorAbschlussErzeugt",
	}

	constructor(_json: IBenachrichtigungsoption) {
		super(_json)
	}
}
