import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Staat } from "@generated/de/lohn24/model/staat/Staat"
import { IPostanschrift, Postanschrift } from "@generated/de/lohn24/model/brief/Postanschrift"
import { AnschriftArt } from "@generated/de/lohn24/model/anschrift/AnschriftArt"

export interface IAnschrift extends IModel {
	land: string
	art: AnschriftArt
	postanschrift: IPostanschrift
	anrede: string | null
	name1: string
	name2: string | null
	ort: string
	postleitzahl: string
	strasse: string
	titel: string | null
	zusatz: string
}

export class Anschrift extends Model<Anschrift> {
	public land!: Id<Staat>
	public art!: AnschriftArt
	public postanschrift!: Postanschrift
	public anrede!: string | null
	public name1!: string
	public name2!: string | null
	public ort!: string
	public postleitzahl!: string
	public strasse!: string
	public titel!: string | null
	public zusatz!: string

	static _types: Record<keyof IAnschrift, IRuntimeTypeInformation> = {
		id: { type: Id },
		land: { type: Id },
		art: { type: AnschriftArt },
		postanschrift: { type: Postanschrift },
		anrede: { type: String, nullable: true },
		name1: { type: String },
		name2: { type: String, nullable: true },
		ort: { type: String },
		postleitzahl: { type: String },
		strasse: { type: String },
		titel: { type: String, nullable: true },
		zusatz: { type: String },
	}

	static props: Record<keyof IAnschrift, string> = {
		id: "id",
		land: "land",
		art: "art",
		postanschrift: "postanschrift",
		anrede: "anrede",
		name1: "name1",
		name2: "name2",
		ort: "ort",
		postleitzahl: "postleitzahl",
		strasse: "strasse",
		titel: "titel",
		zusatz: "zusatz",
	}

	constructor(_json: IAnschrift) {
		super(_json)
	}
}
