import {Serializable} from "@lib/common/serializable/Serializable"
import {DateTime} from "luxon"
import {deserializeDateTime} from "@lib/common/extension/DateTime"
import {Id} from "@lib/common/model/Id"
import {LocalDate} from "@lib/common/LocalDate"
import _ from "lodash"
import {LocalMonth} from "@lib/common/LocalMonth"

Serializable.add(DateTime, value => value ? deserializeDateTime(value as string) : null)
Serializable.add(Id, (value) => {
    if (value === null)
        return null
    return new Id(value as any)
})
Serializable.add(Number, value => value)
Serializable.add(LocalDate, value => {
    if (value === null) {
        return null
    } else {
        return LocalDate.from(value as string)
    }
})
Serializable.add(LocalMonth, value => {
    if (value instanceof LocalMonth)
        return value
    if (_.isString(value))
        return LocalMonth.fromString(value as string)
    return value
})
Serializable.add(DateTime, value => value ? deserializeDateTime(value as string) : null)
Serializable.add(String, value => value)


