import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_orig = _resolveComponent("orig")!

  return (_openBlock(), _createBlock(_component_orig, _mergeProps({ class: "markdown" }, _ctx.$attrs), {
    default: _withCtx(() => [
      _createElementVNode("template", null, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 16))
}