import {ContextMenuBuilder} from "@lib/common/contextmenu/ContextMenuBuilder"
import {ElTable} from "element-plus"
import {ContextMenuItemOption, ContextSubMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import {convertToCSV} from "@lib/common/elementplus/Functions"
import IconDownload from "@lib/view/icons/IconDownload.vue"
import {downloadAsFile} from "@lib/common/Functions"
import {MimeType} from "@lib/common/Enums"

interface CSVContextMenuContext {
    event: PointerEvent,
    table?: InstanceType<typeof ElTable>,
    name?: string,
}

export class CSVContextMenu extends ContextMenuBuilder {

    constructor(private context: CSVContextMenuContext) {
        super(context.event)
        this.build()
    }

    elTableToCSV(context: CSVContextMenuContext) {
        return new ContextMenuItemOption(
            "CSV",
            async () => {
                const table = context.table
                if (!table) return
                const content = convertToCSV(table)
                downloadAsFile(context.name ?? "csv", content, MimeType.CSV)
            },
            IconDownload,
        )
    }

    override buildOptions(): (ContextMenuItemOption | ContextSubMenuItemOption)[] {
        return super.buildOptions()
            .concat(
                this.elTableToCSV(this.context),
            )
    }
}