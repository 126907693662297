import {PdfMenuItem} from "@intranet/view/pdf/PdfMenuItem"
import {Resource} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"

export const DebitorAnschriftentraegerPdf = new PdfMenuItem<{ debitor: string, anschrift: string }>({
    path: "anschriftentraeger/debitor/:debitor/:anschrift",
    cb({anschrift, debitor}) {
        return Resource.debitor.anschriftentraeger(
            new Id(debitor),
            new Id(anschrift),
        )
    },
})