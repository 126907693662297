import {ViewModel} from "@lib/common/model/ViewModel"
import {ListController} from "@lib/common/controller/ListController"

export interface ArrangeableViewModel {
    position: number
}

export class ArrangeableController<L extends ListController<V>, V extends ViewModel<any> & ArrangeableViewModel> {

    constructor(
        private controller: L,
        private sort: (a: V, b: V) => number = (a, b) => b.position - a.position,
    ) {
    }

    get data() {
        return this.controller.data
    }

    cleanup() {
        const sortedList = this.data.slice().sort(this.sort)
        sortedList.forEach((item, index) => item.position = index)
    }

    findUpperNeighbourToSwapWith(row: ArrangeableViewModel) {
        return this.data.find(it => it.position === (row.position + 1))
    }

    findLowerNeighbourToSwapWith(row: ArrangeableViewModel) {
        return this.data.find(it => it.position === (row.position - 1))
    }

    moveUp(row: ArrangeableViewModel) {
        const upperRow = this.findUpperNeighbourToSwapWith(row)
        if (upperRow) {
            row.position += 1
            upperRow.position -= 1
        } else {
            this.cleanup()
            if (row.position < this.data.length - 1)
                this.moveUp(row)
        }
    }

    moveDown(row: ArrangeableViewModel) {
        const lowerRow = this.findLowerNeighbourToSwapWith(row)
        if (lowerRow) {
            row.position -= 1
            lowerRow.position += 1
        } else {
            this.cleanup()
            if (row.position > 0)
                this.moveDown(row)
        }
    }
}