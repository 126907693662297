import { IRuntimeTypeInformation, Id, LocalDate, LocalMonth, Serializable } from "@lib/index"
import { Organisation } from "@generated/de/lohn24/model/organisation/Organisation"
import { MandantStatus } from "@generated/de/lohn24/model/mandant/MandantStatus"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Lohnsteueranmeldeperiode } from "@generated/de/lohn24/model/mandant/Lohnsteueranmeldeperiode"
import { LohnabrechnungSystem } from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { BereitstellungOptionZahlungenArt } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungenArt"
import { BereitstellungOptionZahlungen } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungen"
import { BereitstellungOptionLohn } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionLohn"
import { Beitragsnachweis } from "@generated/de/lohn24/model/mandant/Beitragsnachweis"
import { AutomatischeRechnung } from "@generated/de/lohn24/model/mandant/AutomatischeRechnung"
import { Anschrift } from "@generated/de/lohn24/model/anschrift/Anschrift"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IMandantListElement {
	id: string
	anschrift: string
	debitor: string | null
	effektiverSachbearbeiter: string | null
	kooperationspartner: string | null
	organisation: string
	rechnungssteller: string
	sachbearbeiter: string | null
	sammelrechnung: string | null
	temporaererSachbearbeiter: string | null
	vertretung: string | null
	vorherigerSachbearbeiter: string | null
	automatischeRechnung: AutomatischeRechnung
	beitragsnachweis: Beitragsnachweis
	beitragAbbuchung: boolean
	direktvertrag: boolean
	gesperrt: boolean
	keinDirektkontakt: boolean
	keineRechnung: boolean
	lohncall: boolean
	meldetSichVorAbschluss: boolean
	bereitstellung: Array<string>
	bereitstellungenLohn: Array<BereitstellungOptionLohn>
	bereitstellungenZahlungen: Array<BereitstellungOptionZahlungen>
	zahlungenArt: Array<BereitstellungOptionZahlungenArt>
	abschlusstag: number
	klassifizierung: number | null
	kuendigungsdatum: LocalDate | null
	naechstesAbschlussdatum: LocalDate
	abrechnungszeitraum: string
	abrechnungssystem: LohnabrechnungSystem
	lohnsteueranmeldeperiode: Lohnsteueranmeldeperiode
	leistungsdatenSumme: number
	ticketCount: number
	kennung: string
	status: MandantStatus
	abschlusshinweis: string
	bearbeitungshinweis: string
	betriebsnummer: string | null
	gesperrtNotiz: string | null
	name: string
	statusNotiz: string | null
	steuernummer: string | null
	versandhinweis: string
}

export class MandantListElement extends Serializable<MandantListElement> {
	public id!: Id<Mandant>
	public anschrift!: Id<Anschrift>
	public debitor!: Id<Debitor> | null
	public effektiverSachbearbeiter!: Id<Account> | null
	public kooperationspartner!: Id<Kooperationspartner> | null
	public organisation!: Id<Organisation>
	public rechnungssteller!: Id<Firma>
	public sachbearbeiter!: Id<Account> | null
	public sammelrechnung!: Id<Kooperationspartner> | null
	public temporaererSachbearbeiter!: Id<Account> | null
	public vertretung!: Id<Account> | null
	public vorherigerSachbearbeiter!: Id<Account> | null
	public automatischeRechnung!: AutomatischeRechnung
	public beitragsnachweis!: Beitragsnachweis
	public beitragAbbuchung!: boolean
	public direktvertrag!: boolean
	public gesperrt!: boolean
	public keinDirektkontakt!: boolean
	public keineRechnung!: boolean
	public lohncall!: boolean
	public meldetSichVorAbschluss!: boolean
	public bereitstellung!: Array<string>
	public bereitstellungenLohn!: Array<BereitstellungOptionLohn>
	public bereitstellungenZahlungen!: Array<BereitstellungOptionZahlungen>
	public zahlungenArt!: Array<BereitstellungOptionZahlungenArt>
	public abschlusstag!: number
	public klassifizierung!: number | null
	public kuendigungsdatum!: LocalDate | null
	public naechstesAbschlussdatum!: LocalDate
	public abrechnungszeitraum!: LocalMonth
	public abrechnungssystem!: LohnabrechnungSystem
	public lohnsteueranmeldeperiode!: Lohnsteueranmeldeperiode
	public leistungsdatenSumme!: number
	public ticketCount!: number
	public kennung!: string
	public status!: MandantStatus
	public abschlusshinweis!: string
	public bearbeitungshinweis!: string
	public betriebsnummer!: string | null
	public gesperrtNotiz!: string | null
	public name!: string
	public statusNotiz!: string | null
	public steuernummer!: string | null
	public versandhinweis!: string

	static _types: Record<keyof IMandantListElement, IRuntimeTypeInformation> = {
		id: { type: Id },
		anschrift: { type: Id },
		debitor: { type: Id, nullable: true },
		effektiverSachbearbeiter: { type: Id, nullable: true },
		kooperationspartner: { type: Id, nullable: true },
		organisation: { type: Id },
		rechnungssteller: { type: Id },
		sachbearbeiter: { type: Id, nullable: true },
		sammelrechnung: { type: Id, nullable: true },
		temporaererSachbearbeiter: { type: Id, nullable: true },
		vertretung: { type: Id, nullable: true },
		vorherigerSachbearbeiter: { type: Id, nullable: true },
		automatischeRechnung: { type: AutomatischeRechnung },
		beitragsnachweis: { type: Beitragsnachweis },
		beitragAbbuchung: { type: Boolean },
		direktvertrag: { type: Boolean },
		gesperrt: { type: Boolean },
		keinDirektkontakt: { type: Boolean },
		keineRechnung: { type: Boolean },
		lohncall: { type: Boolean },
		meldetSichVorAbschluss: { type: Boolean },
		bereitstellung: { type: [String] },
		bereitstellungenLohn: { type: [BereitstellungOptionLohn] },
		bereitstellungenZahlungen: { type: [BereitstellungOptionZahlungen] },
		zahlungenArt: { type: [BereitstellungOptionZahlungenArt] },
		abschlusstag: { type: Number },
		klassifizierung: { type: Number, nullable: true },
		kuendigungsdatum: { type: LocalDate, nullable: true },
		naechstesAbschlussdatum: { type: LocalDate },
		abrechnungszeitraum: { type: LocalMonth },
		abrechnungssystem: { type: LohnabrechnungSystem },
		lohnsteueranmeldeperiode: { type: Lohnsteueranmeldeperiode },
		leistungsdatenSumme: { type: Number },
		ticketCount: { type: Number },
		kennung: { type: String },
		status: { type: MandantStatus },
		abschlusshinweis: { type: String },
		bearbeitungshinweis: { type: String },
		betriebsnummer: { type: String, nullable: true },
		gesperrtNotiz: { type: String, nullable: true },
		name: { type: String },
		statusNotiz: { type: String, nullable: true },
		steuernummer: { type: String, nullable: true },
		versandhinweis: { type: String },
	}

	static props: Record<keyof IMandantListElement, string> = {
		id: "id",
		anschrift: "anschrift",
		debitor: "debitor",
		effektiverSachbearbeiter: "effektiverSachbearbeiter",
		kooperationspartner: "kooperationspartner",
		organisation: "organisation",
		rechnungssteller: "rechnungssteller",
		sachbearbeiter: "sachbearbeiter",
		sammelrechnung: "sammelrechnung",
		temporaererSachbearbeiter: "temporaererSachbearbeiter",
		vertretung: "vertretung",
		vorherigerSachbearbeiter: "vorherigerSachbearbeiter",
		automatischeRechnung: "automatischeRechnung",
		beitragsnachweis: "beitragsnachweis",
		beitragAbbuchung: "beitragAbbuchung",
		direktvertrag: "direktvertrag",
		gesperrt: "gesperrt",
		keinDirektkontakt: "keinDirektkontakt",
		keineRechnung: "keineRechnung",
		lohncall: "lohncall",
		meldetSichVorAbschluss: "meldetSichVorAbschluss",
		bereitstellung: "bereitstellung",
		bereitstellungenLohn: "bereitstellungenLohn",
		bereitstellungenZahlungen: "bereitstellungenZahlungen",
		zahlungenArt: "zahlungenArt",
		abschlusstag: "abschlusstag",
		klassifizierung: "klassifizierung",
		kuendigungsdatum: "kuendigungsdatum",
		naechstesAbschlussdatum: "naechstesAbschlussdatum",
		abrechnungszeitraum: "abrechnungszeitraum",
		abrechnungssystem: "abrechnungssystem",
		lohnsteueranmeldeperiode: "lohnsteueranmeldeperiode",
		leistungsdatenSumme: "leistungsdatenSumme",
		ticketCount: "ticketCount",
		kennung: "kennung",
		status: "status",
		abschlusshinweis: "abschlusshinweis",
		bearbeitungshinweis: "bearbeitungshinweis",
		betriebsnummer: "betriebsnummer",
		gesperrtNotiz: "gesperrtNotiz",
		name: "name",
		statusNotiz: "statusNotiz",
		steuernummer: "steuernummer",
		versandhinweis: "versandhinweis",
	}

	constructor(_json: IMandantListElement) {
		super(_json)
	}
}
