import {ViewModel} from "@lib/common/model/ViewModel"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {ArrangeableViewModel} from "@lib/common/Arrangeable"
import {RecordOf} from "@lib/types"
import {_props} from "@lib/common/_props"
import {ValidateableListItem} from "@lib/common/ValidateableListItem"
import {KontaktFunktion} from "@generated/de/lohn24/model/kontakt/KontaktFunktion"
import {KontaktAnrede} from "@generated/de/lohn24/model/kontakt/KontaktAnrede"
import {Formatter} from "@lib/common/Formatter"
import {capitalize} from "lodash"

export class KontaktViewModel extends ViewModel<KontaktViewModel> implements ArrangeableViewModel, ValidateableListItem {

    static label: RecordOf<KontaktViewModel> & Record<string, string> = {
        emptyText: "Keine Kontakte vorhanden.",
        anrede: "Anrede",
        titel: "Titel",
        vorname: "Vorname",
        name: "Name",
        telefon: "Telefon",
        telefonMobil: "Mobil",
        email: "E-Mail",
        funktion: "Funktion",
    }

    static funktionLabel: Map<KontaktFunktion, string> = new Map([
        [KontaktFunktion.HAUPTANSPRECHPARTNER, "Hauptansprechpartner"],
        [KontaktFunktion.ANSPRECHPARTNER, "Ansprechpartner"],
        [KontaktFunktion.FINANZBUCHHALTUNG, "Finanzbuchhaltung"],
        [KontaktFunktion.EMAILPOSTFACH, "E-Mail-Postfach"],
        [KontaktFunktion.GESCHAEFTSFUEHRUNG, "Geschäftsführung"],
    ])

    static labelFunktion = new Map(
        Array.from(
            KontaktViewModel.funktionLabel.entries(),
            ([k, v]) => [v, k]),
    )

    static properties = _props<InstanceType<typeof this>>()
    kontakt: Kontakt

    validationIndex: number = 0

    constructor(kontakt: Kontakt) {
        super()
        this.kontakt = this.registerShallowReactive(kontakt)
    }

    get anrede() {
        return capitalize(this.kontakt.anrede ?? "")
    }

    set anrede(value: string | "") {
        this.kontakt.anrede = this.getValueOrNull(value)?.toUpperCase() as KontaktAnrede | null
    }

    get titel() {
        return this.kontakt.titel ?? ""
    }

    set titel(value) {
        this.kontakt.titel = this.getValueOrNull(value)
    }

    get telefon(): string {
        return Formatter.telefon.formatInternational(this.kontakt.telefon ?? "")
    }

    set telefon(value) {
        this.kontakt.telefon = this.getValueOrNull(value)
    }

    get telefonMobil(): string {
        return Formatter.telefon.formatInternational(this.kontakt.telefonMobil ?? "")
    }

    set telefonMobil(value) {
        this.kontakt.telefonMobil = this.getValueOrNull(value)
    }

    get email(): string {
        return this.kontakt.email ?? ""
    }

    set email(value) {
        this.kontakt.email = this.getValueOrNull(value)
    }

    get funktion() {
        return KontaktViewModel.funktionLabel.get(this.kontakt.funktion)!
    }

    set funktion(value: string) {
        this.kontakt.funktion = KontaktViewModel.labelFunktion.get(value)!

        if (this.kontakt.funktion === KontaktFunktion.EMAILPOSTFACH) {
            this.kontakt.name = null
            this.kontakt.vorname = null
            this.kontakt.titel = null
            this.kontakt.anrede = null
            this.kontakt.telefon = null
            this.kontakt.telefonMobil = null
        }
    }

    get vorname(): string {
        return this.kontakt.vorname ?? ""
    }

    set vorname(value: string) {
        this.kontakt.vorname = this.getValueOrNull(value)
    }

    get name() {
        return this.kontakt.name ?? ""
    }

    set name(value: string) {
        this.kontakt.name = this.getValueOrNull(value)
    }

    get position() {
        return this.kontakt.order
    }

    set position(value: number) {
        this.kontakt.order = value
    }

    get anredeDisabled(): boolean {
        return this.istEmailpostfach()
    }

    get titelDisabled(): boolean {
        return this.istEmailpostfach()
    }

    get vornameDisabled(): boolean {
        return this.istEmailpostfach()
    }

    get nameDisabled(): boolean {
        return this.istEmailpostfach()
    }

    get telefonDisabled(): boolean {
        return this.istEmailpostfach()
    }

    get telefonMobilDisabled(): boolean {
        return this.istEmailpostfach()
    }

    private istEmailpostfach(): boolean {
        return this.kontakt.funktion == KontaktFunktion.EMAILPOSTFACH
    }

    override equals(other: ViewModel<KontaktViewModel>): boolean {
        if (other instanceof KontaktViewModel) {
            return this.kontakt.id.value === other.kontakt.id.value
        }
        return false
    }
}