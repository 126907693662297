export enum RightIdentifier {
	GLOBAL_SETTINGS = "GLOBAL_SETTINGS",
	AUSWERTUNG = "AUSWERTUNG",
	PBX = "PBX",
	INTRANET = "INTRANET",
	NEWS = "NEWS",
	MANDANT_KONTAKTE = "MANDANT_KONTAKTE",
	ARTIKEL = "ARTIKEL",
	MANDANT_ARTIKEL = "MANDANT_ARTIKEL",
	RECHNUNG = "RECHNUNG",
	RECHNUNGSWESEN = "RECHNUNGSWESEN",
	DEVELOPMENT = "DEVELOPMENT",
	ROLES = "ROLES",
	VERSAND = "VERSAND",
	KLASSIFIZIERUNG = "KLASSIFIZIERUNG",
	INTERN = "INTERN",
}
