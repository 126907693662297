import {Ref, ref} from "vue"
import {Model} from "@lib/common/model/Model"
import {ElMessageBox, MessageBoxData} from "element-plus"
import {Config} from "@lib/Config"
import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {RouteLocationNormalizedLoaded, Router, useRoute, useRouter} from "vue-router"
import {Logger} from "@lib/common/logger/Logger"

export abstract class DialogController {

    abstract title: Ref<string>
    visible = ref(true)
    protected logger = new Logger(this)
    protected router!: Router
    protected route!: RouteLocationNormalizedLoaded

    static isDialogController<M extends Model<M>>(value: unknown): value is DialogController {
        return value instanceof DialogController
    }

    static confirmCloseOfUnsavedChanges(): Promise<MessageBoxData> {
        return ElMessageBox.confirm(
            "Wird die Eingabe abgebrochen, gehen nicht gespeicherte Fortschritte verloren.",
            "Fortfahren?",
            {
                cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
                confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
                cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
                confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
                type: Config.buttonLabel.TYPE_INFO,
            },
        )
    }

    static async closeDialogs() {
        ComponentRenderer.vanish()
    }

    protected async onClose(): Promise<void> {
        this.visible.value = false
        return DialogController.closeDialogs()
    }

    modified(): boolean {
        return false
    }

    async onBeforeClose(done: (close: boolean) => void) {
        if (this.modified())
            try {
                await DialogController.confirmCloseOfUnsavedChanges()
                done(true)
            } catch (e) {
                // do nothing
                done(false)
            }
        else
            done(true)
    }

    close(): Promise<void> {
        this.logger.info("close")
        return this.onBeforeClose(
            async (close) => {
                this.logger.info(close)
                if (close) await this.onClose()
            },
        )
    }

    async save(): Promise<void> {
        // not used
    }

    protected useRouting() {
        this.router = useRouter()
        this.route = useRoute()
    }

    // use only within vue setup
    useController() {
        this.useRouting()
    }

    closes: () => Promise<boolean> = () => {
        return new Promise(resolve => {
            this.onBeforeClose((close) => {
                resolve(close)
            })
        })
    }
}