import {Repository} from "@lib/common/repository/Repository"
import {TicketAnhang} from "@generated/de/lohn24/model/ticket/anhang/TicketAnhang"
import {TicketArtikel} from "@generated/de/lohn24/model/ticket/artikel/TicketArtikel"
import {File} from "@generated/dev/api/model/file/File"
import {Ticket} from "@generated/de/lohn24/model/ticket/Ticket"

export class TicketAnhangRepository extends Repository<TicketAnhang> {
    constructor() {
        super(TicketAnhang, "ticketanhang")
    }

    artikel = this.buildRelation(TicketArtikel, "artikel")
    file = this.buildRelation(File, "file")
    ticket = this.buildRelation(Ticket, "ticket")
}