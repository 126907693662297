<template>
  <img alt="logo" class="logo" src="../assets/logo.svg" />
</template>

<script lang="ts">
import {defineComponent} from "vue"

export default defineComponent({
    name: "Logo",
})
</script>

<style lang="scss">
img.logo {
  user-select: none;
}
</style>
