import {Repository} from "@lib/common/repository/Repository"
import {Beleg} from "@generated/de/lohn24/model/beleg/Beleg"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {
    RepositoryDispatchMap,
    RepositoryEventMap,
    RepostioryEvents,
} from "@lib/common/repository/RepositoryEventTarget"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {RecordOf} from "@lib/types"
import {Id} from "@lib/common/model/Id"
import {BelegAusgangListDistinct} from "@lib/model/beleg/BelegAusgangListDistinct"
import {LocalMonth} from "@lib/common/LocalMonth"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {BelegBuilderDistinct} from "@intranet/view/rechnung/einzelrechnung/BelegBuilderDistinct"
import {Resource} from "@lib/model/Resource"
import {
    SammelrechnungBelegBuilderDistinct,
} from "@intranet/view/rechnung/sammelrechnung/SammelrechnungBelegBuilderDistinct"
import {BelegBuilder} from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import {EinzelrechnungListElement} from "@generated/de/lohn24/model/beleg/builder/EinzelrechnungListElement"
import {SammelrechnungListElement} from "@generated/de/lohn24/model/beleg/builder/SammelrechnungListElement"
import {
    BelegausgangAuswertung,
    IBelegausgangAuswertung,
} from "@generated/de/lohn24/model/beleg/auswertung/BelegausgangAuswertung"
import {BelegausgangListElement} from "@generated/de/lohn24/model/beleg/BelegausgangListElement"

export type BelegRepositoryEvents =
    RepostioryEvents
    | "festschreiben"
    | "freigeben"
    | "verwerfen"
    | "korrigieren"
    | "erneut-versenden"

export type BelegRepositoryDispatchMap = RepositoryDispatchMap<Beleg> & {
    "festschreiben": undefined
    "freigeben": BelegBuilder
    "verwerfen": undefined,
    "korrigieren": undefined,
    "erneut-versenden"
}

export type BelegRepositoryEventMap = RepositoryEventMap<Beleg> & {
    "festschreiben": () => void
    "freigeben": () => void
    "verwerfen": () => void
    "korrigieren": () => void
    "erneut-versenden": () => void
}

export class BelegRepository extends Repository<Beleg> {
    anschrift = this.buildRelation(Anschrift, "anschrift")
    builder = this.buildBuilder(BelegBuilder)

    constructor() {
        super(Beleg, "beleg")
    }

    override dispatchEvent<L extends BelegRepositoryEvents>(type: L, data: BelegRepositoryDispatchMap[L]): any {
        //@ts-expect-error type ist eigentlich string, wird aber auf benutzte Worte reduziert
        return super.dispatchEvent(type, data)
    }

    override addEventListener<L extends BelegRepositoryEvents>(type: L, cb: BelegRepositoryEventMap[L]): any {
        //@ts-expect-error type ist eigentlich string, wird aber auf benutzte Worte reduziert
        return super.addEventListener(type, cb)
    }

    override removeEventListener<L extends BelegRepositoryEvents>(type: L, cb: BelegRepositoryEventMap[L]): any {
        //@ts-expect-error type ist eigentlich string, wird aber auf benutzte Worte reduziert
        return super.removeEventListener(type, cb)
    }

    async belegPdf(belegId: Id<Beleg>): Promise<Blob> {
        const url = `/beleg/${belegId.value}/pdf/beleg`
        return await this.api.getBlob(url, {})
    }

    async vorschau(belegBuilder: Id<BelegBuilder>) {
        return await this.api.getBlob(this.route("pdf/vorschau"),
            {
                ["belegBuilder"]: belegBuilder,
            }
        )
    }

    async sammelrechnungVorschau(leistungszeitraum: LocalMonth, kooperationspartnerId: Id<Kooperationspartner>) {
        return await this.api.getBlob(this.route("pdf/vorschau"),
            {
                ["kooperationspartner"]: kooperationspartnerId.value,
                ["leistungszeitraum"]: leistungszeitraum,
            })
    }

    async ruecklastschriftPdf(belegId: Id<Beleg>): Promise<Blob> {
        return await this.api.getBlob(this.route(`${belegId.value}/pdf/ruecklastschrift`))
    }

    async einzelrechnung(params?: Record<string, any>): Promise<PartitionedResultSet<EinzelrechnungListElement, BelegBuilderDistinct>> {
        const result = await this.api.get<PartitionedResultSet<EinzelrechnungListElement, BelegBuilderDistinct>>(
            this.route("einzelrechnung"),
            params || {},
        )
        return new PartitionedResultSet(EinzelrechnungListElement, BelegBuilderDistinct, result)
    }

    async sammelrechnung<P>(params?: P): Promise<PartitionedResultSet<SammelrechnungListElement, SammelrechnungBelegBuilderDistinct>> {
        const result = await this.api.get<PartitionedResultSet<SammelrechnungListElement, SammelrechnungBelegBuilderDistinct>>(this.route("sammelrechnung"), params || {})
        return new PartitionedResultSet(SammelrechnungListElement, SammelrechnungBelegBuilderDistinct, result)
    }

    async belegausgangListe(params: Record<string, any>): Promise<PartitionedResultSet<BelegausgangListElement, BelegAusgangListDistinct>> {
        const result = (await this.api.get<RecordOf<PartitionedResultSet<BelegausgangListElement, BelegAusgangListDistinct>>>(
                this.route("/ausgangsliste"), params)
        )
        return new PartitionedResultSet(BelegausgangListElement, BelegAusgangListDistinct, result)
    }

    async belegausgangAuswertung(params: Record<string, any>): Promise<BelegausgangAuswertung> {
        const result = (await this.api.get<IBelegausgangAuswertung>(this.route("ausgangsliste/auswertung"), params))
        return new BelegausgangAuswertung(result)
    }

    async korrektur(id: Id<Beleg>): Promise<void> {
        await this.api.post<RecordOf<BelegausgangListElement>>(this.route(`${id.value}/festschreiben/korrektur`), {})
        this.dispatchEvent("korrigieren", undefined)
    }

    async einzelrechnungFreigeben(belegBuilderId: Id<BelegBuilder>): Promise<BelegBuilder> {
        const belegBuilder = await Resource.beleg.builder.get(belegBuilderId)
        belegBuilder.sammelrechnungFreigabe = true
        const result = await Resource.beleg.builder.update(belegBuilder)
        this.dispatchEvent("freigeben", result)
        return result
    }

    async einzelrechnungFestschreiben(belegBuilder: Id<BelegBuilder>): Promise<void> {

        if (await this.builder.find(belegBuilder)) {
            await this.api.post<void>(
                this.route(`festschreiben/einzelrechnung`),
                {belegBuilder: belegBuilder},
            )
            this.dispatchEvent("festschreiben", undefined)
        }
    }

    async sammelrechnungFestschreiben(kooperationspartner: Id<Kooperationspartner>, leistungszeitraum: LocalMonth): Promise<void> {
        await this.api.post<void>(
            this.route(`festschreiben/sammelrechnung`),
            {
                kooperationspartner: kooperationspartner,
                leistungszeitraum: leistungszeitraum,
            },
        )
        this.dispatchEvent("festschreiben", undefined)
    }

    async verwerfen(belegBuilder: Id<BelegBuilder>) {
        if (await this.builder.find(belegBuilder)) {
            await this.builder.delete(belegBuilder)
        }
        this.dispatchEvent("verwerfen", undefined)
    }

    async mailErneutVersenden(beleg: Id<Beleg>, additionalEmail?: string): Promise<void> {
        const payload = additionalEmail ? {email: additionalEmail.isNotEmpty() ? additionalEmail : null} : {}
        await this.api.post(this.route(`${beleg.value}/mail/beleg`), payload)
        this.dispatchEvent("erneut-versenden", undefined)
    }

    async ruecklastschriftVersenden(beleg: Id<Beleg>, additionalEmail?: string): Promise<void> {
        const payload = additionalEmail ? {email: additionalEmail.isNotEmpty() ? additionalEmail : null} : {}
        await this.api.post(this.route(`${beleg.value}/mail/ruecklastschrift`), payload)
        this.dispatchEvent("erneut-versenden", undefined)
    }
}
