import {computed} from "vue"
import {ViewModel} from "@lib/common/model/ViewModel"
import {OptionalModelController} from "@lib/common/controller/OptionalModelController"

export abstract class ModelController<T extends ViewModel<T>> extends OptionalModelController<T> {

    override model = computed<T>(() => this.editableValue.value as T)

    constructor(model: T) {
        super(model, () => true)
    }
}