import {ViewModel} from "@lib/common/model/ViewModel"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {_props} from "@lib/common/_props"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {Id} from "@lib/common/model/Id"

export class KooperationspartnerStammdatenViewModel extends ViewModel<KooperationspartnerStammdatenViewModel> {

    static props = _props<InstanceType<typeof this>>()
    static label = {
        [this.props.name]: "Name",
        [this.props.kennung]: "Kennung",
        [this.props.sammelrechnungFestschreiben]: "Stichtag festschreiben",
        [this.props.sammelrechnung]: "Sammelrechnung",
        [this.props.keineRechnung]: "Keine Rechnung",
        [this.props.artikeltextAusfuehrlich]: "Ausführlicher Artikeltext",
    }
    kooperationspartner: Kooperationspartner
    sammelrechnungArtikelkatalog: Id<Artikelkatalog> | null = null

    constructor(kooperationspartner: Kooperationspartner) {
        super()
        this.kooperationspartner = this.registerShallowReactive(kooperationspartner)
    }

    get id() {
        return this.kooperationspartner.id
    }

    get name() {
        return this.kooperationspartner.name
    }

    set name(value: string) {
        this.kooperationspartner.name = value
    }

    get artikeltextAusfuehrlich(): boolean {
        return this.kooperationspartner.artikeltextAusfuehrlich
    }

    set artikeltextAusfuehrlich(value: boolean) {
        this.kooperationspartner.artikeltextAusfuehrlich = value
    }

    get kennung() {
        return this.kooperationspartner.kennung
    }

    set kennung(value: string) {
        this.kooperationspartner.kennung = value
    }

    get sammelrechnungFestschreiben() {
        return this.kooperationspartner.sammelrechnungFestschreibenTag
    }

    set sammelrechnungFestschreiben(value) {
        this.kooperationspartner.sammelrechnungFestschreibenTag = value
    }

    get sammelrechnung() {
        return this.kooperationspartner.sammelrechnung
    }

    set sammelrechnung(id: Id<Kooperationspartner> | null) {
        this.kooperationspartner.sammelrechnung = id
        if (id !== null && this.kooperationspartner.id.value !== id.value)
            this.kooperationspartner.artikelkatalog = null
    }

    get artikelkatalogDisabled(): boolean {
        if (this.kooperationspartner.sammelrechnung !== null)
            return this.kooperationspartner.sammelrechnung.value !== this.kooperationspartner.id.value

        return false
    }

    get artikelkatalog() {
        return this.kooperationspartner.artikelkatalog ?? this.sammelrechnungArtikelkatalog
    }

    set artikelkatalog(value) {
        this.kooperationspartner.artikelkatalog = value
    }

    get keineRechnung() {
        return this.kooperationspartner.keineRechnung
    }

    set keineRechnung(value) {
        this.kooperationspartner.keineRechnung = value
    }
}