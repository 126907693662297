import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, LocalMonth, Model } from "@lib/index"
import { Beleg } from "@generated/de/lohn24/model/beleg/Beleg"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IAbschluss extends IModel {
	beleg: string | null
	rechnungsbearbeiter: string | null
	sachbearbeiter: string | null
	abgeschlossen: LocalDateTime | null
	abrechnungszeitraum: string
}

export class Abschluss extends Model<Abschluss> {
	public beleg!: Id<Beleg> | null
	public rechnungsbearbeiter!: Id<Account> | null
	public sachbearbeiter!: Id<Account> | null
	public abgeschlossen!: LocalDateTime | null
	public abrechnungszeitraum!: LocalMonth

	static _types: Record<keyof IAbschluss, IRuntimeTypeInformation> = {
		id: { type: Id },
		beleg: { type: Id, nullable: true },
		rechnungsbearbeiter: { type: Id, nullable: true },
		sachbearbeiter: { type: Id, nullable: true },
		abgeschlossen: { type: LocalDateTime, nullable: true },
		abrechnungszeitraum: { type: LocalMonth },
	}

	static props: Record<keyof IAbschluss, string> = {
		id: "id",
		beleg: "beleg",
		rechnungsbearbeiter: "rechnungsbearbeiter",
		sachbearbeiter: "sachbearbeiter",
		abgeschlossen: "abgeschlossen",
		abrechnungszeitraum: "abrechnungszeitraum",
	}

	constructor(_json: IAbschluss) {
		super(_json)
	}
}
