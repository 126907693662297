import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Role } from "@generated/dev/api/model/role/Role"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IAccountRole extends IModel {
	account: string
	role: string
}

export class AccountRole extends Model<AccountRole> {
	public account!: Id<Account>
	public role!: Id<Role>

	static _types: Record<keyof IAccountRole, IRuntimeTypeInformation> = {
		id: { type: Id },
		account: { type: Id },
		role: { type: Id },
	}

	static props: Record<keyof IAccountRole, string> = {
		id: "id",
		account: "account",
		role: "role",
	}

	constructor(_json: IAccountRole) {
		super(_json)
	}
}
