import {ListController} from "@lib/common/controller/ListController"
import {ViewModel} from "@lib/common/model/ViewModel"
import {shallowReactive} from "vue"

export abstract class ExpandableViewModel<V extends ViewModel<V>> extends ViewModel<V> {
    abstract rowKey: string

    expanded(rowKeys: string[]): boolean {
        return rowKeys.includes(this.rowKey)
    }
}

export abstract class ExpandableListController<V extends ExpandableViewModel<V>> extends ListController<V> {

    expandedRows = shallowReactive<string[]>([])

    async expandedChange({row}: { row: V }) {
        const index = this.expandedRows.findIndex(it => it === row.rowKey)
        if (index >= 0) {
            this.expandedRows.splice(index, 1)
        } else {
            this.expandedRows.length = 0
            this.expandedRows.push(row.rowKey)
            await this.onExpandedAdded(row)
        }
    }

    async onExpandedAdded(row: V) {

    }
}