import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"

export interface ITicketgruppe extends IModel {
	zammadUserIds: Array<number>
	zammadId: number
	name: string
}

export class Ticketgruppe extends Model<Ticketgruppe> {
	public zammadUserIds!: Array<number>
	public zammadId!: number
	public name!: string

	static _types: Record<keyof ITicketgruppe, IRuntimeTypeInformation> = {
		id: { type: Id },
		zammadUserIds: { type: [Number] },
		zammadId: { type: Number },
		name: { type: String },
	}

	static props: Record<keyof ITicketgruppe, string> = {
		id: "id",
		zammadUserIds: "zammadUserIds",
		zammadId: "zammadId",
		name: "name",
	}

	constructor(_json: ITicketgruppe) {
		super(_json)
	}
}
