<script generic="V" lang="ts" setup>
import {ValidateableProps, ValidateableTrigger} from "@lib/view/validateable/ValidateableProps"
import {FontSize} from "@lib/common/Enums"
import {computed} from "vue"
import {ValidateableEmits} from "@lib/view/validateable/ValidateableEmits"

const emits = defineEmits<ValidateableEmits<V>>()
const props = withDefaults(
    defineProps<ValidateableProps>(),
    {
        size: FontSize.default,
        trigger: ValidateableTrigger.blur,
    },
)

const errorText = computed(() => {
    return props.error?.length === 0
        ? props.warning?.length === 0 ? null : props.warning
        : props.error
        ?? ""
})

if (props.immediate)
    emits("validate")

function classNames() {
    const names = ["validatable_input_wrapper"]
    if (props.noMargin) names.push("no-margin-important")
    if (props.hideErrorText) names.push("hide-error")
    if (props.warning && !props.error) names.push("is-warning")
    return names.join(" ")
}
</script>

<template>
  <el-form-item
          :class="classNames()"
          :error="errorText"
          :label="label"
          :label-width="label ? '' : 0"
          :prop="prop ?? ''"
          :size="props.size"
  >
    <el-tooltip :content="error" :disabled="!error" :show-after="630" :trigger-keys="[]" placement="top">
      <template #default>
        <slot>
        </slot>
      </template>
      <template #content>
        <slot name="tooltip-content"></slot>
      </template>
    </el-tooltip>
  </el-form-item>
</template>

<style lang="scss">
.validatable_input_wrapper {
  word-break: break-word;

  &.no-margin-important {
    margin-bottom: 0 !important;
  }

  .el-form-item__content {
    margin-left: 0 !important;
  }

  &.is-warning {
    .el-input__wrapper, .el-select-v2__wrapper {
      box-shadow: 0 0 0 1px var(--el-color-warning) inset;
      border: none;
    }

    .el-form-item__error {
      color: var(--el-color-warning);
    }
  }


  &.is-error.hide-error {
    .el-form-item__error {
      display: none;
    }
  }
}

.validatable_input_wrapper.no-margin-important.no-justify {
  .el-form-item__content {
    justify-content: start;
  }
}
</style>