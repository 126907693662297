import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, LocalMonth, Model } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { LohnabrechnungSystem } from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"
import { LohnabrechnungStatus } from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungStatus"

export interface ILohnabrechnungExport extends IModel {
	mandant: string
	abrechnungsstatus: Array<LohnabrechnungStatus>
	exportErrors: Array<string> | null
	import: LocalDateTime | null
	version: number | null
	zeitraum: string
	abrechnungssystem: LohnabrechnungSystem
}

export class LohnabrechnungExport extends Model<LohnabrechnungExport> {
	public mandant!: Id<Mandant>
	public abrechnungsstatus!: Array<LohnabrechnungStatus>
	public exportErrors!: Array<string> | null
	public import!: LocalDateTime | null
	public version!: number | null
	public zeitraum!: LocalMonth
	public abrechnungssystem!: LohnabrechnungSystem

	static _types: Record<keyof ILohnabrechnungExport, IRuntimeTypeInformation> = {
		id: { type: Id },
		mandant: { type: Id },
		abrechnungsstatus: { type: [LohnabrechnungStatus] },
		exportErrors: { type: [String], nullable: true },
		import: { type: LocalDateTime, nullable: true },
		version: { type: Number, nullable: true },
		zeitraum: { type: LocalMonth },
		abrechnungssystem: { type: LohnabrechnungSystem },
	}

	static props: Record<keyof ILohnabrechnungExport, string> = {
		id: "id",
		mandant: "mandant",
		abrechnungsstatus: "abrechnungsstatus",
		exportErrors: "exportErrors",
		import: "import",
		version: "version",
		zeitraum: "zeitraum",
		abrechnungssystem: "abrechnungssystem",
	}

	constructor(_json: ILohnabrechnungExport) {
		super(_json)
	}
}
