import { IModel, IRuntimeTypeInformation, Id, ModelBuilder } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { ArtikelkatalogArtikel } from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"

export interface IMandantArtikelBuilder extends IModel {
	artikel: string | null
	mandant: string | null
	rechnungsabschluss: boolean | null
	sachbearbeiterabschluss: boolean | null
	vorgabeMengeRechnung: number | null
	netto: number | null
	name: string | null
}

export class MandantArtikelBuilder extends ModelBuilder<MandantArtikelBuilder> {
	public artikel!: Id<ArtikelkatalogArtikel> | null
	public mandant!: Id<Mandant> | null
	public rechnungsabschluss!: boolean | null
	public sachbearbeiterabschluss!: boolean | null
	public vorgabeMengeRechnung!: number | null
	public netto!: number | null
	public name!: string | null

	static _types: Record<keyof IMandantArtikelBuilder, IRuntimeTypeInformation> = {
		id: { type: Id },
		artikel: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		rechnungsabschluss: { type: Boolean, nullable: true },
		sachbearbeiterabschluss: { type: Boolean, nullable: true },
		vorgabeMengeRechnung: { type: Number, nullable: true },
		netto: { type: Number, nullable: true },
		name: { type: String, nullable: true },
	}

	static props: Record<keyof IMandantArtikelBuilder, string> = {
		id: "id",
		artikel: "artikel",
		mandant: "mandant",
		rechnungsabschluss: "rechnungsabschluss",
		sachbearbeiterabschluss: "sachbearbeiterabschluss",
		vorgabeMengeRechnung: "vorgabeMengeRechnung",
		netto: "netto",
		name: "name",
	}

	constructor(_json: IMandantArtikelBuilder) {
		super(_json)
	}
}
