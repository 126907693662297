import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"

export interface IFailure extends IModel {
	created: LocalDateTime
	updated: LocalDateTime
	causedBy: string | null
	className: string
	message: string
	model: string
	note: string | null
	stackTrace: string
	modelId: string
}

export class Failure extends Model<Failure> {
	public created!: LocalDateTime
	public updated!: LocalDateTime
	public causedBy!: string | null
	public className!: string
	public message!: string
	public model!: string
	public note!: string | null
	public stackTrace!: string
	public modelId!: string

	static _types: Record<keyof IFailure, IRuntimeTypeInformation> = {
		id: { type: Id },
		created: { type: LocalDateTime },
		updated: { type: LocalDateTime },
		causedBy: { type: String, nullable: true },
		className: { type: String },
		message: { type: String },
		model: { type: String },
		note: { type: String, nullable: true },
		stackTrace: { type: String },
		modelId: { type: String },
	}

	static props: Record<keyof IFailure, string> = {
		id: "id",
		created: "created",
		updated: "updated",
		causedBy: "causedBy",
		className: "className",
		message: "message",
		model: "model",
		note: "note",
		stackTrace: "stackTrace",
		modelId: "modelId",
	}

	constructor(_json: IFailure) {
		super(_json)
	}
}
