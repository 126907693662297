import {defineComponent, onMounted} from "vue"
import {LocationAsRelativeRaw, NavigationGuardNext, RouteLocationNormalized, useRouter} from "vue-router"
import {MenuItem} from "@lib/common/menu/MenuItem"
import {LoginMenuItem} from "@lib/common/menu/LoginMenuItem"
import {unwrapped} from "@lib/common/Functions"

type LogoutFunction = (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
    loginRoute: LocationAsRelativeRaw,
) => Promise<void>

export class LogoutMenuItem extends MenuItem {

    private logout: LogoutFunction

    constructor(logout: LogoutFunction | LogoutMenuItem) {
        super({
            label: "Logout",
            name: "logout",
            view: defineComponent({
                name: "Logout",
                setup() {

                    onMounted(() => {
                        return useRouter().push({name: super.routeRecordName})
                    })
                },
            }),
            hooks: {
                beforeEnter: (to, from, next) => {
                    (logout instanceof LogoutMenuItem ? logout.logout : logout)(to, from, next, unwrapped(LoginMenuItem.route))
                },
            },
            //@ts-expect-error it exists
            [MenuItem.uuid]: logout instanceof LogoutMenuItem ? logout.routeRecordName : undefined,
        })
        this.logout = logout instanceof LogoutMenuItem ? logout.logout : logout
    }
}