import {ListController} from "@lib/common/controller/ListController"
import Pagination from "@lib/common/pagination/Pagination"
import {Selection} from "@lib/common/controller/Selection"
import {ViewModel} from "@lib/common/model/ViewModel"
import {RowClassNameArgument} from "@lib/common/elementplus/types"
import {TableColumnCtx} from "element-plus"

export type SortOrderType = "ascending" | "descending" | null
export type SortFunctionFactory<V> = (column: TableColumnCtx<V> | null, sortOrder: SortOrderType) => SortFunction<V>
export type SortFunction<V> = ((a: V, b: V) => number)
export type FilterPredicateFunctionFactory<V> = () => FilterPredicateFunction<V>
export type FilterPredicateFunction<V> = ((value: V, index: number, array: V[]) => boolean)

export interface ListContentProps<
    V extends ViewModel<V> = any
> // /*extends wird von vue SFC nicht korrekt verarbeitet */ extends TableProps<V>, CrudActionColumnProps<V>, PaginatorProps, SelectionProps<V>, SettingsProps<V>, ScrollProps
{
    controller: ListController<V>

    selection?: Selection<V>,
    multiselect?: boolean
    multiselectDropdown?: boolean

    neu?: string
    editable?: boolean
    createable?: boolean
    deletable?: boolean
    fixed?: boolean

    csv?: boolean

    emptyText?: string
    stripe?: boolean
    showSummary?: boolean
    expandRowKeys?: (number | string)[]
    userSelectNone?: boolean
    rowClassName?: (context: RowClassNameArgument<V>) => string

    scrollPositionInPageHeight?: number
    searchables?: ((row: V) => keyof V)[]
    settings?: boolean
    settingsBelow?: boolean
    type?: ListContentType
    crudColumn?: boolean
    classprefix: string
    rowKey?: (row: V) => string
    data?: (controller: ListController<V>) => V[]
    formatter?: Record<string | symbol | number, (...args: any[]) => string>
    crudCellClass?: (row: V, index: number, name: string) => string

    paginatable?: boolean
    remotePagination?: Pagination
    localSort?: SortFunctionFactory<V>
    localFilter?: FilterPredicateFunctionFactory<V>
    beforePageChange?: () => (boolean | Promise<boolean>)
}

export interface CrudActionColumnProps<V extends ViewModel<V>> {
    controller: ListController<V>,
    neu: string
    editable?: boolean
    createable?: boolean
    deletable?: boolean
    fixed?: boolean
}

export interface PaginatorProps {
    paginatable: boolean
    externalPagination?: Pagination
    beforePageChange: () => (boolean | Promise<boolean>)
}

export interface SelectionProps<V extends ViewModel<V>> {
    selection: Selection<V>,
    multiselect?: boolean
    multiselectDropdown: boolean
}

export interface SettingsProps<V extends ViewModel<V>> {
    settings: boolean,
    searchables: ((row: V) => keyof V)[]
}

export interface ScrollProps {
    scrollPositionInPageHeight: number
}

export interface TableProps<V extends ViewModel<V>> {
    emptyText: string
    stripe?: boolean
    showSummary?: boolean
    expandRowKeys?: (number | string)[]
    userSelectNone?: boolean
    rowClassName: (context: RowClassNameArgument<V>) => string
}

export enum ListContentType {
    ROUTER,
    LISTE,
    TABLE,
}

export enum TableCellType {
    SYSTEM_ICON = "SYSTEM_ICON",
    SELECT = "SELECT",
    BUTTON = "BUTTON"
}