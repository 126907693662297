<script lang="ts" setup>
import {ComponentRenderer, ComponentRendererProps} from "@lib/common/dialog/ComponentRenderer"
import {computed, shallowRef} from "vue"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {
    KooperationspartnerSummaryDialogController,
} from "@intranet/view/kooperationspartner/summary/KooperationspartnerSummaryDialogController"
import KooperationspartnerZammadTicket
    from "@intranet/view/kooperationspartner/ticket/KooperationspartnerZammadTicket.vue"
import KontaktSummary from "@intranet/view/kontakt/KontaktSummary.vue"
import SummaryDialog from "@intranet/view/SummaryDialog.vue"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {MandantSummaryDialog} from "@intranet/view/mandant/summary/MandantSummaryDialog"
import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"
import {FontSize} from "@lib/common/Enums"
import {
    KooperationspartnerMandant,
} from "@generated/de/lohn24/model/kooperationspartner/KooperationspartnerMandanten/KooperationspartnerMandant"
import {capitalize} from "lodash"
import TooltipButton from "@lib/view/TooltipButton.vue"
import {ContextMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import IconKennungKopieren from "@lib/view/icons/IconKennungKopieren.vue"
import {copyToClipboard} from "@lib/common/Functions"

const props = withDefaults(
    defineProps<ComponentRendererProps<Kooperationspartner, KooperationspartnerSummaryDialogController>>(),
    {
        __loader: ComponentRenderer.loaderDefault,
        onVanish: ComponentRenderer.onVanishDefault,
    },
)

const anschrift = shallowRef<Anschrift | null>(null)
const kontakte = shallowRef<Kontakt[] | null>(null)
const mandanten = shallowRef<KooperationspartnerMandant[] | null>(null)

function edit() {
    props.controller.edit(props.controller.viewModel)
}

props.controller.anschrift(anschrift)
props.controller.kontakte(kontakte)
props.controller.mandanten(mandanten)

const sortedMandanten = computed(() => {
    return (mandanten.value ?? []).sortBy(it => {
        switch (it.status) {
            case MandantStatus.NEU:
                return 1
            case MandantStatus.AKTIV:
                return 2
            case MandantStatus.INAKTIV:
                return 99
            case MandantStatus.WARTEN:
                return 6
            case MandantStatus.INSOLVENT:
                return 30
        }
    })
})

function mandantenkennungCopyOption() {
    return new ContextMenuItemOption(
        "Mandantenkennungen kopieren",
        async (ev) => {
            await copyToClipboard((mandanten.value ?? []).map(it => it.kennung)?.join(";"))
        },
        IconKennungKopieren,
    )
}

function getRowClassName({row: mandant}: { row: Mandant }): string {
    const rowClass = ["user-select-none"]

    if (mandant.gesperrt) {
        rowClass.push("status-gesperrt")
    }
    if (mandant.status === MandantStatus.INAKTIV) {
        rowClass.push("status-inaktiv")
    } else if (mandant.status === MandantStatus.WARTEN) {
        rowClass.push("status-warten")
    }

    return rowClass.join(" ")
}

</script>

<template>
  <summary-dialog
          :anschrift="anschrift"
          :controller="controller"
          @edit="edit()"
  >
    <template #content-left-append>
      <kontakt-summary
              v-if="kontakte"
              :data="kontakte"
      >
        <template #email-prefix="{email, kontakt}">
          <kooperationspartner-zammad-ticket
                  :kontakt="kontakt"
                  :kooperationspartner="props.controller.viewModel.kooperationspartner"
          />
        </template>
      </kontakt-summary>
    </template>
    <template #content-right>
      <el-row :gutter="20">
        <el-col>
          <el-row justify="space-between">
            <div class="el-col">
              <h3>Mandanten</h3>
            </div>
            <div class="el-col">
              <tooltip-button :options="mandantenkennungCopyOption" />
            </div>
          </el-row>
          <el-table
                  :size="FontSize.small"
                  :data="sortedMandanten"
                  :row-class-name="getRowClassName"
                  max-height="600"
                  @row-click="({id}) => MandantSummaryDialog.show(id)"
                  class="mandanten"
                  @row-contextmenu="(row, column, event) => props.controller.onMandantRowContextMenu(row, column,event)"
          >
            <el-table-column width="100" prop="status" label="Status">
              <template #default="{row}: {row: KooperationspartnerMandant}">
                {{ capitalize(row.status.toLocaleLowerCase()) }}
              </template>
            </el-table-column>
            <el-table-column :width="100" sortable prop="kennung" label="Kennung"></el-table-column>
            <el-table-column sortable prop="name" label="Name" show-overflow-tooltip></el-table-column>
            <el-table-column sortable prop="anzahlLohnabrechnung" label="Anzahl Lohnabrechnungen"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </template>
  </summary-dialog>
</template>

<style>
.mandanten {
    .el-table__row.status-warten {
        background: var(--l24-color-table-bg-warning) !important;
    }

    .el-table__row.status-gesperrt {
        background: var(--l24-color-table-bg-danger) !important;
    }

    tr.el-table__row.status-gesperrt:hover {
        color: var(--l24-color-text);
    }

    .el-table__row.status-inaktiv {
        color: var(--l24-color-table-text-inactive);
        background: var(--l24-color-table-bg-inactive);
    }
}
</style>