<script generic="T extends []" lang="ts" setup>
import _ from "lodash"

const props = withDefaults(
    defineProps<{ wait: number, leading?: boolean, trailing?: boolean }>(),
    {
        leading: true,
        trailing: false,
    },
)

let debounced: any = null

function debounce(fn: (...args: T) => void, ...args: T) {
    if (debounced === null)
        debounced = _.debounce(fn, props.wait, {
            leading: props.leading,
            trailing: props.trailing,
        })
    debounced(...args)
}
</script>

<template>
  <slot :debounce="debounce"></slot>
</template>

<style scoped>

</style>