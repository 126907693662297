import {showdown} from "vue-showdown"

const options: showdown.ShowdownOptions = {
    headerLevelStart: 4,
    simpleLineBreaks: true,
}
const flavor: showdown.Flavor = "original"

const vueShowdownConfig = {
    flavor,
    options,
}
export {vueShowdownConfig}
