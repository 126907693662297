import {LocalMonth} from "@lib/common/LocalMonth"
import {Serializable} from "@lib/common/serializable/Serializable"

export class LeistungdatenDistinct extends Serializable<LeistungdatenDistinct> {

    static _types = {
        abrechnungszeitraum: [LocalMonth],
    }

    abrechnungszeitraum: LocalMonth[] = []
}