import {ViewModel} from "@lib/common/model/ViewModel"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {debitorZammadTicket} from "@intranet/view/debitor/ticket/DebitorZammadTicket"

export class DebitorSummaryDialogViewModel extends ViewModel<DebitorSummaryDialogViewModel> {

    static label = {
        rechnung: "Rechnung",
    }
    debitor: Debitor

    constructor(debitor: Debitor) {
        super()
        this.debitor = this.registerShallowReactive(debitor)
    }

    get titel() {
        return `${this.debitor.konto} // ${this.debitor.name}`
    }

    get rechnungsemail(): { email: string }[] {
        return this.debitor.mail ? [{email: this.debitor.mail}] : []
    }

    debitorTicketOptions() {
        return debitorZammadTicket(() => ({
            debitor: this.debitor,
        }))
    }
}