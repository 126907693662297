import {MenuItem} from "@lib/common/menu/MenuItem"
import {
    createRouter,
    createWebHistory,
    RouteLocationNamedRaw,
    RouteLocationNormalizedLoaded,
    Router,
    RouteRecordRaw,
} from "vue-router"
import {Config} from "@lib/Config"
import {isRef, shallowRef, watch} from "vue"
import {authMiddleware} from "@lib/common/menu/AuthMiddleware"
import {MiddlewareContext, nextMiddlewareFactory} from "@lib/common/menu/Middleware"
import {auth} from "@lib/common/Authentication"
import {NotFoundMenuItem} from "@lib/common/menu/404MenuItem"
import {LoginMenuItem} from "@lib/common/menu/LoginMenuItem"
import {unwrapped} from "@lib/common/Functions"

export class Menu {
    headerMenuItems = shallowRef<MenuItem[]>([])
    readonly menuItems: MenuItem[]
    private createRouterCalledCount = 0

    constructor(...menuItems: MenuItem[]) {
        this.menuItems = [...new Set(menuItems.concat(NotFoundMenuItem))]
        this.headerMenuItems.value = menuItems.slice()
    }

    static filterRecursive(menuItems: readonly MenuItem[], rule: (item: MenuItem) => boolean): MenuItem[] {
        return menuItems.filter(rule).map(item => {
            //@ts-expect-error construct not defined in typescript
            const shallowClone = new item.constructor(item)
            if (item.routeRecordName && shallowClone.routeRecordName != item.routeRecordName) {
                throw Error(`route.name uuid changed for ${item.constructor.name}:${item.name} from ${item.routeRecordName} to ${shallowClone.routeRecordName}`)
            }
            if (Array.isArray(item.children)) {
                shallowClone.children = Menu.filterRecursive(item.children, rule)
            }
            return shallowClone
        })
    }

    private static setRequiredThirdLevelProps(secondLevelItem: MenuItem) {
        if (!Array.isArray(secondLevelItem.children)) return secondLevelItem.props
        const router = true
        const tabs = secondLevelItem.children
            .map(child => {
                return {
                    label: unwrapped(child.label),
                    dataKey: (child.props as Record<string, any>)?.dataKey || child.routeRecordName,
                    route: child.route,
                }
            })
        return Object.assign({}, secondLevelItem.props, {router, tabs})
    }

    private static createRoute(menuItems: readonly MenuItem[], parent?: {
        menuItem: MenuItem,
        recordRaw: RouteRecordRaw
    }, level?: number) {
        const rootPath = parent ?
            parent.recordRaw.path ?
                ("/" + parent.recordRaw.path).replace("//", "/") :
                `/${parent?.menuItem?.name?.toString().toLocaleLowerCase()}`
            : ""
        return (menuItems || [])
            .flatMap((child) => {
                const props = level && level === 1 ? this.setRequiredThirdLevelProps(child) : child.props
                const routeItem = {
                    path: rootPath + ((child.visible || child.children === undefined) ? (child.path || `/${(child.name as string).toLocaleLowerCase()}`) : ""),
                    name: child.routeRecordName,
                    props: props,
                    meta: {middleware: this.buildMiddleware(child)},
                    children: [] as any,
                    // children: (child.nestedRoutes && createRoute(child.nestedRoutes, child, (level || 0) + 1)) || undefined,
                    component: child.view,
                    ...(child.hooks || {}),
                }
                if (child.children && child.children.length > 0) {
                    routeItem.children = this.createRoute(child.children, {
                        recordRaw: routeItem,
                        menuItem: child,
                    }, (level || 0) + 1)
                } else if (child.param) {
                    routeItem.path += `/:${child.param}?/:id?`
                }
                return routeItem
            })
    }

    private static buildMiddleware(menuItem: MenuItem) {
        return [authMiddleware(Config.LOGIN_CONFIG), ...(menuItem.middleware || [])]
    }

    createRouter(): Router {
        if (this.createRouterCalledCount > 1) {
            throw Error("create router should only be called once")
        }
        this.createRouterCalledCount++

        const router = createRouter({
            history: createWebHistory("/"),
            routes: this.createRoute(),
        })

        router.beforeEach(async (to, from, next): Promise<void> => {
            await auth.initialise()
            if (!to.name) {
                await this.getNamedRoute(router, to)
            }

            if (Array.isArray(to.meta.middleware) ? to.meta.middleware.length > 0 : to.meta.middleware) {
                const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
                const context: MiddlewareContext = {
                    to,
                    from,
                    next,
                    router,
                }
                const nextMiddleware = nextMiddlewareFactory(context, middleware)
                return middleware[0]({...context, next: nextMiddleware})
            }
            return next()
        })

        watch(() => auth.session?.rights, async () => {
            await this.updateRoutes(router)
        }, {deep: true, immediate: true})

        watch(() => auth.session?.id, async () => {
            if (auth.hasNoSession()) {
                return this.toNamedRoute(
                    router,
                    {name: LoginMenuItem.routeRecordName},
                )
            }
        })
        return router
    }

    async updateRoutes(router: Router) {
        router.getRoutes().forEach(route => {
            if (route.name && route.name !== LoginMenuItem.routeRecordName)
                router.removeRoute(route.name)
        })
        this.createRoute()
            .forEach(route => {
                    router.addRoute(route)
                },
            )
        this.headerMenuItems.value = Menu.filterRecursive(this.menuItems, (item) => this.isVisible(item))
    }

    private getNamedRoute(router: Router, route: RouteLocationNormalizedLoaded) {
        if (!route.name) {
            router.getRoutes().forEach(route => {
                if (route.name && route.name !== unwrapped(LoginMenuItem.route).name)
                    router.removeRoute(route.name)
            })
            this.createRoute()
                .forEach(route => {
                        router.addRoute(route)
                    },
                )
            const resolvedRoute = router.resolve(window.location.pathname)
            if (resolvedRoute.name) {
                return router.push(Object.assign(route, resolvedRoute))
            }
            return router.push({name: NotFoundMenuItem.routeRecordName})
        } else {
            return route
        }
    }

    private toNamedRoute(router: Router, to?: RouteLocationNamedRaw) {
        const currentRoute = router.currentRoute.value
        if (!(to?.name || currentRoute.name)) {
            const routes = router.getRoutes()
            for (const route of routes) {
                if (route.path === window.location.pathname)
                    return router.push(Object.assign(currentRoute, route, {params: currentRoute.params}))
            }
            return router.push({name: NotFoundMenuItem.routeRecordName})
        }
    }

    private isVisible(item: MenuItem): boolean {
        if (item.visible === false || (isRef(item.visible) && !item.visible.value)) return false
        else if (item.rights) return auth.hasAccess(item.rights)
        else return true
    }

    private createRoute() {
        const menuItems = Menu.filterRecursive(
            this.menuItems,
            (item) => item.rights ? auth.hasAccess(item.rights) : true,
        )
        return Menu.createRoute(menuItems)
    }
}