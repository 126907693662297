import {ModelController} from "@lib/common/controller/ModelController"
import {BelegBuilderViewModel} from "@intranet/view/rechnung/builder/BelegBuilderViewModel"
import {Resource} from "@lib/model/Resource"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"
import {computed} from "vue"
import {BelegBuilderModus} from "@intranet/view/rechnung/builder/BelegBuilderModus"
import {KatalogPositionViewModel} from "@intranet/view/rechnung/builder/KatalogPositionViewModel"
import {MandantArtikel} from "@generated/de/lohn24/model/mandant/artikel/MandantArtikel"
import {FreitextBelegPositionViewModel} from "@intranet/view/rechnung/builder/FreitextBelegPositionViewModel"
import {assertNotNull, from} from "@lib/common/Functions"
import {Validator} from "@lib/common/Validator"
import {ValidateableList} from "@lib/common/ValidateableListItem"
import {ValidationError} from "@lib/common/axios/AxiosError"
import {ArtikelEinheit} from "@generated/de/lohn24/model/artikel/ArtikelEinheit"
import {KatalogArtikelPosition} from "@generated/de/lohn24/model/beleg/builder/KatalogArtikelPosition"
import {FreitextArtikelPosition} from "@generated/de/lohn24/model/beleg/builder/FreitextArtikelPosition"
import {BelegBuilder} from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"
import {Pair} from "@lib/common/repository/RepositoryHasManyWithRelation"

export class BelegBuilderController extends ModelController<BelegBuilderViewModel> {

    dataSaveFailedMessage: string = ""
    dataSavedSuccesfullyMessage: string = ""
    freitextArtikelPositionen = computed(() => this.model.value.freitextArtikelPositionen)
    katalogArtikelPositionen = computed(() => this.model.value.katalogArtikelPositionen)
    validatorIdentifier = "belegbuilder"

    constructor(viewModel: BelegBuilderViewModel, private modus: BelegBuilderModus) {
        super(viewModel)
        this.updateItems()
    }

    override get modified(): boolean {
        const belegBuilder = this.model.value
        const katalogArtikelPositionen = belegBuilder.katalogArtikelPositionen
        const freitextArtikelPositionen = belegBuilder.freitextArtikelPositionen
        belegBuilder.freitextArtikelPositionen = freitextArtikelPositionen.filter(it => it.menge != 0 || it.text != "")
        const result = this.model.value?.changed || false
        belegBuilder.katalogArtikelPositionen = katalogArtikelPositionen
        belegBuilder.freitextArtikelPositionen = freitextArtikelPositionen
        return result
    }

    updateItems() {
        const belegBuilder = this.model.value
        belegBuilder.artikel.forEach(artikel => {
            const mandantArtikel = this.model.value.mandantArtikel.find(ma => ma.artikel.value === artikel.first.id.value)
            const positionen = this.katalogArtikelPositionen.value.filter(pos => pos.artikelkatalogArtikel?.id.value === artikel.first.id.value)

            // if (belegBuilder.builder.abschluss.isNull())
            //     positionen.forEach(it => {
            //         const vorgabeMenge = mandantArtikel?.vorgabeMengeRechnung ?? artikel.vorgabeMengeRechnung
            //         if (it.menge == vorgabeMenge && it.textIsBlanc() && !it.bearbeitungszustand.deleted) {
            //             it.bearbeitungszustand.flagDeleted()
            //             this.removeKatalogArtikel(it)
            //         }
            //     })
            if (positionen.filter(it => !it.bearbeitungszustand.deleted).length === 0) {
                this.addKatalogArtikel(artikel, mandantArtikel)
            }
        })
    }

    allowShow(item: KatalogPositionViewModel) {
        if (item.menge != 0) return true
        if (this.modus === BelegBuilderModus.MANUELL) return true

        let result = false
        if (item.mandantArtikel && item.mandantArtikel.sachbearbeiterabschluss !== null)
            result = result || (this.modus === BelegBuilderModus.SACHBEARBEITERABSCHLUSS ? item.mandantArtikel.sachbearbeiterabschluss : false)
        else
            result = result || (this.modus === BelegBuilderModus.SACHBEARBEITERABSCHLUSS && item.artikelkatalogArtikel?.sachbearbeiterabschluss) || false
        if (item.mandantArtikel && item.mandantArtikel.rechnungsabschluss !== null)
            result = result || (this.modus === BelegBuilderModus.RECHNUNGSABSCHLUSS ? item.mandantArtikel.rechnungsabschluss : false)
        else
            result = result || (this.modus === BelegBuilderModus.RECHNUNGSABSCHLUSS && item.artikelkatalogArtikel?.rechnungsabschluss) || false
        return result
    }

    addKatalogArtikel(artikel: Pair<ArtikelkatalogArtikel, Artikel>, mandantArtikel?: Nullable<MandantArtikel>) {
        const katalogPositionViewModel = new KatalogPositionViewModel(
            from(
                KatalogArtikelPosition,
                {
                    artikel: artikel.first.id,
                    menge: mandantArtikel?.vorgabeMengeRechnung ?? artikel.first.vorgabeMengeRechnung ?? 0,
                },
            ),
            artikel,
            mandantArtikel ?? undefined,
        )

        if (this.allowShow(katalogPositionViewModel)) {
            katalogPositionViewModel.bearbeitungszustand.flagCreated()
            katalogPositionViewModel.validationIndex = ValidateableList.nextIndex(this.model.value.katalogArtikelPositionen)
            this.model.value.katalogArtikelPositionen = this.model.value.katalogArtikelPositionen.concat(katalogPositionViewModel)
        }
    }

    removeKatalogArtikel(position: KatalogPositionViewModel) {
        const index = this.model.value.katalogArtikelPositionen.findIndex(it => it.equals(position))
        if (index >= 0) {
            const mutable = this.model.value.katalogArtikelPositionen.slice()
            mutable.splice(index, 1)
            this.model.value.katalogArtikelPositionen = mutable
        }
    }

    addFreitextArtikel() {
        const freitextPositionViewModel = new FreitextBelegPositionViewModel(
            from(
                FreitextArtikelPosition,
                {
                    umsatzsteuersatz: 19,
                    menge: 0,
                    position: 0,
                    netto: 0,
                    text: "",
                    notiz: null,
                    mengeneinheit: ArtikelEinheit.EURO,
                },
            ),
        )
        freitextPositionViewModel.bearbeitungszustand.flagCreated()
        freitextPositionViewModel.validationIndex = ValidateableList.nextIndex(this.model.value.freitextArtikelPositionen)
        this.model.value.freitextArtikelPositionen = this.model.value.freitextArtikelPositionen.concat(freitextPositionViewModel)
    }

    removeFreitextArtikel(position: FreitextBelegPositionViewModel) {
        const index = this.model.value.freitextArtikelPositionen.findIndex(it => it === position)
        const mutable = this.model.value.freitextArtikelPositionen.slice()
        mutable.splice(index, 1)
        this.model.value.freitextArtikelPositionen = mutable
    }

    vorgabeMengeRechnung(katalogArtikelPosition: KatalogArtikelPosition, artikel: Record<number, Artikel>, mandantArtikel: Record<number, MandantArtikel>) {
        const artikelId = katalogArtikelPosition.artikel.value
        return (mandantArtikel)[artikelId]?.vorgabeMengeRechnung ?? artikel[artikelId].vorgabeMengeRechnung
    }

    sanitizeBuilder(builder: BelegBuilder, artikel: Pair<ArtikelkatalogArtikel, Artikel>[], mandantArtikel?: MandantArtikel[]) {
        const idArtikelMap = artikel.associateBy(it => it.first.id.value)
        const idMandantArtikelMap = mandantArtikel?.associateBy(it => it.artikel.value) ?? {}
        const belegBuilder = builder.clone()
        // if (belegBuilder.abschluss.isNull())
        //     belegBuilder.katalogArtikelPositionen = belegBuilder
        //         .katalogArtikelPositionen
        //         .filter(it => it.menge != this.vorgabeMengeRechnung(it, idArtikelMap, idMandantArtikelMap) || it.text != null)
        belegBuilder.freitextArtikelPositionen = belegBuilder
            .freitextArtikelPositionen
            .filter(it => it.menge != 0 || it.text != null)

        belegBuilder.summeNetto = belegBuilder.katalogArtikelPositionen.sumBy(it =>
            it.menge * (
                idMandantArtikelMap[it.artikel.value]?.netto ?? assertNotNull(idArtikelMap[it.artikel.value].first.netto)
            )) + belegBuilder.freitextArtikelPositionen.sumBy(it => it.menge * it.netto)
        return belegBuilder
    }

    async validate(): Promise<ValidationError | null> {
        Validator.clearValidationState()
        return await Validator.validate("beleg/build", this.model.value.builder, this.validatorIdentifier)
    }

    override async update(): Promise<BelegBuilderViewModel> {
        const error = (await this.validate())
        if (error)
            throw error

        const updated = await super.update()
        this.updateItems()
        return updated
    }

    async save(): Promise<BelegBuilderViewModel | null> {
        try {
            return await this.update()
        } catch (e) {
            if (e instanceof ValidationError) {
                return null
            } else {
                throw e
            }
        }
    }

    protected async createModel(model: BelegBuilderViewModel): Promise<BelegBuilderViewModel> {
        const builder = await Resource.beleg.builder.insert(this.sanitizeBuilder(model.builder, model.artikel, model.mandantArtikel))
        return new BelegBuilderViewModel(builder, model.artikel, model.mandantArtikel)
    }

    protected async updateModel(model: BelegBuilderViewModel): Promise<BelegBuilderViewModel> {
        const builder = await Resource.beleg.builder.update(this.sanitizeBuilder(model.builder, model.artikel, model.mandantArtikel))
        return new BelegBuilderViewModel(builder, model.artikel, model.mandantArtikel)
    }
}