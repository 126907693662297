import {ViewModel} from "@lib/common/model/ViewModel"
import {_props} from "@lib/common/_props"
import {RecordOf} from "@lib/types"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Resource, ResourceCache} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Formatter} from "@lib/common/Formatter"
import {DateTime} from "luxon"
import {LocalMonth} from "@lib/common/LocalMonth"
import {Beitragsnachweis} from "@generated/de/lohn24/model/mandant/Beitragsnachweis"
import {Account} from "@generated/dev/api/model/account/Account"
import {Lohnsteueranmeldeperiode} from "@lib/model/abrechnung/Lohnsteueranmeldeperiode"
import {Firma} from "@generated/de/lohn24/model/firma/Firma"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import {LohnabrechnungSystem} from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"

export class MandantViewModel extends ViewModel<MandantViewModel> {

    static readonly properties = _props<MandantViewModel>()

    static label: RecordOf<string> & Record<string, any> = {
        name: "Name",
        automatischeRechnung: "Automatische Rechnung",
        steuernummer: "Steuernummer",
        kooperationspartner: "Kooperationspartner",
        sammelrechnung: "Sammelrechnung",
        klassifizierung: "Klassifizierung",
        kuendigungsdatum: "Kündigung zum",
        keineRechnung: "Keine Rechnung",
        kennung: "Mandantennummer",
        direkvertrag: "Direktvertrag",
    }
    readonly mandant: Mandant

    constructor(mandant: Mandant) {
        super()
        this.mandant = this.registerShallowReactive(mandant)
    }

    get name() {
        return this.mandant.name
    }

    set name(value: string) {
        this.mandant.name = value
    }

    get mandantengruppe() {
        return this.mandant.organisation
    }

    set mandantengruppe(value: Id<Organisation>) {
        this.mandant.organisation = value
    }

    get kooperationspartnerName(): string {
        if (this.mandant.kooperationspartner !== null) {
            return ResourceCache
                .kooperationspartner
                .mapped(
                    this.mandant.kooperationspartner,
                    it => it?.name ?? "",
                )
                .value
        }
        return ""
    }

    get kennung() {
        return this.mandant.kennung
    }

    set kennung(value) {
        this.mandant.kennung = (+value)
            .toString()
            .padStart(5, "0")
            .substring(0, 5)
    }

    get gesperrt(): boolean {
        return this.mandant.gesperrt
    }

    set gesperrt(value: boolean) {
        this.mandant.gesperrt = value
    }

    get gesperrtNotiz(): string {
        return this.mandant.gesperrtNotiz ?? ""
    }

    set gesperrtNotiz(value: string) {
        this.mandant.gesperrtNotiz = value === "" ? null : value
    }

    get status(): MandantStatus {
        return this.mandant.status
    }

    set status(value: MandantStatus) {
        this.mandant.status = value
    }

    get statusNotiz(): string {
        return this.mandant.statusNotiz ?? ""
    }

    set statusNotiz(value: string) {
        this.mandant.statusNotiz = value === "" ? null : value
    }

    get abrechnungszeitraum() {
        return this.mandant.abrechnungszeitraum
    }

    get keinDirektkontakt() {
        return this.mandant.keinDirektkontakt
    }

    set keinDirektkontakt(value: boolean) {
        this.mandant.keinDirektkontakt = value
    }

    get keineRechnung() {
        return this.mandant.keineRechnung
    }

    set keineRechnung(value: boolean) {
        this.mandant.keineRechnung = value
    }

    get sammelrechnung() {
        return this.mandant.sammelrechnung
    }

    set sammelrechnung(value: Id<Kooperationspartner> | null) {
        this.mandant.sammelrechnung = value
    }

    get debitor() {
        return this.mandant.debitor
    }

    get kuendigungsdatum() {
        return Formatter.zeit.datum(this.mandant.kuendigungsdatum)
    }

    get effektiverAbrechnungszeitraum(): DateTime {
        const beitragsschaetzung = this.mandant.beitragsnachweis === Beitragsnachweis.SCHAETZUNG && this.mandant.abschlusstag === 10
        const abrechnungszeitraum = LocalMonth.fromString(this.mandant.abrechnungszeitraum!.YYYY_MM).dateTime
        if (beitragsschaetzung) {
            abrechnungszeitraum.plus({month: 1})
        }
        return abrechnungszeitraum
    }

    get sachbearbeiter() {
        return this.mandant.sachbearbeiter
    }

    set sachbearbeiter(value: Id<Account> | null) {
        this.mandant.sachbearbeiter = value
    }

    get lohnsteueranmeldeperiode() {
        return this.mandant.lohnsteueranmeldeperiode
    }

    set lohnsteueranmeldeperiode(value: Lohnsteueranmeldeperiode) {
        this.mandant.lohnsteueranmeldeperiode = value
    }

    get abrechnungssystem() {
        return this.mandant.abrechnungssystem
    }

    set abrechnungssystem(value: LohnabrechnungSystem) {
        this.mandant.abrechnungssystem = value
    }

    get kooperationspartner() {
        return this.mandant.kooperationspartner
    }

    set kooperationspartner(value: Id<Kooperationspartner> | null) {
        this.mandant.kooperationspartner = value
    }

    get beitragsnachweis() {
        return this.mandant.beitragsnachweis
    }

    set beitragsnachweis(value: Beitragsnachweis) {
        this.mandant.beitragsnachweis = value
    }

    get beitragAbbuchung() {
        return this.mandant.beitragAbbuchung
    }

    set beitragAbbuchung(value: boolean) {
        this.mandant.beitragAbbuchung = value
    }

    get meldetSichVorAbschluss() {
        return this.mandant.meldetSichVorAbschluss
    }

    set meldetSichVorAbschluss(value: boolean) {
        this.mandant.meldetSichVorAbschluss = value
    }

    get needsDebitor(): boolean {
        return !this.sammelrechnung?.value
    }

    get bearbeitungshinweis() {
        return this.mandant.bearbeitungshinweis
    }

    set bearbeitungshinweis(value: string) {
        this.mandant.bearbeitungshinweis = value
    }

    get abschlusstag() {
        return this.mandant.abschlusstag
    }

    set abschlusstag(value: number) {
        this.mandant.abschlusstag = value
    }

    async firma(): Promise<Id<Firma>> {
        const sammelrechnungId: Id<Kooperationspartner> | null = this.mandant.sammelrechnung
        const debitorId: Id<Debitor> | null = this.mandant.debitor
        if (sammelrechnungId?.value) {
            const debitor = (await Resource.kooperationspartner.debitor.all(sammelrechnungId))[0]
            return debitor.rechnungssteller
        } else if (debitorId?.value) {
            const debitor = (await Resource.mandant.debitor.all(this.mandant.id))[0]
            return debitor.rechnungssteller
        } else {
            throw Error("firma muss existieren")
        }
    }

    // TODO api call
    async artikelkatalogId(firmaId?: Id<Firma>): Promise<Id<Artikelkatalog>> {
        const firma = firmaId ?? await this.firma()
        const artikelkatalogId = (await ResourceCache.firma.get(firma)).artikelkatalog.value
        const sammelrechnungId = this.mandant.sammelrechnung
        const sammelrechnungArtikelKatalog = sammelrechnungId &&
            (await ResourceCache.kooperationspartner.get(sammelrechnungId))?.artikelkatalog?.value
        return new Id(sammelrechnungArtikelKatalog || artikelkatalogId)
    }

    override get changed(): boolean {
        return super.changed
    }
}