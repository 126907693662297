import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_abschluss = _resolveComponent("icon-abschluss")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (!_ctx.gesperrt)
    ? (_openBlock(), _createBlock(_component_el_tooltip, {
        key: 0,
        disabled: _ctx.tooltipDisabled,
        "show-after": 1000,
        placement: "top"
      }, {
        content: _withCtx(() => [
          _createElementVNode("span", null, "Mandant " + _toDisplayString(_ctx.viewModel.kennung) + " für den Abrechnungszeitraum " + _toDisplayString(_ctx.abrechnungszeitraum) + " abschließen", 1)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_button, {
            class: _normalizeClass(_ctx.btnClass),
            disabled: _ctx.inaktiv,
            size: _ctx.size,
            name: "abschluss",
            text: "",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.onClick(_ctx.viewModel)), ["stop"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_icon_abschluss, { size: _ctx.size }, null, 8, ["size"])
            ]),
            _: 1
          }, 8, ["class", "disabled", "size"])
        ]),
        _: 1
      }, 8, ["disabled"]))
    : _createCommentVNode("", true)
}