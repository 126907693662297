import {Repository} from "@lib/common/repository/Repository"
import {Ticketgruppe} from "@generated/de/lohn24/model/ticketgruppe/Ticketgruppe"
import {TicketgruppeMapping} from "@lib/model/TicketgruppeMapping/TicketgruppeMapping"

export class TicketgruppeRepository extends Repository<Ticketgruppe> {

    mapping = this.buildRelation(TicketgruppeMapping, "mapping")

    constructor() {
        super(Ticketgruppe, "ticketgruppe")
    }
}