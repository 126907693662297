import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.phoneNumber)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.call
      }, _toDisplayString(_ctx.phoneNumber), 9, _hoisted_1))
    : (_openBlock(), _createElementBlock("span", _hoisted_2))
}