import { IRuntimeTypeInformation, Serializable } from "@lib/index"

export interface ISftpConfiguration {
    username: string
    password: string
	host: string
	port: number
	destinationDirectory: string | null
}

export class SftpConfiguration extends Serializable<SftpConfiguration> {
    public username!: string
    public password!: string
	public host!: string
	public port!: number
	public destinationDirectory!: string | null

	static _types: Record<keyof ISftpConfiguration, IRuntimeTypeInformation> = {
        username: { type: String },
        password: { type: String },
		host: { type: String },
		port: { type: Number },
		destinationDirectory: { type: String, nullable: true },
	}

	static props: Record<keyof ISftpConfiguration, string> = {
		port: "port",
		destinationDirectory: "destinationDirectory",
		host: "host",
		username: "username",
		password: "password",
	}

	constructor(_json: ISftpConfiguration) {
		super(_json)
	}
}
