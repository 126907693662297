import { IRuntimeTypeInformation, Serializable } from "@lib/index"
import { IFailure, Failure } from "@generated/dev/api/model/failure/Failure"

export interface IFailureTreeNode {
	isLeaf: boolean
	value: IFailure | null
	count: number
	key: string
	label: string
}

export class FailureTreeNode extends Serializable<FailureTreeNode> {
	public isLeaf!: boolean
	public value!: Failure | null
	public count!: number
	public key!: string
	public label!: string

	static _types: Record<keyof IFailureTreeNode, IRuntimeTypeInformation> = {
		isLeaf: { type: Boolean },
		value: { type: Failure, nullable: true },
		count: { type: Number },
		key: { type: String },
		label: { type: String },
	}

	static props: Record<keyof IFailureTreeNode, string> = {
		isLeaf: "isLeaf",
		value: "value",
		count: "count",
		key: "key",
		label: "label",
	}

	constructor(_json: IFailureTreeNode) {
		super(_json)
	}
}
