import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"

export interface IArtikelkatalog extends IModel {
	name: string
}

export class Artikelkatalog extends Model<Artikelkatalog> {
	public name!: string

	static _types: Record<keyof IArtikelkatalog, IRuntimeTypeInformation> = {
		id: { type: Id },
		name: { type: String },
	}

	static props: Record<keyof IArtikelkatalog, string> = {
		id: "id",
		name: "name",
	}

	constructor(_json: IArtikelkatalog) {
		super(_json)
	}
}
