import {PdfMenuItem} from "@intranet/view/pdf/PdfMenuItem"
import {Resource} from "@lib/model/Resource"
import {LocalMonth} from "@lib/common/LocalMonth"
import {Id} from "@lib/common/model/Id"

export const SammelrechnungVorschauPdf = new PdfMenuItem<{ kooperationspartner: string, leistungszeitraum: string }>
({
    path: "/vorschau/:kooperationspartner/:leistungszeitraum",
    cb({kooperationspartner, leistungszeitraum}) {
        return Resource.beleg.sammelrechnungVorschau(
            LocalMonth.fromString(leistungszeitraum),
            new Id(kooperationspartner),
        )
    },
})