import {BaseIdType, Id} from "@lib/common/model/Id"
import {Serializable} from "@lib/common/serializable/Serializable"

export type ModelProps<M> = Omit<M, keyof Omit<Model<any>, "id"> | "ctor">
export type PartialModel<M> = Partial<ModelProps<M>>

export interface IModel {
    id?: BaseIdType
}

export abstract class Model<M> extends Serializable<M> {

    protected static _types: Partial<Record<keyof InstanceType<typeof this>, any>> = {
        id: {type: Id},
    }
    id!: Id<M>

    constructor(json: any) {
        super(json)
    }
}