import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_icon_edit = _resolveComponent("icon-edit")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_vue_showdown = _resolveComponent("vue-showdown")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, null, {
    default: _withCtx(() => [
      (!_ctx.dialogTitle)
        ? (_openBlock(), _createBlock(_component_el_col, {
            key: 0,
            span: 23
          }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString(_ctx.dialogTitle), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.editable)
        ? (_openBlock(), _createBlock(_component_el_col, {
            key: 1,
            span: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                size: "small",
                text: "",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_edit, { size: "large" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_col, { class: "readable-text content" }, {
        default: _withCtx(() => [
          _createVNode(_component_vue_showdown, { markdown: _ctx.modelValue }, null, 8, ["markdown"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}