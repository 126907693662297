import {EinzelrechnungElementViewModel} from "@intranet/view/rechnung/einzelrechnung/EinzelrechnungElementViewModel"
import {assertNotNull} from "@lib/common/Functions"
import {Resource} from "@lib/model/Resource"
import {ListController} from "@lib/common/controller/ListController"
import {LocalMonth} from "@lib/common/LocalMonth"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {h, nextTick, ref} from "vue"
import {AutomatischeRechnung} from "@generated/de/lohn24/model/mandant/AutomatischeRechnung"
import {ElForm, ElFormItem, ElMessageBox, ElRadioButton, ElRadioGroup} from "element-plus"
import {Formatter} from "@lib/common/Formatter"
import {Config} from "@lib/Config"

export async function festschreiben(row: EinzelrechnungElementViewModel, controller?: ListController<any>) {
    try {
        row.pendingRequest = true
        await nextTick()
        const mandant = row.element.mandant !== null ? await Resource.mandant.find(row.element.mandant) : undefined

        await confirmEinzelrechnungFestschreiben({
            name: row.name,
            kennung: row.kennung,
            leistungszeitraum: assertNotNull(row.abrechnungszeitraum || row.leistungszeitraum),
        }, mandant)
        await controller?.toggleLoading(true)
        await Resource.beleg.einzelrechnungFestschreiben(row.belegBuilderId)
        await controller?.fetch()
        await controller?.toggleLoading(false)
    } catch (e) {
        if (e instanceof Error) {
            throw e
        }
    } finally {
        row.pendingRequest = false
    }
}

export async function freigeben(row: EinzelrechnungElementViewModel, controller?: ListController<any>) {
    try {
        const mandant = row.element.mandant !== null ? (await Resource.mandant.find(row.element.mandant)) : undefined
        await confirmEinzelrechnungFreigeben({
            name: row.name,
            kennung: row.kennung,
            zeitraum: assertNotNull(row.abrechnungszeitraum || row.leistungszeitraum),
        }, mandant)
        await Resource.beleg.einzelrechnungFreigeben(row.belegBuilderId)
        await controller?.toggleLoading(true)

        await controller?.fetch()
        await controller?.toggleLoading(false)
    } catch (e) {
        if (e instanceof Error) {
            throw e
        }
    }
}

export async function verwerfen(row: EinzelrechnungElementViewModel) {
    try {
        await row.confirmVerwerfen()
        await Resource.beleg.verwerfen(row.belegBuilderId)
    } catch (e) {
        if (e instanceof Error) {
            throw e
        }
    }
}

export async function confirmEinzelrechnungFreigeben(element: {
    kennung: string,
    name: string,
    zeitraum: LocalMonth
}, mandant?: Mandant) {
    const automatischeRechnung = ref<AutomatischeRechnung | null>(mandant?.automatischeRechnung ?? null)
    const automatischeRechnungFormItem = () => mandant
        ? [
            h("br"),
            h("br"),
            h(ElForm, null, [
                h(
                    ElFormItem,
                    {label: "Automatisches Freigeben:"},
                    h(
                        ElRadioGroup,
                        {
                            modelValue: automatischeRechnung.value!,
                            "onUpdate:modelValue": (value) => automatischeRechnung.value = value as AutomatischeRechnung,
                            size: "small",
                        },
                        [
                            h(ElRadioButton, {label: AutomatischeRechnung.JA}, "Ja"),
                            h(ElRadioButton, {label: AutomatischeRechnung.NEIN}, "Nein"),
                            h(ElRadioButton, {label: AutomatischeRechnung.NIE}, "Nie"),
                        ],
                    ),
                ),
            ]),
        ]
        : []

    const result = await ElMessageBox.confirm(
        () => h("p", null, [
            h(
                "span",
                null,
                `Den Mandanten ${element.kennung} // ${element.name} für ${Formatter.zeit.zeitraum(element.zeitraum)}  freigeben?`,
            ),
            ...automatischeRechnungFormItem(),
        ]),
        `Freigeben`,
        {
            cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
        },
    )

    if (mandant && mandant.automatischeRechnung !== automatischeRechnung.value && automatischeRechnung.value !== null) {
        await Resource.mandant.aktualisiereAutomatischeRechnung(mandant.id, automatischeRechnung.value)
    }

    return result
}

export async function confirmEinzelrechnungFestschreiben(element: {
    kennung: string,
    name: string,
    leistungszeitraum: LocalMonth
}, mandant?: Mandant) {
    const automatischeRechnung = ref<AutomatischeRechnung | null>(mandant?.automatischeRechnung ?? null)
    const automatischeRechnungFormItem = () => mandant
        ? [
            h("br"),
            h("br"),
            h(ElForm, null, [
                h(
                    ElFormItem,
                    {label: "Automatisches Festschreiben:"},
                    h(
                        ElRadioGroup,
                        {
                            modelValue: automatischeRechnung.value!,
                            "onUpdate:modelValue": (value) => automatischeRechnung.value = value as AutomatischeRechnung,
                            size: "small",
                        },
                        [
                            h(ElRadioButton, {label: AutomatischeRechnung.JA}, "Ja"),
                            h(ElRadioButton, {label: AutomatischeRechnung.NEIN}, "Nein"),
                            h(ElRadioButton, {label: AutomatischeRechnung.NIE}, "Nie"),
                        ],
                    ),
                ),
            ]),
        ]
        : []

    const result = await ElMessageBox.confirm(
        () => h("p", null, [
            h(
                "span",
                null,
                `Den Beleg für ${element.kennung} // ${element.name} im Leistungszeitraum ${Formatter.zeit.zeitraum(element.leistungszeitraum)} festschreiben?`,
            ),
            ...automatischeRechnungFormItem(),
        ]),
        `Festschreiben`,
        {
            cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
        },
    )

    if (mandant && mandant.automatischeRechnung !== automatischeRechnung.value && automatischeRechnung.value !== null) {
        await Resource.mandant.aktualisiereAutomatischeRechnung(mandant.id, automatischeRechnung.value)
    }

    return result
}