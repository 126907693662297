import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"
import { IRole, Role } from "@generated/dev/api/model/role/Role"
import { IGroup, Group } from "@generated/dev/api/model/group/Group"
import { IEffectiveRight, EffectiveRight } from "@generated/dev/api/model/right/EffectiveRight"
import { Account } from "@generated/dev/api/model/account/Account"

export interface ISession extends IModel {
	account: string
	groups: Array<IGroup>
	rights: Array<IEffectiveRight>
	roles: Array<IRole>
	created: LocalDateTime
	expired: LocalDateTime
	token: string
}

export class Session extends Model<Session> {
	public account!: Id<Account>
	public groups!: Array<Group>
	public rights!: Array<EffectiveRight>
	public roles!: Array<Role>
	public created!: LocalDateTime
	public expired!: LocalDateTime
	public token!: string

	static _types: Record<keyof ISession, IRuntimeTypeInformation> = {
		id: { type: Id },
		account: { type: Id },
		groups: { type: [Group] },
		rights: { type: [EffectiveRight] },
		roles: { type: [Role] },
		created: { type: LocalDateTime },
		expired: { type: LocalDateTime },
		token: { type: String },
	}

	static props: Record<keyof ISession, string> = {
		id: "id",
		account: "account",
		groups: "groups",
		rights: "rights",
		roles: "roles",
		created: "created",
		expired: "expired",
		token: "token",
	}

	constructor(_json: ISession) {
		super(_json)
	}
}
