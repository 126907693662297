<script lang="ts" setup>
import {
    kooperationspartnerZammadTicket,
    KooperationspartnerZammadTicketContext,
} from "@intranet/view/kooperationspartner/ticket/KooperationspartnerZammadTicket"

const props = withDefaults(
    defineProps<KooperationspartnerZammadTicketContext & { btnClass?: string }>(),
    {btnClass: ""},
)

const options = kooperationspartnerZammadTicket(() => (props))
</script>

<template>
  <el-tooltip :content="options.label" :show-after="1000" placement="top">
    <el-button :class="btnClass" text v-bind="$attrs" @click.stop="(event)=>options.action(event)">
      <component :is="options.icon" />
    </el-button>
  </el-tooltip>
</template>