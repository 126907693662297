import {Repository} from "@lib/common/repository/Repository"
import {Id} from "@lib/common/model/Id"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {from} from "@lib/common/Functions"
import {News} from "@generated/de/lohn24/model/news/News"
import {MitarbeiterMandantDistincts} from "@lib/model/mitarbeiter/MitarbeiterMandantDistincts"
import {RepositoryDispatchMap, RepositoryEventMap, RepostioryEvents} from "@lib/common/repository/RepositoryEventTarget"
import {IMitarbeiter, Mitarbeiter} from "@generated/de/lohn24/model/mitarbeiter/Mitarbeiter"
import {Formatter} from "@lib/common/Formatter"

export type MitarbeiterRepostioryEvents = RepostioryEvents | "gelesen" | "heartbeat"
export type MitarbeiterRepositoryDispatchMap<M> = RepositoryDispatchMap<M> & {
    "gelesen": News,
    "heartbeat": void,
}
export type MitarbeiterRepositoryEventMap<M> = RepositoryEventMap<M> & {
    "gelesen": (evt: News) => void,
    "heartbeat": (evt?) => void
}

export class MitarbeiterRepository extends Repository<Mitarbeiter> {

    constructor() {
        super(Mitarbeiter, "mitarbeiter")
    }

    async mandanten(params: {
        mitarbeiter: Id<Mitarbeiter> | undefined,
        keineVertretenen?: boolean,
    } | {
        limit: number,
        offset: number
    }): Promise<PartitionedResultSet<Mandant, MitarbeiterMandantDistincts>> {
        const result = await this.api.get<Partial<PartitionedResultSet<Mandant, MitarbeiterMandantDistincts>>>(
            this.route("mandanten"), params,
        )
        return new PartitionedResultSet(Mandant, MitarbeiterMandantDistincts, result)
    }

    async updateStatus(entity: Mitarbeiter): Promise<Mitarbeiter> {
        const result = await this.api.post<IMitarbeiter>(this.route("status"), {
            status: entity.mitarbeiterStatus,
            arbeitsplatz: entity.arbeitsplatz,
        })
        const mitarbeiter = from(Mitarbeiter, result)
        this.dispatchEvent("update", mitarbeiter)
        return mitarbeiter
    }

    async heartbeat(): Promise<void> {
        await this.api.post<void>(this.route("heartbeat"), {})
        this.dispatchEvent("heartbeat", undefined)
    }

    override dispatchEvent<E extends MitarbeiterRepostioryEvents>(type: E, data: MitarbeiterRepositoryDispatchMap<Mitarbeiter>[E]): void {
        //@ts-expect-error override types
        super.dispatchEvent(type, data)
    }

    override addEventListener<E extends MitarbeiterRepostioryEvents>(type: E, cb: MitarbeiterRepositoryEventMap<Mitarbeiter>[E]): any {
        //@ts-expect-error override types
        return super.addEventListener(type, cb)
    }

    override removeEventListener<E extends MitarbeiterRepostioryEvents>(type: E, cb: MitarbeiterRepositoryEventMap<Mitarbeiter>[E]): any {
        //@ts-expect-error override types
        return super.removeEventListener(type, cb)
    }

    async me(): Promise<Mitarbeiter> {
        const result = await this.api.get<IMitarbeiter>(this.route("me"))
        return this.deserialize(result)
    }

    async createZammadAccessToken(): Promise<Mitarbeiter> {
        const result = (await this.api.post(this.route("zammad"), {})) as Mitarbeiter
        return this.deserialize(result)
    }

    mitarbeiterName(id: Id<Mitarbeiter> | null) {
        if (id === null)
            return null

        return this.cache.mapped(id, mitarbeiter =>
            mitarbeiter ?
                Formatter.mitarbeiter.vorUndZu({
                    vorname: mitarbeiter.vorname,
                    nachname: mitarbeiter.nachname,
                })
                : null,
        ).value
    }
}