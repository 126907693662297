import {AppVersionService} from "@lib/common/vue/AppVersionService"
import {auth} from "@lib/common/Authentication"
import {Id} from "@lib/common/model/Id"
import {Account} from "@generated/dev/api/model/account/Account"
import {Plugin} from "@lib/common/vue/plugin/Plugin"
import {IntervalPlugin} from "@lib/common/vue/plugin/IntervalPlugin"
import {App} from "vue"

export class ReloadToUpdatePlugin implements Plugin<{ interval: number }> {
    private intervalPlugin = new IntervalPlugin<Id<Account>>()

    static async reloadOnDiff(server: string, local: string) {
        if (server != local && server.isNotEmpty()) {
            try {
                window.location.reload()
            } catch (e) {
                throw Error(`honestly ... ${local} -> ${server} needed`)
            }
        }
    }

    install(app: App, options: { interval: number }): any {
        AppVersionService.addAppListener(ReloadToUpdatePlugin.reloadOnDiff)
        AppVersionService.addVendorListener(ReloadToUpdatePlugin.reloadOnDiff)
        this.intervalPlugin.install(app, {
            uniqueName: "AppVersionService",
            interval: options.interval,
            methods: [
                () => AppVersionService.execute(),
            ],
            startWithWatcher: {
                source: () => auth.mitarbeiter.value.id,
                triggerRule: (id?: Id<Account> | null) => id !== null && id !== undefined,
            },
        })
    }

}