import {ListController} from "@lib/common/controller/ListController"
import {PropType} from "vue"

export const crudActionColumnProps = {
    controller: {
        type: Object as PropType<ListController<any>>,
        required: true,
    },
    editable: Boolean,
    createable: Boolean,
    deletable: Boolean,
    neu: {
        type: String,
        default: "Neuen Eintrag erstellen",
    },
    fixed: Boolean,
}