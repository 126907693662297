import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import KooperationspartnerSummaryDialogView
    from "@intranet/view/kooperationspartner/summary/KooperationspartnerSummaryDialogView.vue"
import {
    KooperationspartnerSummaryDialogController,
} from "@intranet/view/kooperationspartner/summary/KooperationspartnerSummaryDialogController"
import {Resource} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"

export class KooperationspartnerSummaryDialog extends ComponentRenderer<typeof KooperationspartnerSummaryDialogView> {
    constructor(controller: KooperationspartnerSummaryDialogController, done: (m?: any) => void) {
        super(
            KooperationspartnerSummaryDialogView,
            {
                controller: controller,
                done: done,
            },
        )
    }

    static async show(kooperationspartner: Id<Kooperationspartner>): Promise<void> {
        const koop = await Resource.kooperationspartner.find(kooperationspartner)
        const controller = new KooperationspartnerSummaryDialogController(koop)
        return new Promise(resolve => {
            return new KooperationspartnerSummaryDialog(controller, resolve)
        })
    }
}