import { IRuntimeTypeInformation, Serializable } from "@lib/index"

export interface ISummary {
	deleted: number
	inserted: number
	updated: number
}

export class Summary extends Serializable<Summary> {
	public deleted!: number
	public inserted!: number
	public updated!: number

	static _types: Record<keyof ISummary, IRuntimeTypeInformation> = {
		deleted: { type: Number },
		inserted: { type: Number },
		updated: { type: Number },
	}

	static props: Record<keyof ISummary, string> = {
		deleted: "deleted",
		inserted: "inserted",
		updated: "updated",
	}

	constructor(_json: ISummary) {
		super(_json)
	}
}
