import {computed, ref, unref, WritableComputedRef} from "vue"
import {RouteLocationNormalizedLoaded} from "vue-router"
import {MenuItem, uuid} from "@lib/common/menu/MenuItem"
import {ElHeader} from "element-plus"
import {skipFrame} from "@lib/common/Functions"
import {TabMenuItem} from "@lib/common/menu/TabMenuItem"
import {KeyboardEventlistener} from "@lib/common/dialog/KeyboardEventlistener"
import {router} from "@intranet/plugin/router/Router"

export type SubBarContent = string | symbol | null | undefined;

const root = document.documentElement

const _headerHeight = 50

const headerHeight_ = ref<number>(_headerHeight)

const baseHeaderHeight = ref<number>(_headerHeight)

export const header = ref<InstanceType<typeof ElHeader>>()

export const headerHeight: WritableComputedRef<number> = computed<number>({
    get() {
        return headerHeight_.value
    },
    set(value) {
        root.style.setProperty("--l24-dyn-header-height", `${value}px`)
        headerHeight_.value = value
    },
})

const _subbarContent = ref<SubBarContent>()

export const subBarContent = computed<SubBarContent>({
    get() {
        return _subbarContent.value
    },
    set(value) {
        _subbarContent.value = value
    },
})

let onHeaderTransistionEndCb: () => void | Promise<void> = () => undefined
let lastHeaderTransitionEventNow = 0
let headerTimeout = 0
document.documentElement.ontransitionend = (ev) => {
    const currentHeaderTransitionEventNow = window.performance.now()
    if ((header.value?.$el as HTMLDivElement).contains(ev.target as HTMLElement)) {
        if (currentHeaderTransitionEventNow - lastHeaderTransitionEventNow > 10) {
            clearTimeout(headerTimeout)
            headerTimeout = setTimeout(async () => {
                await skipFrame()
                await skipFrame()
                onHeaderTransistionEndCb()
                onHeaderTransistionEndCb = () => undefined
            }, 200) as unknown as number
        }

        lastHeaderTransitionEventNow = currentHeaderTransitionEventNow
    }
}

export function onHeaderTransistionEnd(cb: () => void | Promise<void>) {
    onHeaderTransistionEndCb = cb
}

export function openSubBar(content?: string | symbol | null) {
    subBarContent.value = content
    headerHeight.value = (5 / 3) * baseHeaderHeight.value
}

export function closeSubBar(content?: string | symbol | null) {
    subBarContent.value = content
    restoreHeaderHeight()
}

export function activate(children: unknown[] | undefined) {
    return Array.isArray(children) && children.length > 0 ? openSubBar : closeSubBar
}

export function initializeSubBarContent(menuItems: MenuItem[], route: RouteLocationNormalizedLoaded) {
    const [tabMenuItems, baseMenuItems] = menuItems.partition(it => it instanceof TabMenuItem)

    for (const menuItem of tabMenuItems) {
        if (route.name == menuItem.routeRecordName || menuItem.children?.find((x) =>
            x.routeRecordName == route.name || x.children?.find(y => y.routeRecordName == route.name),
        )) {
            return closeSubBar(menuItem.name)
        }
    }

    for (const menuItem of baseMenuItems) {
        if (route.name == menuItem.routeRecordName || menuItem.children?.find((x) =>
            x.routeRecordName == route.name || x.children?.find(y => y.routeRecordName == route.name),
        )) {
            return activate(menuItem.children)(menuItem.name)
        }
    }
}

export const shortcuts = ref<(string)[]>([])
const listener = new KeyboardEventlistener()

let timeout: any | null

function activeShortcuts(...item: string[]) {
    shortcuts.value = [...item]
    clearTimeout(timeout)
    timeout = setTimeout(() => {
        shortcuts.value = []
    }, 2000)
}

export function initializeKeyboardEventlistener(menuItems: MenuItem[]) {
    listener.clear()
    const [tabMenuItems, baseMenuItems] = menuItems.partition(it => it instanceof TabMenuItem)
    for (const menuItem of (tabMenuItems as TabMenuItem[])) {
        listener.add({
            event: () => false,
            ctrl: (queue) => {
                return queue[0] === menuItem.key
            },
            action: async () => {
                activeShortcuts(menuItem[uuid])
            },
        })
        for (const child of menuItem.nestedChildren) {
            listener.add({
                ctrl: (queue) => {
                    return queue[0] === menuItem.key && queue[1] === child.key
                },
                event: () => false,
                action: async () => {
                    activeShortcuts(menuItem[uuid], child[uuid])
                    await router.push(unref(child.route))
                },
            })
        }
    }
}

function restoreHeaderHeight() {
    headerHeight.value = baseHeaderHeight.value
}