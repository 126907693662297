import {Id} from "@lib/common/model/Id"
import {timestamp, uuidv4, uuidv7, version} from "@lib/common/uuid/UUID"

export class UuidService {

    public static v7(): string {
        return uuidv7()
    }

    public static v4(): string {
        return uuidv4()
    }

    static isV7(uuid: string): boolean {
        return version(uuid) === 7
    }

    static isV4(uuid: string): boolean {
        return version(uuid) === 4
    }

    static id<M>(): Id<M> {
        return new Id<M>(this.v7())
    }

    static timeStamp(uuid: string) {
        return timestamp(uuid)
    }
}