import { IModel, IRuntimeTypeInformation, Id, LocalDate, LocalDateTime, LocalMonth, Model } from "@lib/index"
import { IPostanschrift, Postanschrift } from "@generated/de/lohn24/model/brief/Postanschrift"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { DebitorStatus } from "@generated/de/lohn24/model/debitor/DebitorStatus"
import { DebitorSepa } from "@generated/de/lohn24/model/debitor/DebitorSepa"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { IBriefabsenderDaten, BriefabsenderDaten } from "@generated/dev/core/model/brief/BriefabsenderDaten"
import { BriefabsenderArt } from "@generated/de/lohn24/model/brief/BriefabsenderArt"
import { BelegArt } from "@generated/de/lohn24/model/beleg/BelegArt"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IBeleg extends IModel {
	belegbearbeiter: string
	debitor: string
	kooperationspartner: string | null
	korrektur: string | null
	mandant: string | null
	rechnungssteller: string
	belegart: BelegArt
	abweichendeBeleganschrift: boolean
	sammelrechnung: boolean
	sepa: boolean
	rechnungsstellerBrieftyp: BriefabsenderArt
	absender: IBriefabsenderDaten
	debitorSepa: DebitorSepa | null
	debitorStatus: DebitorStatus
	rechnungsstellerLogoSkalierung: number | null
	datevExportBeleg: LocalDateTime | null
	festgeschrieben: LocalDateTime
	datum: LocalDate
	debitorSepaGueltigkeit: LocalDate | null
	debitorSepaUnterschrift: LocalDate | null
	leistungsdatum: LocalDate | null
	abrechnungszeitraum: string | null
	leistungszeitraum: string
	summeNetto: number
	summeUmsatzsteuer: number
	postanschrift: IPostanschrift
	rechnungsanschrift: IPostanschrift
	art: string
	beleganschriftZeile1: string
	beleganschriftZeile10: string
	beleganschriftZeile11: string
	beleganschriftZeile2: string
	beleganschriftZeile3: string
	beleganschriftZeile4: string
	beleganschriftZeile5: string
	beleganschriftZeile6: string
	beleganschriftZeile7: string
	beleganschriftZeile8: string
	beleganschriftZeile9: string
	belegnummer: string
	belegtext: string
	debitorAbweichenderKontoinhaber: string | null
	debitorIban: string | null
	debitorKonto: string
	debitorMail: string | null
	debitorRechnungszusatz: string | null
	debitorSepaMandatsreferenz: string | null
	debitorUmsatzsteuerid: string | null
	postanschriftZeile1: string
	postanschriftZeile10: string
	postanschriftZeile11: string
	postanschriftZeile2: string
	postanschriftZeile3: string
	postanschriftZeile4: string
	postanschriftZeile5: string
	postanschriftZeile6: string
	postanschriftZeile7: string
	postanschriftZeile8: string
	postanschriftZeile9: string
	rechnungsstellerBic: string
	rechnungsstellerEmail: string
	rechnungsstellerGeschaeftsfuehrung: string
	rechnungsstellerGlaeubigerId: string | null
	rechnungsstellerIban: string
	rechnungsstellerInternet: string
	rechnungsstellerKurzname: string
	rechnungsstellerLogo: string | null
	rechnungsstellerName: string
	rechnungsstellerOrt: string
	rechnungsstellerPostleitzahl: string
	rechnungsstellerRegistergericht: string
	rechnungsstellerRegisternummer: string
	rechnungsstellerRuecksendeangabe: string
	rechnungsstellerStrasse: string
	rechnungsstellerTelefax: string
	rechnungsstellerTelefon: string
	rechnungsstellerUmsatzsteuerid: string
}

export class Beleg extends Model<Beleg> {
	public belegbearbeiter!: Id<Account>
	public debitor!: Id<Debitor>
	public kooperationspartner!: Id<Kooperationspartner> | null
	public korrektur!: Id<Beleg> | null
	public mandant!: Id<Mandant> | null
	public rechnungssteller!: Id<Firma>
	public belegart!: BelegArt
	public abweichendeBeleganschrift!: boolean
	public sammelrechnung!: boolean
	public sepa!: boolean
	public rechnungsstellerBrieftyp!: BriefabsenderArt
	public absender!: BriefabsenderDaten
	public debitorSepa!: DebitorSepa | null
	public debitorStatus!: DebitorStatus
	public rechnungsstellerLogoSkalierung!: number | null
	public datevExportBeleg!: LocalDateTime | null
	public festgeschrieben!: LocalDateTime
	public datum!: LocalDate
	public debitorSepaGueltigkeit!: LocalDate | null
	public debitorSepaUnterschrift!: LocalDate | null
	public leistungsdatum!: LocalDate | null
	public abrechnungszeitraum!: LocalMonth | null
	public leistungszeitraum!: LocalMonth
	public summeNetto!: number
	public summeUmsatzsteuer!: number
	public postanschrift!: Postanschrift
	public rechnungsanschrift!: Postanschrift
	public art!: string
	public beleganschriftZeile1!: string
	public beleganschriftZeile10!: string
	public beleganschriftZeile11!: string
	public beleganschriftZeile2!: string
	public beleganschriftZeile3!: string
	public beleganschriftZeile4!: string
	public beleganschriftZeile5!: string
	public beleganschriftZeile6!: string
	public beleganschriftZeile7!: string
	public beleganschriftZeile8!: string
	public beleganschriftZeile9!: string
	public belegnummer!: string
	public belegtext!: string
	public debitorAbweichenderKontoinhaber!: string | null
	public debitorIban!: string | null
	public debitorKonto!: string
	public debitorMail!: string | null
	public debitorRechnungszusatz!: string | null
	public debitorSepaMandatsreferenz!: string | null
	public debitorUmsatzsteuerid!: string | null
	public postanschriftZeile1!: string
	public postanschriftZeile10!: string
	public postanschriftZeile11!: string
	public postanschriftZeile2!: string
	public postanschriftZeile3!: string
	public postanschriftZeile4!: string
	public postanschriftZeile5!: string
	public postanschriftZeile6!: string
	public postanschriftZeile7!: string
	public postanschriftZeile8!: string
	public postanschriftZeile9!: string
	public rechnungsstellerBic!: string
	public rechnungsstellerEmail!: string
	public rechnungsstellerGeschaeftsfuehrung!: string
	public rechnungsstellerGlaeubigerId!: string | null
	public rechnungsstellerIban!: string
	public rechnungsstellerInternet!: string
	public rechnungsstellerKurzname!: string
	public rechnungsstellerLogo!: string | null
	public rechnungsstellerName!: string
	public rechnungsstellerOrt!: string
	public rechnungsstellerPostleitzahl!: string
	public rechnungsstellerRegistergericht!: string
	public rechnungsstellerRegisternummer!: string
	public rechnungsstellerRuecksendeangabe!: string
	public rechnungsstellerStrasse!: string
	public rechnungsstellerTelefax!: string
	public rechnungsstellerTelefon!: string
	public rechnungsstellerUmsatzsteuerid!: string

	static _types: Record<keyof IBeleg, IRuntimeTypeInformation> = {
		id: { type: Id },
		belegbearbeiter: { type: Id },
		debitor: { type: Id },
		kooperationspartner: { type: Id, nullable: true },
		korrektur: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		rechnungssteller: { type: Id },
		belegart: { type: BelegArt },
		abweichendeBeleganschrift: { type: Boolean },
		sammelrechnung: { type: Boolean },
		sepa: { type: Boolean },
		rechnungsstellerBrieftyp: { type: BriefabsenderArt },
		absender: { type: BriefabsenderDaten },
		debitorSepa: { type: DebitorSepa, nullable: true },
		debitorStatus: { type: DebitorStatus },
		rechnungsstellerLogoSkalierung: { type: Number, nullable: true },
		datevExportBeleg: { type: LocalDateTime, nullable: true },
		festgeschrieben: { type: LocalDateTime },
		datum: { type: LocalDate },
		debitorSepaGueltigkeit: { type: LocalDate, nullable: true },
		debitorSepaUnterschrift: { type: LocalDate, nullable: true },
		leistungsdatum: { type: LocalDate, nullable: true },
		abrechnungszeitraum: { type: LocalMonth, nullable: true },
		leistungszeitraum: { type: LocalMonth },
		summeNetto: { type: Number },
		summeUmsatzsteuer: { type: Number },
		postanschrift: { type: Postanschrift },
		rechnungsanschrift: { type: Postanschrift },
		art: { type: String },
		beleganschriftZeile1: { type: String },
		beleganschriftZeile10: { type: String },
		beleganschriftZeile11: { type: String },
		beleganschriftZeile2: { type: String },
		beleganschriftZeile3: { type: String },
		beleganschriftZeile4: { type: String },
		beleganschriftZeile5: { type: String },
		beleganschriftZeile6: { type: String },
		beleganschriftZeile7: { type: String },
		beleganschriftZeile8: { type: String },
		beleganschriftZeile9: { type: String },
		belegnummer: { type: String },
		belegtext: { type: String },
		debitorAbweichenderKontoinhaber: { type: String, nullable: true },
		debitorIban: { type: String, nullable: true },
		debitorKonto: { type: String },
		debitorMail: { type: String, nullable: true },
		debitorRechnungszusatz: { type: String, nullable: true },
		debitorSepaMandatsreferenz: { type: String, nullable: true },
		debitorUmsatzsteuerid: { type: String, nullable: true },
		postanschriftZeile1: { type: String },
		postanschriftZeile10: { type: String },
		postanschriftZeile11: { type: String },
		postanschriftZeile2: { type: String },
		postanschriftZeile3: { type: String },
		postanschriftZeile4: { type: String },
		postanschriftZeile5: { type: String },
		postanschriftZeile6: { type: String },
		postanschriftZeile7: { type: String },
		postanschriftZeile8: { type: String },
		postanschriftZeile9: { type: String },
		rechnungsstellerBic: { type: String },
		rechnungsstellerEmail: { type: String },
		rechnungsstellerGeschaeftsfuehrung: { type: String },
		rechnungsstellerGlaeubigerId: { type: String, nullable: true },
		rechnungsstellerIban: { type: String },
		rechnungsstellerInternet: { type: String },
		rechnungsstellerKurzname: { type: String },
		rechnungsstellerLogo: { type: String, nullable: true },
		rechnungsstellerName: { type: String },
		rechnungsstellerOrt: { type: String },
		rechnungsstellerPostleitzahl: { type: String },
		rechnungsstellerRegistergericht: { type: String },
		rechnungsstellerRegisternummer: { type: String },
		rechnungsstellerRuecksendeangabe: { type: String },
		rechnungsstellerStrasse: { type: String },
		rechnungsstellerTelefax: { type: String },
		rechnungsstellerTelefon: { type: String },
		rechnungsstellerUmsatzsteuerid: { type: String },
	}

	static props: Record<keyof IBeleg, string> = {
		id: "id",
		belegbearbeiter: "belegbearbeiter",
		debitor: "debitor",
		kooperationspartner: "kooperationspartner",
		korrektur: "korrektur",
		mandant: "mandant",
		rechnungssteller: "rechnungssteller",
		belegart: "belegart",
		abweichendeBeleganschrift: "abweichendeBeleganschrift",
		sammelrechnung: "sammelrechnung",
		sepa: "sepa",
		rechnungsstellerBrieftyp: "rechnungsstellerBrieftyp",
		absender: "absender",
		debitorSepa: "debitorSepa",
		debitorStatus: "debitorStatus",
		rechnungsstellerLogoSkalierung: "rechnungsstellerLogoSkalierung",
		datevExportBeleg: "datevExportBeleg",
		festgeschrieben: "festgeschrieben",
		datum: "datum",
		debitorSepaGueltigkeit: "debitorSepaGueltigkeit",
		debitorSepaUnterschrift: "debitorSepaUnterschrift",
		leistungsdatum: "leistungsdatum",
		abrechnungszeitraum: "abrechnungszeitraum",
		leistungszeitraum: "leistungszeitraum",
		summeNetto: "summeNetto",
		summeUmsatzsteuer: "summeUmsatzsteuer",
		postanschrift: "postanschrift",
		rechnungsanschrift: "rechnungsanschrift",
		art: "art",
		beleganschriftZeile1: "beleganschriftZeile1",
		beleganschriftZeile10: "beleganschriftZeile10",
		beleganschriftZeile11: "beleganschriftZeile11",
		beleganschriftZeile2: "beleganschriftZeile2",
		beleganschriftZeile3: "beleganschriftZeile3",
		beleganschriftZeile4: "beleganschriftZeile4",
		beleganschriftZeile5: "beleganschriftZeile5",
		beleganschriftZeile6: "beleganschriftZeile6",
		beleganschriftZeile7: "beleganschriftZeile7",
		beleganschriftZeile8: "beleganschriftZeile8",
		beleganschriftZeile9: "beleganschriftZeile9",
		belegnummer: "belegnummer",
		belegtext: "belegtext",
		debitorAbweichenderKontoinhaber: "debitorAbweichenderKontoinhaber",
		debitorIban: "debitorIban",
		debitorKonto: "debitorKonto",
		debitorMail: "debitorMail",
		debitorRechnungszusatz: "debitorRechnungszusatz",
		debitorSepaMandatsreferenz: "debitorSepaMandatsreferenz",
		debitorUmsatzsteuerid: "debitorUmsatzsteuerid",
		postanschriftZeile1: "postanschriftZeile1",
		postanschriftZeile10: "postanschriftZeile10",
		postanschriftZeile11: "postanschriftZeile11",
		postanschriftZeile2: "postanschriftZeile2",
		postanschriftZeile3: "postanschriftZeile3",
		postanschriftZeile4: "postanschriftZeile4",
		postanschriftZeile5: "postanschriftZeile5",
		postanschriftZeile6: "postanschriftZeile6",
		postanschriftZeile7: "postanschriftZeile7",
		postanschriftZeile8: "postanschriftZeile8",
		postanschriftZeile9: "postanschriftZeile9",
		rechnungsstellerBic: "rechnungsstellerBic",
		rechnungsstellerEmail: "rechnungsstellerEmail",
		rechnungsstellerGeschaeftsfuehrung: "rechnungsstellerGeschaeftsfuehrung",
		rechnungsstellerGlaeubigerId: "rechnungsstellerGlaeubigerId",
		rechnungsstellerIban: "rechnungsstellerIban",
		rechnungsstellerInternet: "rechnungsstellerInternet",
		rechnungsstellerKurzname: "rechnungsstellerKurzname",
		rechnungsstellerLogo: "rechnungsstellerLogo",
		rechnungsstellerName: "rechnungsstellerName",
		rechnungsstellerOrt: "rechnungsstellerOrt",
		rechnungsstellerPostleitzahl: "rechnungsstellerPostleitzahl",
		rechnungsstellerRegistergericht: "rechnungsstellerRegistergericht",
		rechnungsstellerRegisternummer: "rechnungsstellerRegisternummer",
		rechnungsstellerRuecksendeangabe: "rechnungsstellerRuecksendeangabe",
		rechnungsstellerStrasse: "rechnungsstellerStrasse",
		rechnungsstellerTelefax: "rechnungsstellerTelefax",
		rechnungsstellerTelefon: "rechnungsstellerTelefon",
		rechnungsstellerUmsatzsteuerid: "rechnungsstellerUmsatzsteuerid",
	}

	constructor(_json: IBeleg) {
		super(_json)
	}
}
