import { IRuntimeTypeInformation, Id, Serializable } from "@lib/index"
import { ArtikelkatalogArtikel } from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"

export interface IKatalogArtikelPosition {
	artikel: string
	menge: number
	position: number
	notiz: string | null
	text: string | null
}

export class KatalogArtikelPosition extends Serializable<KatalogArtikelPosition> {
	public artikel!: Id<ArtikelkatalogArtikel>
	public menge!: number
	public position!: number
	public notiz!: string | null
	public text!: string | null

	static _types: Record<keyof IKatalogArtikelPosition, IRuntimeTypeInformation> = {
		artikel: { type: Id },
		menge: { type: Number },
		position: { type: Number },
		notiz: { type: String, nullable: true },
		text: { type: String, nullable: true },
	}

	static props: Record<keyof IKatalogArtikelPosition, string> = {
		artikel: "artikel",
		menge: "menge",
		position: "position",
		notiz: "notiz",
		text: "text",
	}

	constructor(_json: IKatalogArtikelPosition) {
		super(_json)
	}
}
