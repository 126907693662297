import { IRuntimeTypeInformation, Serializable } from "@lib/index"

export interface IProcessing {
	exceptions: Record<string,number>
	name: string
}

export class Processing extends Serializable<Processing> {
	public exceptions!: Record<string,number>
	public name!: string

	static _types: Record<keyof IProcessing, IRuntimeTypeInformation> = {
		exceptions: { type: [String, Number] },
		name: { type: String },
	}

	static props: Record<keyof IProcessing, string> = {
		exceptions: "exceptions",
		name: "name",
	}

	constructor(_json: IProcessing) {
		super(_json)
	}
}
