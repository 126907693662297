<script lang="ts" setup>
import {shallowRef, watch} from "vue"
import ListContent from "@lib/view/list/listcontent/ListContent.vue"
import {
    MandantBenachrichtigungsoptionListController,
} from "@intranet/view/mandant/benachrichtigung/MandantBenachrichtigungsoptionListController"
import {
    MandantBenachrichtigungsoptionViewModel,
} from "@intranet/view/mandant/benachrichtigung/MandantBenachrichtigungsoptionViewModel"
import {RowClassNameArgument} from "@lib/common/elementplus/types"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {KontaktListController} from "@intranet/view/kontakt/KontaktListController"
import {Resource} from "@lib/model/Resource"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"

export interface MandantBenachrichtigungsoptionProps {
    mandant: Mandant,
    controller: MandantBenachrichtigungsoptionListController | null
    controllerKontakte: KontaktListController<Mandant> | null
    controllerKooperationspartnerKontakte: KontaktListController<Kooperationspartner> | null
    sammelrechnung?: boolean
}

export interface MandantBenachrichtigungsoptionEmits extends /* @vue-ignore*/ Record<string, any> {
    "update:controller": [],
    "update:controllerKontakte": [],
    "update:controllerKooperationspartnerKontakte": []
}

const emits = defineEmits<MandantBenachrichtigungsoptionEmits>()
const props = defineProps<MandantBenachrichtigungsoptionProps>()

const benachrichtigungController = shallowRef<MandantBenachrichtigungsoptionListController | null>(null)
const kontaktListController = shallowRef<KontaktListController<any> | null>(null)
const kooperationspartnerKontaktListController = shallowRef<KontaktListController<any> | null>(null)

watch(() => props.controllerKontakte, async value => {
    if (value) {
        kontaktListController.value = props.controllerKontakte

        if (value.data.length <= 0 && !value.modified) {
            await value.fetch()
        }

        const kontakte = value.data.map(it => it.kontakt)
        const koopKontakte = (await initializeKoopKontaktController())?.value?.data.map(it => it.kontakt) ?? []

        if (props.controller?.containsAll(kontakte)) {
            benachrichtigungController.value = props.controller
        } else {
            benachrichtigungController.value = new MandantBenachrichtigungsoptionListController(
                props.mandant,
                kontakte,
                koopKontakte,
            )
            await benachrichtigungController.value.fetch()
            emits("update:controller", benachrichtigungController.value)
        }

    } else {
        const controller = new KontaktListController(
            Resource.organisation.kontakt,
            props.mandant.organisation,
        )

        emits("update:controllerKontakte", controller)
    }
}, {immediate: true})

function rowClassName({row}: RowClassNameArgument<MandantBenachrichtigungsoptionViewModel>) {
    const names: string[] = []
    if (!row.hasEmail) {
        names.push("keine-email")
    }
    return names.join(" ")
}

async function initializeKoopKontaktController() {
    let koopKontaktController = kooperationspartnerKontaktListController.value = props.controllerKooperationspartnerKontakte ?? null
    if (!koopKontaktController) {
        if (props.mandant.kooperationspartner !== null) {
            koopKontaktController = kooperationspartnerKontaktListController.value = new KontaktListController(
                Resource.kooperationspartner.kontakt,
                props.mandant.kooperationspartner,
            )
            emits("update:controllerKooperationspartnerKontakte", koopKontaktController)
        }
    }
    if (koopKontaktController && koopKontaktController.data.length <= 0 && !koopKontaktController.modified) {
        await koopKontaktController?.fetch()
    }
    return kooperationspartnerKontaktListController
}
</script>

<template>
  <h3>Benachrichtigung</h3>
  <h4>Organisation</h4>
  <list-content
          v-if="benachrichtigungController"
          :controller="benachrichtigungController"
          :data="MandantBenachrichtigungsoptionListController.filterOrganisationsKontakte"
          :paginatable="false"
          :row-class-name="rowClassName"
          :settings="false"
          :stripe="true"
          classprefix="benachrichtigungsoption"
          title="Benachrichtigungsoption"
  >
    <template #columns>
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.kontaktName"
              :prop="MandantBenachrichtigungsoptionViewModel.properties.kontaktName"
              :show-overflow-tooltip="true"
              width="200"
      />
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.email"
              :prop="MandantBenachrichtigungsoptionViewModel.properties.email"
              :show-overflow-tooltip="true"
              width="300"
      />
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.vorAbschluss"
              width="120"
      >
        <template #default="{row}">
          <el-switch
                  v-model="row.vorAbschluss"
                  :disabled="row.disabled"
                  active-icon="check"
                  inactive-icon="close"
                  inline-prompt
                  @click.stop
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.nachAbschluss"
              width="120"
      >
        <template #default="{row}">
          <el-switch
                  v-model="row.nachAbschluss"
                  :disabled="row.disabled"
                  active-icon="check"
                  inactive-icon="close"
                  inline-prompt
                  @click.stop
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.news"
              width="120"
      >
        <template #default="{row}">
          <el-switch
                  v-model="row.news"
                  :disabled="row.disabled"
                  active-icon="check"
                  inactive-icon="close"
                  inline-prompt
                  @click.stop
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
              v-if="!sammelrechnung"
              :label="MandantBenachrichtigungsoptionViewModel.label.rechnung"
              width="120"
      >
        <template #default="{row}">
          <el-switch
                  v-model="row.rechnung"
                  :disabled="row.disabled"
                  active-icon="check"
                  inactive-icon="close"
                  inline-prompt
                  @click.stop
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column />
    </template>
  </list-content>
  <br>
  <h4>Kooperationspartner</h4>
  <list-content
          v-if="benachrichtigungController"
          :controller="benachrichtigungController"
          :data="MandantBenachrichtigungsoptionListController.filterKooperationsKontakte"
          :paginatable="false"
          :row-class-name="rowClassName"
          :settings="false"
          :stripe="true"
          classprefix="benachrichtigungsoption"
          title="Benachrichtigungsoption"
  >
    <template #columns>
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.kontaktName"
              :prop="MandantBenachrichtigungsoptionViewModel.properties.kontaktName"
              :show-overflow-tooltip="true"
              width="200"
      />
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.email"
              :prop="MandantBenachrichtigungsoptionViewModel.properties.email"
              :show-overflow-tooltip="true"
              width="300"
      />
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.vorAbschluss"
              width="120"
      >
        <template #default="{row}">
          <el-switch
                  v-model="row.vorAbschluss"
                  :disabled="row.disabled"
                  active-icon="check"
                  inactive-icon="close"
                  inline-prompt
                  @click.stop
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column
              :label="MandantBenachrichtigungsoptionViewModel.label.nachAbschluss"
              width="120"
      >
        <template #default="{row}">
          <el-switch
                  v-model="row.nachAbschluss"
                  :disabled="row.disabled"
                  active-icon="check"
                  inactive-icon="close"
                  inline-prompt
                  @click.stop
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column />
    </template>
  </list-content>
</template>

<style lang="scss">
.benachrichtigungsoption-content {
  .keine-email {
    user-select: none;
    color: var(--el-disabled-text-color);
    cursor: not-allowed !important;
  }
}
</style>