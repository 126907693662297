import {MenuItem} from "@lib/common/menu/MenuItem"
import {computed} from "vue"
import {auth} from "@lib/common/Authentication"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/rights/RightIdentifier"

export const TicketgruppeMappingVerwaltungMenuItem = new MenuItem({
    label: "Ticketgruppen",
    name: "ticketgruppeverwaltung",
    view: () => import("@intranet/view/ticketgruppemappingverwaltung/TicketgruppeMappingVerwaltung.vue"),
    visible: computed(() => auth.hasAccess([RightAccess.full(RightIdentifier.GLOBAL_SETTINGS)])),
})