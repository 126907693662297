import {Repository} from "@lib/common/repository/Repository"
import {ArtikelkatalogArtikel} from "@generated/de/lohn24/model/artikelkatalogartikel/ArtikelkatalogArtikel"
import {Artikel} from "@generated/de/lohn24/model/artikel/Artikel"

export class ArtikelkatalogArtikelRepository extends Repository<ArtikelkatalogArtikel> {
    artikel = this.buildRelation(Artikel, "artikel")

    constructor() {
        super(ArtikelkatalogArtikel, "artikelkatalogartikel")
    }
}