import {Repository} from "@lib/common/repository/Repository"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Id} from "@lib/common/model/Id"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {KooperationspartnerBuilder} from "@generated/de/lohn24/model/kooperationspartner/KooperationspartnerBuilder"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Ticketgruppe} from "@generated/de/lohn24/model/ticketgruppe/Ticketgruppe"
import {from} from "@lib/common/Functions"
import {KooperationspartnerDistinct} from "@lib/model/kooperationspartner/KooperationspartnerDistinct"
import {Briefabsender} from "@generated/de/lohn24/model/briefabsender/Briefabsender"
import {
    KooperationspartnerListElement,
} from "@generated/de/lohn24/model/kooperationspartner/KooperationspartnerList/KooperationspartnerListElement"
import {
    KooperationspartnerMandant,
} from "@generated/de/lohn24/model/kooperationspartner/KooperationspartnerMandanten/KooperationspartnerMandant"

export class KooperationspartnerRepository extends Repository<Kooperationspartner> {

    anschrift = this.buildRelation(Anschrift, "anschrift")
    debitor = this.buildRelation(Debitor, "debitor")
    kontakt = this.buildRelation(Kontakt, "kontakt")
    mandant = this.buildRelation(Mandant, "mandant")
    sammelrechnungMandant = this.buildRelation(Mandant, "sammelrechnung")
    briefabsender = this.buildRelation(Briefabsender, "briefabsender")

    builder = this.buildBuilder(KooperationspartnerBuilder)

    constructor() {
        super(Kooperationspartner, "kooperationspartner")
    }

    all<K>(params?: K): Promise<Kooperationspartner[]> {
        return super.all(params)
    }

    async liste<K>(params: K): Promise<PartitionedResultSet<KooperationspartnerListElement, KooperationspartnerDistinct>> {
        const result = await this.api.get<PartitionedResultSet<KooperationspartnerListElement, KooperationspartnerDistinct>>(this.route("/kooperationspartnerliste"), params)
        return new PartitionedResultSet(KooperationspartnerListElement, KooperationspartnerDistinct, result)
    }

    async update(kooperationspartner: Kooperationspartner): Promise<Kooperationspartner> {
        if (kooperationspartner.id === null) {
            throw new Error(`Object ID ist ${kooperationspartner.id}`)
        } else {
            return super.update(kooperationspartner)
        }
    }

    async delete(kooperationspartner: Kooperationspartner | Id<Kooperationspartner>): Promise<void> {
        const id = kooperationspartner instanceof Kooperationspartner ? kooperationspartner.id : kooperationspartner
        await this.api.delete(this.route(`${id.value}`))
    }

    async ticketgruppe(id: Id<Kooperationspartner>): Promise<Ticketgruppe> {
        const result = await this.api.get<Partial<Ticketgruppe>>(this.route(`${id}/ticketgruppe`))
        return from(Ticketgruppe, result)
    }

    async mandanten(id: Id<Kooperationspartner>): Promise<KooperationspartnerMandant[]> {
        const result = await this.api.get<Partial<KooperationspartnerMandant>[]>(this.route(`${id}/mandanten`))
        return result.map(it => from(KooperationspartnerMandant, it))
    }
}