import { IRuntimeTypeInformation, Serializable } from "@lib/index"

export interface IPostanschrift {
	zeile1: string
	zeile10: string
	zeile11: string
	zeile2: string
	zeile3: string
	zeile4: string
	zeile5: string
	zeile6: string
	zeile7: string
	zeile8: string
	zeile9: string
}

export class Postanschrift extends Serializable<Postanschrift> {
	public zeile1!: string
	public zeile10!: string
	public zeile11!: string
	public zeile2!: string
	public zeile3!: string
	public zeile4!: string
	public zeile5!: string
	public zeile6!: string
	public zeile7!: string
	public zeile8!: string
	public zeile9!: string

	static _types: Record<keyof IPostanschrift, IRuntimeTypeInformation> = {
		zeile1: { type: String },
		zeile10: { type: String },
		zeile11: { type: String },
		zeile2: { type: String },
		zeile3: { type: String },
		zeile4: { type: String },
		zeile5: { type: String },
		zeile6: { type: String },
		zeile7: { type: String },
		zeile8: { type: String },
		zeile9: { type: String },
	}

	static props: Record<keyof IPostanschrift, string> = {
		zeile1: "zeile1",
		zeile10: "zeile10",
		zeile11: "zeile11",
		zeile2: "zeile2",
		zeile3: "zeile3",
		zeile4: "zeile4",
		zeile5: "zeile5",
		zeile6: "zeile6",
		zeile7: "zeile7",
		zeile8: "zeile8",
		zeile9: "zeile9",
	}

	constructor(_json: IPostanschrift) {
		super(_json)
	}
}
