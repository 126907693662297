import {AxiosError as IAxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios"
import {ValidationResult} from "@lib/common/ValidationResult"

export class AxiosError<T = unknown, D = any> extends Error implements IAxiosError<T, D> {
    code?: string
    config?: InternalAxiosRequestConfig<any>
    isAxiosError!: boolean
    request!: any
    response?: AxiosResponse<T, D>
    cause?: Error

    toJSON!: () => object

    constructor(e: IAxiosError<T, D>) {
        super()
        this.code = e.code
        this.config = e.config
        this.isAxiosError = e.isAxiosError
        this.request = e.request
        this.response = e.response
        this.cause = e.cause
    }

    static error(e: IAxiosError): AxiosError {
        if (e.response === undefined) {
            return new NetworkError(e)
        }

        switch (e.response.status) {
            case 400:
                return new ValidationError(e)
            case 401:
                return new UnauthorizedError(e)
            case 403:
                return new Forbidden(e)
            case 404:
                return new EntityNotFoundException(e)
            case 405:
                return new MethodNotAllowed(e)
            default:
                return new AxiosError(e)
        }
    }

}

export class EntityNotFoundException extends AxiosError {
}

export class Forbidden extends AxiosError {
}

export class MethodNotAllowed extends AxiosError {
}

export class NetworkError extends AxiosError {
}

export class ValidationError extends AxiosError {
    declare response: AxiosResponse<ValidationResult[]> | undefined
}

export class BadRequest extends AxiosError {
}

export class UnauthorizedError extends AxiosError {
}

export class BadRequestError extends AxiosError {
}

export class ConfirmVerwerfenError extends Error {
}

