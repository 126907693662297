import { IRuntimeTypeInformation, Serializable } from "@lib/index"
import { ArtikelEinheit } from "@generated/de/lohn24/model/artikel/ArtikelEinheit"

export interface IFreitextArtikelPosition {
	mengeneinheit: ArtikelEinheit
	menge: number
	umsatzsteuersatz: number
	position: number
	netto: number
	notiz: string | null
	text: string
}

export class FreitextArtikelPosition extends Serializable<FreitextArtikelPosition> {
	public mengeneinheit!: ArtikelEinheit
	public menge!: number
	public umsatzsteuersatz!: number
	public position!: number
	public netto!: number
	public notiz!: string | null
	public text!: string

	static _types: Record<keyof IFreitextArtikelPosition, IRuntimeTypeInformation> = {
		mengeneinheit: { type: ArtikelEinheit },
		menge: { type: Number },
		umsatzsteuersatz: { type: Number },
		position: { type: Number },
		netto: { type: Number },
		notiz: { type: String, nullable: true },
		text: { type: String },
	}

	static props: Record<keyof IFreitextArtikelPosition, string> = {
		mengeneinheit: "mengeneinheit",
		menge: "menge",
		umsatzsteuersatz: "umsatzsteuersatz",
		position: "position",
		netto: "netto",
		notiz: "notiz",
		text: "text",
	}

	constructor(_json: IFreitextArtikelPosition) {
		super(_json)
	}
}
