import {DateTime} from "luxon"

export class LocalDate {
    /**
     *
     * @param _year
     * @param _month 1-12
     * @param _day 1-31
     * @private
     */
    private constructor(private _year: number, private _month: number, private _day: number) {
    }

    get year() {
        return this._year
    }

    get month() {
        return this._month
    }

    get day() {
        return this._day
    }

    get dateTime(): DateTime {
        return DateTime.local(this.year, this.month, this.day)
    }

    /**
     *
     * @param text -> yyyy-MM-dd
     */
    static from(text: string): LocalDate {
        const numbers = text.split("-").map(it => +it) as [number, number, number]
        if (numbers.length != 3) {
            throw new Error(`Invalid input: ${text}`)
        }
        return new LocalDate(numbers[0], numbers[1], numbers[2])
    }

    static fromDateTime(datetime: DateTime) {
        return new LocalDate(datetime.year, datetime.month, datetime.day)
    }

    static fromJSDate(date: Date) {
        return this.fromDateTime(DateTime.fromJSDate(date))
    }

    static deserialize(text: string) {
        return this.from(text)
    }

    static now(): LocalDate {
        return LocalDate.fromJSDate(new Date(Date.now()))
    }

    toJSDate(): Date {
        return this.dateTime.toJSDate()
    }

    /**
     * ms since 1.1.1970 00:00 UTC
     */
    valueOf(): number {
        return new Date(this._year, this._month - 1, this._day).valueOf()
    }

    toString() {
        return `${this.year}-${this.monthPadded()}-${this.dayPadded()}`
    }

    toJSON() {
        return this.toString()
    }

    minusMonth(value: number): LocalDate {
        const jsDate = this.toJSDate()
        jsDate.setMonth(jsDate.getMonth() - value)
        return LocalDate.fromJSDate(jsDate)
    }

    private monthPadded(): string {
        return `${this.month}`.padStart(2, "0")
    }

    private dayPadded(): string {
        return `${this.day}`.padStart(2, "0")
    }
}

