<script lang="ts" setup>

import {computed, isRef} from "vue"
import {MandantAbschlussdatenViewModel} from "@intranet/view/mandant/abschlussdaten/MandantAbschlussdatenViewModel"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import IconTrue from "@lib/view/icons/IconTrue.vue"
import IconFalse from "@lib/view/icons/IconFalse.vue"
import {
    BereitstellungOptionZahlungenArt,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungenArt"
import {
    BereitstellungOptionZahlungen,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungen"
import {BereitstellungOptionLohn} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionLohn"
import {
    BereitstellungOptionRechnung,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionRechnung"

const props = defineProps<{
    mandant: Mandant
    debitor?: Debitor | null
}>()

const viewModel = computed<MandantAbschlussdatenViewModel>(() => new MandantAbschlussdatenViewModel(props.mandant, props.debitor))
const zahlungArten = computed(() => {
    return [
        viewModel.value.bereitstellungZahlungenArt.includes(BereitstellungOptionZahlungenArt.DATEI) && MandantAbschlussdatenViewModel.label.zahlungenArt.datei,
        viewModel.value.bereitstellungZahlungenArt.includes(BereitstellungOptionZahlungenArt.LISTE) && MandantAbschlussdatenViewModel.label.zahlungenArt.liste,
        viewModel.value.bereitstellungZahlungenArt.includes(BereitstellungOptionZahlungenArt.EBICS) && MandantAbschlussdatenViewModel.label.zahlungenArt.ebics,
        viewModel.value.bereitstellungZahlungenArt.includes(BereitstellungOptionZahlungenArt.TRAEGER) && MandantAbschlussdatenViewModel.label.zahlungenArt.traeger,
    ].filter(it => it)
})
const zahlungBereitstellungen = computed(() => {
    return [
        viewModel.value.bereitstellungZahlungen.includes(BereitstellungOptionZahlungen.FAX) && MandantAbschlussdatenViewModel.label.bereitstellungenZahlungen.fax,
        viewModel.value.bereitstellungZahlungen.includes(BereitstellungOptionZahlungen.POST) && MandantAbschlussdatenViewModel.label.bereitstellungenZahlungen.post,
        viewModel.value.bereitstellungZahlungen.includes(BereitstellungOptionZahlungen.CLOUD) && MandantAbschlussdatenViewModel.label.bereitstellungenZahlungen.cloud,
        viewModel.value.bereitstellungZahlungen.includes(BereitstellungOptionZahlungen.PMC) && MandantAbschlussdatenViewModel.label.bereitstellungenZahlungen.pmc,
        viewModel.value.bereitstellungZahlungen.includes(BereitstellungOptionZahlungen.SYSTEM) && viewModel.value.abrechnungssystem,
    ].filter(it => it)
})

const lohnBereitstellungen = computed(() => {
    return [
        viewModel.value.bereitstellungLohn.includes(BereitstellungOptionLohn.FAX) && MandantAbschlussdatenViewModel.label.bereitstellungenLohn.fax,
        viewModel.value.bereitstellungLohn.includes(BereitstellungOptionLohn.POST) && MandantAbschlussdatenViewModel.label.bereitstellungenLohn.post,
        viewModel.value.bereitstellungLohn.includes(BereitstellungOptionLohn.CLOUD) && MandantAbschlussdatenViewModel.label.bereitstellungenLohn.cloud,
        viewModel.value.bereitstellungLohn.includes(BereitstellungOptionLohn.PMC) && MandantAbschlussdatenViewModel.label.bereitstellungenLohn.pmc,
        viewModel.value.bereitstellungLohn.includes(BereitstellungOptionLohn.SYSTEM) && viewModel.value.abrechnungssystem,
    ].filter(it => it)
})

const rechnungBereitstellungen = computed(() => {
    return [
        viewModel.value.bereitstellungRechnung.includes(BereitstellungOptionRechnung.FAX) && MandantAbschlussdatenViewModel.label.bereitstellungenRechnung.fax,
        viewModel.value.bereitstellungRechnung.includes(BereitstellungOptionRechnung.POST) && MandantAbschlussdatenViewModel.label.bereitstellungenRechnung.post,
        viewModel.value.bereitstellungRechnung.includes(BereitstellungOptionRechnung.CLOUD) && MandantAbschlussdatenViewModel.label.bereitstellungenRechnung.cloud,
        viewModel.value.bereitstellungRechnung.includes(BereitstellungOptionRechnung.MAIL) && MandantAbschlussdatenViewModel.label.bereitstellungenRechnung.email,
        viewModel.value.bereitstellungRechnung.includes(BereitstellungOptionRechnung.SYSTEM) && viewModel.value.abrechnungssystem,
    ].filter(it => it)
})

const data = computed(() => [
    {
        key: MandantAbschlussdatenViewModel.label.abrechnungszeitraum,
        value: viewModel.value.abrechnungszeitraum,
    },
    {
        key: MandantAbschlussdatenViewModel.label.sachbearbeiter,
        value: viewModel.value.sachbearbeiter,
    },
    {
        key: MandantAbschlussdatenViewModel.label.zuletztAbgerechnetVon,
        value: viewModel.value.zuletztAbgerechnetVon,
    },
    {
        key: MandantAbschlussdatenViewModel.label.lohnsteueranmeldeperiode,
        value: viewModel.value.lohnsteueranmeldeperiode,
    },
    {
        key: MandantAbschlussdatenViewModel.label.direktkontakt,
        value: viewModel.value.direktkontakt,
    },
    {
        key: MandantAbschlussdatenViewModel.label.bereitstellung.zahlungenArt,
        value: zahlungArten,
    },
    {
        key: MandantAbschlussdatenViewModel.label.bereitstellung.zahlungenOrt,
        value: zahlungBereitstellungen,
    },
    {
        key: MandantAbschlussdatenViewModel.label.bereitstellung.lohnOrt,
        value: lohnBereitstellungen,
    },
    {
        key: MandantAbschlussdatenViewModel.label.bereitstellung.rechnungOrt,
        value: rechnungBereitstellungen,
    },
    {
        key: MandantAbschlussdatenViewModel.label.gekuendigtZum,
        value: viewModel.value.kuendigungsdatum,
    },
].filter(item => {
    if (item.key === MandantAbschlussdatenViewModel.label.zuletztAbgerechnetVon) return true
    if (isRef(item.value)) return item.value.value.length > 0
    if (typeof item.value === "string") return item.value.length > 0
    if (item.key === MandantAbschlussdatenViewModel.label.gekuendigtZum) return !!item.value
    return item.value !== null
}))

function rowClassName({row, rowIndex}: { row: { key: string, value: any }, rowIndex: number }): string {
    const classNames: string[] = []
    if ((rowIndex + 1) % 2 === 0) {
        classNames.push("stripe-row")
    }
    if (
        row.key === MandantAbschlussdatenViewModel.label.gekuendigtZum
        && (viewModel.value.isLastMonth || viewModel.value.gekuendigtInDerVergangenheit)
    ) {
        classNames.push("gekuendigt")
        classNames.push("gekuendigt-letzter-monat")
    }
    return classNames.join(" ")
}

</script>

<template>
  <el-row class="abschlussdaten">
    <el-col>
      <el-row>
        <el-col>
          <h3>Abschlussdaten</h3>
        </el-col>
        <el-col></el-col>
      </el-row>
      <el-table
              :data="data"
              :row-class-name="rowClassName"
              size="small"
      >
        <el-table-column prop="key"></el-table-column>
        <el-table-column prop="value">
          <template #header><span style="color: var(--el-dialog-bg-color)">.</span></template>
          <template #default="{ row }">
            <el-row v-if="row.key === MandantAbschlussdatenViewModel.label.direktkontakt">
              <el-col>
                <icon-true v-if="viewModel.direktkontakt" />
                <icon-false v-else />
              </el-col>
            </el-row>
            <el-row v-else-if="row.key === MandantAbschlussdatenViewModel.label.gekuendigtZum">
              <el-col>{{ row.value }}</el-col>
            </el-row>
            <el-row v-else-if="Array.isArray(row.value)">
              <el-col v-for="value in row.value" :key="value" :span="12">
                {{ value }}
              </el-col>
            </el-row>
            <el-row v-else>
              <el-col>{{ row.value }}</el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<style lang="scss">
.abschlussdaten {
  th {
    display: none;
  }
}

tr {
  &.stripe-row {
    background-color: var(--el-table-row-hover-bg-color);
  }

  &.gekuendigt.gekuendigt-letzter-monat {
    background: repeating-linear-gradient(
                    -45deg,
                    var(--l24-color-brand-light-80),
                    var(--l24-color-brand-light-80) 25%,
                    #fff 25%,
                    #fff 50%
    );
    background-size: 40px 40px;
  }
}

</style>
