import {ModelController} from "@lib/common/controller/ModelController"
import {KooperationspartnerViewModel} from "@intranet/view/kooperationspartner/KooperationspartnerViewModel"
import {Resource} from "@lib/model/Resource"

export class KooperationspartnerModelController extends ModelController<KooperationspartnerViewModel> {

    get needsDebitor() {
        return this.model.value.needsDebitor
    }

    protected createModel(model: KooperationspartnerViewModel, args: any): Promise<KooperationspartnerViewModel> {
        throw new Error("needs builder")
    }

    protected async updateModel(model: KooperationspartnerViewModel, args: any): Promise<KooperationspartnerViewModel> {
        const kooperationspartner = await Resource.kooperationspartner.update(model.kooperationspartner)
        return new KooperationspartnerViewModel(kooperationspartner)
    }
}