import {ViewModel} from "@lib/common/model/ViewModel"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {_props} from "@lib/common/_props"
import {Id} from "@lib/common/model/Id"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {MandantStatus} from "@generated/de/lohn24/model/mandant/MandantStatus"

export class MandantSummaryViewModel extends ViewModel<MandantSummaryViewModel> {

    static properties = _props<InstanceType<typeof this>>()
    static label = {}

    mandant: Mandant

    constructor(mandant: Mandant) {
        super()
        this.mandant = this.registerShallowReactive(mandant)
    }

    get titel(): string {
        return `${this.mandant.kennung} // ${this.mandant.name}`
    }

    get id(): Id<Mandant> {
        return this.mandant.id
    }

    get organisation(): Id<Organisation> {
        return this.mandant.organisation
    }

    get kooperationspartner(): Id<Kooperationspartner> | null {
        return this.mandant.kooperationspartner
    }

    get kennung() {
        return this.mandant.kennung
    }

    get isInactive(): boolean {
        return this.mandant.status === MandantStatus.INAKTIV
    }

    get gesperrt(): boolean {
        return this.mandant.gesperrt
    }

    get isActive(): boolean {
        return this.mandant.status === MandantStatus.AKTIV || this.mandant.status === MandantStatus.NEU
    }

    get keinDirektkontakt(): boolean {
        return this.mandant.keinDirektkontakt
    }

    get bearbeitungshinweis() {
        return this.mandant.bearbeitungshinweis
    }

    get abschlusshinweis() {
        return this.mandant.abschlusshinweis
    }

    get versandhinweis() {
        return this.mandant.versandhinweis
    }

    get hatHinweise(): boolean {
        return this.bearbeitungshinweis.isNotEmpty()
            || this.abschlusshinweis.isNotEmpty()
            || this.versandhinweis.isNotEmpty()
    }
}