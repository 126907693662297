import {Repository} from "@lib/common/repository/Repository"
import {INews, News} from "@generated/de/lohn24/model/news/News"
import {Id} from "@lib/common/model/Id"
import {from} from "@lib/common/Functions"
import {IMitarbeiter, Mitarbeiter} from "@generated/de/lohn24/model/mitarbeiter/Mitarbeiter"
import {_props} from "@lib/common/_props"

export class NewsRepository extends Repository<News> {
    constructor() {
        super(News, "news")
    }

    async latest(amount: number): Promise<News[]> {
        return this.deserialize(
            await this.api.get<News[]>(
                `/news`,
                {
                    limit: amount,
                    orderBy: `${_props<News>().released}-desc`,
                },
            ),
        )
    }

    override async delete(news: News | Id<News>): Promise<void> {
        const id = news instanceof News ? news.id : news
        await this.api.delete<News>(this.route(`${id}`))
    }

    async nichtGelesen(newsId: Id<News>): Promise<Mitarbeiter[]> {
        const result = await this.api.get<IMitarbeiter[]>(this.route(`${newsId}/unread`))
        return result.map(it => from(Mitarbeiter, it))
    }

    async setzteGelesen(id: Id<News>): Promise<void> {
        await this.api.post<INews>(this.route(`${id}/read`), {})
    }

    async ungeleseneNews(): Promise<News[]> {
        const result = await this.api.get<INews>(this.route(`unread`))
        return this.deserialize(result)
    }
}