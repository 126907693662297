<script lang="ts" setup>

import Relation from "@lib/view/relation/Relation.vue"
import {Resource} from "@lib/model/Resource"
import {
    KooperationspartnerBriefabsenderController,
} from "@intranet/view/kooperationspartner/briefabsender/KooperationspartnerBriefabsenderController"
import {
    KooperationspartnerBriefabsenderViewModel,
} from "@intranet/view/kooperationspartner/briefabsender/KooperationspartnerBriefabsenderViewModel"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {Id} from "@lib/common/model/Id"
import BriefabsenderView from "@lib/view/briefabsender/BriefabsenderView.vue"
import {computed} from "vue"
import {Briefabsender} from "@generated/de/lohn24/model/briefabsender/Briefabsender"
import {from} from "@lib/common/Functions"

interface KooperationspartnerBriefabsenderProps {
    kooperationspartner: Id<Kooperationspartner>
    briefabsenderPath?: string
    controller: KooperationspartnerBriefabsenderController | null
}

const props = withDefaults(
    defineProps<KooperationspartnerBriefabsenderProps>(),
    {
        briefabsenderPath: "briefabsender",
    },
)
const emits = defineEmits<{
    "update:controller": [controller: KooperationspartnerBriefabsenderController],
    "validate": [path: string]
}>()

function buildController(model: Briefabsender | null): KooperationspartnerBriefabsenderController {

    return new KooperationspartnerBriefabsenderController(
        new KooperationspartnerBriefabsenderViewModel(
            model ?? from(Briefabsender, {}),
        ),
    )
}

function validate() {
    emits("validate", props.briefabsenderPath)
}

const model = computed<KooperationspartnerBriefabsenderViewModel>(() => props.controller!.model.value!)

</script>

<template>
  <relation
          :build-controller-instance="buildController"
          :controller="props.controller"
          :parent="props.kooperationspartner"
          :relation="Resource.kooperationspartner.briefabsender"
          @update:controller="emits('update:controller', $event)"
  >
    <template #default="{model}">
      <briefabsender-view
              :model="model"
              :path="props.briefabsenderPath"
              @validate="validate"
      />
    </template>
  </relation>
</template>

<style lang="scss">

</style>