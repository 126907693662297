import {Plugin, ref} from "vue"

export const relativeScrollPositionYInPageHeight = ref<number>(0)
export const currentScrollPositionY = ref<number>(0)
export const lastScrollPositionY = ref<number>(0)
export const direction = ref<"up" | "down" | null>(null)

export const ScrollDirectionPlugin: Plugin = {
    install(app, options?: { sensitivity: number }) {
        const onScroll = function onScroll() {
            currentScrollPositionY.value = window.scrollY
            relativeScrollPositionYInPageHeight.value = window.scrollY / window.innerHeight

            if (
                currentScrollPositionY.value < 0 ||
                Math.abs(lastScrollPositionY.value - currentScrollPositionY.value) <= (options?.sensitivity || 50)
            )
                return

            direction.value = currentScrollPositionY.value < lastScrollPositionY.value ? "up" : "down"
            lastScrollPositionY.value = currentScrollPositionY.value
        }

        window.addEventListener("scroll", onScroll)
    },
}

export function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    })
}

export function scrollIntoView(elementID: string, headerHeight = 0) {
    const elm = document.getElementById(elementID)
    if (elm) {
        const elmTop = elm.getBoundingClientRect().top
        let top = elmTop + currentScrollPositionY.value - 2 * headerHeight
        if (top < elmTop) {
            top += headerHeight
        }
        window.scrollTo({
            top,
            behavior: "smooth",
        })
    }
}
