<script lang="ts">
export default {}
</script>

<script generic="B extends ModelBuilder<B>, M extends Model<M>, STEP extends string" lang="ts" setup>
import {computed, onErrorCaptured, onMounted, ref, watch} from "vue"
import {ElForm, ElMessage} from "element-plus"
import IconClose from "@lib/view/icons/IconClose.vue"
import {ValidationError} from "@lib/common/axios/AxiosError"
import BadgedLabel from "@lib/view/BadgedLabel.vue"
import BuilderOptions from "@lib/view/formitem/BuilderOptions.vue"
import {ModelBuilder} from "@lib/common/model/ModelBuilder"
import {BuilderDialogController} from "@lib/common/controller/BuilderDialogController"
import Debounced from "@lib/view/Debounced.vue"
import {Model} from "@lib/common/model/Model"
import {showError} from "@intranet/view/error/ErrorMenuItem"
import CustomDialogV2 from "@lib/view/dialog/CustomDialogV2.vue"

export interface BuildDialogProps {
    controller: BuilderDialogController<B, M, STEP>,
    builderOptionLabel?: (m: B) => string,
    error?: Record<STEP, number>,
    submitText?: string
    saveText?: string
    submit: () => Promise<void> | void
    save?: () => void
    clickable?: boolean
    zwischenspeichern?: boolean
    warnung?: string
}

const props = withDefaults(
    defineProps<BuildDialogProps>(),
    {
        error: () => ({}) as Record<string, number>,
        submitText: () => "Absenden",
        saveText: () => "Zwischenspeichern",

    },
)

const emits = defineEmits<{
    submit: []
    step: [context: { step: number, from: number }]
    "step:before": [target: { target: string, from: string, to: string, done: (error?: any) => void }]
    "update:form": [form: InstanceType<typeof ElForm> | undefined]
}>()

props.controller.useController()
const firstStep = 1
const active = ref<number>(firstStep)
const builder = computed(() => props.controller.builderController)
const steps = computed(() => props.controller.steps.value)
const lastStep = computed(() => steps.value.length)
const form = ref<InstanceType<typeof ElForm>>()

const errorSum = computed<number>(() => steps.value.sumBy(it => props.error[it] || 0))

async function nextStep(): Promise<void> {
    await new Promise<void>((resolve) => {
        const from = active.value
        emits("step:before", {
            target: "forward", from: steps.value[from - 1], to: steps.value[(from)], done: (error?: any) => {
                if (error) {
                    return resolve(error)
                }
                toStep(++active.value, from)
                return resolve()
            },
        })
    })
}

async function gotoStep(step: STEP): Promise<void> {
    await new Promise<void>((resolve) => {
        const currentStep = steps.value[active.value - 1]
        emits("step:before", {
            target: "forward", from: currentStep, to: step, done: (error?: any) => {
                if (error)
                    return resolve(error)//reject(error)
                toStep(steps.value.indexOf(step) + 1, steps.value.indexOf(currentStep) + 1)
                return resolve()
            },
        })
    })
}

async function previousStep(): Promise<void> {
    return new Promise<void>((resolve) => {
        const from = active.value
        emits("step:before", {
            target: "back", from: steps.value[from - 1], to: steps.value[(from - 2)], done: (error?: any) => {
                if (error)
                    return resolve(error)//reject(error)
                toStep(--active.value, from)
                return resolve()
            },
        })
    })
}

async function toStep(step: number, from: number) {
    active.value = step
    emits("step", {step, from})
}

watch(active, async () => {
    try {
        await form.value?.validate()
    } catch (e) {
        // ignore
    }
})

watch(form, async (value) => {
    if (value) {
        try {
            await value.validate()
        } catch (e) {
            // ignore
        }
    }
    emits("update:form", form.value)
})

async function submitValidationCatched() {
    try {
        await props.submit()
    } catch (e) {
        if (e instanceof ValidationError) {
            active.value = firstStep
            ElMessage.error({
                message: `${e.response?.data.map(it => it.message).join() || ""}`,
            })
        }
        throw e
    }
}

onErrorCaptured((error) => {
    showError(error)
})

</script>

<template>
  <custom-dialog-v2
          v-model="props.controller.visible.value"
          :close="() => controller.close()"
          :title="props.controller.title.value"
          intent="build-dialog"
  >
    <!--      <el-dialog-->
    <!--              -->
    <!--              :before-close="controller.onBeforeClose"-->
    <!--              :close-on-click-modal="false"-->
    <!--              :close-on-press-escape="false"-->
    <!--              :show-close="false"-->
    <!--              destroy-on-close-->
    <!--              top="7vh"-->
    <!--              -->
    <!--              width="80%"-->
    <!--      >-->
    <template #header>
      <el-col :span="24">
        <el-row justify="space-between">
          <div class="el-col">
            <slot name="header-left">
              <el-form-item size="small">
                <template #label>

                    <span v-if="steps.length > 1" class="el-dialog__title">
                        {{ controller.title.value }}
                    </span>
                  <badged-label
                          v-else
                          :label="controller.title.value"
                          :value="errorSum"
                          class="el-dialog__title"
                  />
                </template>
                <builder-options
                        v-if="builder && zwischenspeichern"
                        :class="{'shift-right': steps.length <= 1}"
                        :model-value="builder.builder?.id"
                        :option-label="builderOptionLabel"
                        :repository="builder.builderRepository"
                        width="350px"
                        @change="builder.builder = $event"

                />
              </el-form-item>
            </slot>
          </div>
          <div class="el-col">

          </div>
          <div class="el-col">
            <el-button-group>
              <slot :step="active" name="header-right"></slot>
              <slot name="header-close">
                <el-button
                        class="dialog-headline-btn"
                        text
                        @click="() => controller.close()"
                >
                  <icon-close size="medium" />
                </el-button>
              </slot>
            </el-button-group>
          </div>
        </el-row>
        <el-row>
          <el-col>

          </el-col>
        </el-row>
        <el-row v-if="warnung">
          <slot name="warnung">
            <el-col :span="24" class="warnung">
              <el-text type="warning">{{ warnung }}</el-text>
            </el-col>
          </slot>
        </el-row>
      </el-col>
    </template>
    <template #default>
      <el-row v-if="steps.length > 1">
        <el-col>
          <el-steps :key="steps.join('_')" :active="active - 1" simple>
            <el-step v-for="step of steps"
                     :key="step"
                     :title="step"
            >
              <template #title>
                <badged-label
                        :class="{'clickable-step': clickable}"
                        :label="step"
                        :value="error ? error[step]: undefined"
                        @click="clickable && gotoStep(step)"
                />
              </template>
            </el-step>
          </el-steps>
        </el-col>
      </el-row>
      <hr v-if="steps.length > 1">
      <el-form
              ref="form"
              :model="controller.validator.value.ruleModel"
              :rules="controller.validator.value.rules"
              :validate-on-rule-change="false"
              label-position="left"
              label-width="150px"
              size="small"
      >
        <el-row>
          <el-col>
            <slot :name="steps[active -1]">
              <el-row :gutter="40">
                <el-col :span="12" class="left-column">
                  <slot :name="`${steps[active -1]}-left`"></slot>
                </el-col>
                <el-col :span="12" class="right-column">
                  <slot :name="`${steps[active -1]}-right`"></slot>
                </el-col>
              </el-row>
            </slot>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <template #footer>
      <el-row>
        <el-col>

          <el-button v-if="active !== firstStep" name="previous" @click="previousStep()">
            zurück
          </el-button>
          <el-button
                  v-if="active<lastStep"
                  data-cy="next"
                  name="next"
                  type="primary"
                  @click="nextStep()"
          >
            weiter
          </el-button>
          <el-button v-if="(save || builder) && errorSum > 0 && zwischenspeichern" name="save"
                     @click="(save ?? controller.save)()"
          >
            {{ saveText }}
          </el-button>
          <debounced :wait="600">
            <template #default="{debounce}">
              <el-button v-if="active===lastStep" :disabled="errorSum > 0" data-cy="submit"
                         name="submit"
                         type="primary"
                         @click="debounce(submitValidationCatched)"
              >
                {{ submitText }}
              </el-button>
            </template>
          </debounced>
        </el-col>
      </el-row>
    </template>
  </custom-dialog-v2>
</template>

<style lang="scss">
.create-dialog {
  .shift-right {
    margin-left: 20px;
  }

  .el-dialog__title {
    user-select: none;
    cursor: default;

    sup.el-badge__content.is-fixed {
      right: -4px
    }


  }

  .el-step__title {
    user-select: none;
    cursor: default;

    sup.el-badge__content.is-fixed {
      right: -4px
    }

    .clickable-step {
      cursor: pointer;
    }
  }
}
</style>