import { IModel, IRuntimeTypeInformation, Id, LocalDate, Model } from "@lib/index"
import { File } from "@generated/dev/api/model/file/File"

export interface IVersand extends IModel {
	file: string | null
	versandt: LocalDate | null
}

export class Versand extends Model<Versand> {
	public file!: Id<File> | null
	public versandt!: LocalDate | null

	static _types: Record<keyof IVersand, IRuntimeTypeInformation> = {
		id: { type: Id },
		file: { type: Id, nullable: true },
		versandt: { type: LocalDate, nullable: true },
	}

	static props: Record<keyof IVersand, string> = {
		id: "id",
		file: "file",
		versandt: "versandt",
	}

	constructor(_json: IVersand) {
		super(_json)
	}
}
