import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { AccountStatus } from "@generated/dev/api/model/account/AccountStatus"

export interface IAccount extends IModel {
	status: AccountStatus
	email: string | null
	ldapId: string | null
	nachname: string | null
	password: string | null
	username: string
	vorname: string | null
}

export class Account extends Model<Account> {
	public status!: AccountStatus
	public email!: string | null
	public ldapId!: string | null
	public nachname!: string | null
	public password!: string | null
	public username!: string
	public vorname!: string | null

	static _types: Record<keyof IAccount, IRuntimeTypeInformation> = {
		id: { type: Id },
		status: { type: AccountStatus },
		email: { type: String, nullable: true },
		ldapId: { type: String, nullable: true },
		nachname: { type: String, nullable: true },
		password: { type: String, nullable: true },
		username: { type: String },
		vorname: { type: String, nullable: true },
	}

	static props: Record<keyof IAccount, string> = {
		id: "id",
		status: "status",
		email: "email",
		ldapId: "ldapId",
		nachname: "nachname",
		password: "password",
		username: "username",
		vorname: "vorname",
	}

	constructor(_json: IAccount) {
		super(_json)
	}
}
