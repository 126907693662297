<script lang="ts" setup>
import {computed} from "vue"
import {ResourceCache} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"
import {Firma} from "@generated/de/lohn24/model/firma/Firma"
import {FirmaKurzname} from "@lib/model/firma/FirmaKurzname"
import IconRag from "@lib/view/icons/IconRAG/IconRag.vue"
import IconL24 from "@lib/view/icons/IconL24/IconL24.vue"
import IconRZL from "@lib/view/icons/IconRZL/IconRZL.vue"

const props = defineProps<{
    rechnungssteller: Nullable<Id<Firma>>
}>()

const idKurzname = computed(() => {
    if (props.rechnungssteller)
        return ResourceCache.firma.mapped(props.rechnungssteller, it => it?.kurzname).value
    return null
})


</script>

<template>
  <icon-rag v-if="idKurzname === FirmaKurzname.LOHN24RAG"
            data-cy="icon-rag"
            v-bind="$attrs"
  />
  <icon-l24 v-else-if="idKurzname === FirmaKurzname.L24"
            data-cy="icon-l24"
            v-bind="$attrs"
  />
  <icon-r-z-l v-else-if="idKurzname === FirmaKurzname.RZL"
              data-cy="icon-rzl"
              v-bind="$attrs"
  />
</template>