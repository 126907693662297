import {App} from "vue"
import {Plugin} from "@lib/common/vue/plugin/Plugin"

export type KeyboardEventlistenerConfig = {
    ctrl?: (queue: string[]) => boolean,
    event: (currentEvent: KeyboardEvent, lastEvent: KeyboardEvent | null) => boolean
    action: () => Promise<void>
}

export class KeyboardEventlistener implements Plugin<KeyboardEventlistenerConfig[]> {

    private configs: KeyboardEventlistenerConfig[] = []
    private static listeners: Set<KeyboardEventlistener> = new Set()

    private static get configs(): KeyboardEventlistenerConfig[] {
        return Array.from(this.listeners.values()).flatMap(it => it.configs)
    }

    private static initialized: boolean = false

    constructor() {
        KeyboardEventlistener.listeners.add(this)
        KeyboardEventlistener.initialize()
    }

    private static lastEvent: KeyboardEvent | null = null
    private static eventQueue: KeyboardEvent[] = []
    private static ctrlKeyedQueue: string[] = []

    private static keyUpListener = async (event: KeyboardEvent) => {
        if (event.repeat)
            return

        switch (event.key) {
            case "Control":
                this.ctrlKeyedQueue.length = 0
        }

    }

    private static keyDownListener = async (event: KeyboardEvent) => {
        if (event.repeat)
            return

        if (this.lastEvent != null && ((event.timeStamp - this.lastEvent?.timeStamp) > 1000 * 2)) {
            this.lastEvent = null
            this.eventQueue.length = 0
            this.ctrlKeyedQueue.length = 0
        }

        if (!event.ctrlKey)
            this.ctrlKeyedQueue.length = 0

        if (event.ctrlKey && event.key.length === 1)
            this.ctrlKeyedQueue.push(event.key)

        const configs: KeyboardEventlistenerConfig[] = []
        configs.push(...KeyboardEventlistener
            .configs
            .filter(it => it.event(event, this.lastEvent)),
        )

        configs.push(...KeyboardEventlistener
            .configs
            .filter(it => it.ctrl && it.ctrl(KeyboardEventlistener.ctrlKeyedQueue.slice())),
        )

        this.lastEvent = event
        this.eventQueue.push(event)

        if (configs.isNotEmpty()) {
            event.preventDefault()
            for (const config of configs)
                await config.action()
        }
    }

    static initialize() {
        if (!this.initialized) {
            window.addEventListener("keydown", KeyboardEventlistener.keyDownListener)
            window.addEventListener("keyup", KeyboardEventlistener.keyUpListener)
            this.initialized = true
        }
    }

    install(app: App, ...options: KeyboardEventlistenerConfig[]) {
        this.add(...options)
    }

    add(...options: KeyboardEventlistenerConfig[]) {
        this.configs.push(...options)
    }

    clear() {
        this.configs.length = 0
    }

    remove() {
        KeyboardEventlistener.listeners.delete(this)
    }
}