<script lang="ts" setup>

import {Mitarbeiter} from "@generated/de/lohn24/model/mitarbeiter/Mitarbeiter"
import {RepositoryOptionsProps} from "@lib/view/formitem/RepositoryOptionsProps"
import RepositoryOptions from "@lib/view/formitem/RepositoryOptions.vue"
import {Resource, ResourceCache} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"
import {computed} from "vue"
import {Account} from "@generated/dev/api/model/account/Account"
import {Abteilung} from "@lib/model/account/Abteilung"

const emits = defineEmits<{
    "change": [mitarbeiter: Mitarbeiter | null]
    "update:modelValue": [accountId: Id<Account> | null]
}>()

const props = defineProps<
    {
        modelValue: Id<Account> | null
        optionFilter?: (m: Mitarbeiter) => boolean
    } & Omit<RepositoryOptionsProps<Mitarbeiter>, "repository" | "modelValue">
>()

function filter(mitarbeiter: Mitarbeiter) {
    return (props.optionFilter ? props.optionFilter(mitarbeiter) : true) && mitarbeiter.abteilung !== Abteilung.EXTERN
}

const mitarbeiterId = computed<Id<Mitarbeiter> | null>({
    get() {
        const map = ResourceCache
            .mitarbeiter
            .map(it => it)
            .value
            .groupBy(it => it.account.value)
        if (props.modelValue !== null)
            return map[props.modelValue.value]
                    ?.first()
                    .id
                ?? null
        else
            return null
    },
    set(id) {
        if (id !== null) {
            const accountId = ResourceCache
                .mitarbeiter
                .mapped(id, it => it?.account ?? null)
                .value
            emits("update:modelValue", accountId)
        } else {
            emits("update:modelValue", null)
        }
    },
})

</script>

<template>
  <repository-options
          v-bind="props"
          @change="emits('change', $event)"
          v-model="mitarbeiterId"
          :option-filter="filter"
          :option-label="m => `${m.vorname} ${m.nachname}`"
          :repository="Resource.mitarbeiter"
  />
</template>