import {Dictionary, groupBy, sortBy} from "lodash"

declare global {
    function emptyList<T>(): Array<T>

    interface Array<T> {
        groupBy<K>(cb: (it: T) => K): Dictionary<T[]>

        sortBy<K>(cb: (it: T) => K): Array<T>

        sortByDesc<K>(cb: (it: T) => K): Array<T>

        first(): T

        firstOfOrDefault<K extends T[keyof T]>(cb: (it: T) => K, defaultValue?: K)

        firstOrNull(): Nullable<T>

        last(): T

        /**
         * changes this context of cb
         * @param cb
         */
        any(cb: (it: T, index: number) => boolean): boolean

        includesAny(...args: T[]): boolean

        sumBy(cb: (it: T, index: number) => number): number

        countBy(cb: (it: T, index: number) => boolean)

        max(cb: (it: T, index: number) => number)

        min(cb: (it: T, index: number) => number)

        isEmpty(): boolean

        isNotEmpty(): boolean

        mapNotNull<R>(cb: (it: T, index: number) => R | null): R[]

        associateBy<B extends string | number | symbol>(cb: (it: T) => B): Record<B, T>

        associateWith<B, K extends T & string>(cb: (it: K) => B): Record<K, B>

        toSet(): Set<T>

        single(): T

        toSetOf<B>(cb: (it: T) => B): Set<B>

        toListOfUnique<B extends string | number | symbol>(cb: (it: T) => B): B[]

        /**
         * This method mutates the array
         * @param predicate
         */
        remove(predicate: (item: T) => boolean): number

        partition(predicate: (item: T) => boolean): [T[], T[]]

        // TODO()
        without(...items: T[]): T[]
    }

}

// must cast as any to set property on window
const _global = (window /* browser */ || global /* node */) as any

_global.emptyList = function () {
    return []
}

Array.prototype.groupBy = function (cb) {
    return groupBy(this, cb)
}

Array.prototype.sortBy = function (cb) {
    return sortBy(this, cb)
}

Array.prototype.sortByDesc = function (cb) {
    return sortBy(cb).reverse()
}

Array.prototype.first = function () {
    if (this.length === 0)
        throw new Error(`item does not exist`)
    return this[0]
}

Array.prototype.firstOfOrDefault = function (cb, defaultValue) {
    return this.length > 0 ? cb(this.first()) : defaultValue
}

Array.prototype.firstOrNull = function () {
    return this[0] ?? null
}

Array.prototype.last = function () {
    return this[this.length - 1]
}

Array.prototype.any = function (cb) {
    let index: number = 0
    for (const item of this) {
        if (cb(item, index)) return true
        index++
    }
    return false
}

Array.prototype.includesAny = function (...args): boolean {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    return args.any(it => self.includes(it))
}

Array.prototype.sumBy = function (cb) {
    let sum: number = 0
    let index: number = 0
    for (const item of this) {
        sum += cb(item, index)
        index++
    }
    return sum
}

Array.prototype.countBy = function (cb) {
    let sum = 0
    let index = 0
    for (const item of this) {
        sum += cb(item, index) ? 1 : 0
        index++
    }
    return sum
}

Array.prototype.isEmpty = function () {
    return this.length === 0
}

Array.prototype.isNotEmpty = function () {
    return !this.isEmpty()
}

Array.prototype.max = function (cb) {
    return Math.max(...this.map(cb))
}

Array.prototype.min = function (cb) {
    return Math.min(...this.map(cb))
}

Array.prototype.mapNotNull = function <R>(cb) {
    return this
        .map((it, index) => cb(it, index))
        .filter(it => it !== null) as R[]
}

Array.prototype.associateBy = function (cb) {
    return this.reduce((acc, item) => {
        acc[cb(item)] = item
        return acc
    }, {})
}

Array.prototype.associateWith = function (cb) {
    return this.reduce((acc, item) => {
        acc[item] = cb(item)
        return acc
    }, {})
}

Array.prototype.toSet = function () {
    return new Set(this)
}

Array.prototype.toSetOf = function (cb) {
    return this.map(cb).toSet()
}
Array.prototype.toListOfUnique = function (cb) {
    return Array.from(this.toSetOf(cb).values())
}

Array.prototype.remove = function (cb) {
    const index = this.findIndex(cb)
    if (index >= 0)
        this.splice(index, 1)
    return index
}

Array.prototype.single = function () {
    if (this.length !== 1)
        throw new Error("single nicht genau ein Element")
    return this[0]
}

Array.prototype.partition = function (cb) {
    const trueList = [] as any[]
    const falseList = [] as any[]

    for (const item of this) {
        if (cb(item))
            trueList.push(item)
        else
            falseList.push(item)
    }

    return [trueList, falseList]
}