import {ModelController} from "@lib/common/controller/ModelController"
import {DebitorViewModel} from "@intranet/view/debitor/DebitorViewModel"
import {OptionalAnschriftController} from "@intranet/view/anschrift/OptionalAnschriftController"
import {computed, shallowRef} from "vue"
import {AnschriftController} from "@intranet/view/anschrift/AnschriftController"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {AnschriftViewModel} from "@intranet/view/anschrift/AnschriftViewModel"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Id} from "@lib/common/model/Id"
import {Resource} from "@lib/model/Resource"
import {from} from "@lib/common/Functions"
import {Model} from "@lib/common/model/Model"
import {RepositoryRelation} from "@lib/common/repository/RepositoryRelation"

export class DebitorController<L extends Model<L>> extends ModelController<DebitorViewModel> {
    postanschrift = shallowRef<OptionalAnschriftController<Debitor> | null>(null)

    dataSaveFailedMessage = "Debitor"
    dataSavedSuccesfullyMessage = "Debitor"
    rechnungsanschrift = shallowRef<AnschriftController<Debitor> | null>(null)
    hasPostanschrift = computed<any>(() => {
        return this.postanschrift.value?.artProxy.value !== "" || this.postanschrift.value?.model.value != null
    })

    constructor(model: DebitorViewModel, protected relation?: RepositoryRelation<L, Debitor>, rechnungsanschrift?: Anschrift, postanschrift?: Anschrift) {
        super(model)
        if (postanschrift)
            this.postanschrift = shallowRef(
                new OptionalAnschriftController(
                    new AnschriftViewModel(postanschrift),
                    this.postanschriftRelation,
                ),
            )
        if (rechnungsanschrift)
            this.rechnungsanschrift = shallowRef(
                new AnschriftController(
                    new AnschriftViewModel(rechnungsanschrift),
                    this.rechnungsanschriftRelation,
                ),
            )
    }

    override get modified(): boolean {
        return super.modified
            || (this.rechnungsanschrift.value?.modified ?? false)
            || (this.postanschrift.value?.modified ?? false)
    }

    get rechnungsanschriftRelation() {
        return Resource.debitor.rechnungsanschrift
    }

    get debitorId() {
        return this.model.value.debitor.id
    }

    get postanschriftRelation() {
        return Resource.debitor.postanschrift
    }

    static build<L extends Model<L>>(debitor: Debitor | null, relation: RepositoryRelation<L, Debitor>) {
        return new DebitorController(new DebitorViewModel(debitor ?? from(Debitor, {})), relation)
    }

    async initialize(): Promise<void> {
    }

    async initializeAnschriften(
        defaults?: { rechnungsanschrift?: AnschriftViewModel, postanschrift?: AnschriftViewModel },
    ) {
        if (this.model.value.bearbeitungszustand.created
            || this.model.value.rechnungsanschrift === null
            || defaults?.rechnungsanschrift
        ) {
            this.rechnungsanschrift.value = new AnschriftController(
                (defaults?.rechnungsanschrift || new AnschriftViewModel(from(Anschrift, {}))),
                this.rechnungsanschriftRelation,
            )
        } else {
            this.rechnungsanschrift.value = new AnschriftController(
                new AnschriftViewModel(
                    (
                        await this.rechnungsanschriftRelation.all(this.debitorId)
                    ).first(),
                ),
                this.rechnungsanschriftRelation,
            )
        }

        if (this.model.value.bearbeitungszustand.created
            || this.model.value.postanschrift === null
            || defaults?.postanschrift
        ) {
            this.postanschrift.value = new OptionalAnschriftController(
                defaults?.postanschrift || new AnschriftViewModel(from(Anschrift, {})),
                this.postanschriftRelation,
            )
        } else {
            this.postanschrift.value = new OptionalAnschriftController(
                new AnschriftViewModel(
                    (
                        await this.postanschriftRelation.all(this.debitorId)
                    ).first(),
                ),
                this.postanschriftRelation,
            )
        }
    }

    override async update(id?: Id<any>, defaultValues?: { rechnungsanschrift?: Anschrift }): Promise<DebitorViewModel> {
        return super.update(id, defaultValues)
    }

    protected async createModel(): Promise<DebitorViewModel> {
        throw Error("use builder")
    }

    protected async updateModel(model: DebitorViewModel, id: Id<L> | null = null): Promise<DebitorViewModel> {
        if (this.relation) {
            const debitor = await this.relation.update(Id.required(id), model.debitor)
            return new DebitorViewModel(debitor)
        } else {
            const debitor = await Resource.debitor.update(model.debitor)
            return new DebitorViewModel(debitor)
        }
    }
}