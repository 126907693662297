import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"

export interface ILeistungsmerkmal extends IModel {
	lohnabrechnung: boolean
	merkmal: string
}

export class Leistungsmerkmal extends Model<Leistungsmerkmal> {
	public lohnabrechnung!: boolean
	public merkmal!: string

	static _types: Record<keyof ILeistungsmerkmal, IRuntimeTypeInformation> = {
		id: { type: Id },
		lohnabrechnung: { type: Boolean },
		merkmal: { type: String },
	}

	static props: Record<keyof ILeistungsmerkmal, string> = {
		id: "id",
		lohnabrechnung: "lohnabrechnung",
		merkmal: "merkmal",
	}

	constructor(_json: ILeistungsmerkmal) {
		super(_json)
	}
}
