<template>
  <el-tabs :key="currentPathName" v-model="activeTab" class="tab-view" type="border-card">
    <el-tab-pane v-for="(tab, index) of tabs"
                 :key="tab.label + index"
                 :label="tab.label"
                 :name="tab.dataKey"
                 lazy
    >
      <template #label>
        <span class="el-card__header">{{ tab.label }}</span>
      </template>
      <router-view v-if="tabIsRoute(tab)" :key="currentPathName" v-slot="{Component}">
        <template v-if="Component">
          <Suspense>
            <component :is="Component"/>
            <template #fallback>
              Loading...
            </template>
          </Suspense>
        </template>
      </router-view>
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts">
import {Component, computed, defineComponent, PropType, ref} from "vue"
import {useRouter} from "vue-router"

type Tab = {
  dataKey: string,
  label: string,
  route?: string | { name: string },
  is?: string | Component,
  attrs?: Record<string, any>
}

export default defineComponent({
  name: "TabView",
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter()
    const currentPathName = computed(() => router.currentRoute.value.name)
    const localActiveTab = ref()
    const activeTab = computed({
      get: () => {
        if (!localActiveTab.value)
          for (const tab of props.tabs || []) {
            if (tabIsRoute(tab))
              return tab.dataKey
          }
        return localActiveTab.value
      },
      set: value => {
        if (props.tabs) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const route = props.tabs.find(it => it.dataKey === value)!.route!
          router.push(route)
        } else
          localActiveTab.value = value
      },
    })

    function tabIsRoute(tab): boolean {
      const routeName = router.currentRoute.value.name
      return tab.route && (tab.route === routeName || tab.route?.["name"] === routeName)
    }

    return {
      activeTab,
      currentPathName,
      tabIsRoute,
    }
  },
})
</script>

<style lang="scss">
.tab-view {
  --el-tabs-header-height: 36px;

  .el-tabs__item {
    &.is-active .el-card__header {
      color: var(--l24-color-brand)
    }
  }
}
</style>