import {computed} from "vue"
import {MenuItem} from "@lib/common/menu/MenuItem"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/rights/RightIdentifier"
import {newsUngelesenListController} from "@intranet/view/news/ungelesen/NewsUngelesenListController"

export const NewsUngelesenMenuItem = new MenuItem({
    label: computed(() => `Ungelesene Artikel`),
    name: "ungeleseneNews",
    badge: computed(() => newsUngelesenListController.data.length),
    rights: [RightAccess.readable(RightIdentifier.NEWS)],
    view: () => import("@intranet/view/news/ungelesen/NewsUngelesen.vue"),
})