import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {DebitorEditDialogController} from "@intranet/view/debitor/edit/DebitorEditDialogController"
import {Resource} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"
import DebitorEditDialogView from "@intranet/view/debitor/edit/DebitorEditDialogView.vue"

export class DebitorEditDialog extends ComponentRenderer<typeof DebitorEditDialogView> {
    constructor(controller: DebitorEditDialogController, done: (debitor: Debitor | null) => void) {
        super(
            DebitorEditDialogView,
            {
                controller: controller,
                done: done,
            },
        )
    }

    static async show(id: Id<Debitor>): Promise<Debitor | null> {
        const debitor = await Resource.debitor.find(id)
        return new Promise(resolve => {
            new DebitorEditDialog(
                new DebitorEditDialogController(debitor),
                (it) => resolve(it))
        })
    }
}