import {Serializable} from "@lib/common/serializable/Serializable"
import {BaseIdType, Id} from "@lib/common/model/Id"
import {IRuntimeTypeInformation} from "@lib/common/model/IRuntimeTypeInformation"

export interface IModelIdMapping {
    id: BaseIdType
    name: string
    kennung: string
}

export class ModelIdMapping<M> extends Serializable<M> {
    static _types: Record<string, IRuntimeTypeInformation> = {
        id: {type: Id},
        name: {type: String},
        kennung: {type: String},
    }

    id!: Id<M>
    name!: string
    kennung!: string
}