import {ContextMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import {Config} from "@lib/Config"
import IconZammadTicket from "@lib/view/icons/IconZammadTicket.vue"

export function zammadTicketByMandantennummer(context: () => { kennung: string }) {
    return new ContextMenuItemOption(
        "Offene Tickets im Zammad suchen",
        async () => {
            window.open(
                `${Config.ZAMMAD_BASE_URL}/#search/mandantennummer%3A%20${context().kennung}%20AND%20state.name%3A(NOT("closed")%20AND%20NOT("merged"))`,
                Config.ZAMMAD_WINDOW_TARGET,
            )
        },
        IconZammadTicket,
    )
}

