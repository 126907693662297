<template>
  <el-icon v-bind="$attrs">
    <logo />
  </el-icon>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import Logo from "./LogoSVG.vue"

export default defineComponent({
    components: {
        Logo,
    },
})
</script>
