import {fas} from "@fortawesome/free-solid-svg-icons"
import {far} from "@fortawesome/free-regular-svg-icons"
import {defineComponent, h, Plugin} from "vue"

export const FontAwesomePlugin = {
    install(app) {
        [far, fas].forEach((pack) => {
            Object.keys(pack).forEach((key) => {
                const iconDef = pack[key]
                const [width, height, aliase, , iconPathData] = iconDef.icon
                if (!app.component(`${iconDef.prefix}-${iconDef.iconName}`)) {
                    const component = defineComponent({
                        render() {
                            return h(
                                "svg",
                                {
                                    class: "icon",
                                    width,
                                    height,
                                    viewBox: `0 0 ${width} ${height}`,
                                    xmlns: "http://www.w3.org/2000/svg",
                                },
                                [h("path", {fill: "currentColor", d: iconPathData})],
                            )
                        },
                    })
                    app.component(`${iconDef.prefix}-${iconDef.iconName}`, component)
                    aliase.forEach((alias) => {
                        app.component(`${iconDef.prefix}-${alias}`, component)
                    })
                }
            })
        })
    },
} as Plugin
