import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_delete = _resolveComponent("icon-delete")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_validateable_input = _resolveComponent("validateable-input")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_ctx.tableData.length > 0)
    ? (_openBlock(), _createBlock(_component_el_table, {
        key: 0,
        "cell-class-name": _ctx.cellClassName,
        data: _ctx.tableData,
        "row-class-name": _ctx.rowClassName,
        "data-cy": "freitextartikel-table"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, { width: 40 }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_el_button, {
                size: "large",
                text: "",
                onClick: ($event: any) => (_ctx.controller.removeFreitextArtikel(row))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_icon_delete)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            width: 300,
            label: "Freitextartikel"
          }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_validateable_input, {
                ref: "input",
                modelValue: row.text,
                "onUpdate:modelValue": ($event: any) => ((row.text) = $event),
                error: _ctx.error(row, _ctx.FreitextBelegPositionViewModel.properties.text),
                immediate: "",
                size: "small",
                onValidate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validate()))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            property: _ctx.FreitextBelegPositionViewModel.properties.notiz,
            width: 260,
            label: "Notiz"
          }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_validateable_input, {
                modelValue: row.notiz,
                "onUpdate:modelValue": ($event: any) => ((row.notiz) = $event),
                error: _ctx.error(row, _ctx.FreitextBelegPositionViewModel.properties.notiz),
                name: _ctx.FreitextBelegPositionViewModel.properties.notiz,
                size: "small",
                onValidate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validate()))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error", "name"])
            ]),
            _: 1
          }, 8, ["property"]),
          _createVNode(_component_el_table_column),
          _createVNode(_component_el_table_column, {
            width: 150,
            align: "right",
            fixed: "right",
            label: "Rechnung"
          }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_validateable_input, {
                modelValue: row.menge,
                "onUpdate:modelValue": ($event: any) => ((row.menge) = $event),
                error: _ctx.error(row, _ctx.FreitextBelegPositionViewModel.properties.menge),
                min: 0,
                step: 1,
                size: "small",
                type: "input-number",
                onValidate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.validate()))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            width: 150,
            align: "right",
            fixed: "right",
            label: "Netto"
          }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_validateable_input, {
                modelValue: row.netto,
                "onUpdate:modelValue": ($event: any) => ((row.netto) = $event),
                error: _ctx.error(row, _ctx.FreitextBelegPositionViewModel.properties.netto),
                step: 0.01,
                class: "text-align-right",
                "hide-error-text": "",
                size: "small",
                type: "input-number",
                onValidate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validate()))
              }, null, 8, ["modelValue", "onUpdate:modelValue", "error"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: _ctx.FreitextBelegPositionViewModel.properties.summe,
            align: "right",
            fixed: "right",
            label: "Summe",
            width: "80"
          }, null, 8, ["prop"])
        ]),
        _: 1
      }, 8, ["cell-class-name", "data", "row-class-name"]))
    : _createCommentVNode("", true)
}