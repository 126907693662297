import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_repository_options = _resolveComponent("repository-options")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_kontakt = _resolveComponent("kontakt")!

  return (_openBlock(), _createBlock(_component_kontakt, {
    id: _ctx.modelValue,
    key: _ctx.modelValue.value,
    controller: _ctx.controllerKontakte,
    relation: _ctx.Resource.organisation.kontakt,
    "onUpdate:controller": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:controllerKontakte', $event)))
  }, {
    "pre-table": _withCtx(() => [
      (_ctx.keinDirektkontakt)
        ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, null, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, [
                    _createVNode(_component_el_text, {
                      class: "mx-1",
                      type: "warning"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Dieser Mandant ist auf kein Direktkontakt eingestellt. ")
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_row, { gutter: 20 }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                error: _ctx.getOrganisationNameError(),
                label: _ctx.OrganisationViewModel.label.name
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_repository_options, {
                    createable: _ctx.createable,
                    "model-value": _ctx.modelValue,
                    repository: _ctx.Resource.organisation,
                    "edit-dialog": "Neue Organisation anlegen",
                    editable: "",
                    "model-key": "name",
                    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.organisation = $event)),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
                  }, null, 8, ["createable", "model-value", "repository"])
                ]),
                _: 1
              }, 8, ["error", "label"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 12 })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["id", "controller", "relation"]))
}