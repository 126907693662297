import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Leistungsmerkmal } from "@generated/de/lohn24/model/abschluss/leistungsdaten/Leistungsmerkmal"
import { ArtikelFunktion } from "@generated/de/lohn24/model/artikel/ArtikelFunktion"
import { ArtikelEinheit } from "@generated/de/lohn24/model/artikel/ArtikelEinheit"

export interface IArtikel extends IModel {
	leistungsmerkmal: string | null
	einheit: ArtikelEinheit
	funktion: ArtikelFunktion | null
	name: string
}

export class Artikel extends Model<Artikel> {
	public leistungsmerkmal!: Id<Leistungsmerkmal> | null
	public einheit!: ArtikelEinheit
	public funktion!: ArtikelFunktion | null
	public name!: string

	static _types: Record<keyof IArtikel, IRuntimeTypeInformation> = {
		id: { type: Id },
		leistungsmerkmal: { type: Id, nullable: true },
		einheit: { type: ArtikelEinheit },
		funktion: { type: ArtikelFunktion, nullable: true },
		name: { type: String },
	}

	static props: Record<keyof IArtikel, string> = {
		id: "id",
		leistungsmerkmal: "leistungsmerkmal",
		einheit: "einheit",
		funktion: "funktion",
		name: "name",
	}

	constructor(_json: IArtikel) {
		super(_json)
	}
}
