import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"
import { PbxCallDirection } from "@generated/de/lohn24/model/pbx/PbxCallDirection"

export interface IPbxCall extends IModel {
	successful: boolean
	timestamp: LocalDateTime
	direction: PbxCallDirection
	called: string
	calledName: string
	callee: string
	calleeName: string
	caller: string
	callerName: string
	duration: string
}

export class PbxCall extends Model<PbxCall> {
	public successful!: boolean
	public timestamp!: LocalDateTime
	public direction!: PbxCallDirection
	public called!: string
	public calledName!: string
	public callee!: string
	public calleeName!: string
	public caller!: string
	public callerName!: string
	public duration!: string

	static _types: Record<keyof IPbxCall, IRuntimeTypeInformation> = {
		id: { type: Id },
		successful: { type: Boolean },
		timestamp: { type: LocalDateTime },
		direction: { type: PbxCallDirection },
		called: { type: String },
		calledName: { type: String },
		callee: { type: String },
		calleeName: { type: String },
		caller: { type: String },
		callerName: { type: String },
		duration: { type: String },
	}

	static props: Record<keyof IPbxCall, string> = {
		id: "id",
		successful: "successful",
		timestamp: "timestamp",
		direction: "direction",
		called: "called",
		calledName: "calledName",
		callee: "callee",
		calleeName: "calleeName",
		caller: "caller",
		callerName: "callerName",
		duration: "duration",
	}

	constructor(_json: IPbxCall) {
		super(_json)
	}
}
