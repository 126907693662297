import {h} from "vue"
import {Resource} from "@lib/model/Resource"
import {KontaktFunktion} from "@generated/de/lohn24/model/kontakt/KontaktFunktion"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {RouteLocationNormalizedLoaded, Router} from "vue-router"
import {assertNotNull} from "@lib/common/Functions"
import {ElMessageBox} from "element-plus"
import {Formatter} from "@lib/common/Formatter"
import {Config} from "@lib/Config"
import {LocalMonth} from "@lib/common/LocalMonth"
import {DialogController} from "@lib/common/controller/DialogController"
import {Id} from "@lib/common/model/Id"
import {MandantEditDialog} from "@intranet/view/mandant/edit/MandantEditDialog"
import {MandantListElement} from "@generated/de/lohn24/model/mandant/MandantListElement"
import {DateTime} from "luxon"
import {Beitragsnachweis} from "@generated/de/lohn24/model/mandant/Beitragsnachweis"
import {MandantBelegBuilderDialog} from "@intranet/view/mandant/belegbuilder/MandantBelegBuilderDialog"

export function confirmAbschluss(context: {
    kennung: string,
    name: string,
    abrechnungszeitraum: LocalMonth
}) {
    const inDerZukunft = context.abrechnungszeitraum.dateTime.diff(DateTime.now()).milliseconds > 0
    return ElMessageBox.confirm(
        () => h("div", null, [
                h("br"),
                h("table", [
                    h("tr", [
                        h("td", [
                            h("b", {class: "p-r-20"}, "Mandant"),
                        ]),
                        h("td", [
                            h("span", {class: "p-r-20"}, `${context.kennung} ${context.name}`),
                        ]),
                    ]),
                    h("tr", [
                        h("td", [
                            h("b", {class: "p-r-20"}, "Zeitraum"),
                        ]),
                        h("td", [
                            h("strong", null, Formatter.zeit.zeitraum(context.abrechnungszeitraum)),
                        ]),
                    ]),
                ]),
                inDerZukunft ? h("br") : null,
                inDerZukunft ? h("p", [
                    h("strong", null, `Achtung, der Zeitraum befindet sich in der Zukunft!`),
                ]) : null,
                h("br"),
                h("p", [
                    `Soll die Abrechnung abgeschlossen werden?`,
                ]),
                h("br"),
            ].filter(it => it),
        ),
        `Abschließen`,
        {
            cancelButtonText: Config.buttonLabel.CANCEL_BUTTON_TEXT,
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            cancelButtonClass: Config.buttonLabel.CANCEL_BUTTON_CLASS,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
        },
    )
}

export async function mandantInkonsistent(id: Id<Mandant>): Promise<Mandant | null> {
    await ElMessageBox.alert(
        "Die Mandantenstammdaten sind unvollständig oder falsch verwaltet.<br />Bitte behebe die in der Beabreitungsmaske angezeigten Fehler.",
        "Fehler",
        {
            confirmButtonText: Config.buttonLabel.CONFIRM_BUTTON_TEXT,
            confirmButtonClass: Config.buttonLabel.CONFIRM_BUTTON_CLASS,
            dangerouslyUseHTMLString: true,
        },
    )
    const mandant = await MandantEditDialog.show(id)
    await DialogController.closeDialogs()
    return mandant
}

export async function mandantAbschliessen(context: () => {
    mandant: Mandant | MandantListElement,
    route: RouteLocationNormalizedLoaded,
    router: Router
}) {
    if (context().mandant.keineRechnung) {
        try {
            const mandant = context().mandant
            const abrechnungszeitraum = assertNotNull(mandant.abrechnungszeitraum)
            await confirmAbschluss({
                name: mandant.name,
                kennung: mandant.kennung,
                abrechnungszeitraum,
                // abschlussTag: Datum.fromDateTime(effektiverAbrechnungszeitraum({
                //     abschlusstag: mandant.abschlusstag,
                //     abrechnungszeitraum: mandant.abrechnungszeitraum!,
                //     beitragsnachweis: mandant.beitragsnachweis,
                // })),
            })
            await Resource.mandant.abschliessen(
                Id.required(mandant.id),
                abrechnungszeitraum,
            )
            await DialogController.closeDialogs()
        } catch (e) {
            if (e === "cancel") {
                // do nothing
            }
        }
    } else {
        return await MandantBelegBuilderDialog.show(context().mandant.id)
    }
}

export async function emailTicketErfassen(context: () => {
    username: string,
    mandant: Mandant,
    email?: string
}) {

    const email = context().email ?? (await Resource.organisation.kontakt.all(
        context().mandant.organisation,
    )).reduce(
        (acc, kontakt) => {
            return kontakt.funktion === KontaktFunktion.HAUPTANSPRECHPARTNER
                ? kontakt.email ?? acc
                : acc
        },
        "",
    )

    const form = document.createElement("form")
    form.setAttribute("id", "otrs")
    form.setAttribute("target", "_blank")
    form.setAttribute("method", "post")
    form.setAttribute("action", "https://otrs.lohn24.de/otrs/index.pl?Action=AgentTicketEmail");

    [
        {name: "Action", value: "AgentTicketEmail"},
        {name: "Subaction", value: "StoreNew"},
        {name: "ExpandCustomerName", value: "0"},
        {name: "name", value: context().username},
        {name: "ResponsibleAll", value: context().username},
        {name: "PreSelectedCustomerUser", value: ""},
        {name: "SelectedCustomerUser", value: ""},
        {name: "TicketID", value: ""},
        {name: "Dest", value: "23||LOHN::Lohn24"},
        {name: "CustomerID", value: context().mandant.kennung},
        {name: "To", value: email},
    ].map(it => {
        const input = document.createElement("input")
        input.setAttribute("name", it.name)
        input.setAttribute("value", it.value)
        input.setAttribute("type", "hidden")
        form.append(input)
        document.body.appendChild(form)
    })

    form.submit()
    document.body.removeChild(form)
}

export async function rueckrufTicketErfassen(context: () => {
    username: string,
    mandant: Mandant,
    email?: string,
}) {

    const email = context().email ?? (await Resource.organisation.kontakt.all(
        context().mandant.organisation,
    )).reduce(
        (acc, kontakt) => {
            return kontakt.funktion === KontaktFunktion.HAUPTANSPRECHPARTNER
                ? kontakt.email ?? acc
                : acc
        },
        "",
    )

    const form = document.createElement("form")
    form.setAttribute("id", "otrs")
    form.setAttribute("target", "_blank")
    form.setAttribute("method", "post")
    form.setAttribute("action", "https://otrs.lohn24.de/otrs/index.pl?Action=AgentTicketPhone");

    [
        {name: "OwnerAll", value: context().username},
        {name: "ResponsibleAll", value: context().username},
        {name: "Action", value: "AgentTicketPhone"},
        {name: "From", value: context().mandant.kennung},
        {name: "Subaction", value: "StoreNew"},
        {name: "ExpandCustomerName", value: "0"},
        {name: "PreSelectedCustomerUser", value: ""},
        {name: "SelectedCustomerUser", value: ""},
        {name: "TicketID", value: ""},
        {name: "Subject", value: "Rueckrufticket"},
        {name: "Dest", value: "23||LOHN::Lohn24"},
        {name: "CustomerId", value: context().mandant.kennung},
        {name: "To", value: email},
    ].map(it => {
        const input = document.createElement("input")
        input.setAttribute("name", it.name)
        input.setAttribute("value", it.value)
        input.setAttribute("type", "hidden")
        form.append(input)
        document.body.appendChild(form)
    })

    form.submit()
    document.body.removeChild(form)
}

export function effektiverAbrechnungszeitraum(context: {
    beitragsnachweis: Beitragsnachweis,
    abrechnungszeitraum: LocalMonth,
    abschlusstag: number,
}): DateTime {
    const beitragsschaetzung = context.beitragsnachweis === Beitragsnachweis.SCHAETZUNG && context.abschlusstag === 10
    const abrechnungszeitraum = context.abrechnungszeitraum.dateTime
    if (beitragsschaetzung) {
        return abrechnungszeitraum.plus({month: 1})
    }
    return abrechnungszeitraum
}
