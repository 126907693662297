import {Repository} from "@lib/common/repository/Repository"
import {Mitarbeiter} from "@generated/de/lohn24/model/mitarbeiter/Mitarbeiter"
import {Id} from "@lib/common/model/Id"
import {Formatter} from "@lib/common/Formatter"
import {AccountRole} from "@generated/dev/api/model/role/AccountRole"
import {Account} from "@generated/dev/api/model/account/Account"
import {Role} from "@generated/dev/api/model/role/Role"
import {AccountStatus} from "@generated/dev/api/model/account/AccountStatus"

export class AccountRepository extends Repository<Account> {

    role = this.buildHasManyWithRelation(AccountRole, Role, "role")
    mitarbeiter = this.buildRelation(Mitarbeiter, "mitarbeiter")

    constructor() {
        super(Account, "account")
    }

    async me(): Promise<Account> {
        const result = await this.api.get<Account>(this.route("me"))
        return this.deserialize(result)
    }

    async createZammadAccessToken(): Promise<Account> {
        const result = (await this.api.post(this.route("zammad"), {})) as Account
        return this.deserialize(result)
    }

    accountName(id: Id<Account> | null): Nullable<string> {
        if (id === null)
            return null

        return this.cache.mapped(id, account => {
                if (account?.vorname && account?.nachname)
                    return Formatter.mitarbeiter.vorUndZu({
                        vorname: account.vorname,
                        nachname: account.nachname,
                    })
                else if (account?.username === "system") {
                    return Formatter.mitarbeiter.vorUndZu({
                        vorname: "System",
                        nachname: "User",
                    })
                } else {
                    return null
                }
            },
        ).value
    }

    filterAccounts: (account: Account) => boolean = (account) => {
        return account.status !== AccountStatus.INAKTIV
            // eigentlich != Abteilung.EXTERN - hier aber keinen Zugriff auf den Mitarbeiter
            && account.username !== "c.ziegler"
    }
}
