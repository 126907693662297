import {onMounted, onUnmounted} from "vue"
import {assertNotNull} from "@lib/common/Functions"

let mountCounter = 0

function queryHtmlElement(): HTMLElement {
    return assertNotNull(document.querySelector("html")) as HTMLElement
}

export function useDialogOverflow() {
    onMounted(() => {
        mountCounter++
        queryHtmlElement().style.overflow = "hidden"
    })

    onUnmounted(() => {
        if (--mountCounter <= 0) {
            queryHtmlElement().style.overflow = "auto"
        }
    })
}