import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"

export interface IRole extends IModel {
	name: string
}

export class Role extends Model<Role> {
	public name!: string

	static _types: Record<keyof IRole, IRuntimeTypeInformation> = {
		id: { type: Id },
		name: { type: String },
	}

	static props: Record<keyof IRole, string> = {
		id: "id",
		name: "name",
	}

	constructor(_json: IRole) {
		super(_json)
	}
}
