import { IRuntimeTypeInformation, LocalMonth, Serializable } from "@lib/index"

export interface ILeistungsdatenListElement {
	menge: number
	zeitraum: string
	mandanten: number
	netto: number
	leistungsmerkmal: string
	nachname: string
	vorname: string
	sachbearbeiter: string
}

export class LeistungsdatenListElement extends Serializable<LeistungsdatenListElement> {
	public menge!: number
	public zeitraum!: LocalMonth
	public mandanten!: number
	public netto!: number
	public leistungsmerkmal!: string
	public nachname!: string
	public vorname!: string
	public sachbearbeiter!: string

	static _types: Record<keyof ILeistungsdatenListElement, IRuntimeTypeInformation> = {
		menge: { type: Number },
		zeitraum: { type: LocalMonth },
		mandanten: { type: Number },
		netto: { type: Number },
		leistungsmerkmal: { type: String },
		nachname: { type: String },
		vorname: { type: String },
		sachbearbeiter: { type: String },
	}

	static props: Record<keyof ILeistungsdatenListElement, string> = {
		menge: "menge",
		zeitraum: "zeitraum",
		mandanten: "mandanten",
		netto: "netto",
		leistungsmerkmal: "leistungsmerkmal",
		nachname: "nachname",
		vorname: "vorname",
		sachbearbeiter: "sachbearbeiter",
	}

	constructor(_json: ILeistungsdatenListElement) {
		super(_json)
	}
}
