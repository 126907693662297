import {DialogController} from "@lib/common/controller/DialogController"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {computed, reactive, shallowRef, ShallowRef} from "vue"
import {DebitorEditDialogViewModel} from "@intranet/view/debitor/edit/DebitorEditDialogViewModel"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {DebitorViewModel} from "@intranet/view/debitor/DebitorViewModel"
import {AnschriftController} from "@intranet/view/anschrift/AnschriftController"
import {OptionalAnschriftController} from "@intranet/view/anschrift/OptionalAnschriftController"
import {ValidationModel, Validator} from "@lib/common/Validator"
import {_props} from "@lib/common/_props"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {EditDialogTab} from "@lib/view/editdialog/EditDialogTab"
import {ValidationProperties} from "@lib/common/ValidationProperties"
import {from} from "@lib/common/Functions"
import {Logger} from "@lib/common/logger/Logger"

export class DebitorEditDialogController extends DialogController {
    title = computed(() => this.viewModel.titel)
    debitorController: ShallowRef<DebitorController<any>>
    rechnungsanschriftController = shallowRef<AnschriftController<any> | null>(null)
    postanschriftController = shallowRef<OptionalAnschriftController<any> | null>(null)
    tabs = reactive([
        new EditDialogTab(
            "debitor",
            "Stammdaten",
            [
                new ValidationProperties("debitor", Object.values(Debitor.props)),
                new ValidationProperties(_props<Debitor>().rechnungsanschrift, Object.values(Anschrift.props)),
                new ValidationProperties(_props<Debitor>().postanschrift, Object.values(Anschrift.props)),
            ],
        ),
    ])

    constructor(debitor: Debitor) {
        super()
        this.debitorController = shallowRef(new DebitorController(new DebitorViewModel(debitor)))
    }

    get debitor(): Debitor {
        return this.debitorController.value.model.value.debitor
    }

    get viewModel() {
        return new DebitorEditDialogViewModel(this.debitor)
    }

    override modified() {
        return this.debitorController.value.modified
            || (this.rechnungsanschriftController.value?.modified ?? false)
            || (this.postanschriftController.value?.modified ?? false)
    }

    get validator() {
        return new Validator(
            new ValidationModel(
                "debitor",
                "debitor",
                () => this.debitor,
            ),
            new ValidationModel(
                "anschrift",
                _props<Debitor>().rechnungsanschrift,
                () => this.rechnungsanschriftController.value?.model?.value?.anschrift ?? from(Anschrift, {}),
                () => !this.rechnungsanschriftController.value,
            ),
            new ValidationModel(
                "anschrift",
                _props<Debitor>().postanschrift,
                () => this.postanschriftController.value?.model.value?.anschrift || from(Anschrift, {}),
                () => !this.postanschriftController.value,
            ),
        )
    }

    override async save(): Promise<void> {
        (new Logger(this)).info(this.rechnungsanschriftController.value)
        await this.rechnungsanschriftController.value?.update(this.debitor.id)
        if (this.debitor.postanschrift == null)
            this.postanschriftController.value?.create(this.debitor.id)
        else
            await this.postanschriftController.value?.update(this.debitor.id)
        await this.debitorController.value.update()
    }
}