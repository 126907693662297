import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Role } from "@generated/dev/api/model/role/Role"
import { Right } from "@generated/dev/api/model/right/Right"

export interface IRoleRight extends IModel {
	right: string
	role: string | null
	delete: boolean
	read: boolean
	write: boolean
}

export class RoleRight extends Model<RoleRight> {
	public right!: Id<Right>
	public role!: Id<Role> | null
	public delete!: boolean
	public read!: boolean
	public write!: boolean

	static _types: Record<keyof IRoleRight, IRuntimeTypeInformation> = {
		id: { type: Id },
		right: { type: Id },
		role: { type: Id, nullable: true },
		delete: { type: Boolean },
		read: { type: Boolean },
		write: { type: Boolean },
	}

	static props: Record<keyof IRoleRight, string> = {
		id: "id",
		right: "right",
		role: "role",
		delete: "delete",
		read: "read",
		write: "write",
	}

	constructor(_json: IRoleRight) {
		super(_json)
	}
}
