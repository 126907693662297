import {ElTable} from "element-plus"
import {unparse} from "papaparse"

export function convertToCSV(table: InstanceType<typeof ElTable>) {
    const [header, body] = table.$el.querySelectorAll("table")
    const headerRow = Array.from(header.querySelectorAll("th") as HTMLTableCellElement[]).map(it => it.textContent)
    const bodyRows = Array.from(body.querySelectorAll("tr") as HTMLTableRowElement[]).map(row => {
        return (Array.from(row.querySelectorAll("td")) as HTMLTableCellElement[]).map(it => it.textContent)
    })

    return unparse(
        [headerRow, ...bodyRows],
        {
            header: true,
            newline: "\n",
            delimiter: ",",
        },
    )

}