import {ModelBuilder} from "@lib/common/model/ModelBuilder"
import {Model} from "@lib/common/model/Model"
import {BuilderRepository} from "@lib/common/repository/BuilderRepository"
import {Id} from "@lib/common/model/Id"

export abstract class ModelBuilderController<B extends ModelBuilder<B>, M extends Model<M>> {
    abstract builderRepository: BuilderRepository<B, M>
    protected builderId: Id<B> | null = null

    get builder() {
        return this.getBuilder()
    }

    set builder(builder) {
        this.builderId = builder?.id ?? null
        this.setBuilder(builder)
    }

    static getBuilder<B extends ModelBuilder<B>, M extends Model<M>>(controller: ModelBuilderController<B, M>) {
        return controller.getBuilder()
    }

    abstract getBuilder(): B

    abstract setBuilder(builder: Nullable<B>)

    abstract fromModel(
        model: M,
        ...other: Nullable<Model<any>>[]
    ): B
}