<template>
  <el-tooltip :content="options.label" :show-after="1000" placement="top">
    <el-button :class="btnClass" text v-bind="$attrs" @click.stop="openLink">
      <component :is="options.icon" />
    </el-button>
  </el-tooltip>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import {zammadTicketByMandantennummer} from "@intranet/view/mandant/ticket/ZammadTicketByMandantennummer"

export default defineComponent({
    name: "MandantZammadSuche",
    props: {
        btnClass: {
            type: [String, Number],
            default: "",
        },
        kennung: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: "large",
        },
    },
    setup(props) {

        const options = zammadTicketByMandantennummer(() => ({kennung: props.kennung}))
        const openLink = (event: MouseEvent) => options.action(event)

        return {
            openLink,
            options,
        }
    },
})
</script>
