import {ViewModel} from "@lib/common/model/ViewModel"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Id} from "@lib/common/model/Id"

export class DebitorEditDialogViewModel extends ViewModel<DebitorEditDialogViewModel> {

    debitor: Debitor

    constructor(debitor: Debitor) {
        super()
        this.debitor = this.registerShallowReactive(debitor)
    }

    get id(): Id<Debitor> {
        return this.debitor.id
    }

    get titel(): string {
        return `${this.debitor.konto} // ${this.debitor.name}`
    }
}