import Api from "@lib/common/axios/Api"
import {RepositoryEvent} from "@lib/common/repository/RepositoryEventTarget"
import {ContentType} from "@lib/common/Enums"

export type AppVersionCB = (server: string, local: string) => void

const serverAppVersionRegExp = /src="\/js\/app\.([a-zA-Z0-9]*)\.*js/
const localAppVersionRegExp = /app\.([a-zA-Z0-9]*)\.*js/
const serverVendorVersionRegExp = /src="\/js\/chunk-vendors\.([a-zA-Z0-9]*)\.*js/
const localVendorVersionRegExp = /chunk-vendors\.([a-zA-Z0-9]*)\.*js/

export enum AppVersionEventidentifier {
    APP = "app-version",
    VENDOR = "vendor-version",
}

export class AppVersionEvent extends CustomEvent<{ server: string, local: string }> {
    constructor(target: AppVersionEventidentifier, server: string, local: string) {
        super(
            target,
            {
                detail: {
                    server,
                    local,
                },
            },
        )
    }
}

export class AppVersionEventTarget {
    registeredTypes: Map<any, string[]> = new Map()
    registered: Map<any, any> = new Map<any, any>()
    private eventTarget = new EventTarget()

    addEventListener(
        type: AppVersionEventidentifier,
        callback: AppVersionCB,
        options?: AddEventListenerOptions | boolean,
    ) {
        const typeList = this.registeredTypes.get(callback)
        if (typeList) typeList.push(type)
        else this.registeredTypes.set(callback, [type])
        if (!this.registered.has(callback))
            this.registered.set(callback, (event: AppVersionEvent) => callback(event.detail.server, event.detail.local))
        this.eventTarget.addEventListener(type, this.registered.get(callback), options)
    }

    removeEventListener(
        type: AppVersionEventidentifier,
        callback: AppVersionCB,
        options?: EventListenerOptions | boolean,
    ) {
        this.eventTarget.removeEventListener(type, this.registered.get(callback), options)
        const typelist = this.registeredTypes.get(callback)
        if (typelist) {
            const index = typelist.indexOf(type)
            if (index > -1)
                typelist.splice(index, 1)
            if (typelist.length === 0) {
                this.registeredTypes.delete(callback)
                this.registered.delete(callback)
            }
        }
    }

    dispatchEvent<R>(event: RepositoryEvent<R>) {
        this.eventTarget.dispatchEvent(event)
    }

    remove(callback: AppVersionCB) {
        const typelist = this.registeredTypes.get(callback)
        if (typelist)
            for (const type of typelist) {
                this.eventTarget.removeEventListener(type, this.registered.get(callback))
                this.registered.delete(callback)
            }
    }
}

export class AppVersionService {
    static readonly eventTarget = new AppVersionEventTarget()

    static async execute(apiBaseUrl: string = "") {
        const path = window.location.pathname
        const api = Api.Instance(apiBaseUrl)
        const result = await api.get<string>(path, {}, ContentType.HTML)
        const serverAppVersion = (result.match(serverAppVersionRegExp) ?? [])[1] ?? ""
        const localAppVersion = Array
            .from(
                document.head.getElementsByTagName("script"),
                (it: HTMLScriptElement) => it.src.match(localAppVersionRegExp),
            )
            .mapNotNull(it => it && it[1])
            .firstOrNull() ?? ""
        const serverVendorVersion = (result.match(serverVendorVersionRegExp) ?? [])[1] ?? ""
        const localVendorVersion = Array
            .from(
                document.head.getElementsByTagName("script"),
                (it: HTMLScriptElement) => it.src.match(localVendorVersionRegExp),
            )
            .mapNotNull(it => it && it[1])
            .firstOrNull() ?? ""

        this.eventTarget.dispatchEvent(new AppVersionEvent(AppVersionEventidentifier.APP, serverAppVersion, localAppVersion))
        this.eventTarget.dispatchEvent(new AppVersionEvent(AppVersionEventidentifier.VENDOR, serverVendorVersion, localVendorVersion))
    }

    static addVendorListener(cb: AppVersionCB) {
        this.eventTarget.addEventListener(AppVersionEventidentifier.VENDOR, cb)
    }

    static removeVendorListener(cb: AppVersionCB) {
        this.eventTarget.removeEventListener(AppVersionEventidentifier.VENDOR, cb)
    }

    static addAppListener(cb: AppVersionCB) {
        this.eventTarget.addEventListener(AppVersionEventidentifier.APP, cb)
    }

    static removeAppListener(cb: AppVersionCB) {
        this.eventTarget.removeEventListener(AppVersionEventidentifier.APP, cb)
    }
}