<script generic="M extends Model<M>" lang="ts" setup>

import {Formatter} from "@lib/common/Formatter"
import RepositoryRadioGroup from "@lib/view/formitem/RepositoryRadioGroup.vue"
import FormItem from "@lib/view/formitem/FormItem.vue"
import {computed} from "vue"
import {DebitorViewModel} from "@intranet/view/debitor/DebitorViewModel"
import {from} from "@lib/common/Functions"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {DebitorController} from "@intranet/view/debitor/DebitorController"
import {Model} from "@lib/common/model/Model"
import {Resource} from "@lib/model/Resource"
import {DebitorSepa} from "@generated/de/lohn24/model/debitor/DebitorSepa"
import IbanInput from "@lib/view/IbanInput.vue"
import Relation from "@lib/view/relation/Relation.vue"
import {AnschriftController} from "@intranet/view/anschrift/AnschriftController"
import AnschriftForm from "@intranet/view/anschrift/AnschriftForm.vue"
import {OptionalAnschriftController} from "@intranet/view/anschrift/OptionalAnschriftController"
import {RelationDefaultTemplateContext} from "@lib/view/relation/RelationDefaultTemplateContext"
import {
    BereitstellungOptionRechnung,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionRechnung"
import EnumCheckboxButtonGroup from "@lib/view/formitem/EnumCheckboxButtonGroup.vue"
import {LohnabrechnungSystem} from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"

const props = withDefaults(
    defineProps<{
        path?: string
        controller: DebitorController<M>
        rechnungssteller?: boolean
        abrechnungssystem?: LohnabrechnungSystem
        rechnungsanschrift?: boolean
        pathRechnungsanschrift?: string
        postanschrift?: boolean
        pathPostanschrift?: string
        rechnungsanschriftController: AnschriftController<Debitor>,
        postanschriftController: OptionalAnschriftController<Debitor>,
    }>(),
    {
        path: "debitor",
        pathRechnungsanschrift: "rechnungsanschrift",
        pathPostanschrift: "postanschrift",
        abrechnungssystem: LohnabrechnungSystem.ADDISON,
        rechnungsanschrift: false,
        postanschrift: false,
        rechnungssteller: false,
    },
)

const emits = defineEmits<{
    validate: [path?: string]
    "update:rechnungsanschriftController": [controller: AnschriftController<Debitor>]
    "update:postanschriftController": [controller: OptionalAnschriftController<Debitor>]
}>()

const debitorViewModel = computed<DebitorViewModel>(() =>
    props.controller.model.value ?? new DebitorViewModel(from(Debitor, {})),
)

const emitValidate = (path?: string) => emits("validate", path)

</script>

<template>
  <el-row :gutter="40">
    <el-col :span="12">
      <h3>Stammdaten</h3>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              label="Rechnungssteller"
              property="rechnungssteller"
      >
        <template #default="{validate}">
          <repository-radio-group
                  v-model="debitorViewModel.firmaId"
                  :disabled="!rechnungssteller"
                  :repository="Resource.firma"
                  data-cy="debitor-rechnungssteller"
                  @change="validate(path)"
          />
        </template>

      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.name"
              label="Name"
      >
        <el-input
                v-model="debitorViewModel.name"
                data-cy="debitor-name"
                name="name"
        />
      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.konto"
              label="Debitorenkonto"
      >
        <el-input
                v-model="debitorViewModel.konto"
                data-cy="debitor-konto"
                name="konto"
        />
      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.mail"
              label="E-Mail"
      >
        <el-input
                v-model="debitorViewModel.mail"
                data-cy="debitor-mail"
        />
      </form-item>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.rechnungszusatz"
              data-cy="debitor-rechnungszusatz"
              label="Rechnungszusatz"
      >
        <el-input v-model="debitorViewModel.rechnungszusatz"></el-input>
      </form-item>
    </el-col>
    <el-col :span="12">
      <h3>SEPA</h3>

      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.sepa"
              label="Mandatstyp"
      >

        <el-select v-model="debitorViewModel.sepa" clearable data-cy="debitor-sepa"
                   placeholder="Kein Sepamandat"
                   @change="$emit('validate', path)"
        >
          <el-option :value="DebitorSepa.BASISLASTSCHRIFT" label="Basislastschrift">Basislastschrift</el-option>
          <el-option :value="DebitorSepa.FIRMENLASTSCHRIFT" label="Firmenlastschrift">Firmenlastschrift</el-option>
        </el-select>
      </form-item>
      <div
              v-if="debitorViewModel.sepa === DebitorSepa.FIRMENLASTSCHRIFT || debitorViewModel.sepa === DebitorSepa.BASISLASTSCHRIFT"
      >
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.iban"
                label="IBAN"
        >
          <iban-input v-model="debitorViewModel.iban" data-cy="debitor-iban" />
        </form-item>
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.abweichenderKontoinhaber"
                label="Abw. Kontoinhaber"
        >
          <el-input v-model="debitorViewModel.abweichenderKontoinhaber"
                    data-cy="debitor-abweichenderKontoinhaber"
          />
        </form-item>
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.sepaMandatsreferenz"
                label="Mandatsreferenz"
        >
          <el-input
                  v-model="debitorViewModel.sepaMandatsreferenz"
                  data-cy="debitor-sepaMandatsreferenz"
          />
        </form-item>
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.sepaUnterschrift"
                label="Unterschrieben am"
        >
          <el-date-picker
                  v-model="debitorViewModel.sepaUnterschrift"
                  data-cy="debitor-sepaUnterschrift"
                  format="DD.MM.YYYY"
                  @change="emitValidate(props.path)"
          ></el-date-picker>
        </form-item>
        <form-item
                :emit-validate="emitValidate"
                :path="props.path"
                :property="DebitorViewModel.propertyNames.sepaGueltigkeit"
                label="Gültig ab"
        >
          <el-date-picker
                  v-model="debitorViewModel.sepaGueltigkeit"
                  data-cy="debitor-sepaGueltigkeit"
                  format="DD.MM.YYYY"
                  @change="emitValidate(props.path)"
          ></el-date-picker>
        </form-item>

      </div>
      <form-item
              v-else
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.sepaVomKundenAbgelehnt"
              label="Sepamandat vom Kunden abgelehnt"
      >
        <el-checkbox
                v-model="debitorViewModel.sepaVomKundenAbgelehnt"
                data-cy="debitor-sepaVomKundenAbgelehnt"
        />
      </form-item>
    </el-col>
  </el-row>
  <br />
  <el-row :gutter="40">
    <el-col :span="12" />
    <el-col :span="12">
      <h3 class="heading">Datenbereitstellung</h3>
      <form-item
              :emit-validate="emitValidate"
              :path="props.path"
              :property="DebitorViewModel.propertyNames.bereitstellungRechnung"
              label="Rechnung"
      >
        <template #default="{validate}">
          <enum-checkbox-button-group
                  v-model="debitorViewModel.bereitstellungRechnung"
                  :label="{
                      [BereitstellungOptionRechnung.CLOUD]: 'LOHN24 Cloud',
                      [BereitstellungOptionRechnung.POST]: 'Post',
                      [BereitstellungOptionRechnung.FAX]: 'Fax',
                      [BereitstellungOptionRechnung.MAIL]: 'E-Mail',
                      [BereitstellungOptionRechnung.SYSTEM]: Formatter.bereitstellungAbrechnungssystem(abrechnungssystem),
                  }"
                  :options="Object.values(BereitstellungOptionRechnung)"
                  @change="validate(path)"
          />
        </template>
      </form-item>
    </el-col>

  </el-row>
  <br />
  <el-row :gutter="40">
    <el-col :span="12">
      <slot
              v-if="rechnungsanschrift"
              :parent="debitorViewModel.debitor.id"
              :relation="Resource.debitor.rechnungsanschrift"
              name="rechnungsanschrift"
      >
        <relation
                :controller="props.rechnungsanschriftController"
                :build-controller-instance="AnschriftController.build"
                :parent="debitorViewModel.debitor.id"
                :relation="Resource.debitor.rechnungsanschrift"
                @update:controller="emits('update:rechnungsanschriftController', $event)"
        >
          <template #default="{controller}: RelationDefaultTemplateContext<AnschriftController<Debitor>>">
            <anschrift-form
                    :controller="controller"
                    :path="pathRechnungsanschrift"
                    title="Rechnungsanschrift"
                    @validate="emitValidate(pathRechnungsanschrift)"

            />
          </template>
        </relation>
      </slot>
    </el-col>
    <el-col :span="12">
      <slot :parent="debitorViewModel.debitor.id" name="postanschrift">
        <relation
                :controller="props.postanschriftController"
                :build-controller-instance="OptionalAnschriftController.build"
                :parent="debitorViewModel.debitor.id"
                :relation="Resource.debitor.postanschrift"
                @update:controller="emits('update:postanschriftController', $event)"
        >
          <template #default="{controller}: RelationDefaultTemplateContext<OptionalAnschriftController<Debitor>>">
            <anschrift-form
                    :id="debitorViewModel.debitor.id"
                    :controller="controller"
                    :path="pathPostanschrift"
                    title="Abweichende Rechnungsanschrift"
                    @validate="emitValidate(pathPostanschrift)"
            >
            </anschrift-form>
          </template>
        </relation>
      </slot>
    </el-col>
  </el-row>
</template>

<style scoped>
.el-tab-pane {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 20px;
}
</style>