import {DialogController} from "@lib/common/controller/DialogController"
import {reactive, ref, Ref, shallowRef} from "vue"
import {ModelController} from "@lib/common/controller/ModelController"
import {NewsViewModel} from "@intranet/view/news/NewsViewModel"
import {NewsController} from "@intranet/view/news/NewsController"
import {News} from "@generated/de/lohn24/model/news/News"
import {EditDialogTab} from "@lib/view/editdialog/EditDialogTab"
import {ValidationProperties} from "@lib/common/ValidationProperties"

export class NewsDialogController extends DialogController {
    title: Ref<string> = ref("News")

    newsController!: Ref<ModelController<NewsViewModel>>
    tabs = reactive([
        new EditDialogTab(
            "news",
            "Nachricht",
            [
                new ValidationProperties(
                    "news",
                    Object.values(News.props),
                ),
            ],
        ),
    ])

    override modified(): boolean {
        return this.newsController.value.modified
    }

    constructor(private news: NewsViewModel) {
        super()
        this.newsController = shallowRef(new NewsController(news))
    }

    async close(): Promise<void> {
        if (this.newsController.value.modified)
            try {
                await DialogController.confirmCloseOfUnsavedChanges()
                return super.close()
            } catch (e) {
                if (e === "cancel")
                    return
                throw e
            }
        else
            return super.close()
    }

    protected override async onClose(): Promise<void> {
        await super.onClose()
        return DialogController.closeDialogs()
    }

}

