import {ContextMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import IconEmail from "@lib/view/icons/IconEmail.vue"
import {Resource} from "@lib/model/Resource"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {buildCallbackPath, ZammadFormSenderType, ZammadTaskbarBuilder} from "@lib/common/zammad/ZammadTaskbar"
import {auth} from "@lib/common/Authentication"
import {Config} from "@lib/Config"
import {Id} from "@lib/common/model/Id"

export interface MandantZammadTicketContext {
    kontakt?: Kontakt,
    mandant: Id<Mandant>
}

export function mandantZammadTicket(
    context: () => Promise<MandantZammadTicketContext> | MandantZammadTicketContext,
): ContextMenuItemOption {
    return new ContextMenuItemOption(
        "E-Mail-Ticket erfassen",
        async () => {
            const {kontakt, mandant} = await context()
            const taskbar = await (await Resource.zammad()).ticketCreate(new ZammadTaskbarBuilder(
                {
                    formSenderType: ZammadFormSenderType.EMAIL_OUT,
                    customer_id: kontakt ? `${kontakt.zammadId}` : null,
                    group_id: `${(await Resource.mandant.ticketgruppe(mandant)).zammadId}`,
                    owner_id: `${auth.me.zammadId}`,
                },
            ))
            window.open(
                buildCallbackPath(Config.ZAMMAD_BASE_URL, taskbar),
                `${Config.ZAMMAD_WINDOW_TARGET}-${taskbar.key}`,
            )
        },
        IconEmail,
    )
}