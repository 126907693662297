import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validateable_input = _resolveComponent("validateable-input")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_icon_delete = _resolveComponent("icon-delete")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_icon_add = _resolveComponent("icon-add")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    ref: "table",
    "cell-class-name": _ctx.cellClassName,
    data: _ctx.tableData,
    "row-class-name": _ctx.rowClassName,
    "show-header": _ctx.showHeader,
    class: "artikel-erstellung-table"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: _ctx.KatalogPositionViewModel.label.text,
        property: _ctx.KatalogPositionViewModel.properties.text,
        width: 420
      }, {
        default: _withCtx(({row}) => [
          _createVNode(_component_validateable_input, {
            ref: "input",
            error: _ctx.error(row.validationIndex, _ctx.KatalogPositionViewModel.properties.text),
            "model-value": row.text || '',
            name: _ctx.KatalogPositionViewModel.properties.text,
            placeholder: row.artikelName,
            size: _ctx.FontSize.small,
            onValidate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.validate())),
            "onUpdate:modelValue": ($event: any) => (row.text=$event)
          }, null, 8, ["error", "model-value", "name", "placeholder", "size", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["label", "property"]),
      _createVNode(_component_el_table_column, {
        label: _ctx.KatalogPositionViewModel.label.notiz,
        property: _ctx.KatalogPositionViewModel.properties.notiz,
        width: 300
      }, {
        default: _withCtx(({row}) => [
          _createVNode(_component_validateable_input, {
            error: _ctx.error(row.validationIndex, _ctx.KatalogPositionViewModel.properties.notiz),
            "model-value": row.notiz || '',
            name: _ctx.KatalogPositionViewModel.properties.notiz,
            size: _ctx.FontSize.small,
            onValidate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validate())),
            "onUpdate:modelValue": ($event: any) => (row.notiz=$event)
          }, null, 8, ["error", "model-value", "name", "size", "onUpdate:modelValue"])
        ]),
        _: 1
      }, 8, ["label", "property"]),
      _createVNode(_component_el_table_column),
      _createVNode(_component_el_table_column, {
        label: _ctx.KatalogPositionViewModel.label.menge,
        align: "center",
        fixed: "right",
        width: "150"
      }, {
        default: _withCtx(({row}) => [
          _createVNode(_component_validateable_input, {
            modelValue: row.menge,
            "onUpdate:modelValue": ($event: any) => ((row.menge) = $event),
            modelModifiers: { number: true },
            error: _ctx.error(row.validationIndex, _ctx.KatalogPositionViewModel.properties.menge),
            min: 0,
            name: _ctx.KatalogPositionViewModel.properties.menge,
            step: 1,
            size: _ctx.FontSize.small,
            type: _ctx.InputType.InputNumber,
            onValidate: _ctx.validate
          }, null, 8, ["modelValue", "onUpdate:modelValue", "error", "name", "size", "type", "onValidate"])
        ]),
        _: 1
      }, 8, ["label"]),
      (_ctx.preise)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            label: _ctx.KatalogPositionViewModel.label.netto,
            prop: _ctx.KatalogPositionViewModel.properties.netto,
            align: "right",
            fixed: "right",
            width: "100"
          }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_el_tooltip, {
                disabled: !row.hasMandantPreis(),
                content: "Mandantenpreis"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.netto), 1)
                ]),
                _: 2
              }, 1032, ["disabled"])
            ]),
            _: 1
          }, 8, ["label", "prop"]))
        : _createCommentVNode("", true),
      (_ctx.preise)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 1,
            label: _ctx.KatalogPositionViewModel.label.summe,
            prop: _ctx.KatalogPositionViewModel.properties.summe,
            width: 100,
            align: "right",
            fixed: "right"
          }, null, 8, ["label", "prop"]))
        : _createCommentVNode("", true),
      (_ctx.deletable)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 2,
            width: 20,
            fixed: "left"
          }, {
            default: _withCtx(({row}) => [
              (!_ctx.firstEmptyRow(row))
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    text: "",
                    onClick: _withModifiers(($event: any) => (_ctx.clearOrDelete(row)), ["stop"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_delete)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.createable)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 3,
            width: 40,
            fixed: "right"
          }, {
            default: _withCtx(({row}) => [
              (_ctx.firstRow(row))
                ? (_openBlock(), _createBlock(_component_el_button, {
                    key: 0,
                    "data-cy": "katalogartikel-add",
                    text: "",
                    onClick: ($event: any) => (_ctx.add(row))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_icon_add)
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["cell-class-name", "data", "row-class-name", "show-header"]))
}