<script generic="E" lang="ts" setup>

const props = withDefaults(
    defineProps<{
        modelValue: E[]
        options: E[]
        label?: Record<string, string>
    }>(),
    {
        label: () => ({}),
    },
)

const emits = defineEmits<{
    "update:modelValue": [options: E[]]
}>()

function toggleValues(option: E) {
    const values = props.modelValue.slice()
    const index = values.findIndex(it => option === it)
    if (index < 0)
        values.push(option)
    else
        values.splice(index, 1)
    emits("update:modelValue", values)
}

</script>

<template>
  <el-checkbox-group
          :model-value="props.modelValue"
          v-bind="$attrs"
  >
    <slot v-for="option in props.options" :key="option" :name="option">
      <el-checkbox-button :label="option" @change="toggleValues(option)">
        <slot :name="`${option}-label`">
          {{ props.label[option] ?? option }}
        </slot>
      </el-checkbox-button>
    </slot>
  </el-checkbox-group>
</template>

<style lang="scss" scoped>

</style>