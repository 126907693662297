import {Constructable} from "@lib/types"
import {Serializable} from "@lib/common/serializable/Serializable"

export class PartitionedResultSet<M extends Serializable<M>, D extends Serializable<D>> {
    items: M[]
    distincts!: D
    size!: number

    constructor(modelCtor: Constructable<M>, distinctCtor: Constructable<D> | undefined, obj: Partial<PartitionedResultSet<M, D>>) {
        this.items = (obj.items ?? []).map(it => new modelCtor(it)) as M[]
        this.distincts = distinctCtor ? new distinctCtor(obj.distincts) as D : {} as D
        this.size = obj.size!
    }
}