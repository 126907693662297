import {DateTime, Settings} from "luxon"

Settings.defaultZone = "EUROPE/BERLIN"

DateTime.prototype.toJSON = function () {
    return this.toUTC().toISO()
}

export function deserializeDateTime(text: string): DateTime {
    return DateTime.fromISO(text, {zone: "EUROPE/BERLIN"})
}
