import {TabMenuItem} from "@lib/common/menu/TabMenuItem"
import {MandantMenuItem} from "@intranet/view/mandant/MandantMenuItem"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/rights/RightIdentifier"

export const BearbeitungListMenuItem = new TabMenuItem({
    label: "Bearbeitungsliste",
    name: "beabeitungsliste",
    route: MandantMenuItem.route,
    rights: [RightAccess.readable(RightIdentifier.INTRANET)],
    children: [],
    key: "b"
})