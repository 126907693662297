import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'menu-item-inactive': !_ctx.active })
  }, [
    _renderSlot(_ctx.$slots, "default", { items: _ctx.menuItems }, () => [
      _createVNode(_component_el_menu, {
        ref: "menu",
        "default-active": _ctx.currentRoute.name,
        ellipsis: false,
        router: _ctx.router,
        mode: "horizontal"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem, index) => {
            return _renderSlot(_ctx.$slots, `${menuItem.name}_sub-menu-item`, {
              key: index,
              item: menuItem
            }, () => [
              (menuItem.link)
                ? (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: 0,
                    index: menuItem.name,
                    route: _ctx.currentRoute
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("a", {
                        href: menuItem.link,
                        target: "_blank"
                      }, _toDisplayString(_ctx.unwrapped(menuItem.label)), 9, _hoisted_1)
                    ]),
                    _: 2
                  }, 1032, ["index", "route"]))
                : (_openBlock(), _createBlock(_component_el_menu_item, {
                    key: 1,
                    class: _normalizeClass(_ctx.classNames(menuItem)),
                    index: menuItem.name,
                    route: _ctx.unwrapped(menuItem.route) ?? _ctx.currentRoute
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.unwrapped(menuItem.label)), 1)
                    ]),
                    _: 2
                  }, 1032, ["class", "index", "route"]))
            ])
          }), 128))
        ]),
        _: 3
      }, 8, ["default-active", "router"])
    ])
  ], 2))
}