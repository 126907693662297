import {MenuItem} from "@lib/common/menu/MenuItem"
import {LocationQueryRaw, RouteParams} from "vue-router"
import {ref} from "vue"
import {router} from "@intranet/plugin/router/Router"
import {WindowTarget} from "@lib/common/extension/Window"

type PdfMenuItemConstructorArguments<P> = {
    cb: (params: P, query: LocationQueryRaw) => Promise<Blob>,
    path: string,
    [MenuItem.uuid]?: string
}

export class PdfMenuItem<P extends RouteParams, Q extends LocationQueryRaw = LocationQueryRaw> extends MenuItem {

    cb: (params: P, query: LocationQueryRaw) => Promise<Blob>

    constructor({cb, path, [MenuItem.uuid]: uuid}: PdfMenuItemConstructorArguments<P>) {

        const pdfPath = path.startsWith("/pdf/") ? path : `/pdf/${path}`.replace("//", "/")
        const name = pdfPath
            .replace(/:/g, "")
            .replace(/\//g, "_")
        const datei = ref<Blob>()
        super({
            path: pdfPath,
            name: name,
            view: () => import("@intranet/view/pdf/PdfView.vue"),
            visible: false,
            props: () => ({
                datei: datei.value,
            }),
            hooks: {
                async beforeEnter(to, from, next) {
                    datei.value = await cb(to.params as P, to.query)
                    return next()
                },
            },
            //@ts-expect-error it exists
            [MenuItem.uuid]: uuid ?? undefined,
        })
        this.cb = cb
    }

    static open<P extends RouteParams, Q extends LocationQueryRaw>(
        item: PdfMenuItem<P, Q>,
        params: P,
        query?: Q,
        target: WindowTarget | string = WindowTarget.BLANK,
    ) {
        const routeData = router.resolve({
            name: item.routeRecordName,
            params: params,
            query: query ?? {},
        })
        window.open(routeData.href, target)
    }
}
