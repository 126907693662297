import {ViewModel} from "@lib/common/model/ViewModel"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"

export class MandantEditDialogViewModel extends ViewModel<MandantEditDialogViewModel> {

    mandant: Mandant

    constructor(mandant: Mandant) {
        super()
        this.mandant = this.registerShallowReactive(mandant)
    }

    get titel(): string {
        return `${this.mandant.kennung} // ${this.mandant.name}`
    }

    get organisation() {
        return this.mandant.organisation
    }

    set organisation(value) {
        this.mandant.organisation = value
    }

    istSammelrechnung(): boolean {
        return this.mandant.sammelrechnung !== null
    }

    istKeinDirektkontakt(): boolean {
        return this.mandant.keinDirektkontakt
    }
}