<template>
  <el-radio-group
          v-model="id"
          :data-cy="dataCy"
  >
    <el-radio-button
            v-for="option in options"
            :key="option.value"
            :label="option.value"
    >
      {{ option.label }}
    </el-radio-button>
  </el-radio-group>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue"
import {Id} from "@lib/common/model/Id"
import {Repository} from "@lib/common/repository/Repository"
import {Model} from "@lib/common/model/Model"

export default defineComponent({
    name: "RepositoryRadioGroup",
    props: {
        modelValue: {
            type: Object as PropType<Id<any>>,
            required: true,
        },
        repository: {
            type: Repository as PropType<Repository<any>>,
            required: true,
        },
        optionLabel: {
            type: Function as PropType<(m: Model<any>) => string>,
        },
        optionFilter: {
            type: Function as PropType<(m: Model<any>) => boolean>,
        },
        optionSort: {
            type: Function as PropType<(a: Model<any>, b: Model<any>) => 1 | 0 | -1>,
        },
        dataCy: String,
    },
    emits: ["update:modelValue", "change"],
    setup(props, context) {
        const id = computed<string | null>({
            get: () => {
                return props.modelValue.value
            },
            set: (value: null | string) => {
                const id = value ? new Id(value) : null
                context.emit("update:modelValue", id)
                emitChange(id)
            },
        })

        const options = computed(() => {
                return props.repository.cache
                    .map(it => it).value
                    .filter(it => {
                        return props.optionFilter ? props.optionFilter(it) : true
                    })
                    .sort((a, b) => props.optionSort ? props.optionSort(a, b) : 0)
                    .map(it => {
                        return {value: it.id.value, label: props.optionLabel ? props.optionLabel(it) : it.name}
                    })
                    .sort((a, b) => props.optionSort ? 0 : (a.label > b.label ? 1 : a.label == b.label ? 0 : -1))
            },
        )

        function emitChange(id: Id<any> | null) {
            const result = id?.value ? props.repository.cache.mapped(id, it => it).value : null
            context.emit("change", result)
        }

        return {
            id,
            options,
        }
    },
})
</script>

<style scoped>

</style>