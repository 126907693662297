<script lang="ts" setup>
import {computed} from "vue"
import {RouteRecordName, useRoute} from "vue-router"
import {subBarContent} from "@intranet/view/header/HeaderController"
import {auth} from "@lib/common/Authentication"
import {LogoutMenuItem} from "@lib/common/menu/LogoutMenuItem"
import {unwrapped} from "@lib/common/Functions"
import {SwitchButton} from "@element-plus/icons-vue"
import {MitarbeiterStatus} from "@generated/de/lohn24/model/mitarbeiter/MitarbeiterStatus"

const props = defineProps<{
    name: string | RouteRecordName,
    logoutMenuItem: LogoutMenuItem
}>()

const route = useRoute()

const account = computed(() => {
    return auth.mitarbeiter.value
})

async function updateArbeitstatus(status: MitarbeiterStatus): Promise<void> {
    const clone = account.value.clone()
    clone.mitarbeiterStatus = status
    await auth.updateMitarbeiter(clone)
}
</script>

<template>
  <div :class="{ 'menu-item-inactive': subBarContent !== props.name }">
    <el-row>
      <div class="el-col">
        <el-menu
                :default-active="account.arbeitsplatz"
                :ellipsis="false"
                mode="horizontal"
        >
          <el-menu-item
                  :index="MitarbeiterStatus.ONLINE"
                  @click="updateArbeitstatus(MitarbeiterStatus.ONLINE)"
          >
            <span>Online</span>
          </el-menu-item>
          <el-menu-item
                  :index="MitarbeiterStatus.ABWESEND"
                  @click="updateArbeitstatus(MitarbeiterStatus.ABWESEND)"
          >
            <span>Abwesend</span>
          </el-menu-item>
          <el-menu-item
                  :index="MitarbeiterStatus.PAUSE"
                  @click="updateArbeitstatus(MitarbeiterStatus.PAUSE)"
          >
            <span>Pause</span>
          </el-menu-item>
        </el-menu>
      </div>
      <el-divider direction="vertical" />
      <div class="el-col">
        <el-menu
                :default-active="route.name"
                :ellipsis="false"
                mode="horizontal"
                router
        >
          <el-menu-item :index="props.logoutMenuItem.name" :route="unwrapped(props.logoutMenuItem.route)">
            <el-icon>
              <switch-button />
            </el-icon>
            {{ props.logoutMenuItem.label }}
          </el-menu-item>
        </el-menu>
      </div>
    </el-row>
  </div>
</template>
