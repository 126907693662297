import {Logger} from "@lib/common/logger/Logger"

export const STORAGE_KEY = {
    ID_TYPE: "ID_TYPE",
    FILTER_OPTION_ABRECHNUNGSSYSTEM: "FILTER_OPTION_ABRECHNUNGSSYSTEM",
    FILTER_OPTION_ABRECHNUNGSSTATUS: "FILTER_OPTION_ABRECHNUNGSSTATUS",
    FILTER_OPTION_POOL: "FILTER_OPTION_POOL",
    FILTER_OPTION_ABSCHLUSSTAG: "FILTER_OPTION_ABSCHLUSSTAG",
    FILTER_OPTION_INAKTIVE_MANDANTEN: "FILTER_OPTION_INAKTIVE_MANDANTEN",
    PAGINATION_PAGE_INDEX: "PAGINATION_PAGE_INDEX",
    PAGINATION_PAGE_SIZE: "PAGINATION_PAGE_SIZE",
    PAGINATION_PAGE_OFFSET: "PAGINATION_PAGE_OFFSET",
    PAGINATION_PAGE_LIMIT: "PAGINATION_PAGE_LIMIT",
    FILTER_OPTION_ABGESCHLOSSENE_MANDANTEN: "FILTER_OPTION_ABGESCHLOSSENE_MANDANTEN",
    FILTER_OPTION_SUCHE: "FILTER_OPTION_SUCHE",
    FILTER_OPTION_FILTER_IGNORIEREN: "FILTER_OPTION_FILTER_IGNORIEREN",
    FILTER_OPTION_KOOPERATIONSPARTNER: "FILTER_OPTION_KOOPERATIONSPARTNER",
    FILTER_OPTION_SACHBEARBEITER: "FILTER_OPTION_SACHBEARBEITER",
    FILTER_OPTION_ABRECHNUNGSZEITRAUM: "FILTER_OPTION_ABRECHNUNGSZEITRAUM",
    FILTER_OPTION_LST: "FILTER_OPTION_LST",
    FILTER_OPTION_BEITRAGSNACHWEIS: "FILTER_OPTION_BEITRAGSNACHWEIS",
    UI_TABLE_BORDER: "UI_TABLE_BORDER",
    UI_TABLE_HEADER_ROW_CLASS_NAMES: "UI_TABLE_HEADER_ROW_CLASS_NAMES",
    UI_MULTISORT: "UI_MULTISORT",
} as const

export type StorageKey = keyof typeof STORAGE_KEY;

export class LocalStorage {
    private static _instance: LocalStorage

    private constructor() {
        if (localStorage.getItem(STORAGE_KEY.ID_TYPE) !== "UUID") {
            this.clear()
        }
    }

    clear() {
        new Logger(this).info("clears LocalStorage")
        localStorage.clear()
        localStorage.setItem(STORAGE_KEY.ID_TYPE, "UUID")
    }

    public static get Instance(): LocalStorage {
        return this._instance || (this._instance = new this())
    }

    public string(key: string, default_: string): string {
        return localStorage.getItem(key) || default_
    }

    public boolean(key: string, default_: boolean): boolean {
        const stored = localStorage.getItem(key)
        if (stored !== null && stored !== "null") return stored != "false"
        return default_
    }

    public setBoolean(key: string, value: boolean): void {
        this.set(key, value ? "true" : "false")
    }

    public number(key: string, default_: number): number {
        const stored = localStorage.getItem(key)
        if (stored !== "null" && stored !== null) return parseInt(stored)
        return default_
    }

    public set(key: string, value: string): void {
        localStorage.setItem(key, value)
    }

    stringify<T>(key: string, value: T): void {
        localStorage.setItem(key, JSON.stringify(value))
    }

    parse<T>(key: string, default_: T): T {
        const item = localStorage.getItem(key)
        try {
            if (item) return JSON.parse(item)
        } catch (e) {
            // clear key
            localStorage.removeItem(key)
            console.error(e, key, item, default_)
        }
        return default_
    }
}
