<template>
  <img :alt="alt" :src="src" width="16" />
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue"
import {Abrechnungssystem} from "@lib/model/abrechnung/Abrechnungssystem"

export default defineComponent({
    props: {
        abrechnungssystem: {
            type: String as PropType<Abrechnungssystem>,
            required: true,
        },
    },
    setup(prop) {
        const src = computed(() => `/icons/${prop.abrechnungssystem.toLowerCase()}.png`)
        const alt = computed(() => prop.abrechnungssystem)

        return {src, alt}
    },
})
</script>
