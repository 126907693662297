import {ViewModel} from "@lib/common/model/ViewModel"
import {Benachrichtigungsoption} from "@generated/de/lohn24/model/benachrichtigung/Benachrichtigungsoption"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {_props} from "@lib/common/_props"
import {capitalize} from "lodash"

export class MandantBenachrichtigungsoptionViewModel extends ViewModel<MandantBenachrichtigungsoptionViewModel> {

    static properties = _props<InstanceType<typeof this>>()

    static label = {
        kontaktAnrede: "Anrede",
        kontaktName: "Name",
        vorAbschluss: "Vor Abschluss",
        nachAbschluss: "Nach Abschluss",
        news: "News",
        rechnung: "Rechnung",
        email: "E-Mail",
    }

    benachrichtigungsoption: Benachrichtigungsoption
    kontakt: Kontakt

    constructor(benachrichtigungsoption: Benachrichtigungsoption, kontakt: Kontakt, public isKooperationspartner: boolean = false) {
        super()
        this.benachrichtigungsoption = this.registerShallowReactive(benachrichtigungsoption)
        this.kontakt = kontakt
    }

    get kontaktAnrede() {
        return capitalize(this.kontakt.anrede ?? undefined) ?? null
    }

    get kontaktName() {
        const vornameMitAnrede = `${capitalize(this.kontakt.anrede ?? undefined) ?? ""} ${this.kontakt.vorname ?? ""}`.trim()
        return `${vornameMitAnrede} ${this.kontakt.name ?? ""}`.trim()
    }

    get email() {
        return this.kontakt.email
    }

    get hasEmail() {
        return !!this.kontakt.email
    }

    get disabled(): boolean {
        return !this.hasEmail && this.allFalse
    }

    get allFalse() {
        return !(this.vorAbschluss || this.nachAbschluss || this.news || this.rechnung)
    }

    get vorAbschluss(): boolean {
        return this.benachrichtigungsoption.vorAbschluss
    }

    set vorAbschluss(value: boolean) {
        this.benachrichtigungsoption.vorAbschluss = value
    }

    get nachAbschluss(): boolean {
        return this.benachrichtigungsoption.nachAbschluss
    }

    set nachAbschluss(value: boolean) {
        this.benachrichtigungsoption.nachAbschluss = value
    }

    get news(): boolean {
        return this.benachrichtigungsoption.news
    }

    set news(value: boolean) {
        this.benachrichtigungsoption.news = value
    }

    get rechnung(): boolean {
        return this.benachrichtigungsoption.rechnung
    }

    set rechnung(value: boolean) {
        this.benachrichtigungsoption.rechnung = value
    }
}