import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Id} from "@lib/common/model/Id"
import {ContextMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import {Config} from "@lib/Config"
import IconPmc from "@lib/view/icons/IconPmc.vue"

export function pmcMandantContextMenuItemOption(mandant: Id<Mandant>) {
    return new ContextMenuItemOption(
        "Mandant im Payroll Management Center öffnen",
        async () => {
            const url = Config.PMC_BASE_URL + "/l24/mandant/" + mandant.value
            window.open(url, Config.PMC_WINDOW_TARGET)
        },
        IconPmc
    )
}