import axios, {AxiosInstance, AxiosRequestConfig} from "axios"
import {AxiosError, UnauthorizedError} from "@lib/common/axios/AxiosError"
import {ContentType} from "@lib/common/Enums"
import {onUnauthorized} from "@lib/common/Unauthorized"

export default class Api {
    private static _instance: Record<string, Api> = {}
    private axios: AxiosInstance

    private constructor(public baseUrl: string) {
        this.axios = axios.create({
            baseURL: baseUrl,
            headers: {
                "Content-type": ContentType.JSON,
            },
        })
        this.axios.interceptors.response.use((response) => {
            return response
        })
    }

    public static Instance(baseUrl: string): Api {
        return this._instance[baseUrl] || (this._instance[baseUrl] = new this(baseUrl))
    }

    private static error(e) {
        if (axios.isAxiosError(e)) {
            const error = AxiosError.error(e)
            if (error instanceof UnauthorizedError) {
                onUnauthorized()
            }
            throw error
        }
        throw e
    }

    // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
    async post<Response>(endpoint: string, payload: any, config?: AxiosRequestConfig): Promise<Response> {
        try {
            const response = await this.axios.post(endpoint, payload, config)
            return response.data
        } catch (e) {
            return Promise.reject(Api.error(e))
        }
    }

    async getBlob(endpoint: string, params: any = null): Promise<Blob> {
        const options: AxiosRequestConfig = {
            responseType: "blob",
        }
        try {
            const response = await this.axios.get(endpoint, {
                headers: {
                    Accept: ContentType.PLAIN_TEXT,
                },
                params,
                ...options,
            })
            return response.data
        } catch (e: any) {
            return Promise.reject(Api.error(e))
        }
    }

    async get<Response>(endpoint: string, params: any = null, type: ContentType = ContentType.JSON, options: AxiosRequestConfig = {}): Promise<Response> {
        try {
            const response = await this.axios.get(endpoint, {
                headers: {
                    Accept: type,
                },
                params,
                ...options,
            })
            return response.data
        } catch (e: any) {
            return Promise.reject(Api.error(e))
        }
    }

    // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
    async put<Response>(endpoint: string, payload: any, config?: AxiosRequestConfig): Promise<Response> {
        try {

            return (await this.axios.put(endpoint, payload, config)).data
        } catch (e) {
            return Promise.reject(Api.error(e))
        }
    }

    async delete<Response>(
        endpoint: string,
        params: any = null,
        type: ContentType = ContentType.JSON,
    ): Promise<Response> {
        try {
            const response = await this.axios.delete(endpoint, {
                headers: {
                    Accept: type,
                },
                params,
            })
            return response.data
        } catch (e: any) {
            return Promise.reject(Api.error(e))
        }
    }

    setBearerToken(token: string): void {
        this.axios.defaults.headers["Content-Type"] = ContentType.JSON
        this.axios.defaults.headers["Authorization"] = "Bearer ".concat(token)
    }

    removeBearerToken(): void {
        delete this.axios.defaults.headers["Authorization"]
    }
}
