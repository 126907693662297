import {Repository} from "@lib/common/repository/Repository"
import {PartitionedResultSet} from "@lib/common/pagination/PartitionedResultSet"
import {LeistungdatenDistinct} from "@lib/model/leistungsmerkmal/LeistungdatenDistinct"
import {LeistungsdatenListElement} from "@generated/de/lohn24/model/abschluss/leistungsdaten/LeistungsdatenListElement"
import {Leistungsmerkmal} from "@generated/de/lohn24/model/abschluss/leistungsdaten/Leistungsmerkmal"

export class LeistungsmerkmalRepository extends Repository<Leistungsmerkmal> {

    constructor() {
        super(Leistungsmerkmal, "leistungsmerkmal")
    }

    async abschlussLeistungsDaten(params: Record<string, any>): Promise<PartitionedResultSet<LeistungsdatenListElement, LeistungdatenDistinct>> {
        const result = (await this.api.get<Partial<PartitionedResultSet<LeistungsdatenListElement, LeistungdatenDistinct>>>(this.route("/leistungsdaten"), params))
        return new PartitionedResultSet(LeistungsdatenListElement, LeistungdatenDistinct, result)
    }
}