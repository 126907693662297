import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { TicketArtikel } from "@generated/de/lohn24/model/ticket/artikel/TicketArtikel"
import { Ticket } from "@generated/de/lohn24/model/ticket/Ticket"
import { File } from "@generated/dev/api/model/file/File"

export interface ITicketAnhang extends IModel {
	artikel: string
	file: string | null
	ticket: string
	size: number
	zammadId: number
	filename: string
}

export class TicketAnhang extends Model<TicketAnhang> {
	public artikel!: Id<TicketArtikel>
	public file!: Id<File> | null
	public ticket!: Id<Ticket>
	public size!: number
	public zammadId!: number
	public filename!: string

	static _types: Record<keyof ITicketAnhang, IRuntimeTypeInformation> = {
		id: { type: Id },
		artikel: { type: Id },
		file: { type: Id, nullable: true },
		ticket: { type: Id },
		size: { type: Number },
		zammadId: { type: Number },
		filename: { type: String },
	}

	static props: Record<keyof ITicketAnhang, string> = {
		id: "id",
		artikel: "artikel",
		file: "file",
		ticket: "ticket",
		size: "size",
		zammadId: "zammadId",
		filename: "filename",
	}

	constructor(_json: ITicketAnhang) {
		super(_json)
	}
}
