import {Repository} from "@lib/common/repository/Repository"
import {Model} from "@lib/common/model/Model"
import {computed, ComputedRef, reactive} from "vue"
import {BaseIdType, Id} from "@lib/common/model/Id"

export class RepositoryCache<T extends Repository<M>, M extends Model<M>> {

    initialized = false
    private cache: Record<BaseIdType, M> = {}
    private reactive: Record<BaseIdType, M> = reactive({})

    constructor(private repository: T) {
    }

    record<T>(block: (m: M) => T): ComputedRef<Record<BaseIdType, T>> {
        if (!this.initialized) {
            // Es wird absichtlich kein await auf die Funktion ausgeführt, um die Daten über die ComputedRef nachzuladen
            // noinspection JSIgnoredPromiseFromCall
            this.loadCache()
        }

        return computed<Record<BaseIdType, T>>(() => Object.keys(this.reactive)
            .reduce((acc, key) => {
                    acc[key] = block(this.reactive[key])
                    return acc
                },
                {} as Record<BaseIdType, T>),
        )
    }

    map<T>(block: (m: M) => T): ComputedRef<T[]> {
        if (!this.initialized) {
            // Es wird absichtlich kein await auf die Funktion ausgeführt, um die Daten über die ComputedRef nachzuladen
            // noinspection JSIgnoredPromiseFromCall
            this.loadCache()
        }
        return computed(() => Object.values(this.reactive).map(block))
    }

    mapped<T>(id: Id<M>, block: (m?: M) => T): ComputedRef<T> {
        if (!this.initialized) {
            // Es wird absichtlich kein await auf die Funktion ausgeführt, um die Daten über die ComputedRef nachzuladen
            // noinspection JSIgnoredPromiseFromCall
            this.loadCache()
        }
        return computed(() => block(this.reactive[id.value]))
    }

    async mappedAsync<T>(id: Id<M>, block: (m: M) => T): Promise<ComputedRef<T>> {
        if (!this.initialized) {
            await this.loadCache()
        }
        return computed(() => block(this.reactive[id.value]))
    }

    async getCache() {
        if (this.initialized) {
            return this.cache
        }
        await this.loadCache()
        return this.cache
    }

    async all(): Promise<M[]> {
        if (this.initialized)
            return Object.values(this.cache)
        return this.loadCache()
    }

    async get(id: Id<M>): Promise<M> {
        if (this.initialized) {
            const cached = this.cache[id.value]
            if (cached)
                return cached
            const reloaded = await this.repository.get(`${id}`)
            this.cache[reloaded.id.value] = reloaded
            return reloaded
        }
        await this.loadCache()
        return this.get(id)
    }

    async invalidate() {
        const ref = this.cache
        Object.keys(ref).forEach(key => delete ref[key])
        Object.keys(this.reactive).forEach(key => delete this.reactive[key])
        this.initialized = false
    }

    async invalidateId(id: BaseIdType) {
        if (this.initialized) {
            delete this.cache[id]
            delete this.reactive[id]

        }
        if (Object.keys(this.cache).length === 0)
            this.initialized = false
    }

    async update(model: M) {
        if (this.initialized) {
            this.cache[model.id.value] = model
            this.reactive[model.id.value] = model
        }
    }

    private async setCache(result: Promise<M[]>): Promise<void> {
        for (const item of await result) {
            this.cache[item.id.value] = item
            this.reactive[item.id.value] = item
        }
    }

    private async loadCache(): Promise<M[]> {
        this.initialized = true
        const result = this.repository.allUnlimited()
        await this.setCache(result)
        return result
    }
}