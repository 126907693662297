import {ViewModel} from "@lib/common/model/ViewModel"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {_props} from "@lib/common/_props"
import {BaseIdType, Id} from "@lib/common/model/Id"
import {AnschriftArt} from "@generated/de/lohn24/model/anschrift/AnschriftArt"
import {Staat} from "@generated/de/lohn24/model/staat/Staat"

export class AnschriftViewModel extends ViewModel<AnschriftViewModel> {

    static properties = _props<InstanceType<typeof this>>()

    placeholder = {
        anschriftArt: "Keine Anschriftart",
    }

    label = {
        unternehmen: "Unternehmen",
        person: "Natürliche Person",
        postfach: "Postfach",
        vorname: "Vorname",
        nachname: "Nachname",
        titel: "Titel",
        name: "Name",
        firma: "Firma",
        strasse: "Straße und Hausnummer",
        postleitzahl: "Postleitzahl",
        ort: "Ort",
        land: "Land",
        zusatz: "Zusatz",
        rueckgaengig: "Rückgängig",
    }

    anschrift: Anschrift

    constructor(anschrift: Anschrift) {
        super()
        this.anschrift = this.registerShallowReactive(anschrift)
    }

    get properties() {
        return AnschriftViewModel.properties
    }

    get id(): Nullable<BaseIdType> {
        return this.anschrift.id?.value
    }

    get art(): AnschriftArt {
        return this.anschrift.art
    }

    set art(value: AnschriftArt) {
        this.anschrift.art = value
        switch (value) {
            case AnschriftArt.UNTERNEHMEN:
                this.anschrift.name2 = null
                break
            default:
                break
        }
    }

    get titel(): Nullable<string> {
        return this.anschrift.titel
    }

    set titel(value: Nullable<string>) {
        this.anschrift.titel = this.getValueOrNull(value)
    }

    get anrede(): Nullable<string> {
        return this.anschrift.anrede
    }

    set anrede(value: Nullable<string>) {
        this.anschrift.anrede = this.getValueOrNull(value)
    }

    get vorname() {
        return this.anschrift.name2 || ""
    }

    set vorname(value: string) {
        this.anschrift.name2 = this.getValueOrNull(value)
    }

    get nachname() {
        return this.anschrift.name1
    }

    set nachname(value: string) {
        this.anschrift.name1 = value
    }

    get postfachName(): string {
        return this.anschrift.name1
    }

    set postfachName(value: string) {
        this.anschrift.name1 = value
    }

    get unternehmenName(): string {
        return this.anschrift.name1
    }

    set unternehmenName(value: string) {
        this.anschrift.name1 = value
    }

    get strasse() {
        return this.anschrift.strasse
    }

    set strasse(value: string) {
        this.anschrift.strasse = value
    }

    get postleitzahl() {
        return this.anschrift.postleitzahl
    }

    set postleitzahl(value: string) {
        this.anschrift.postleitzahl = value
    }

    get ort() {
        return this.anschrift.ort
    }

    set ort(value: string) {
        this.anschrift.ort = value
    }

    get zusatz() {
        return this.anschrift.zusatz
    }

    set zusatz(value: string) {
        this.anschrift.zusatz = value
    }

    get land() {
        return this.anschrift.land
    }

    set land(id: Id<Staat>) {
        this.anschrift.land = id
    }

    override get changed() {
        return super.changed || this.bearbeitungszustand.deleted
    }

    isEmpty() {
        return this.anschrift.name1 === "" &&
            this.anschrift.strasse === "" &&
            this.anschrift.ort === "" &&
            this.anschrift.postleitzahl === ""
    }

    isNotEmpty() {
        return !this.isEmpty()
    }

    namenZeile() {
        return `${this.anschrift.name2 || ""} ${this.anschrift.name1}`.trim()
    }

    strassenZeile() {
        return `${this.strasse}`.trim()
    }

    ortZeile() {
        return `${this.postleitzahl} ${this.ort}`.trim()
    }

    override equals(other: ViewModel<AnschriftViewModel>): boolean {
        if (other instanceof AnschriftViewModel) {
            return this.anschrift.id.value === other.anschrift.id.value
        }
        return false
    }
}