import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { BelegArt } from "@generated/de/lohn24/model/beleg/BelegArt"
import { Beleg } from "@generated/de/lohn24/model/beleg/Beleg"

export interface IBelegausgangAuswertungBeleg extends IModel {
	debitor: string
	kooperationspartner: string | null
	mandant: string | null
	belegart: BelegArt
	festgeschrieben: LocalDateTime
	summeNetto: number
	belegnummer: string
}

export class BelegausgangAuswertungBeleg extends Model<BelegausgangAuswertungBeleg> {
	public debitor!: Id<Debitor>
	public kooperationspartner!: Id<Kooperationspartner> | null
	public mandant!: Id<Mandant> | null
	public belegart!: BelegArt
	public festgeschrieben!: LocalDateTime
	public summeNetto!: number
	public belegnummer!: string

	static _types: Record<keyof IBelegausgangAuswertungBeleg, IRuntimeTypeInformation> = {
		id: { type: Id },
		debitor: { type: Id },
		kooperationspartner: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		belegart: { type: BelegArt },
		festgeschrieben: { type: LocalDateTime },
		summeNetto: { type: Number },
		belegnummer: { type: String },
	}

	static props: Record<keyof IBelegausgangAuswertungBeleg, string> = {
		id: "id",
		debitor: "debitor",
		kooperationspartner: "kooperationspartner",
		mandant: "mandant",
		belegart: "belegart",
		festgeschrieben: "festgeschrieben",
		summeNetto: "summeNetto",
		belegnummer: "belegnummer",
	}

	constructor(_json: IBelegausgangAuswertungBeleg) {
		super(_json)
	}
}
