import {App, AppContext, Component, ComponentPublicInstance, createVNode, PropType, render} from "vue"
import {ComponentProps} from "@lib/common/vue/types"
import {DialogController} from "@lib/common/controller/DialogController"
import {assertNotNull, replaceRouteParams} from "@lib/common/Functions"
import {useRoute, useRouter} from "vue-router"

export interface RenderOptions extends Record<string, unknown> {
    appendTo?: HTMLElement
}

export interface ComponentRendererProps<M = any, D extends DialogController = any> {
    onVanish?: (clearRoute?: boolean) => void
    __loader?: object
    done: (model: M | null) => void,
    controller: D
}

export abstract class ComponentRenderer<C extends Component<{ onVanish: () => void }>> {
    private static _context: Nullable<AppContext> = null
    private vm: ComponentPublicInstance<{
        onVanish: (clearRoute?: boolean) => void,
        controller: DialogController,
        __loader: Record<string, any>,
    }> | null = null

    protected constructor(
        private ComponentConstructor: C,
        private options: ComponentProps<C> & RenderOptions & {
            done?: (m: any) => void,
            closes?: () => Promise<boolean>
        },
    ) {
        this.vm = this.render(options.appendTo || document.body)
        ComponentRenderer.instance = this
    }

    static onVanishDefault() {
        // do nothing
    }

    public static get onVanishProp() {
        return {
            type: Function as PropType<(clearRoute?: boolean) => void>,
            default: () => this.onVanishDefault(),
        }
    }

    private static _instance: ComponentRenderer<any> | null

    private static get instance(): ComponentRenderer<any> {
        return this._instance!
    }

    private static set instance(value: ComponentRenderer<any>) {
        this.vanish(true)
        this._instance = value
    }

    static async activeInstanceCloses(): Promise<boolean> {
        const close = this.instance?.options.closes
        if (!close)
            return true

        return await close()
    }

    static activeInstanceIsOf<T extends ComponentRenderer<any>>(other: new (...args: any[]) => T) {
        return this.instance instanceof other
    }

    static install(app: App): App {
        ComponentRenderer._context = app._context
        app.config.globalProperties.$dialog = ComponentRenderer
        return app
    }

    static vanish(keep?: boolean) {
        this._instance?.vm?.onVanish && this._instance.vm?.onVanish(!keep)
    }

    static loaderDefault() {
        const route = useRoute()
        const router = useRouter()
        return {
            route,
            router,
        }
    }

    public static ComponentRendererProps<M>() {
        return {
            onVanish: this.onVanishProp,
            __loader: {
                type: Object,
                default() {
                    return ComponentRenderer.loaderDefault()
                },
            },
            done: Function as PropType<(model: M | null) => void>,
        }
    }

    buildProps(container: HTMLElement): ComponentProps<C> | { onVanish: () => void } | RenderOptions {
        return {
            ...this.options,
            onVanish: async (clearRoute?: boolean) => {
                render(null, container)
                ComponentRenderer._instance = null
                if (this.options?.done) {
                    this.options.done(null)
                }
                if (this.vm) {
                    this.vm.controller.visible.value = false

                    if (clearRoute) {
                        const {route, router} = this.vm.__loader
                        await replaceRouteParams(router, route)
                    }
                    this.vm = null
                }
            },
        }
    }

    render(appendTo: HTMLElement, appContext: Nullable<AppContext> = ComponentRenderer._context) {
        const container = document.createElement("div")

        const dialogVNode = this.createVNode(
            this.ComponentConstructor,
            this.buildProps(container),
        )

        dialogVNode.appContext = appContext

        render(dialogVNode, container)

        appendTo.insertBefore(assertNotNull(container.firstChild), appendTo.firstChild)
        // appendTo.appendChild(container.firstChild!)

        return assertNotNull(dialogVNode.component).proxy as ComponentPublicInstance<{
            onVanish: () => void,
            controller: DialogController,
            __loader: Record<string, any>
        }>
    }

    private createVNode(
        constructor: C,
        props: ComponentProps<C> | RenderOptions | { onVanish: () => void },
    ) {
        return createVNode(
            constructor,
            props,
        )
    }
}