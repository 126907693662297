<template>
  <div :class="{'overlay': overlay}"
       :style="`height: ${overlay ? screenHeight - 1 : 0}px`"
       @click="doClose"
       @mousedown="doClose"
  >
    <div ref="root"
         :style="`position: absolute; left: ${mouse.x - ((left || openLeft) && overlay ? rect.width :0)}px; top: ${mouse.y - (openTop ? rect.height : 0)}px;`"
         class="contextmenu"
         role="menu"
         @click.stop
         @mousedown.stop
    >
      <ul>
        <slot
                :active="activeChild" :dx="-((left || openLeft) && overlay ? rect.width :0)"
                :enabled="true"
                :left="(left || openLeft)"
                :onClose="() =>doClose()"
                @update:active="activeChild=$event"
        />
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, ExtractPropTypes, ref, watch} from "vue"
import {dialogProps} from "element-plus"

// doClose ist nicht zugreifbar im script setup pattern

export const contextMenuProps = {
    ...dialogProps,
    x: Number,
    y: Number,
    onVanish: Function,
    overlay: {
        type: Boolean,
        default: true,
    },
    left: Boolean,
}

export type ContextMenuOptions = ExtractPropTypes<typeof contextMenuProps>

export default defineComponent({
    name: "ContextMenu",
    props: contextMenuProps,
    setup(props) {
        const root = ref<HTMLElement>()
        const activeChild = ref()
        const mouse = computed(() => ({
            x: props.x,
            y: props.y,
        }))
        const openLeft = ref<boolean>(false)
        const openTop = ref<boolean>(false)
        const rect = ref<DOMRect>(new DOMRect(0, 0, 0, 0))
        const screenHeight = ref<number>(0)

        watch(root, (div: HTMLElement | undefined) => {
            if (!div) return
            rect.value = div.getBoundingClientRect()
            const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
            const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
            screenHeight.value = Math.max(document.body.scrollHeight, document.body.offsetHeight,
                document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight)
            openLeft.value = vw < rect.value.right
            openTop.value = vh < rect.value.bottom
        })

        function doClose() {
            if (props.onVanish)
                props.onVanish()
        }

        return {
            root,
            rect,
            openLeft,
            openTop,
            doClose,
            activeChild,
            mouse,
            screenHeight,
        }
    },
})
</script>

<style lang="scss">
.overlay {
  position: absolute;
  z-index: 9997;
  width: 100%;
  //background-color: #67c23a;
}

.contextmenu {
  --height: 30px;
  --font-size: 12px;
  --context-menu-item-padding: 20px;

  background: white;
  z-index: 1;
  padding: 5px 0;
  border-radius: 5px;
  box-shadow: var(--el-box-shadow);

  ul {
    padding: 0;
    margin: 0;

    li {
      font-size: var(--font-size);
      text-align: left;
      line-height: var(--height);
      padding: 0 var(--context-menu-item-padding);
      list-style-type: none;
      height: var(--height);
      width: 200px;
      cursor: pointer;

      span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
      }

      &:hover {
        background-color: var(--el-menu-hover-bg-color);
      }

      &.inactive:hover {
        background-color: var(--el-disabled-bg-color);
      }
    }
  }

  .el-menu-item, .el-sub-menu__title {
    height: var(--height);
    width: 200px;
  }

}
</style>