import {Model} from "@lib/common/model/Model"
import {Constructable, RecordOf} from "@lib/types"
import {Id} from "@lib/common/model/Id"
import {AxiosRequestConfig} from "axios"
import Api from "@lib/common/axios/Api"
import {Config} from "@lib/Config"
import {
    RepositoryDispatchMap,
    RepositoryEvent,
    RepositoryEventMap,
    RepositoryEventTarget,
    RepostioryEvents,
} from "@lib/common/repository/RepositoryEventTarget"
import {assertNotNull, from} from "@lib/common/Functions"

export class RepositoryRelation<L extends Model<L>, R extends Model<R>> {

    protected replaceDoubleRegex = new RegExp("//", "g")
    private eventTarget = new RepositoryEventTarget<R>()

    constructor(
        protected path: string,
        protected type: Constructable<R>,
        protected currentEndpoint: string,
        protected api: Api = Api.Instance(Config.API_BASE_URL),
    ) {
    }

    addEventListener<E extends RepostioryEvents>(type: E, cb: RepositoryEventMap<R>[E]) {
        return this.eventTarget.addEventListener.bind(this.eventTarget)(type, cb)
    }

    removeEventListener<E extends RepostioryEvents>(type: E, cb: RepositoryEventMap<R>[E]) {
        return this.eventTarget.removeEventListener.bind(this.eventTarget)(type, cb)
    }

    dispatchEvent<E extends RepostioryEvents>(type: E, data: RepositoryDispatchMap<R>[E]) {
        return this.eventTarget.dispatchEvent.bind(this.eventTarget)(new RepositoryEvent(type, data))
    }

    all(parent: Id<L>, params: Record<string, any> = {}): Promise<R[]> {
        return this.get(parent, null, params)
    }

    async first(parent: Id<L>, params: Record<string, any> = {}): Promise<R> {
        return (await this.all(parent, params)).first()
    }

    async firstOrNull(parent: Id<L>, params: Record<string, any> = {}): Promise<R | null> {
        return (await this.all(parent, params)).firstOrNull()
    }

    async create(parent: Id<L>, model: R): Promise<R> {
        const result = await this.post(parent, model)
        this.dispatchEvent("create", result)
        return result
    }

    async delete(parent: Id<L>, target: R): Promise<void> {
        await this.api.delete(this.route(parent, target.id.toString()))
        this.dispatchEvent("delete", target.id)
    }

    async update(parent: Id<L>, model: R): Promise<R> {
        const result = await this.put(parent, model)
        this.dispatchEvent("update", result)
        return result
    }

    protected fullPath() {
        return (this.path + "/{id}/" + this.currentEndpoint).replace(this.replaceDoubleRegex, "/")
    }

    protected route(parent: Id<L>, path: string | number | null = null): string {
        const result = `${this.pathReplace([parent])}` + (path === null ? `` : `/${path}`)
        return result.replace(this.replaceDoubleRegex, "/")
    }

    protected deserialize(result: RecordOf<R> | RecordOf<R>[]) {
        if (Array.isArray(result)) {
            return result.map(it => this.deserialize(it))
        }
        return from(this.type, result)
    }

    protected async post(parent: Id<L>, model: R, path: string | null = null, config?: AxiosRequestConfig): Promise<R> {
        const partial = await this.api.post<RecordOf<R>>(this.route(parent, path), model.toJSON(), config)
        const result = this.deserialize(partial)
        const id = assertNotNull(partial.id)
        Id.map[model.id.value] = id
        result.id = new Id(id)
        return result
    }

    protected async put(parent: Id<L>, model: R, path: string | null = null, config?: AxiosRequestConfig): Promise<R> {
        const resolvingPath = (path ? `${path}/` : "") + `${model.id.value}`
        const partial = await this.api.put<RecordOf<R>>(this.route(parent, resolvingPath), model.toJSON(), config)
        model.id = new Id(assertNotNull(partial.id))
        return this.deserialize(partial)
    }

    protected async get(parent: Id<L>, path: string | null = null, params?: Record<string, any>): Promise<R[]> {
        const partials = await this.api.get<RecordOf<R> | RecordOf<R>[]>(this.route(parent, path), params)
        return this.deserialize(Array.isArray(partials) ? partials : [partials])
    }

    private pathReplace(args: Id<any>[], placeholder = "{id}"): string {
        const regexp = new RegExp(placeholder)
        let path = this.fullPath()
        args.forEach(id => path = path.replace(regexp, `${id.value}`))
        return path
    }
}