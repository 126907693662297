<template>
  <el-header id="header" ref="header" :class="headerClass">
    <el-row :key="menuItems.map(it => it.name).join('.')" justify="end">
      <el-col class="main-bar">
        <app-logo></app-logo>
        <el-row justify="end">
          <el-menu
                  :default-active="subBarContent"
                  :ellipsis="false"
                  mode="horizontal"
                  router
          >
            <slot
                    v-for="(menuItem) in menuItems"
                    :key="menuItem.name"
                    :badge="isRef(menuItem.badge) ? menuItem.badge.value : menuItem.badge"
                    :item="menuItem"
                    :name="menuItem.name"
            >
              <main-menu-item
                      :badge="isRef(menuItem.badge) ? menuItem.badge.value : menuItem.badge"
                      :current-active="subBarContent"
                      :shortcuts="shortcuts"
                      :name="menuItem.name"
                      :uuid="menuItem.routeRecordName"
                      :route="hasAccessToView(menuItem) ? (unwrapped(menuItem.route) || route) : route"
                      @activate="activate(menuItem.children)($event)"
              >
                <template #title="{badge}">
                  <slot
                          :name="`${menuItem.name.toString().toLowerCase()}_title`"
                          :title="unwrapped(menuItem.label)"
                  >
                    <badged-name :label="unwrapped(menuItem.label) ?? unwrapped(menuItem.name).toString() "
                                 :value="unwrapped(badge)" type="menu"
                    />
                  </slot>
                </template>
              </main-menu-item>
            </slot>
          </el-menu>
        </el-row>
      </el-col>
      <div class="el-col sub-bar">
        <div class="menu-container">
          <slot
                  v-for="(menuItem) in menuItems"
                  :key="menuItem.name"
                  :item="menuItem"
                  :name="`${menuItem.name.toString().toLowerCase()}_submenu`"
          >
            <sub-menu-item
                    v-if="menuItem.children && menuItem"
                    :active="subBarContent === menuItem.name"
                    :menuItems="menuItem.children"
                    :router="!!unwrapped(menuItem.route)"
            />
          </slot>
        </div>
      </div>
    </el-row>
  </el-header>
</template>

<script lang="ts">
import {computed, defineComponent, isRef, PropType, watch} from "vue"
import {useRoute} from "vue-router"
import {
    activate,
    header,
    initializeKeyboardEventlistener,
    initializeSubBarContent,
    subBarContent,
    shortcuts
} from "@intranet/view/header/HeaderController"
import BadgedName from "@lib/view/BadgedLabel.vue"
import {direction} from "@lib/common/vue/plugin/ScrollDirection"
import {auth} from "@lib/common/Authentication"
import SubMenuItem from "@lib/view/menuitem/SubMenuItem.vue"
import MainMenuItem from "@lib/view/menuitem/MainMenuItem.vue"
import {MenuItem} from "@lib/common/menu/MenuItem"
import {unwrapped} from "@lib/common/Functions"
import {menu} from "@intranet/plugin/router/Router"

export default defineComponent({
    name: "L24Header",
    methods: {unwrapped},
    props: {
        menuItems: {
            type: Array as PropType<MenuItem[]>,
            required: true,
        },
    },
    components: {
        BadgedName,
        SubMenuItem,
        MainMenuItem,
    },
    setup(props) {

        const headerClass = computed(() => ({
            "header-hidden": direction.value === "down",
        }))

        const statusColorClass = computed(() => {
            const mitarbeiter = auth.mitarbeiter.value
            return {
                "mitarbeiter-status": true,
                "mitarbeiter-status-pause": mitarbeiter.mitarbeiterStatus === "PAUSE",
                "mitarbeiter-status-abwesend": mitarbeiter.mitarbeiterStatus === "ABWESEND",
                "mitarbeiter-status-online": mitarbeiter.mitarbeiterStatus === "ONLINE",
            }
        })

        const route = useRoute()

        watch(route, r => {
            initializeSubBarContent(menu.menuItems, r)
            initializeKeyboardEventlistener(menu.menuItems)
        })

        watch(route, () => {
            Array.from(header.value?.$el.querySelectorAll("li.el-menu-item"))
                .forEach((it) => (it as HTMLElement).blur())
        })

        function hasAccessToView(item: MenuItem): boolean {
            return item["view-rights"] ? auth.hasAccess(item["view-rights"]) : true
        }

        return {
            isRef,
            headerClass,
            statusColorClass,
            subBarContent,
            shortcuts,
            activate,
            route,
            header,
            hasAccessToView,
        }
    },
})
</script>

<style lang="scss">
#header.el-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2010; // Dialog (2020) > Overlay (2011) > Header (2010) > Loading Overlay
  margin: 0;
  padding: 0;
  width: 100%;
  clear-after: both;
  background: white;
  outline: 1px solid #e6e6e6;
  transition: 0.1s all ease-out;
  transform: translate3d(0, 0, 0);
  height: var(--l24-dyn-header-height);
}

#header {
  .header--hidden {
    transform: translate3d(0, -100%, 0);
  }

  .el-menu {
    margin: 0;
    padding: 0;
  }

  .el-row {
    height: var(--l24-header-height);
  }

  .el-menu {
    border-bottom: none;
  }

  .menu-avatar {
    padding-left: 5px;
  }

  .el-menu-item a {
    text-decoration: none;
    display: block;
  }

  .el-menu--horizontal > .el-menu-item.is-active,
  .el-menu--horizontal > .el-menu-item.is-active:hover {
    color: var(--l24-color-brand) !important;
    border-bottom: 2px solid var(--l24-color-brand);
  }

  // override --el-color-primary
  .el-menu > .el-menu-item > a,
  .el-menu > .el-menu-item > a:hover {
    color: var(--el-text-color);
  }

  .logo {
    float: left;
    height: calc(var(--l24-header-height) / 2);
    padding: calc(var(--l24-header-height) / 4);
  }

  .main-bar {
    outline: rgb(230, 230, 230) solid 1px;
    z-index: 1002;

    .el-menu .el-menu-item {
      height: var(--l24-header-height);
    }
  }
}
</style>
