<script lang="ts" setup>

import IconClose from "@lib/view/icons/IconClose.vue"
import {computed, onErrorCaptured, useAttrs} from "vue"
import {useDialogOverflow} from "@lib/view/dialog/CustomDialogFunctions"
import {useEscapeKeydown} from "element-plus"
import {showError} from "@intranet/view/error/ErrorMenuItem"

defineOptions({
    inheritAttrs: false,
})

const attrs = useAttrs()
const elDialogAttrs = computed<Record<string, any>>(() => {
    const cloned = {...props, ...(attrs)} as Record<string, any>
    delete cloned.intent
    return cloned
})
const emits = defineEmits<{
    "update:modelValue": [visible: boolean]
    "click:outside": [event: PointerEvent]
}>()
const props = withDefaults(
    defineProps<{
        intent: string
        title: string
        close: () => void
        modelValue: boolean
        gutter?: string | number
        top?: string | number
        width?: string | number
        closeOnModal?: boolean
        closeOnEscape?: boolean
    }>(),
    {
        gutter: 30,
        top: "7vh",
        width: "80%",
        closeOnModal: true,
        closeOnEscape: true,
    },
)

function wrapperClass(context = ""): string {
    const rtn = [`dialog__${context}`]
    if (props.intent) rtn.push(`${props.intent}-dialog__${context}`)
    return rtn.join(" ")
}

function clickOutside(event: PointerEvent) {
    if (props.closeOnModal) {
        props.close()
    } else {
        emits("click:outside", event)
    }
}

useDialogOverflow()
useEscapeKeydown(() => {
    if (props.closeOnEscape)
        props.close()
})

onErrorCaptured((error, instance) => {
    showError(error)
})

</script>

<template>
  <teleport to="body">
    <div
            :class="wrapperClass('wrapper')"
            @mousedown.left.passive="clickOutside"
    >
      <el-dialog
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              :data-cy="props.intent + '-dialog'"
              :model-value="props.modelValue"
              :show-close="false"
              :top="props.top"
              :width="props.width"
              v-bind="elDialogAttrs"
              @update:modelValue="emits('update:modelValue', $event)"
              @mousedown.stop
              @contextmenu.stop
      >
        <template #header>
          <el-col :class="wrapperClass('header')">
            <slot name="header">
              <el-col :span="24">
                <el-row justify="space-between">
                  <div class="el-col">
                    <slot name="header-left">
                      <span class="el-dialog__title">{{ title }}</span>
                    </slot>
                  </div>
                  <div class="el-col">
                    <el-button-group class="ml-4">
                      <slot :btnClassName="'dialog-headline-btn'" name="header-right">

                      </slot>
                      <slot name="header-close">
                        <el-button
                                class="dialog-headline-btn dialog-headline-btn__close"
                                text
                                @click.stop="() => props.close()"
                        >
                          <icon-close size="medium" />
                        </el-button>
                      </slot>
                    </el-button-group>
                  </div>
                </el-row>
                <el-row>
                  <div class="el-col">
                    <slot name="sub-header-left"></slot>
                  </div>
                  <div class="el-col">
                    <slot name="sub-header-right"></slot>
                  </div>
                </el-row>
              </el-col>
            </slot>
          </el-col>
        </template>
        <template #default>
          <div :class="wrapperClass('content')">
            <slot :gutter="gutter" name="default">
              <slot :gutter="gutter" name="content">
                <el-row :gutter="gutter">
                  <el-col :span="12" class="content-left">
                    <slot name="content-left"></slot>
                  </el-col>
                  <el-col :span="12" class="content-right">
                    <slot name="content-right"></slot>
                  </el-col>
                </el-row>
                <slot name="content-append"></slot>
              </slot>
            </slot>
          </div>
        </template>
        <template #footer>
          <div :class="wrapperClass('footer')">
            <slot name="footer"></slot>
          </div>
        </template>
      </el-dialog>
    </div>
  </teleport>
</template>

<style lang="scss" scoped>

</style>