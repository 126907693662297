import { IRuntimeTypeInformation, Serializable } from "@lib/index"
import { BriefabsenderArt } from "@generated/de/lohn24/model/brief/BriefabsenderArt"

export interface IBriefabsenderDaten {
	briefabsenderArt: BriefabsenderArt
	logoSkalierung: number | null
	email: string
	fusszeile: string | null
	geschaeftsfuehrung: string
	internet: string
	logo: string | null
	name: string
	ort: string
	postleitzahl: string
	registergericht: string
	registernummer: string
	ruecksendeangabe: string
	strasse: string
	telefax: string
	telefon: string
	umsatzsteuerid: string
}

export class BriefabsenderDaten extends Serializable<BriefabsenderDaten> {
	public briefabsenderArt!: BriefabsenderArt
	public logoSkalierung!: number | null
	public email!: string
	public fusszeile!: string | null
	public geschaeftsfuehrung!: string
	public internet!: string
	public logo!: string | null
	public name!: string
	public ort!: string
	public postleitzahl!: string
	public registergericht!: string
	public registernummer!: string
	public ruecksendeangabe!: string
	public strasse!: string
	public telefax!: string
	public telefon!: string
	public umsatzsteuerid!: string

	static _types: Record<keyof IBriefabsenderDaten, IRuntimeTypeInformation> = {
		briefabsenderArt: { type: BriefabsenderArt },
		logoSkalierung: { type: Number, nullable: true },
		email: { type: String },
		fusszeile: { type: String, nullable: true },
		geschaeftsfuehrung: { type: String },
		internet: { type: String },
		logo: { type: String, nullable: true },
		name: { type: String },
		ort: { type: String },
		postleitzahl: { type: String },
		registergericht: { type: String },
		registernummer: { type: String },
		ruecksendeangabe: { type: String },
		strasse: { type: String },
		telefax: { type: String },
		telefon: { type: String },
		umsatzsteuerid: { type: String },
	}

	static props: Record<keyof IBriefabsenderDaten, string> = {
		briefabsenderArt: "briefabsenderArt",
		logoSkalierung: "logoSkalierung",
		email: "email",
		fusszeile: "fusszeile",
		geschaeftsfuehrung: "geschaeftsfuehrung",
		internet: "internet",
		logo: "logo",
		name: "name",
		ort: "ort",
		postleitzahl: "postleitzahl",
		registergericht: "registergericht",
		registernummer: "registernummer",
		ruecksendeangabe: "ruecksendeangabe",
		strasse: "strasse",
		telefax: "telefax",
		telefon: "telefon",
		umsatzsteuerid: "umsatzsteuerid",
	}

	constructor(_json: IBriefabsenderDaten) {
		super(_json)
	}
}
