<script setup lang="ts" generic="T extends Exclude<SearchResult<any>,'model'>">
import {computed, Directive, nextTick, ref, shallowRef} from "vue"
import LetterCircle from "@lib/view/LetterCircle.vue"
import {SearchResult, searchResultColor} from "@lib/view/search/SearchResult"
import {raf} from "@lib/common/Functions"
import {ElScrollbar} from "element-plus"

const props = withDefaults(
    defineProps<{
        modelValue: string,
        types?: string[]
        type?: string | null,
        results?: T[],
        loading?: boolean,
        showType?: boolean,
        height?: number
    }>(),
    {
        results: () => [],
        types: () => [],
        loading: false,
        type: null,
        showType: false,
        height: 500,
    },
)

const list = true

const emits = defineEmits<{
    "update:modelValue": [suchtext: string],
    "update:loading": [loading: boolean],
    "update:type": [type: string | null],
    "beforeClose": [void]
    "open": [item: T]
}>()

const suchtext = computed({
    get: () => props.modelValue,
    set: (value: string) => {
        emits("update:loading", true)
        emits("update:modelValue", value)
        focusItemIndex.value = 0
    },
})
const dialog = ref()
const input = ref()
const rows = ref()
const scrollContainer = ref<InstanceType<typeof ElScrollbar>>()
const focusItemIndex = shallowRef(0)
const ergebnistext = computed(() => {
    if (suchtext.value.isEmpty())
        return ""

    if (suchtext.value.isNotEmpty() && props.loading)
        return "Suche..."

    if (props.results.length === 0)
        return "Nichts gefunden"

    if (props.results.length === 1)
        return "1 Ergebnis"

    return `${props.results.length} Ergebnisse`
})

const vMatch: Directive = {
    created: (el, binding) => {
        markMatched(el, binding.value)
    },
    updated: (el, binding) => {
        markMatched(el, binding.value)
    },
}

function markMatched(span: HTMLSpanElement, value: string) {
    let textContent = span.textContent
    if (textContent == null)
        return

    value.split(" ").forEach(chunk => {
        const regexp = new RegExp(chunk, "i")
        const match = textContent!.match(regexp)
        if (match == null)
            return

        textContent = textContent!.replace(match[0], `<mark>${match[0]}</mark>`)
    })
    span.innerHTML = textContent
}

function focusInput() {
    input.value?.focus()
}

const enterIndex = ref<number | null>(null)

function enterUp() {
    emits("open", props.results[enterIndex.value!])
    enterIndex.value = null
}

const keys = ["ArrowDown", "ArrowUp", "Enter", "PageUp", "PageDown"]
let scrollBlock = "center"

let waitForDraw = false

async function keydown(event: KeyboardEvent) {
    const key = event.key
    if (keys.includes(key)) {
        event.preventDefault()
        event.stopPropagation()
        if (waitForDraw)
            return
        waitForDraw = true

        const container = scrollContainer.value
        if (!container)
            return

        const containerRect = (container.$el as HTMLElement).getBoundingClientRect()
        const containerTop = containerRect.top
        const containerBottom = containerRect.bottom
        const visible = Object.values(rows.value).filter(it => {
            const rect = ((it as any).$el as HTMLElement).getBoundingClientRect()
            return rect.bottom <= containerBottom && rect.top >= containerTop
        })
        const isFirst = rows.value[focusItemIndex.value] === visible.firstOrNull()
        const isLast = rows.value[focusItemIndex.value] === visible.last()
        const visibleCount = visible.length

        switch (key) {
            case "ArrowDown":
                focusItemIndex.value = Math.min(focusItemIndex.value + 1, props.results.length - 1)
                scrollBlock = "end"
                break
            case "ArrowUp":
                focusItemIndex.value = Math.max(focusItemIndex.value - 1, 0)
                scrollBlock = "start"
                break
            case "Enter":
                enterIndex.value = focusItemIndex.value
                break
            case "PageUp":
                focusItemIndex.value = Math.max(focusItemIndex.value - visibleCount, 0)
                scrollBlock = "start"
                break
            case "PageDown":
                focusItemIndex.value = Math.min(focusItemIndex.value + visibleCount, props.results.length - 1)
                scrollBlock = "end"
                break
        }

        if (enterIndex.value !== null)
            enterIndex.value = focusItemIndex.value

        const isVisible = visible.includes(rows.value[focusItemIndex.value])
        const rowCount = Object.values(rows.value).length
        if ((rowCount <= focusItemIndex.value) && (props.results.length > rowCount)) {
            capped.value += 10
            await nextTick()
        }
        if ((isFirst && scrollBlock === "start") || (isLast && scrollBlock === "end") || !isVisible)
            rows.value[focusItemIndex.value].$el.scrollIntoView({
                behaviour: "smooth",
                block: scrollBlock,
                inline: "start",
            })

        await raf()
        waitForDraw = false
    }

}

function rowClassName(row: T, index: number): string {
    const classNames: string[] = ["result-item"]
    if (index === focusItemIndex.value)
        classNames.push("focus")
    if (index === enterIndex.value)
        classNames.push("fire")
    return classNames.join(" ")
}

function nextType(e: KeyboardEvent) {
    if (props.types.isEmpty())
        return
    e.preventDefault()
    e.stopPropagation()
    const completeTypes = [...props.types, null]
    const index = (completeTypes.indexOf(props.type) + 1) % completeTypes.length
    emits("update:type", completeTypes[index])
}

const capped = shallowRef(10)
const cappedResults = computed(() => props.results.slice(0, capped.value))

function loadMore(context: { scrollLeft: number, scrollTop: number }) {
    const wrapDiv = scrollContainer.value?.wrapRef
    if (!wrapDiv)
        return

    const wrapperHeight = wrapDiv.getBoundingClientRect().height
    const containerHeight = wrapDiv.firstElementChild!.getBoundingClientRect().height
    if (wrapDiv && (containerHeight - wrapperHeight - 20) < context.scrollTop) {
        capped.value += 10
    }
}

</script>

<template>
  <teleport to="body">
      <el-dialog
              class="such-dialog"
              ref="dialog"
              width="640px"
              :model-value="true" title="Suche"
              @opened="focusInput()"
              :show-close="false"
              :close-on-click-modal="true"
              :close-on-press-escape="true"
              :before-close="() => emits('beforeClose')"
              @keydown="keydown"
              @keyup.enter="enterUp"
      >
        <template #header>
          <el-col class="header_wrapper">
            <el-input
                    v-model="suchtext"
                    clearable
                    name="suche"
                    placeholder="Suche..."
                    prefix-icon="search"
                    ref="input"
                    @keydown.tab="nextType"
            >
              <template #suffix v-if="suchtext.length > 0">{{ ergebnistext }}</template>
            </el-input>
            <el-form-item v-if="types.isNotEmpty()" size="small">
              <el-radio-group :model-value="props.type" @update:modelValue="emits('update:type', $event)">
                <el-radio-button v-for="type in types" :key="type" :label="type">{{ type }}</el-radio-button>
                <el-radio-button :label="null">Alle</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </template>
        <template #default>
          <el-row v-if="props.loading">
            <slot name="loading">
            </slot>
          </el-row>
          <el-collapse-transition>
            <el-scrollbar
                    v-show="!props.loading && props.results.length > 0"
                    :max-height="props.results.length > 0 ? height : 0"
                    class="suche-scrollbar"
                    ref="scrollContainer"
                    always
                    @scroll="loadMore($event)"
            >
              <el-row
                      v-for="(result, index) of cappedResults"
                      ref="rows"
                      :key="index"
                      :class="rowClassName(result, index)"
                      @mousedown.left.stop="focusItemIndex = index"
                      @mouseup.left.stop="focusItemIndex === index"

              >
                <slot :name="result.topic" :data="result">
                  <el-col :span="2" class="thumb">
                    <el-tooltip :content="result.topic" placement="left">
                      <letter-circle
                              :radius="13"
                              :background="searchResultColor[result.topic]"
                              :letters="result.name.slice(0,2)"
                      />
                    </el-tooltip>
                  </el-col>
                  <el-col :span="props.showType? 16 : 22">
                    <el-row>
                      <el-col class="result-item__headline">
                        <el-text size="large" v-match="suchtext">
                          {{ result.name }}
                        </el-text>
                      </el-col>
                      <el-col v-for="description in result.descriptions" :key="description">
                        <el-row class="result-item__description">
                          <el-col :span="7">
                            <el-text size="default" type="info">
                              {{ description.label }}
                            </el-text>
                          </el-col>
                          <el-col :span="15">
                            <el-text size="default" type="info" truncated v-match="suchtext">
                              {{ description.value }}
                            </el-text>
                          </el-col>
                          <el-col :span="2">
                            <slot name="action" :result="result">
                              <slot :name="`action-${result.topic}`"
                                    :result="result"
                                    :label="description.label"
                                    :value="description.value"
                              >
                              </slot>
                            </slot>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6" class="result-type" v-if="props.showType">
                    <el-text size="small" type="info">
                      {{ result.topic }}
                    </el-text>
                  </el-col>
                </slot>
              </el-row>
            </el-scrollbar>
          </el-collapse-transition>
        </template>
      </el-dialog>
  </teleport>
</template>

<style lang="scss">
.such-dialog {

  .v-enter-active,
  .v-leave-active {
    -webkit-transition: height 2s;
    -moz-transition: height 2s;
    -ms-transition: height 2s;
    -o-transition: height 2s;
    transition: height 2s ease;
  }

  .v-enter-from,
  .v-leave-to {
    height: 0;
  }

  .el-dialog__header {
    border: none;
    padding-bottom: 4px;
  }

  .header_wrapper {
    padding: 0;

    .el-form-item {
      margin-bottom: 0;
    }

    .el-input__wrapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .el-radio-button__inner {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

  }

  .el-dialog__body {
    padding: 0 0;

  }

  .suche-scrollbar {
    .el-scrollbar_view {
      scroll-padding: 0;
    }
  }

  .result-item {
    padding: 2px 10px;
    margin: 4px 0;
    width: calc(100% - 6px);
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));

    &:hover {
      --ck-focus-ring: solid 1px var(--el-input-focus-border-color, var(--el-color-primary));
      box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset;

    }

    &.focus {
      background-color: var(--el-menu-hover-bg-color, var(--el-color-primary));
    }

    &.fire {
      outline-color: var(--l24-color-brand);
    }

    .thumb {
      justify-content: center;
      align-content: center;
    }

    .result-item__headline {
      padding-bottom: 6px;
    }

    .result-item__description {

      .el-col {
        display: flex;
        align-items: center;

        .el-text {
          padding: 2px 2px;
        }
      }
    }

    .result-type {
      padding-right: 5px;
      align-content: center;
      text-align: end;
    }
  }

}

</style>