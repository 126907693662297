import { IRuntimeTypeInformation, Serializable } from "@lib/index"
import { IRight, Right } from "@generated/dev/api/model/right/Right"

export interface IEffectiveRight {
	delete: boolean
	read: boolean
	write: boolean
	right: IRight
}

export class EffectiveRight extends Serializable<EffectiveRight> {
	public delete!: boolean
	public read!: boolean
	public write!: boolean
	public right!: Right

	static _types: Record<keyof IEffectiveRight, IRuntimeTypeInformation> = {
		delete: { type: Boolean },
		read: { type: Boolean },
		write: { type: Boolean },
		right: { type: Right },
	}

	static props: Record<keyof IEffectiveRight, string> = {
		delete: "delete",
		read: "read",
		write: "write",
		right: "right",
	}

	constructor(_json: IEffectiveRight) {
		super(_json)
	}
}
