import {FontSize} from "@lib/common/Enums"

export interface ValidateableProps {
    label?: string
    trigger?: ValidateableTrigger
    prop?: string
    size?: FontSize
    error?: string
    warning?: string
    immediate?: boolean
    hideErrorText?: boolean
    noMargin?: boolean
}

export interface ValidateableInputProps {
    modelValue: string | number
    type?: InputType
}

export enum InputType {
    "Input" = "Input",
    "InputNumber" = "input-number",
    "select" = "select",
}

export enum ValidateableTrigger {
    "blur" = "blur",
    "change" = "change",
}