import {IModel} from "@lib/common/model/Model"
import {Arbeitnehmer} from "@generated/de/lohn24/model/arbeitnehmer/Arbeitnehmer"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {from, textToRGB} from "@lib/common/Functions"
import {Organisation} from "@generated/de/lohn24/model/organisation/Organisation"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Ticket} from "@generated/de/lohn24/model/ticket/Ticket"
import {Formatter} from "@lib/common/Formatter"

export interface ISearchResult<T extends SearchResultTopic> {
    topic: T,
    model: Record<string, IModel>
}

export enum SearchResultTopic {
    "Arbeitnehmer" = "Arbeitnehmer",
    "Mandant" = "Mandant",
    "Kontakt" = "Kontakt",
    "KooperationspartnerKontakt" = "KooperationspartnerKontakt",
    "Debitor" = "Debitor",
    "Kooperationspartner" = "Kooperationspartner",
    "Anschrift" = "Anschrift",
    "Ticket" = "Ticket",
}

export const searchResultColor = {
    [SearchResultTopic.Arbeitnehmer]: textToRGB(SearchResultTopic.Arbeitnehmer),
    [SearchResultTopic.Mandant]: textToRGB(SearchResultTopic.Mandant),
    [SearchResultTopic.Kontakt]: textToRGB(SearchResultTopic.Kontakt),
    [SearchResultTopic.Debitor]: textToRGB(SearchResultTopic.Debitor),
    [SearchResultTopic.Anschrift]: textToRGB(SearchResultTopic.Anschrift),
    [SearchResultTopic.Kooperationspartner]: textToRGB(SearchResultTopic.Kooperationspartner),
    [SearchResultTopic.Ticket]: textToRGB(SearchResultTopic.Ticket),
}

export type TicketSearchResult = { ticket: Ticket, mandant: Mandant }
export type AnschriftSearchResult =
    { anschrift: Anschrift, mandant: Mandant, debitor: Debitor | null, kooperationspartner: Kooperationspartner | null }
    | {
    anschrift: Anschrift,
    mandant: Mandant | null,
    debitor: Debitor,
    kooperationspartner: Kooperationspartner | null
}
    | {
    anschrift: Anschrift,
    mandant: Mandant | null,
    debitor: Debitor | null,
    kooperationspartner: Kooperationspartner
}
export type ArbeitnehmerSearchResult = { arbeitnehmer: Arbeitnehmer, mandant: Mandant }
export type MandantSearchResult = { mandant: Mandant }
export type KontaktSearchResult = { kontakt: Kontakt, organisation: Organisation, kooperationspartner: null } | {
    kontakt: Kontakt,
    kooperationspartner: Kooperationspartner
    organisation: null
}
export type DebitorSearchResult = { debitor: Debitor }
export type KooperationspartnerSearchResult = { Kooperationspartner: Kooperationspartner }

export type ResultModel<T extends SearchResultTopic> = T extends SearchResultTopic.Arbeitnehmer ? ArbeitnehmerSearchResult : never
    | T extends SearchResultTopic.Mandant ? MandantSearchResult : never
    | T extends SearchResultTopic.Kontakt ? KontaktSearchResult : never
    | T extends SearchResultTopic.Debitor ? DebitorSearchResult : never
    | T extends SearchResultTopic.Kooperationspartner ? KooperationspartnerSearchResult : never
    | T extends SearchResultTopic.Anschrift ? AnschriftSearchResult : never
    | T extends SearchResultTopic.Ticket ? TicketSearchResult : never

export class SearchResult<T extends SearchResultTopic> {
    readonly descriptions: { label: string, value: string | null }[]

    constructor(
        readonly topic: T,
        readonly name: string,
        public model: ResultModel<T>,
        ...descriptions: { label: string, value: string | null }[]
    ) {
        this.descriptions = descriptions
    }

    static parse<T extends SearchResultTopic>(result: ISearchResult<T>): SearchResult<SearchResultTopic> {
        switch (result.topic) {
            case SearchResultTopic.Mandant: {
                const mandant = from(Mandant, result.model.mandant)
                return new SearchResult(
                    SearchResultTopic.Mandant,
                    mandant.name,
                    {mandant: mandant},
                    {label: "Kennung", value: mandant.kennung},
                    {label: "Steuernummer", value: mandant.steuernummer},
                    {label: "Betriebsnummer", value: mandant.betriebsnummer},
                )
            }
            case SearchResultTopic.Arbeitnehmer: {
                const arbeitnehmer = from(Arbeitnehmer, result.model.arbeitnehmer)
                const mandant = from(Mandant, result.model.mandant)
                return new SearchResult(
                    SearchResultTopic.Arbeitnehmer,
                    `${arbeitnehmer.vorname} ${arbeitnehmer.nachname}`,
                    {arbeitnehmer, mandant},
                    {label: "Mandant", value: `${mandant.kennung} // ${mandant.name}`},
                    {label: "Pers.Nr.:", value: arbeitnehmer.arbeitnehmernummer},
                    {label: "Sv.Nr.:", value: arbeitnehmer.sozialversicherungsnummer},
                    {label: "Steuer-IdNr.:", value: arbeitnehmer.steueridentifikationsnummer},
                )
            }
            case SearchResultTopic.Kontakt: {
                const kontakt = from(Kontakt, result.model.kontakt)
                if (result.model.organisation) {
                    const organisation = from(Organisation, result.model.organisation)
                    return new SearchResult(
                        SearchResultTopic.Kontakt,
                        ((kontakt.vorname ?? "") + " " + (kontakt.name ?? "")).trim(),
                        {kontakt, organisation, kooperationspartner: null},
                        {label: "Organisation", value: organisation.name},
                        {label: "E-Mail", value: kontakt.email},
                        {label: "Telefon", value: kontakt.telefon},
                    )
                }

                const kooperationspartner = from(Kooperationspartner, result.model.kooperationspartner)
                return new SearchResult(
                    SearchResultTopic.Kontakt,
                    ((kontakt.vorname ?? "") + " " + (kontakt.name ?? "")).trim(),
                    {kontakt, kooperationspartner, organisation: null},
                    {
                        label: "Kooperationspartner",
                        value: `${kooperationspartner.kennung} // ${kooperationspartner.name}`,
                    },
                    {label: "E-Mail", value: kontakt.email},
                    {label: "Telefon", value: kontakt.telefon},
                )
            }
            case SearchResultTopic.Anschrift: {
                const anschrift = from(Anschrift, result.model.anschrift)
                if (result.model.mandant) {
                    const mandant = from(Mandant, result.model.mandant)
                    return new SearchResult(
                        SearchResultTopic.Anschrift,
                        `${(anschrift.name2 ?? "") + " " + (anschrift.name1)}`.trim(),
                        {anschrift, mandant, debitor: null, kooperationspartner: null},
                        {label: "Mandant", value: mandant.kennung + " // " + mandant.name},
                        {label: "Straße", value: anschrift.strasse},
                        {label: "Ort", value: anschrift.postleitzahl + " " + anschrift.ort},
                    )
                }

                if (result.model.kooperationspartner) {
                    const kooperationspartner = from(Kooperationspartner, result.model.kooperationspartner)
                    return new SearchResult(
                        SearchResultTopic.Anschrift,
                        `${(anschrift.name2 ?? "") + " " + (anschrift.name1)}`.trim(),
                        {anschrift, kooperationspartner, debitor: null, mandant: null},
                        {
                            label: "Kooperationspartner",
                            value: kooperationspartner.kennung + " // " + kooperationspartner.name,
                        },
                        {label: "Straße", value: anschrift.strasse},
                        {label: "Ort", value: anschrift.postleitzahl + " " + anschrift.ort},
                    )
                }

                if (result.model.debitor) {
                    const debitor = from(Debitor, result.model.debitor)
                    return new SearchResult(
                        SearchResultTopic.Anschrift,
                        `${(anschrift.name2 ?? "") + " " + (anschrift.name1)}`.trim(),
                        {anschrift, debitor, kooperationspartner: null, mandant: null},
                        {
                            label: "Debitor",
                            value: debitor.konto + " // " + debitor.name,
                        },
                        {label: "Straße", value: anschrift.strasse},
                        {label: "Ort", value: anschrift.postleitzahl + " " + anschrift.ort},
                    )
                }
                break
            }
            case SearchResultTopic.Ticket: {
                const ticket = from(Ticket, result.model.ticket)
                const mandant = from(Mandant, result.model.mandant)
                return new SearchResult(
                    SearchResultTopic.Ticket,
                    ticket.betreff,
                    {ticket, mandant},
                    {label: "Nummer", value: `${ticket.nummer}`},
                    {label: "Mandant", value: mandant.kennung + " " + mandant.name},
                    {label: "Erstellt", value: Formatter.zeit.datumMitZeit(ticket.erstellt)},
                    {label: "Status", value: ticket.status},
                )
            }

            case SearchResultTopic.Debitor: {
                const debitor = from(Debitor, result.model.debitor)
                return new SearchResult(
                    SearchResultTopic.Debitor,
                    debitor.name,
                    {debitor},
                    {label: "Konto", value: debitor.konto},
                    {label: "IBAN", value: debitor.iban},
                )
            }
        }
        throw new Error(`Unknown type: ${result.topic}`)
    }
}