import { IRuntimeTypeInformation, Id, Serializable } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IBelegausgangListSachbearbeiterAbschlussMandant {
	mandant: string | null
	sachbearbeiter: string | null
}

export class BelegausgangListSachbearbeiterAbschlussMandant extends Serializable<BelegausgangListSachbearbeiterAbschlussMandant> {
	public mandant!: Id<Mandant> | null
	public sachbearbeiter!: Id<Account> | null

	static _types: Record<keyof IBelegausgangListSachbearbeiterAbschlussMandant, IRuntimeTypeInformation> = {
		mandant: { type: Id, nullable: true },
		sachbearbeiter: { type: Id, nullable: true },
	}

	static props: Record<keyof IBelegausgangListSachbearbeiterAbschlussMandant, string> = {
		mandant: "mandant",
		sachbearbeiter: "sachbearbeiter",
	}

	constructor(_json: IBelegausgangListSachbearbeiterAbschlussMandant) {
		super(_json)
	}
}
