<script lang="ts" setup>

import {ContextMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import {computed, shallowRef} from "vue"

const props = withDefaults(
    defineProps<{
        options: () => ContextMenuItemOption
        buttonClass?: string
        showAfter?: number
        tooltip?: boolean
    }>(),
    {
        buttonClass: "",
        showAfter: 1000,
        tooltip: true,
    },
)
const loading = shallowRef(false)
const success = shallowRef<boolean | null>(null)
const failure = shallowRef<boolean | null>(null)
const timeout = shallowRef<number | null>(null)
const classNames = computed(() => {
    const classNames: string[] = ["button-transition", props.buttonClass]
    if (failure.value)
        classNames.push("el-button--danger")
    if (success.value)
        classNames.push("el-button--success")
    return classNames.join(" ")
})

async function executeAction(event: MouseEvent) {
    loading.value = true
    success.value = failure.value = null
    clearTimeout(timeout as any)
    try {
        await props.options().action(event)
        success.value = true
    } catch (e) {
        failure.value = true
    } finally {
        loading.value = false
        timeout.value = setTimeout(() => {
            success.value = failure.value = null
        }, 1800) as any as number
    }
}

</script>

<template>
  <el-tooltip :content="options().label" :disabled="!tooltip" :show-after="props.showAfter" placement="top">
    <el-button :class="classNames"
               text
               v-bind="$attrs"
               :type="(failure && 'danger') || (success && 'success')"
               @click.stop="(event)=> executeAction(event)"
    >
      <component :is="options().icon" />
    </el-button>
  </el-tooltip>
</template>

<style lang="scss" scoped>
.button-transition {
  transition: color 0.6s;

  &:hover.el-button--success {
    color: var(--el-color-success);
  }

  &:hover.el-button--danger {
    color: var(--el-color-danger);
  }
}

//.button-transition:after {
//  content: "";
//  background: var(--l24-color-brand);
//  display: block;
//  position: absolute;
//  padding-top: 10%;
//  padding-left: 10%;
//  border-radius: 100%;
//  //margin-left: -20px!important;
//  //margin-top: -120%;
//  opacity: 0;
//  transition: all 0.8s
//}
//
//.button-transition:active:after {
//  padding: 0;
//  margin: 0;
//  opacity: 1;
//  transition: 0s
//}
</style>