<script lang="ts" setup>

import {NewsType} from "@generated/de/lohn24/model/news/NewsType"
import {NewsBuilderViewModel} from "@intranet/view/news/builder/NewsBuilderViewModel"
import {FontSize} from "@lib/common/Enums"
import ValidateableInput from "@lib/view/validateable/ValidateableInput.vue"
import {ElUpload, UploadUserFile} from "element-plus"
import RichTextEditor from "@lib/common/richtexteditor/RichTextEditor.vue"
import SimpleRichTextToolbar from "@lib/common/richtexteditor/SimpleRichTextToolbar.vue"
import Validateable from "@lib/view/validateable/Validateable.vue"
import {ref} from "vue"
import {uploadFileToBase64} from "@lib/common/Functions"

const props = defineProps<{
    news: {
        type: NewsType,
        title: string,
        teaser: string
    }
    size: FontSize
}>()

const updload = ref<HTMLSpanElement>()

async function onUploadChange(value: UploadUserFile, image: (src: string, title: string, alt: string) => void) {
    image(await uploadFileToBase64(value), value.name, "Das Bild konnte nicht geladen werden")
}
</script>

<template>
  <validateable :error="news.type ? undefined:'Art ist ein Pflichtfeld'" :size="size" hide-error-text>
    <el-select v-model="news.type" placeholder="Artikel / Arbeitsanweisung">
      <el-option :value="NewsType.Artikel" />
      <el-option :value="NewsType.Arbeitsanweisung" />
    </el-select>
  </validateable>
  <validateable-input
          v-model="news.title"
          :no-margin="false"
          :placeholder="NewsBuilderViewModel.label.title"
          :size="props.size"
  />
  <validateable
          :no-margin="true"
          :size="props.size"
  >
    <rich-text-editor
            v-model="news.teaser"
    >
      <template #toolbar="{commands, state}">
        <SimpleRichTextToolbar
                :commands="commands"
                :size="FontSize.small"
                :state="state"
        >
          <template #append>
            <el-button
                    icon="fas-image"
                    text
                    @click="updload?.click()"
            >
              <el-upload
                      :auto-upload="false"
                      :show-file-list="false"
                      accept="image/png, image/jpeg"
                      multiple
                      style="display:none"
                      @change="onUploadChange($event, commands.image)"
              >
                <template #trigger>
                  <span ref="updload"></span>
                </template>
              </el-upload>
            </el-button>
          </template>
        </SimpleRichTextToolbar>
      </template>
    </rich-text-editor>
  </validateable>
</template>

<style lang="scss" scoped>

</style>