<template>
  <div :class="{ 'menu-item-inactive': !active }">
    <slot :items="menuItems" name="default">
      <el-menu
              ref="menu"
              :default-active="currentRoute.name"
              :ellipsis="false"
              :router="router"
              mode="horizontal"
      >
        <slot
                v-for="(menuItem, index) in menuItems"
                :key="index"
                :item="menuItem"
                :name="`${menuItem.name}_sub-menu-item`"
        >
          <el-menu-item
                  v-if="menuItem.link"
                  :index="menuItem.name"
                  :route="currentRoute"
          >
            <a :href="menuItem.link" target="_blank">{{ unwrapped(menuItem.label) }}</a>
          </el-menu-item>
          <el-menu-item
                  v-else
                  :class="classNames(menuItem)"
                  :index="menuItem.name"
                  :route="unwrapped(menuItem.route) ?? currentRoute"
          >
            {{ unwrapped(menuItem.label) }}
          </el-menu-item>
        </slot>
      </el-menu>
    </slot>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType, ref} from "vue"
import {useRoute} from "vue-router"
import {ElMenu} from "element-plus"
import {MenuItem} from "@lib/common/menu/MenuItem"
import {unwrapped} from "@lib/common/Functions"

export default defineComponent({
    methods: {unwrapped},
    props: {
        active: {
            type: Boolean,
            required: true,
        },
        menuItems: {
            type: Array as PropType<Array<MenuItem>>,
            default: () => [],
        },
        router: Boolean,
    },
    setup() {
        const sub_active = ref<string>("")
        const currentRoute = useRoute()
        const menu = ref<InstanceType<typeof ElMenu>>()

        function classNames(menuItem: MenuItem): string {
            const names: string[] = []
            if (menuItem.name == currentRoute.name || menuItem?.children?.find(it => it.name == currentRoute.name))
                names.push("is-active")
            return names.join(" ")
        }

        return {
            sub_active,
            currentRoute,
            menu,
            classNames,
        }
    },
})
</script>

<style lang="scss">
.sub-bar {
  background-color: var(--el-fill-color-blank);
  // box-shadow: var(--el-box-shadow-light);
  height: calc(var(--l24-dyn-header-height) - var(--l24-header-height));
  transition: var(--l24-dyn-header-transistion);

  .menu-container {
    .menu-item-inactive {
      position: absolute;
      right: 0;
      top: var(--l24-header-height);
      transform: translate3d(100%, 0, 0);
      display: none;

      .el-menu {
        height: 0;
        transform: translate3d(100%, 0, 0);
      }

      .el-menu-item {
        height: 0;
        transition: none;
      }
    }

    .el-menu {
      right: 0;
      height: calc(var(--l24-dyn-header-height) - var(--l24-header-height));
      transform: translate3d(0, 0, 0);
      transition: var(--l24-dyn-header-transistion);
    }

    .el-menu-item {
      height: calc(var(--l24-dyn-header-height) - var(--l24-header-height));
    }
  }

  .el-divider .el-divider--vertical {
    transition: var(--l24-dyn-header-transition);
    height: calc(var(--l24-dyn-header-height) - var(--l24-header-height));
  }
}
</style>
