import { IRuntimeTypeInformation, LocalDate, Serializable } from "@lib/index"

export interface IFileReportElement {
	associated: boolean
	processed: boolean
	datum: LocalDate
	count: number
	origin: string
	type: string
}

export class FileReportElement extends Serializable<FileReportElement> {
	public associated!: boolean
	public processed!: boolean
	public datum!: LocalDate
	public count!: number
	public origin!: string
	public type!: string

	static _types: Record<keyof IFileReportElement, IRuntimeTypeInformation> = {
		associated: { type: Boolean },
		processed: { type: Boolean },
		datum: { type: LocalDate },
		count: { type: Number },
		origin: { type: String },
		type: { type: String },
	}

	static props: Record<keyof IFileReportElement, string> = {
		associated: "associated",
		processed: "processed",
		datum: "datum",
		count: "count",
		origin: "origin",
		type: "type",
	}

	constructor(_json: IFileReportElement) {
		super(_json)
	}
}
