import Api from "@lib/common/axios/Api"
import RepositoryConfig, {repositoryConfig} from "@lib/common/repository/RepositoryConfig"
import {from} from "@lib/common/Functions"
import {
    AddisonExportMetrics,
    IAddisonExportMetrics,
} from "@generated/de/lohn24/endpoint/AddisonExportMetricsQuery/AddisonExportMetrics"

export class AddisonExportRepository {
    api: Api

    constructor(config: RepositoryConfig = repositoryConfig) {
        this.api = Api.Instance(config.baseURL)
    }

    async metrics(): Promise<AddisonExportMetrics> {
        const result = await this.api.get<IAddisonExportMetrics>("addisonexport/metrics")
        return from(AddisonExportMetrics, result)
    }
}