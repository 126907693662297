import { IRuntimeTypeInformation, Id, LocalDateTime, Serializable } from "@lib/index"
import { Ticketgruppe } from "@generated/de/lohn24/model/ticketgruppe/Ticketgruppe"
import { ITicketTag, TicketTag } from "@generated/de/lohn24/model/ticket/tag/TicketTag"
import { TicketStatus } from "@generated/de/lohn24/model/ticket/TicketStatus"
import { ITicketArtikel, TicketArtikel } from "@generated/de/lohn24/model/ticket/artikel/TicketArtikel"
import { ITicketAnhang, TicketAnhang } from "@generated/de/lohn24/model/ticket/anhang/TicketAnhang"
import { Ticket } from "@generated/de/lohn24/model/ticket/Ticket"
import { Organisation } from "@generated/de/lohn24/model/organisation/Organisation"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Kontakt } from "@generated/de/lohn24/model/kontakt/Kontakt"
import { Account } from "@generated/dev/api/model/account/Account"

export interface ITicketListElement {
	id: string
	besitzer: string | null
	kontakt: string | null
	kooperationspartner: string | null
	mandant: string | null
	organisation: string | null
	ticketgruppe: string | null
	artikel: Array<ITicketArtikel>
	attachments: Array<ITicketAnhang>
	tags: Array<ITicketTag>
	erstellt: LocalDateTime
	geloescht: LocalDateTime | null
	zugewiesen: LocalDateTime | null
	nummer: number
	zammadId: number
	absender: string
	betreff: string
	status: TicketStatus
}

export class TicketListElement extends Serializable<TicketListElement> {
	public id!: Id<Ticket>
	public besitzer!: Id<Account> | null
	public kontakt!: Id<Kontakt> | null
	public kooperationspartner!: Id<Kooperationspartner> | null
	public mandant!: Id<Mandant> | null
	public organisation!: Id<Organisation> | null
	public ticketgruppe!: Id<Ticketgruppe> | null
	public artikel!: Array<TicketArtikel>
	public attachments!: Array<TicketAnhang>
	public tags!: Array<TicketTag>
	public erstellt!: LocalDateTime
	public geloescht!: LocalDateTime | null
	public zugewiesen!: LocalDateTime | null
	public nummer!: number
	public zammadId!: number
	public absender!: string
	public betreff!: string
	public status!: TicketStatus

	static _types: Record<keyof ITicketListElement, IRuntimeTypeInformation> = {
		id: { type: Id },
		besitzer: { type: Id, nullable: true },
		kontakt: { type: Id, nullable: true },
		kooperationspartner: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		organisation: { type: Id, nullable: true },
		ticketgruppe: { type: Id, nullable: true },
		artikel: { type: [TicketArtikel] },
		attachments: { type: [TicketAnhang] },
		tags: { type: [TicketTag] },
		erstellt: { type: LocalDateTime },
		geloescht: { type: LocalDateTime, nullable: true },
		zugewiesen: { type: LocalDateTime, nullable: true },
		nummer: { type: Number },
		zammadId: { type: Number },
		absender: { type: String },
		betreff: { type: String },
		status: { type: TicketStatus },
	}

	static props: Record<keyof ITicketListElement, string> = {
		id: "id",
		besitzer: "besitzer",
		kontakt: "kontakt",
		kooperationspartner: "kooperationspartner",
		mandant: "mandant",
		organisation: "organisation",
		ticketgruppe: "ticketgruppe",
		artikel: "artikel",
		attachments: "attachments",
		tags: "tags",
		erstellt: "erstellt",
		geloescht: "geloescht",
		zugewiesen: "zugewiesen",
		nummer: "nummer",
		zammadId: "zammadId",
		absender: "absender",
		betreff: "betreff",
		status: "status",
	}

	constructor(_json: ITicketListElement) {
		super(_json)
	}
}
