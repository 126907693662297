import { IRuntimeTypeInformation, Id, LocalDateTime, LocalMonth, Serializable } from "@lib/index"
import { Versand } from "@generated/de/lohn24/model/versand/Versand"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { File } from "@generated/dev/api/model/file/File"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { BereitstellungOptionZahlungenArt } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungenArt"
import { BereitstellungOptionZahlungen } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionZahlungen"
import { BereitstellungOptionRechnung } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionRechnung"
import { BereitstellungOptionLohn } from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionLohn"
import { IAnschrift, Anschrift } from "@generated/de/lohn24/model/anschrift/Anschrift"

export interface IVersandListElement {
	id: string
	debitor: string | null
	file: string | null
	firma: string | null
	kooperationspartner: string | null
	mandant: string | null
	anschrift: IAnschrift
	bereitstellungOptionenLohn: Array<BereitstellungOptionLohn>
	bereitstellungOptionenRechnung: Array<BereitstellungOptionRechnung>
	bereitstellungOptionenZahlungen: Array<BereitstellungOptionZahlungen>
	bereitstellungOptionenZahlungenArt: Array<BereitstellungOptionZahlungenArt>
	hinzugefuegt: LocalDateTime
	zeitraum: string
	mandantKennung: string | null
	dateiName: string | null
	debitorKonto: string | null
	debitorName: string | null
	kooperationspartnerKennung: string | null
	kooperationspartnerName: string | null
	mandantName: string | null
}

export class VersandListElement extends Serializable<VersandListElement> {
	public id!: Id<Versand>
	public debitor!: Id<Debitor> | null
	public file!: Id<File> | null
	public firma!: Id<Firma> | null
	public kooperationspartner!: Id<Kooperationspartner> | null
	public mandant!: Id<Mandant> | null
	public anschrift!: Anschrift
	public bereitstellungOptionenLohn!: Array<BereitstellungOptionLohn>
	public bereitstellungOptionenRechnung!: Array<BereitstellungOptionRechnung>
	public bereitstellungOptionenZahlungen!: Array<BereitstellungOptionZahlungen>
	public bereitstellungOptionenZahlungenArt!: Array<BereitstellungOptionZahlungenArt>
	public hinzugefuegt!: LocalDateTime
	public zeitraum!: LocalMonth
	public mandantKennung!: string | null
	public dateiName!: string | null
	public debitorKonto!: string | null
	public debitorName!: string | null
	public kooperationspartnerKennung!: string | null
	public kooperationspartnerName!: string | null
	public mandantName!: string | null

	static _types: Record<keyof IVersandListElement, IRuntimeTypeInformation> = {
		id: { type: Id },
		debitor: { type: Id, nullable: true },
		file: { type: Id, nullable: true },
		firma: { type: Id, nullable: true },
		kooperationspartner: { type: Id, nullable: true },
		mandant: { type: Id, nullable: true },
		anschrift: { type: Anschrift },
		bereitstellungOptionenLohn: { type: [BereitstellungOptionLohn] },
		bereitstellungOptionenRechnung: { type: [BereitstellungOptionRechnung] },
		bereitstellungOptionenZahlungen: { type: [BereitstellungOptionZahlungen] },
		bereitstellungOptionenZahlungenArt: { type: [BereitstellungOptionZahlungenArt] },
		hinzugefuegt: { type: LocalDateTime },
		zeitraum: { type: LocalMonth },
		mandantKennung: { type: String, nullable: true },
		dateiName: { type: String, nullable: true },
		debitorKonto: { type: String, nullable: true },
		debitorName: { type: String, nullable: true },
		kooperationspartnerKennung: { type: String, nullable: true },
		kooperationspartnerName: { type: String, nullable: true },
		mandantName: { type: String, nullable: true },
	}

	static props: Record<keyof IVersandListElement, string> = {
		id: "id",
		debitor: "debitor",
		file: "file",
		firma: "firma",
		kooperationspartner: "kooperationspartner",
		mandant: "mandant",
		anschrift: "anschrift",
		bereitstellungOptionenLohn: "bereitstellungOptionenLohn",
		bereitstellungOptionenRechnung: "bereitstellungOptionenRechnung",
		bereitstellungOptionenZahlungen: "bereitstellungOptionenZahlungen",
		bereitstellungOptionenZahlungenArt: "bereitstellungOptionenZahlungenArt",
		hinzugefuegt: "hinzugefuegt",
		zeitraum: "zeitraum",
		mandantKennung: "mandantKennung",
		dateiName: "dateiName",
		debitorKonto: "debitorKonto",
		debitorName: "debitorName",
		kooperationspartnerKennung: "kooperationspartnerKennung",
		kooperationspartnerName: "kooperationspartnerName",
		mandantName: "mandantName",
	}

	constructor(_json: IVersandListElement) {
		super(_json)
	}
}
