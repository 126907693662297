import {ViewModel} from "@lib/common/model/ViewModel"
import {News} from "@generated/de/lohn24/model/news/News"
import {DateTime} from "luxon"
import {Resource} from "@lib/model/Resource"
import {Account} from "@generated/dev/api/model/account/Account"
import {Id} from "@lib/common/model/Id"

export class NewsViewModel extends ViewModel<NewsViewModel> {
    news: News

    constructor(news: News) {
        super()
        this.news = this.registerShallowReactive(news)
    }

    get id() {
        return this.news.id.value
    }

    get titel() {
        return this.news.title
    }

    set titel(value: string) {
        this.news.title = value
    }

    get teaser() {
        return this.news.teaser
    }

    set teaser(value: string) {
        this.news.teaser = value
    }

    set author(value: Id<Account>) {
        if (value)
            this.news.author = value
    }

    get authorName() {
        return Resource.account.accountName(this.news.author)
    }

    get released() {
        return this.news.released
    }

    set released(value: Nullable<DateTime>) {
        this.news.released = value
    }

    get created() {
        return this.news.created
    }

    get veroeffentlichtVon() {
        return `Veröffentlicht von ${this.authorName} am ${this.released?.toLocaleString({
            day: "2-digit",
            month: "short",
            year: "numeric",
        })}`
    }
}