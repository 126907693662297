import {Repository} from "@lib/common/repository/Repository"
import {File} from "@generated/dev/api/model/file/File"
import {LohnabrechnungExport} from "@generated/de/lohn24/model/lohnabrechnung/export/LohnabrechnungExport"

export class LohnabrechnungExportRepository extends Repository<LohnabrechnungExport> {

    constructor() {
        super(LohnabrechnungExport, "lohnabrechnungexport")
    }

    files = this.buildRelation(File, "files")
}