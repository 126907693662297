import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "el-col sub-bar" }
const _hoisted_2 = { class: "menu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_logo = _resolveComponent("app-logo")!
  const _component_badged_name = _resolveComponent("badged-name")!
  const _component_main_menu_item = _resolveComponent("main-menu-item")!
  const _component_el_menu = _resolveComponent("el-menu")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_sub_menu_item = _resolveComponent("sub-menu-item")!
  const _component_el_header = _resolveComponent("el-header")!

  return (_openBlock(), _createBlock(_component_el_header, {
    id: "header",
    ref: "header",
    class: _normalizeClass(_ctx.headerClass)
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_el_row, {
        key: _ctx.menuItems.map(it => it.name).join('.'),
        justify: "end"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { class: "main-bar" }, {
            default: _withCtx(() => [
              _createVNode(_component_app_logo),
              _createVNode(_component_el_row, { justify: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_menu, {
                    "default-active": _ctx.subBarContent,
                    ellipsis: false,
                    mode: "horizontal",
                    router: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
                        return _renderSlot(_ctx.$slots, menuItem.name, {
                          key: menuItem.name,
                          badge: _ctx.isRef(menuItem.badge) ? menuItem.badge.value : menuItem.badge,
                          item: menuItem
                        }, () => [
                          _createVNode(_component_main_menu_item, {
                            badge: _ctx.isRef(menuItem.badge) ? menuItem.badge.value : menuItem.badge,
                            "current-active": _ctx.subBarContent,
                            shortcuts: _ctx.shortcuts,
                            name: menuItem.name,
                            uuid: menuItem.routeRecordName,
                            route: _ctx.hasAccessToView(menuItem) ? (_ctx.unwrapped(menuItem.route) || _ctx.route) : _ctx.route,
                            onActivate: ($event: any) => (_ctx.activate(menuItem.children)($event))
                          }, {
                            title: _withCtx(({badge}) => [
                              _renderSlot(_ctx.$slots, `${menuItem.name.toString().toLowerCase()}_title`, {
                                title: _ctx.unwrapped(menuItem.label)
                              }, () => [
                                _createVNode(_component_badged_name, {
                                  label: _ctx.unwrapped(menuItem.label) ?? _ctx.unwrapped(menuItem.name).toString() ,
                                  value: _ctx.unwrapped(badge),
                                  type: "menu"
                                }, null, 8, ["label", "value"])
                              ])
                            ]),
                            _: 2
                          }, 1032, ["badge", "current-active", "shortcuts", "name", "uuid", "route", "onActivate"])
                        ])
                      }), 128))
                    ]),
                    _: 3
                  }, 8, ["default-active"])
                ]),
                _: 3
              })
            ]),
            _: 3
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
                return _renderSlot(_ctx.$slots, `${menuItem.name.toString().toLowerCase()}_submenu`, {
                  key: menuItem.name,
                  item: menuItem
                }, () => [
                  (menuItem.children && menuItem)
                    ? (_openBlock(), _createBlock(_component_sub_menu_item, {
                        key: 0,
                        active: _ctx.subBarContent === menuItem.name,
                        menuItems: menuItem.children,
                        router: !!_ctx.unwrapped(menuItem.route)
                      }, null, 8, ["active", "menuItems", "router"]))
                    : _createCommentVNode("", true)
                ])
              }), 128))
            ])
          ])
        ]),
        _: 3
      }))
    ]),
    _: 3
  }, 8, ["class"]))
}