import { IRuntimeTypeInformation, Id, Serializable } from "@lib/index"
import { MandantStatus } from "@generated/de/lohn24/model/mandant/MandantStatus"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { LohnabrechnungSystem } from "@generated/de/lohn24/model/lohnabrechnung/LohnabrechnungSystem"

export interface IKooperationspartnerMandant {
	id: string
	gesperrt: boolean
	abrechnungssystem: LohnabrechnungSystem
	anzahlLohnabrechnung: number
	kennung: string
	status: MandantStatus
	name: string
}

export class KooperationspartnerMandant extends Serializable<KooperationspartnerMandant> {
	public id!: Id<Mandant>
	public gesperrt!: boolean
	public abrechnungssystem!: LohnabrechnungSystem
	public anzahlLohnabrechnung!: number
	public kennung!: string
	public status!: MandantStatus
	public name!: string

	static _types: Record<keyof IKooperationspartnerMandant, IRuntimeTypeInformation> = {
		id: { type: Id },
		gesperrt: { type: Boolean },
		abrechnungssystem: { type: LohnabrechnungSystem },
		anzahlLohnabrechnung: { type: Number },
		kennung: { type: String },
		status: { type: MandantStatus },
		name: { type: String },
	}

	static props: Record<keyof IKooperationspartnerMandant, string> = {
		id: "id",
		gesperrt: "gesperrt",
		abrechnungssystem: "abrechnungssystem",
		anzahlLohnabrechnung: "anzahlLohnabrechnung",
		kennung: "kennung",
		status: "status",
		name: "name",
	}

	constructor(_json: IKooperationspartnerMandant) {
		super(_json)
	}
}
