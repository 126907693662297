<template>
  <a v-if="phoneNumber" :href="call">{{ phoneNumber }}</a>
  <span v-else></span>
</template>

<script lang="ts">
import {computed, defineComponent, PropType} from "vue"

export default defineComponent({
    name: "TelefonLink",
    props: {
        phoneNumber: {
            type: String as PropType<string>,
        },
    },
    setup(props) {
        const call = computed(() => `tel:${props.phoneNumber}`)
        return {
            call,
        }
    },
})
</script>