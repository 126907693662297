import {Model} from "@lib/common/model/Model"
import {Constructable, RecordOf} from "@lib/types"
import Api from "@lib/common/axios/Api"
import {Config} from "@lib/Config"
import {Id} from "@lib/common/model/Id"
import {assertNotNull, from} from "@lib/common/Functions"
import {AxiosRequestConfig} from "axios"
import {
    RepositoryDispatchMap,
    RepositoryEvent,
    RepositoryEventMap,
    RepositoryEventTarget,
    RepostioryEvents,
} from "@lib/common/repository/RepositoryEventTarget"

export class Pair<F, S> {
    constructor(public first: F, public second: S) {

    }
}

export class RepositoryHasManyWithRelation<L extends Model<L>, M extends Model<M>, R extends Model<R>> {
    eventTarget = new RepositoryEventTarget<Pair<M, R>>()
    protected replaceDoubleRegex = new RegExp("//", "g")

    constructor(
        protected path: string,
        protected typeMiddle: Constructable<M>,
        protected typeRight: Constructable<R>,
        protected currentEndpoint: string,
        protected api: Api = Api.Instance(Config.API_BASE_URL),
    ) {
    }

    all(parent: Id<L>, params: Record<string, any> = {}): Promise<Pair<M, R>[]> {
        return this.get(parent, null, params)
    }

    async first(parent: Id<L>, params: Record<string, any> = {}): Promise<M> {
        return (await this.all(parent, params)).first().first
    }

    async firstOrNull(parent: Id<L>, params: Record<string, any> = {}): Promise<M | null> {
        return (await this.all(parent, params)).firstOrNull()?.first ?? null
    }

    async update(parent: Id<L>, model: M): Promise<Pair<M, R>> {
        const result = await this.put(parent, model)
        this.dispatchEvent("update", result)
        return result

    }

    async delete(parent: Id<L>, target: M): Promise<void> {
        await this.api.delete(this.route(parent, target.id.toString()))
    }

    async create(parent: Id<L>, model: M): Promise<Pair<M, R>> {
        const result = await this.post(parent, model)
        this.dispatchEvent("create", result)
        return result
    }

    protected fullPath() {
        return (this.path + "/{id}/" + this.currentEndpoint).replace(this.replaceDoubleRegex, "/")
    }

    protected route(parent: Id<L>, path: string | number | null = null): string {
        const result = `${this.pathReplace([parent])}` + (path === null ? `` : `/${path}`)
        return result.replace(this.replaceDoubleRegex, "/")
    }

    protected deserializeMiddle(result: RecordOf<M> | RecordOf<M>[]) {
        if (Array.isArray(result)) {
            return result.map(it => this.deserializeMiddle(it))
        }
        return from(this.typeMiddle, result)
    }

    protected deserializeRight(result: RecordOf<R> | RecordOf<R>[]) {
        if (Array.isArray(result)) {
            return result.map(it => this.deserializeRight(it))
        }
        return from(this.typeRight, result)
    }

    protected deserializePair(result: Pair<RecordOf<M>, RecordOf<R>> | Pair<RecordOf<M>, RecordOf<R>>[]) {
        if (Array.isArray(result)) {
            return result.map(it => this.deserializePair(it))
        }
        return new Pair(this.deserializeMiddle(result.first), this.deserializeRight(result.second))
    }

    protected async get(parent: Id<L>, path: string | null = null, params?: Record<string, any>): Promise<Pair<M, R>[]> {
        const partials = await this.api.get<Pair<RecordOf<M>, RecordOf<R>> | Pair<RecordOf<M>, RecordOf<R>>[]>(this.route(parent, path), params)
        return this.deserializePair(Array.isArray(partials) ? partials : [partials])
    }

    protected async put(parent: Id<L>, model: M, path: string | null = null, config?: AxiosRequestConfig): Promise<Pair<M, R>> {
        const resolvingPath = (path ? `${path}/` : "") + `${model.id.value}`
        const partial = await this.api.put<Pair<RecordOf<M>, RecordOf<R>>>(this.route(parent, resolvingPath), model.toJSON(), config)
        return this.deserializePair(partial)
    }

    protected async post(parent: Id<L>, model: M, path: string | null = null, config?: AxiosRequestConfig): Promise<Pair<M, R>> {
        const partial = await this.api.post<Pair<RecordOf<M>, RecordOf<R>>>(this.route(parent, path), model.toJSON(), config)
        const middle = this.deserializeMiddle(partial.first)
        const id = assertNotNull(partial.first.id)
        middle.id = new Id(id)
        const right = this.deserializeRight(partial.second)
        return new Pair<M, R>(middle, right)
    }

    private pathReplace(args: Id<any>[], placeholder = "{id}"): string {
        const regexp = new RegExp(placeholder)
        let path = this.fullPath()
        args.forEach(id => path = path.replace(regexp, `${id.value}`))
        return path
    }

    addEventListener<E extends RepostioryEvents>(type: E, cb: RepositoryEventMap<Pair<M, R>>[E]) {
        return this.eventTarget.addEventListener.bind(this.eventTarget)(type, cb)
    }

    removeEventListener<E extends RepostioryEvents>(type: E, cb: RepositoryEventMap<Pair<M, R>>[E]) {
        return this.eventTarget.removeEventListener.bind(this.eventTarget)(type, cb)
    }

    dispatchEvent<E extends RepostioryEvents>(type: E, data: RepositoryDispatchMap<Pair<M, R>>[E]) {
        return this.eventTarget.dispatchEvent.bind(this.eventTarget)(new RepositoryEvent(type, data))
    }
}