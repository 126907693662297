import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {SearchDialogController} from "@lib/view/search/SearchDialogController"
import {KeyboardEventlistenerConfig} from "@lib/common/dialog/KeyboardEventlistener"
import {auth} from "@lib/common/Authentication"
import SearchDialogView from "@lib/view/search/SearchDialogView.vue"

export class SearchDialog extends ComponentRenderer<typeof SearchDialogView> {
    constructor(controller: SearchDialogController, done: (m: any) => void) {
        super(
            SearchDialogView,
            {
                controller: controller,
                done: done,
            },
        )
    }

    static async show() {
        if (await ComponentRenderer.activeInstanceCloses())
            return new Promise(resolve => {
                new SearchDialog(new SearchDialogController(), (it) => {
                    return resolve(it)
                })
            })
    }

    static config: KeyboardEventlistenerConfig = {
        action: async () => {
            if (!ComponentRenderer.activeInstanceIsOf(SearchDialog))
                await SearchDialog.show()
        },
        event: (event: KeyboardEvent, lastEvent: KeyboardEvent | null) => {
            if (!auth.valid())
                return false

            if ((event.ctrlKey || event.metaKey) && (event.key == "k" || event.key == "K"))
                return true

            return (event.key === "Shift" && lastEvent?.key === "Shift")
        },
    }
}