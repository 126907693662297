import {Constructable} from "@lib/types"
import _ from "lodash"
import {deepDiff} from "@lib/common/extension/lodash"
import {IRuntimeTypeInformation} from "@lib/common/model/IRuntimeTypeInformation"
import {shallowReactive} from "vue"

type PrimitiveType = string | number | Array<any> | object

export abstract class Serializable<T> {

    protected static _types: Record<string, any>
    private static typeMap = new Map<unknown, (value: unknown) => unknown>()
    private static typeDefaultsMap = new Map<unknown, () => unknown>()

    private static getValue = (target, p) => {
        if (p === "_original") {
            if (target[p] === undefined) {
                target[p] = {}
                const types = (target.constructor as any)._types
                Object.keys(types).forEach(key => {
                    target[p][key] = target.serialize(target.deserialize(target._json[key], types[key]))
                })
            }
            return target[p]
        }
        if (Object.hasOwn(target._json, p) || Object.hasOwn(target._cache, p)) {
            const types = (target.constructor as any)._types
            try {
                return target._cache[p] = target._cache[p] !== undefined ? target._cache[p] : (Object.prototype.hasOwnProperty.call(types, p)
                    ? target.deserialize(target._json[p], types[p])
                    : target._json[p])
            } catch (e) {
                throw {e, target, p}
            }

        }
        return target[p]
    }

    static addDefaultValue<T, R>(type: T, defaultValue: () => R): void {
        this.typeDefaultsMap.set(type, defaultValue)
    }

    static getDefaultValue<T>(type: T): () => T {
        const defaultValue = this.typeDefaultsMap.get(type)
        if (Serializable.isAncestorOf(type) && defaultValue === undefined) {
            return () => new (type as Constructable<T>)({})
        }
        return defaultValue as () => T
    }

    static add<T, R>(type: T, deserialize: (value: unknown) => R) {
        this.typeMap.set(type, deserialize)
    }

    static get<T>(type: T): (value: unknown) => T {
        return this.typeMap.get(type) as (value: unknown) => T
    }

    static deserialize<T>(type: T, value): T {
        if (Serializable.isAncestorOf(type))
            return value !== null ? new (type as Constructable<any>)(value) : value
        if (Serializable.get(type))
            return Serializable.get(type)(value)
        return value
    }

    private static deserializeMap<T>(valueType: T, value: Record<any, PrimitiveType>): Record<string | number, T> {
        const record = {}
        Object.keys(value).forEach(key => {
            const deserializedKey = key
            const deserializedValue = Serializable.deserialize(valueType, value[key])
            record[key] = deserializedValue
        })
        return record
    }

    static isAncestorOf(obj: unknown): obj is Serializable<unknown> {
        if (!obj) return false
        if (_.isObject(obj))
            // eslint-disable-next-line no-prototype-builtins
            return "prototype" in obj ? obj["prototype"] instanceof this : obj instanceof this
        return false
    }

    private _cache: Partial<T> = {}
    _original

    public constructor(protected readonly _json: object) {
        const proxy = new Proxy(this, {
            has: (target, p) => Serializable.getValue(target, p),
            get: (target, p) => Serializable.getValue(target, p),
            set: (target, p, newValue) => {
                if (p === "_types") {
                    throw new Error(`${p} sollte in ${target.constructor.name} als statisches Feld genutzt werden.`)
                }
                target._cache[p] = newValue
                return true
            },
        })
        return shallowReactive(proxy)
    }

    get props(): (keyof T | any)[] {
        return Object.values((this.constructor as any).props) as (keyof T)[]
    }

    updateJson() {
        const json = {}
        Object.keys(this._cache).forEach(key => {
            if (this._cache[key] !== undefined)
                json[key] = this._json[key] = this.serialize(this._cache[key], key)
            else if (this._json[key] == undefined)
                json[key] = this._json[key] = this.serialize(this[key], key)
            else
                json[key] = this._json[key]
        })
        return json
    }

    private deserialize(value, typeInfo: IRuntimeTypeInformation) {
        if ((value === undefined) && typeInfo.nullable)
            return null

        const type = typeInfo.type
        if (Array.isArray(type)) {
            if (type.length === 1)
                return (value ?? []).map(it => Serializable.deserialize(type.first(), it))
            if (type.length === 2) {
                const [_, valueType] = type
                return Serializable.deserializeMap(valueType, value)
            }
        }

        if (value === undefined) {
            const defaultValue = Serializable.getDefaultValue(type)
            if (defaultValue)
                return defaultValue()
            return undefined
        }

        return Serializable.deserialize(typeInfo.type, value)
    }

    equals(model: unknown): boolean {
        return Object.keys(this._diff(model)).length === 0
    }

    changed(): boolean {
        return !this.equals(this._original)
    }

    _diff(other: unknown): Record<string, any> {
        if (Serializable.isAncestorOf(other)) {
            return this._diffSerializable(other as Serializable<unknown>)
        }
        return this._diffOther(other)
    }

    _diffSerializable(other: Nullable<Serializable<unknown>>): Record<string, any> {
        return deepDiff(this.toJSON(), other?.toJSON())
    }

    _diffOther(other: unknown): Record<string, any> {
        return deepDiff(this.toJSON(), other)
    }

    clone(): T {
        // @ts-expect-error dangerously construct object
        return new this.constructor(this.toJSON())
    }

    toJSON(): object {
        const json = {}
        Object.keys(this._cache).forEach(key => {
            if (this._cache[key] !== undefined)
                json[key] = this.serialize(this._cache[key])
            else if (this._json[key] == undefined)
                json[key] = this.serialize(this[key])
            else
                json[key] = this._json[key]
        })
        return json
    }

    notEquals(model: unknown): boolean {
        return !this.equals(model)
    }

    private serialize(value: unknown, key?: string) {
        if (value === null)
            return null
        if (value instanceof Serializable)
            return value.toJSON()
        if (value instanceof Map) {
            return JSON.parse(
                JSON.stringify(Array.from(value.keys()).associateWith(key => value[key])),
            )
        }
        if (_.isNumber(value))
            return value
        if (Array.isArray(value))
            return value.map(it => this.serialize(it))
        if (_.isObject(value)) {
            return this.asJsonValue(value, key)
        }
        if (_.isBoolean(value)) {
            return value
        }
        return this.asJsonValue(value, key)
    }

    private asJsonValue(value?: { toJSON?: (key?) => any, valueOf?: () => any }, key?) {
        if (value === undefined) return value
        if (value.toJSON)
            return value.toJSON(key)
        return value.toString()
    }

    merge<T extends Serializable<T>>(other: T) {
        const json = other._json
        Object.assign(this._json, json)
    }
}