import {RightIdentifier} from "@generated/de/lohn24/model/konstanten/RightIdentifier"

export class RightAccess {
    delete: boolean

    constructor(
        public right: RightIdentifier,
        public read: boolean = false,
        public write: boolean = false,
        del = false,
    ) {
        this.delete = del
    }

    static readable(right: RightIdentifier): RightAccess {
        return new RightAccess(
            right,
            true,
            false,
            false,
        )
    }

    static editable(right: RightIdentifier): RightAccess {
        return new RightAccess(
            right,
            true,
            true,
            false,
        )
    }

    static full(right: RightIdentifier): RightAccess {
        return new RightAccess(
            right,
            true,
            true,
            true,
        )
    }
}