import Api from "@lib/common/axios/Api"
import {ModelWorker} from "@lib/common/worker/ModelWorker"
import {Config} from "@lib/Config"
import {from} from "@lib/common/Functions"
import {ISession, Session} from "@generated/dev/api/model/session/Session"

export class SessionRepository {
    private api = Api.Instance(Config.API_BASE_URL)

    async get(): Promise<Session> {
        const session = from(Session, (await this.api.get<Session>("/session")))
        ModelWorker.Instance.post(session)
        return session
    }

    async create(username: string, password: string): Promise<Session | null> {
        const result: Partial<ISession> = await this.api.post<ISession>("/login", {
            username: username,
            password: password,
        })
        return from(Session, {
            id: result.id,
            token: result.token,
            account: result.account,
            groups: result.groups,
            roles: result.roles,
            rights: result.rights,
        } as ISession)
    }

    async close(): Promise<void> {
        return this.api.post("/logout", {})
    }
}
