import Api from "@lib/common/axios/Api"
import RepositoryConfig, {repositoryConfig} from "@lib/common/repository/RepositoryConfig"
import {IProcessing, Processing} from "@generated/de/lohn24/model/file/ProcessingQuery/Processing"
import {from} from "@lib/common/Functions"

export class ProcessingRepository {
    api: Api

    constructor(config: RepositoryConfig = repositoryConfig) {
        this.api = Api.Instance(config.baseURL)
    }

    async all(): Promise<Processing[]> {
        const result = await this.api.get<IProcessing[]>("processing")
        return result.map(it => from(Processing, it))
    }

    async exceptions(name: string, className: string): Promise<string[]> {
        return await this.api.get<string[]>("processing/exceptions", {
            processing_name: name,
            class_name: className,
        })
    }
}