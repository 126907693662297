import {MenuItem} from "@lib/common/menu/MenuItem"
import {RightAccess} from "@lib/model/role/RoleRight"
import {RightIdentifier} from "@generated/de/lohn24/model/rights/RightIdentifier"

export const EinzelrechnungMenuItem = new MenuItem({
    label: "Einzelrechnung",
    name: "einzelrechnung",
    rights: [RightAccess.readable(RightIdentifier.RECHNUNGSWESEN)],
    view: async () => import("@intranet/view/rechnung/einzelrechnung/EinzelrechnungList.vue"),
    "view-rights": [RightAccess.readable(RightIdentifier.RECHNUNGSWESEN)],
})