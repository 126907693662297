import { IRuntimeTypeInformation, Id, LocalDateTime, Serializable } from "@lib/index"
import { MandantExport } from "@generated/de/lohn24/model/mandant/export/MandantExport"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Failure } from "@generated/dev/api/model/failure/Failure"

export interface IAssociationSummary {
	exportFailure: string | null
	exportId: string | null
	mandantId: string | null
	export: LocalDateTime | null
	exportUpdate: LocalDateTime | null
	fileCreatedMax: LocalDateTime | null
	fileCreatedMin: LocalDateTime | null
	mutating: LocalDateTime | null
	fileMandant: number | null
	count: number
	mandant: string | null
	exportFailureCause: string | null
}

export class AssociationSummary extends Serializable<AssociationSummary> {
	public exportFailure!: Id<Failure> | null
	public exportId!: Id<MandantExport> | null
	public mandantId!: Id<Mandant> | null
	public export!: LocalDateTime | null
	public exportUpdate!: LocalDateTime | null
	public fileCreatedMax!: LocalDateTime | null
	public fileCreatedMin!: LocalDateTime | null
	public mutating!: LocalDateTime | null
	public fileMandant!: number | null
	public count!: number
	public mandant!: string | null
	public exportFailureCause!: string | null

	static _types: Record<keyof IAssociationSummary, IRuntimeTypeInformation> = {
		exportFailure: { type: Id, nullable: true },
		exportId: { type: Id, nullable: true },
		mandantId: { type: Id, nullable: true },
		export: { type: LocalDateTime, nullable: true },
		exportUpdate: { type: LocalDateTime, nullable: true },
		fileCreatedMax: { type: LocalDateTime, nullable: true },
		fileCreatedMin: { type: LocalDateTime, nullable: true },
		mutating: { type: LocalDateTime, nullable: true },
		fileMandant: { type: Number, nullable: true },
		count: { type: Number },
		mandant: { type: String, nullable: true },
		exportFailureCause: { type: String, nullable: true },
	}

	static props: Record<keyof IAssociationSummary, string> = {
		exportFailure: "exportFailure",
		exportId: "exportId",
		mandantId: "mandantId",
		export: "export",
		exportUpdate: "exportUpdate",
		fileCreatedMax: "fileCreatedMax",
		fileCreatedMin: "fileCreatedMin",
		mutating: "mutating",
		fileMandant: "fileMandant",
		count: "count",
		mandant: "mandant",
		exportFailureCause: "exportFailureCause",
	}

	constructor(_json: IAssociationSummary) {
		super(_json)
	}
}
