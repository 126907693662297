import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"

export interface IRight extends IModel {
	name: string
}

export class Right extends Model<Right> {
	public name!: string

	static _types: Record<keyof IRight, IRuntimeTypeInformation> = {
		id: { type: Id },
		name: { type: String },
	}

	static props: Record<keyof IRight, string> = {
		id: "id",
		name: "name",
	}

	constructor(_json: IRight) {
		super(_json)
	}
}
