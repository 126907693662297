import {ContextMenuBuilder, ContextMenuRowContext} from "@lib/common/contextmenu/ContextMenuBuilder"
import {MandantOrganisationViewModel} from "@intranet/view/mandant/organisation/MandantOrganisationViewModel"
import {ContextMenuItemOption, ContextSubMenuItemOption} from "@lib/view/contextmenu/ContextMenuClass"
import IconLink from "@lib/view/icons/IconLink.vue"
import {MandantSummaryDialog} from "@intranet/view/mandant/summary/MandantSummaryDialog"

export class MandantOrganisationContextMenu extends ContextMenuBuilder {

    constructor(private context: ContextMenuRowContext<MandantOrganisationViewModel>) {
        super(context.event)
        this.build()
    }

    mandant = (context: ContextMenuRowContext<MandantOrganisationViewModel>) => new ContextMenuItemOption(
        context.row.name,
        async () => {
            await MandantSummaryDialog.show(context.row.mandant.id)
        },
        IconLink,
    )

    override buildOptions(): (ContextMenuItemOption | ContextSubMenuItemOption)[] {
        return super.buildOptions()
            .concat(
                this.mandant(this.context),
            )
    }
}