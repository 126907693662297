import {auth} from "@lib/common/Authentication"
import {Resource, ResourceCache} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"
import {Account} from "@generated/dev/api/model/account/Account"
import {IntervalPlugin} from "@lib/common/vue/plugin/IntervalPlugin"
import {Plugin} from "@lib/common/vue/plugin/Plugin"
import {App} from "vue"

export class HeartbeatPlugin implements Plugin<{ interval: number }> {

    private intervalPlugin = new IntervalPlugin<Id<Account>>()

    install(app: App, options: { interval: number }) {
        this.intervalPlugin.install(app, {
            uniqueName: "HeartbeatPlugin",
            interval: options.interval,
            methods: [
                () => Resource.mitarbeiter.heartbeat(),
                () => ResourceCache.mitarbeiter.invalidate(),
            ],
            startWithWatcher: {
                source: () => auth.mitarbeiter.value.id,
                triggerRule: (id?: Id<Account> | null) => id !== null && id !== undefined,
            },
        })
    }
}