import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { Artikelkatalog } from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import { Artikel } from "@generated/de/lohn24/model/artikel/Artikel"

export interface IArtikelkatalogArtikel extends IModel {
	artikel: string
	artikelkatalog: string
	umsatzsteuersatz: number
	rechnungsabschluss: boolean
	sachbearbeiterabschluss: boolean
	vorgabeMengeRechnung: number
	netto: number
	anzeigename: string | null
}

export class ArtikelkatalogArtikel extends Model<ArtikelkatalogArtikel> {
	public artikel!: Id<Artikel>
	public artikelkatalog!: Id<Artikelkatalog>
	public umsatzsteuersatz!: number
	public rechnungsabschluss!: boolean
	public sachbearbeiterabschluss!: boolean
	public vorgabeMengeRechnung!: number
	public netto!: number
	public anzeigename!: string | null

	static _types: Record<keyof IArtikelkatalogArtikel, IRuntimeTypeInformation> = {
		id: { type: Id },
		artikel: { type: Id },
		artikelkatalog: { type: Id },
		umsatzsteuersatz: { type: Number },
		rechnungsabschluss: { type: Boolean },
		sachbearbeiterabschluss: { type: Boolean },
		vorgabeMengeRechnung: { type: Number },
		netto: { type: Number },
		anzeigename: { type: String, nullable: true },
	}

	static props: Record<keyof IArtikelkatalogArtikel, string> = {
		id: "id",
		artikel: "artikel",
		artikelkatalog: "artikelkatalog",
		umsatzsteuersatz: "umsatzsteuersatz",
		rechnungsabschluss: "rechnungsabschluss",
		sachbearbeiterabschluss: "sachbearbeiterabschluss",
		vorgabeMengeRechnung: "vorgabeMengeRechnung",
		netto: "netto",
		anzeigename: "anzeigename",
	}

	constructor(_json: IArtikelkatalogArtikel) {
		super(_json)
	}
}
