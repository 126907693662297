import {Plugin} from "vue"

import IconAbrechnunssysstem from "@lib/view/icons/IconAbrechnungssystem.vue"
import IconL24 from "@lib/view/icons/IconL24"
import IconRZL from "@lib/view/icons/IconRZL"
import IconRAG from "@lib/view/icons/IconRAG"

import IconAdd from "@lib/view/icons/IconAdd.vue"
import IconAvatar from "@lib/view/icons/IconAvatar.vue"
import IconChecked from "@lib/view/icons/IconChecked.vue"
import IconDelete from "@lib/view/icons/IconDelete.vue"
import IconEmail from "@lib/view/icons/IconEmail.vue"
import IconEdit from "@lib/view/icons/IconEdit.vue"
import IconFalse from "@lib/view/icons/IconFalse.vue"
import IconTelefon from "@lib/view/icons/IconTelefon.vue"
import IconTicket from "@lib/view/icons/IconTicket.vue"
import IconTrue from "@lib/view/icons/IconTrue.vue"
import IconAbschluss from "@lib/view/icons/IconAbschluss.vue"
import IconWartend from "@lib/view/icons/IconWartend.vue"
import IconWeiter from "@lib/view/icons/IconWeiter.vue"
import IconGesperrt from "@lib/view/icons/IconGesperrt.vue"
import IconEntsperrt from "@lib/view/icons/IconEntsperrt.vue"
import IconClose from "@lib/view/icons/IconClose.vue"
import IconPublish from "@lib/view/icons/IconPublish.vue"
import IconCopy from "@lib/view/icons/IconCopy.vue"
import IconSettings from "@lib/view/icons/IconSettings.vue"
import IconUndo from "@lib/view/icons/IconUndo.vue"
import IconBorderNone from "@lib/view/icons/IconBorderNone.vue"
import IconBorderAll from "@lib/view/icons/IconBorderAll.vue"
import IconRechnung from "@lib/view/icons/IconRechnung.vue"
import IconFiles from "@lib/view/icons/IconFiles.vue"
import IconFirma from "@lib/view/icons/IconFirma.vue"
import IconArrowUp from "@lib/view/icons/IconArrowUp.vue"

export const Icons = {
    IconBorderAll,
    IconBorderNone,
    IconAbrechnunssysstem,
    IconAdd,
    IconAvatar,
    IconChecked,
    IconDelete,
    IconEmail,
    IconEdit,
    IconFalse,
    IconTelefon,
    IconTicket,
    IconTrue,
    IconAbschluss,
    IconWartend,
    IconWeiter,
    IconGesperrt,
    IconEntsperrt,
    IconClose,
    IconPublish,
    IconCopy,
    IconL24,
    IconRZL,
    IconRAG,
    IconSettings,
    IconUndo,
    IconRechnung,
    IconFiles,
    IconFirma,
    IconArrowUp,
}

export default Icons

export const IconPlugin = {
    install(app) {
        for (const icon in Icons) {
            app.component(icon, Icons[icon])
        }
    },
} as Plugin
