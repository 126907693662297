import {ViewModel} from "@lib/common/model/ViewModel"
import {Debitor} from "@generated/de/lohn24/model/debitor/Debitor"
import {_props} from "@lib/common/_props"
import {Id} from "@lib/common/model/Id"
import {Firma} from "@generated/de/lohn24/model/firma/Firma"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {LocalDate} from "@lib/common/LocalDate"
import {Serializable} from "@lib/common/serializable/Serializable"
import {DebitorSepa} from "@generated/de/lohn24/model/debitor/DebitorSepa"
import {UuidService} from "@lib/common/uuid/UuidService"
import {
    BereitstellungOptionRechnung,
} from "@generated/de/lohn24/model/mandant/abrechnungsoption/BereitstellungOptionRechnung"

export class DebitorViewModel extends ViewModel<DebitorViewModel> {

    static propertyNames = _props<InstanceType<typeof this>>()

    debitor: Debitor

    get bereitstellungRechnung(): BereitstellungOptionRechnung[] {
        return this.debitor.bereitstellungRechnung
    }

    set bereitstellungRechnung(options: BereitstellungOptionRechnung[]) {
        this.debitor.bereitstellungRechnung = options
    }

    constructor(
        debitor: Debitor,
    ) {
        super()
        this.debitor = this.registerShallowReactive(debitor)
    }

    get sepa(): Nullable<DebitorSepa> {
        return this.debitor.sepa
    }

    set sepa(value: Nullable<DebitorSepa> | "") {
        const sepaValue = value === "" ? null : value
        this.debitor.sepa = sepaValue
        if (sepaValue !== null)
            this.debitor.sepaVomKundenAbgelehnt = false
    }

    get name(): string {
        return this.debitor.name
    }

    set name(value: string) {
        this.debitor.name = value
    }

    get firmaId(): Id<Firma> {
        return this.debitor.rechnungssteller
    }

    set firmaId(value: Id<Firma>) {
        this.debitor.rechnungssteller = value
    }

    get konto() {
        return this.debitor.konto
    }

    set konto(value: string) {
        this.debitor.konto = value
    }

    get abweichenderKontoinhaber() {
        return this.debitor.abweichenderKontoinhaber ?? ""
    }

    set abweichenderKontoinhaber(value: string) {
        this.debitor.abweichenderKontoinhaber = value ? value : null
    }

    get sepaMandatsreferenz() {
        return this.debitor.sepaMandatsreferenz ?? ""
    }

    set sepaMandatsreferenz(value) {
        this.debitor.sepaMandatsreferenz = value ? value : null
    }

    get sepaUnterschrift(): Date | "" {
        return this.debitor.sepaUnterschrift?.toJSDate() ?? ""
    }

    set sepaUnterschrift(value: Date | "") {
        this.debitor.sepaUnterschrift = value ? LocalDate.fromJSDate(value) : null
    }

    get sepaGueltigkeit() {
        return this.debitor.sepaGueltigkeit?.toJSDate() ?? ""
    }

    set sepaGueltigkeit(value: Date | "") {
        this.debitor.sepaGueltigkeit = value ? LocalDate.fromJSDate(value) : null
    }

    get sepaVomKundenAbgelehnt(): boolean {
        return this.debitor.sepaVomKundenAbgelehnt
    }

    set sepaVomKundenAbgelehnt(value: boolean) {
        this.debitor.sepaVomKundenAbgelehnt = value
        if (value) {
            this.sepa = null
        }
    }

    get rechnungsanschrift() {
        return this.debitor.rechnungsanschrift
    }

    set rechnungsanschrift(value: Id<Anschrift> | null) {
        if (value)
            this.debitor.rechnungsanschrift = new Id(value)
        else
            this.debitor.rechnungsanschrift = UuidService.id()
    }

    get postanschrift() {
        return this.debitor.postanschrift
    }

    set postanschrift(value: Id<Anschrift> | null) {
        this.debitor.postanschrift = value
    }

    get mail() {
        return this.debitor.mail ?? ""
    }

    set mail(value: string) {
        this.debitor.mail = value.toNullIfEmpty()
    }

    get rechnungszusatz() {
        return this.debitor.rechnungszusatz ?? ""
    }

    set rechnungszusatz(value: string) {
        this.debitor.rechnungszusatz = value.toNullIfEmpty()
    }

    get iban() {
        return this.debitor.iban ?? ""
    }

    set iban(value: string) {
        this.debitor.iban = value.toNullIfEmpty()
    }

    override registerShallowReactive<T extends Serializable<T> | Serializable<unknown>[]>(obj: T): T {
        return super.registerShallowReactive(obj)
    }
}