import {ViewModel} from "@lib/common/model/ViewModel"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {_props} from "@lib/common/_props"
import {KontaktFunktion} from "@generated/de/lohn24/model/kontakt/KontaktFunktion"
import {Formatter} from "@lib/common/Formatter"
import {capitalize} from "lodash"

export class KontaktSummaryViewModel extends ViewModel<KontaktSummaryViewModel> {

    static label = {
        anrede: "Anrede",
        titel: "Titel",
        vorname: "Vorname",
        name: "Name",
        telefon: "Telefon",
        mobil: "Mobil",
        email: "E-Mail",
        funktion: "Funktion",
        benachrichtigungLohn: "Benachrichtigung",
        ansprechpartner: "Ansprechpartner",
        emptyText: "Keine Kontakte vorhanden.",
        postfach: "E-Mail-Postfach",
    }
    static properties = _props<InstanceType<typeof this>>()

    kontakt: Kontakt

    constructor(kontakt: Kontakt) {
        super()
        this.kontakt = this.registerShallowReactive(kontakt)
    }

    get ansprechpartner(): string {
        return `${(capitalize(this.kontakt.anrede ?? undefined) || "")} ${this.kontakt.titel || ""} ${this.kontakt.vorname || ""} ${this.kontakt.name || ""}`
            .trim()
    }

    get telefon(): string {
        return Formatter.telefon.formatInternational(this.kontakt.telefon ?? "")
    }

    get mobil(): string {
        return Formatter.telefon.formatInternational(this.kontakt.telefonMobil ?? "")
    }

    get email(): string {
        return this.kontakt.email ?? ""
    }

    get funktion(): KontaktFunktion {
        return this.kontakt.funktion
    }

    override equals(other: ViewModel<KontaktSummaryViewModel>): boolean {
        if (other instanceof KontaktSummaryViewModel) {
            return this.kontakt.id.value === other.kontakt.id.value
        }
        return false
    }
}