import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    content: _ctx.options.label,
    "show-after": 1000,
    placement: "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_button, _mergeProps({
        class: _ctx.btnClass,
        text: ""
      }, _ctx.$attrs, {
        onClick: _withModifiers(_ctx.openLink, ["stop"])
      }), {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.options.icon)))
        ]),
        _: 1
      }, 16, ["class", "onClick"])
    ]),
    _: 1
  }, 8, ["content"]))
}