import { IModel, IRuntimeTypeInformation, Id, Model } from "@lib/index"
import { KooperationspartnerStatus } from "@generated/de/lohn24/model/kooperationspartner/KooperationspartnerStatus"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { Briefabsender } from "@generated/de/lohn24/model/briefabsender/Briefabsender"
import { Artikelkatalog } from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"
import { Anschrift } from "@generated/de/lohn24/model/anschrift/Anschrift"

export interface IKooperationspartner extends IModel {
	anschrift: string
	artikelkatalog: string | null
	briefabsender: string | null
	debitor: string | null
	sammelrechnung: string | null
	artikeltextAusfuehrlich: boolean
	keineRechnung: boolean
	sammelrechnungFestschreibenTag: number
	status: KooperationspartnerStatus
	zammadId: number | null
	kennung: string
	name: string
}

export class Kooperationspartner extends Model<Kooperationspartner> {
	public anschrift!: Id<Anschrift>
	public artikelkatalog!: Id<Artikelkatalog> | null
	public briefabsender!: Id<Briefabsender> | null
	public debitor!: Id<Debitor> | null
	public sammelrechnung!: Id<Kooperationspartner> | null
	public artikeltextAusfuehrlich!: boolean
	public keineRechnung!: boolean
	public sammelrechnungFestschreibenTag!: number
	public status!: KooperationspartnerStatus
	public zammadId!: number | null
	public kennung!: string
	public name!: string

	static _types: Record<keyof IKooperationspartner, IRuntimeTypeInformation> = {
		id: { type: Id },
		anschrift: { type: Id },
		artikelkatalog: { type: Id, nullable: true },
		briefabsender: { type: Id, nullable: true },
		debitor: { type: Id, nullable: true },
		sammelrechnung: { type: Id, nullable: true },
		artikeltextAusfuehrlich: { type: Boolean },
		keineRechnung: { type: Boolean },
		sammelrechnungFestschreibenTag: { type: Number },
		status: { type: KooperationspartnerStatus },
		zammadId: { type: Number, nullable: true },
		kennung: { type: String },
		name: { type: String },
	}

	static props: Record<keyof IKooperationspartner, string> = {
		id: "id",
		anschrift: "anschrift",
		artikelkatalog: "artikelkatalog",
		briefabsender: "briefabsender",
		debitor: "debitor",
		sammelrechnung: "sammelrechnung",
		artikeltextAusfuehrlich: "artikeltextAusfuehrlich",
		keineRechnung: "keineRechnung",
		sammelrechnungFestschreibenTag: "sammelrechnungFestschreibenTag",
		status: "status",
		zammadId: "zammadId",
		kennung: "kennung",
		name: "name",
	}

	constructor(_json: IKooperationspartner) {
		super(_json)
	}
}
