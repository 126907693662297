import {ModelController} from "@lib/common/controller/ModelController"
import {MandantViewModel} from "@intranet/view/mandant/MandantViewModel"
import {Resource} from "@lib/model/Resource"

export class MandantModelController extends ModelController<MandantViewModel> {
    get needsDebitor() {
        return this.model.value.needsDebitor
    }

    override get modified(): boolean {
        return super.modified
    }

    protected createModel(): Promise<MandantViewModel> {
        throw new Error("needs builder")
    }

    protected async updateModel(model: MandantViewModel): Promise<MandantViewModel> {
        const mandant = await Resource.mandant.update(model.mandant)
        return new MandantViewModel(mandant)
    }
}