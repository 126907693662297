<template>
  <el-icon>
    <rzl-svg />
  </el-icon>
</template>

<script lang="ts">
import {defineComponent} from "vue"
import RzlSvg from "./RzlSVG.vue"

export default defineComponent({
    components: {
        RzlSvg,
    },
})
</script>
