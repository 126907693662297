import {DialogController} from "@lib/common/controller/DialogController"
import {
    KooperationspartnerSummaryDialogViewModel,
} from "@intranet/view/kooperationspartner/summary/KooperationspartnerSummaryDialogViewModel"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import {computed, shallowReactive, ShallowRef} from "vue"
import {Resource} from "@lib/model/Resource"
import {Anschrift} from "@generated/de/lohn24/model/anschrift/Anschrift"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {KooperationspartnerEditDialog} from "@intranet/view/kooperationspartner/edit/KooperationspartnerEditDialog"
import {MandantenContextMenu} from "@intranet/view/kooperationspartner/summary/MandantenContextMenu"
import {
    KooperationspartnerMandant
} from "@generated/de/lohn24/model/kooperationspartner/KooperationspartnerMandanten/KooperationspartnerMandant"

export class KooperationspartnerSummaryDialogController extends DialogController {
    title = computed(() => this.viewModel.titel)

    readonly kooperationspartner: Kooperationspartner

    constructor(kooperationspartner: Kooperationspartner) {
        super()
        this.kooperationspartner = shallowReactive(kooperationspartner)
    }

    get viewModel() {
        return new KooperationspartnerSummaryDialogViewModel(this.kooperationspartner)
    }

    async anschrift(ref: ShallowRef<Anschrift | null>): Promise<Anschrift> {
        const result = await Resource.kooperationspartner.anschrift.first(this.kooperationspartner.id)
        ref.value = result
        return result
    }

    async kontakte(ref: ShallowRef<Kontakt[] | null>): Promise<Kontakt[] | null> {
        const result = await Resource.kooperationspartner.kontakt.all(this.kooperationspartner.id)
        ref.value = result
        return result
    }

    async mandanten(ref: ShallowRef<KooperationspartnerMandant[] | null>): Promise<KooperationspartnerMandant[] | null> {
        return ref.value = await Resource.kooperationspartner.mandanten(this.kooperationspartner.id)
    }

    async edit(row: KooperationspartnerSummaryDialogViewModel) {
        return await KooperationspartnerEditDialog.show(row.kooperationspartner.id)
    }

    onMandantRowContextMenu(row: KooperationspartnerMandant, column: any,event: PointerEvent) {
        event.stopPropagation()
        event.preventDefault()
        new MandantenContextMenu({
            row,
            event,
            column: column,
            route: this.route,
            router: this.router,
        })
    }
}