import {NavigationGuard, RouteComponent, RouteRecordName} from "vue-router"
import {ref, Ref} from "vue"
import {RouteRecordProps} from "@lib/types"
import {v4} from "uuid"
import {RightAccess} from "@lib/model/role/RoleRight"

export const uuid = Symbol("uuid")

export class MenuItem {

    // @ts-expect-error best way to use it
    static readonly uuid: unique symbol = uuid
    path?: string
    /**
     * will be set to 'dialog' by TabMenuItem when added to nested children
     */
    param?: string
    name!: string
    route: { name?: RouteRecordName | string } | Ref<{ name?: string | RouteRecordName }>
    props?: RouteRecordProps
    link?: string
    rights?: Array<RightAccess>
    label?: string | Ref<string>
    children?: MenuItem[]
    view?: RouteComponent | { (): Promise<RouteComponent> }
    "view-rights"?: Array<RightAccess>
    middleware?: Array<{ (...args: any[]): void | Promise<void> }>
    hooks?: Record<string, NavigationGuard>
    nestedRoutes?: Array<MenuItem>
    visible: boolean | Ref<boolean> = true
    badge: Ref<number> = ref(0)
    key: string | null = null

    protected [uuid]: string

    constructor(item: Partial<MenuItem>) {
        Object.keys(item).forEach((key) => {
            this[key] = item[key]
        })
        this[uuid] = item[uuid] ?? v4()
        this.route = item.route ?? {name: this.routeRecordName}
        this.label = item.label || item.name as string
    }

    get routeRecordName() {
        return this[uuid]
    }

    add(menuItem: MenuItem): this {
        this.children = this.children || []
        this.children.push(menuItem)
        return this
    }
}