import {Repository} from "@lib/common/repository/Repository"
import {Firma} from "@generated/de/lohn24/model/firma/Firma"
import {Artikelkatalog} from "@generated/de/lohn24/model/artikelkatalog/Artikelkatalog"

export class FirmaRepository extends Repository<Firma> {
    artikelKatalog = this.buildRelation(Artikelkatalog, "artikelkatalog")

    constructor() {
        super(Firma, "firma")
    }
}