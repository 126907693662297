import {ViewModel} from "@lib/common/model/ViewModel"
import {Briefabsender, IBriefabsender} from "@generated/de/lohn24/model/briefabsender/Briefabsender"
import {BriefabsenderArt} from "@generated/de/lohn24/model/brief/BriefabsenderArt"

export class KooperationspartnerBriefabsenderViewModel extends ViewModel<KooperationspartnerBriefabsenderViewModel> implements IBriefabsender {

    briefabsender: Briefabsender

    constructor(briefabsender: Briefabsender) {
        super()
        this.briefabsender = this.registerShallowReactive(briefabsender)
    }

    get typ(): BriefabsenderArt {
        if (this.briefabsenderArt) {
            return this.briefabsenderArt
        } else {
            throw new Error("kein BriefabsenderTyp gesetzt")
        }
    }

    set typ(value: BriefabsenderArt) {
        this.briefabsenderArt = value
    }

    get email(): string {
        return this.briefabsender.email ?? ""
    }

    set email(value: string) {
        this.briefabsender.email = value
    }

    get geschaeftsfuehrung(): string {
        return this.briefabsender.geschaeftsfuehrung ?? ""
    }

    set geschaeftsfuehrung(value: string) {
        this.briefabsender.geschaeftsfuehrung = this.briefabsenderValue(value)
    }

    get internet(): string {
        return this.briefabsender.internet ?? ""
    }

    set internet(value: string) {
        this.briefabsender.internet = this.briefabsenderValue(value)
    }

    get logo(): string | null {
        return this.briefabsender.logo
    }

    set logo(value: string | null) {
        this.briefabsender.logo = value?.isNotEmpty() ? value : null
    }

    get logoSkalierung(): number | null {
        return this.briefabsender.logoSkalierung
    }

    set logoSkalierung(value: number | null) {
        this.briefabsender.logoSkalierung = value
    }

    get ort(): string {
        return this.briefabsender.ort ?? ""
    }

    set ort(value: string) {
        this.briefabsender.ort = this.briefabsenderValue(value)
    }

    get postleitzahl(): string {
        return this.briefabsender.postleitzahl ?? ""
    }

    set postleitzahl(value: string) {
        this.briefabsender.postleitzahl = this.briefabsenderValue(value)
    }

    get registergericht(): string {
        return this.briefabsender.registergericht ?? ""
    }

    set registergericht(value: string) {
        this.briefabsender.registergericht = this.briefabsenderValue(value)
    }

    get registernummer(): string {
        return this.briefabsender.registernummer ?? ""
    }

    set registernummer(value: string) {
        this.briefabsender.registernummer = this.briefabsenderValue(value)
    }

    get ruecksendeangabe(): string {
        return this.briefabsender.ruecksendeangabe ?? ""
    }

    set ruecksendeangabe(value: string) {
        this.briefabsender.ruecksendeangabe = this.briefabsenderValue(value)
    }

    get strasse(): string {
        return this.briefabsender.strasse ?? ""
    }

    set strasse(value: string) {
        this.briefabsender.strasse = this.briefabsenderValue(value)
    }

    get telefax(): string {
        return this.briefabsender.telefax ?? ""
    }

    set telefax(value: string) {
        this.briefabsender.telefax = this.briefabsenderValue(value)
    }

    get telefon(): string {
        return this.briefabsender.telefon ?? ""
    }

    set telefon(value: string) {
        this.briefabsender.telefon = this.briefabsenderValue(value)
    }

    get umsatzsteuerid(): string {
        return this.briefabsender.umsatzsteuerid ?? ""
    }

    set umsatzsteuerid(value: string) {
        this.briefabsender.umsatzsteuerid = this.briefabsenderValue(value)
    }

    get briefabsenderArt(): BriefabsenderArt {
        return this.briefabsender.briefabsenderArt
    }

    set briefabsenderArt(value: BriefabsenderArt) {
        // TODO:
        this.briefabsender.briefabsenderArt = value
    }

    get name(): string {
        return this.briefabsender.name
    }

    set name(value) {
        this.briefabsender.name = value
    }

    get fusszeile() {
        return this.briefabsender.fusszeile ?? ""
    }

    set fusszeile(value) {
        this.briefabsender.fusszeile = this.briefabsenderValue(value)
    }

    hatBrieftyp(): boolean {
        return this.briefabsender.briefabsenderArt !== null && this.briefabsender.briefabsenderArt.isNotEmpty()
    }

    private briefabsenderValue<T>(value: T): T {
        return value
    }

}