import {Validator} from "@lib/common/Validator"
import {Model} from "@lib/common/model/Model"

export interface ValidateableListItem {
    validationIndex: number
}

export class ValidateableList<V extends ValidateableListItem> {

    constructor(private path: string, private model: (it: V) => Model<any>) {
    }

    static nextIndex(items: ValidateableListItem[], defaultIndex: number = 0): number {
        if (items.length === 0)
            return defaultIndex
        return items.max(it => it.validationIndex) + 1
    }

    prop(name: string, index: number): string {
        return `${this.indexedPath(index)}.${name}`
    }

    error(row: V, name: string): string {
        return Validator.getErrorText(this.prop(name, row.validationIndex))
    }

    async validate(row: V) {
        return await Validator.validate(
            this.path,
            this.model(row),
            this.indexedPath(row.validationIndex),
        ) === null
    }

    clear(row: V) {
        const indexedPath = this.indexedPath(row.validationIndex)
        Object
            .keys(Validator.validationstate.value)
            .filter(it => it.includes(indexedPath))
            .forEach(key => {
                delete Validator.validationstate.value[key]
            })
    }

    private indexedPath(index: number) {
        return `${this.path}.${index}`
    }

    async validateAll(data: V[]): Promise<boolean> {
        let rtn: boolean = true
        for (const viewModel of data) {
            if (await this.validate(viewModel))
                continue
            rtn = false
        }
        return rtn
    }
}