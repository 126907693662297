import {ValidationResult} from "@lib/common/ValidationResult"
import {LocationAsRelativeRaw} from "vue-router"
import {MitarbeiterMenuItem} from "@intranet/view/mitarbeiter/MitarbeiterMenuItem"

const local: boolean = process.env.NODE_ENV === "development"
const development: boolean = local || window.location.host != "intranet.lohn24.de"

export const Config = {
    local: local,
    development: development,
    async showDataPullFailedMessage(e: unknown, message?: string) {
    },
    async showDataSaveFailedMessage(e: unknown, message?: string) {
    },
    async showDataSavedSuccessfullyMessage(message?: string) {
    },
    async showValidationErrorMessage(data?: ValidationResult[]) {
    },
    async showDataDeletedSuccessfullyMessage(message?: string) {
    },
    async showDataDeletionCanceledMessage(message?: string) {
    },
    async showDataDeletionFailedMessage(message?: string) {
    },
    API_BASE_URL: local ? "http://localhost:8080/api" : "/api/",
    ZAMMAD_BASE_URL: development ? "https://zammad.lohn24.de" : "https://tickets.lohn24.de",
    ZAMMAD_WINDOW_TARGET: "zammad",
    PMC_BASE_URL: !development ? "https://pmc.lohn24.de" : local ? "http://localhost:3000": "https://pmc.release.lohn24.de",
    PMC_WINDOW_TARGET: "pmc",
    LOGIN_CONFIG: {
        afterLoginRoute: MitarbeiterMenuItem.route,
    } as {
        afterLoginRoute: LocationAsRelativeRaw & { name: string }
    },
    buttonLabel: {
        CANCEL_BUTTON_TEXT: "Abbrechen",
        CONFIRM_BUTTON_TEXT: "Ok",
        CONFIRM_DELETE_BUTTON_TEXT: "Löschen",
        CONFIRM_BUTTON_CLASS: "confirm",
        CANCEL_BUTTON_CLASS: "cancel",
        TYPE_INFO: "info",
        TYPE_WARNING: "warning",
        TYPE_ERROR: "error",
        TYPE_SUCCESS: "success",
    } as const,
}