import { IModelIdMapping, IRuntimeTypeInformation, Id, ModelIdMapping, Serializable } from "@lib/index"
import { Mandant } from "@generated/de/lohn24/model/mandant/Mandant"
import { Kooperationspartner } from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import { Firma } from "@generated/de/lohn24/model/firma/Firma"
import { Debitor } from "@generated/de/lohn24/model/debitor/Debitor"
import { IBelegausgangAuswertungBeleg, BelegausgangAuswertungBeleg } from "@generated/de/lohn24/model/beleg/auswertung/BelegausgangAuswertungBeleg"
import { IBelegBuilder, BelegBuilder } from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"

export interface IBelegausgangAuswertungElement {
	rechnungssteller: string
	belegBuilder: Array<IBelegBuilder>
	belegeMonat: Array<IBelegausgangAuswertungBeleg>
	belegeVorherigerMonat: Array<IBelegausgangAuswertungBeleg>
	debitor: IModelIdMapping
	kooperationspartner: IModelIdMapping | null
	mandant: IModelIdMapping | null
	summeMonat: number
	summeVorherigerMonat: number
}

export class BelegausgangAuswertungElement extends Serializable<BelegausgangAuswertungElement> {
	public rechnungssteller!: Id<Firma>
	public belegBuilder!: Array<BelegBuilder>
	public belegeMonat!: Array<BelegausgangAuswertungBeleg>
	public belegeVorherigerMonat!: Array<BelegausgangAuswertungBeleg>
	public debitor!: ModelIdMapping<Debitor>
	public kooperationspartner!: ModelIdMapping<Kooperationspartner> | null
	public mandant!: ModelIdMapping<Mandant> | null
	public summeMonat!: number
	public summeVorherigerMonat!: number

	static _types: Record<keyof IBelegausgangAuswertungElement, IRuntimeTypeInformation> = {
		rechnungssteller: { type: Id },
		belegBuilder: { type: [BelegBuilder] },
		belegeMonat: { type: [BelegausgangAuswertungBeleg] },
		belegeVorherigerMonat: { type: [BelegausgangAuswertungBeleg] },
		debitor: { type: ModelIdMapping },
		kooperationspartner: { type: ModelIdMapping, nullable: true },
		mandant: { type: ModelIdMapping, nullable: true },
		summeMonat: { type: Number },
		summeVorherigerMonat: { type: Number },
	}

	static props: Record<keyof IBelegausgangAuswertungElement, string> = {
		rechnungssteller: "rechnungssteller",
		belegBuilder: "belegBuilder",
		belegeMonat: "belegeMonat",
		belegeVorherigerMonat: "belegeVorherigerMonat",
		debitor: "debitor",
		kooperationspartner: "kooperationspartner",
		mandant: "mandant",
		summeMonat: "summeMonat",
		summeVorherigerMonat: "summeVorherigerMonat",
	}

	constructor(_json: IBelegausgangAuswertungElement) {
		super(_json)
	}
}
