import { IModel, IRuntimeTypeInformation, Id, LocalDateTime, Model } from "@lib/index"
import { MitarbeiterStatus } from "@generated/de/lohn24/model/mitarbeiter/MitarbeiterStatus"
import { MitarbeiterArbeitsplatz } from "@generated/de/lohn24/model/mitarbeiter/MitarbeiterArbeitsplatz"
import { Account } from "@generated/dev/api/model/account/Account"

export interface IMitarbeiter extends IModel {
	account: string
	lastSeen: LocalDateTime | null
	thinclientLastSeen: LocalDateTime | null
	position: number
	zammadId: number | null
	arbeitsplatz: MitarbeiterArbeitsplatz
	mitarbeiterStatus: MitarbeiterStatus
	abteilung: string
	addisonUsername: string | null
	durchwahl: string | null
	durchwahlHomeoffice: string | null
	email: string | null
	mobilnummer: string | null
	nachname: string
	thinclientIp: string | null
	vorname: string
	windowsProfile: string | null
	windowsUsername: string | null
	zammadUserAccessToken: string | null
}

export class Mitarbeiter extends Model<Mitarbeiter> {
	public account!: Id<Account>
	public lastSeen!: LocalDateTime | null
	public thinclientLastSeen!: LocalDateTime | null
	public position!: number
	public zammadId!: number | null
	public arbeitsplatz!: MitarbeiterArbeitsplatz
	public mitarbeiterStatus!: MitarbeiterStatus
	public abteilung!: string
	public addisonUsername!: string | null
	public durchwahl!: string | null
	public durchwahlHomeoffice!: string | null
	public email!: string | null
	public mobilnummer!: string | null
	public nachname!: string
	public thinclientIp!: string | null
	public vorname!: string
	public windowsProfile!: string | null
	public windowsUsername!: string | null
	public zammadUserAccessToken!: string | null

	static _types: Record<keyof IMitarbeiter, IRuntimeTypeInformation> = {
		id: { type: Id },
		account: { type: Id },
		lastSeen: { type: LocalDateTime, nullable: true },
		thinclientLastSeen: { type: LocalDateTime, nullable: true },
		position: { type: Number },
		zammadId: { type: Number, nullable: true },
		arbeitsplatz: { type: MitarbeiterArbeitsplatz },
		mitarbeiterStatus: { type: MitarbeiterStatus },
		abteilung: { type: String },
		addisonUsername: { type: String, nullable: true },
		durchwahl: { type: String, nullable: true },
		durchwahlHomeoffice: { type: String, nullable: true },
		email: { type: String, nullable: true },
		mobilnummer: { type: String, nullable: true },
		nachname: { type: String },
		thinclientIp: { type: String, nullable: true },
		vorname: { type: String },
		windowsProfile: { type: String, nullable: true },
		windowsUsername: { type: String, nullable: true },
		zammadUserAccessToken: { type: String, nullable: true },
	}

	static props: Record<keyof IMitarbeiter, string> = {
		id: "id",
		account: "account",
		lastSeen: "lastSeen",
		thinclientLastSeen: "thinclientLastSeen",
		position: "position",
		zammadId: "zammadId",
		arbeitsplatz: "arbeitsplatz",
		mitarbeiterStatus: "mitarbeiterStatus",
		abteilung: "abteilung",
		addisonUsername: "addisonUsername",
		durchwahl: "durchwahl",
		durchwahlHomeoffice: "durchwahlHomeoffice",
		email: "email",
		mobilnummer: "mobilnummer",
		nachname: "nachname",
		thinclientIp: "thinclientIp",
		vorname: "vorname",
		windowsProfile: "windowsProfile",
		windowsUsername: "windowsUsername",
		zammadUserAccessToken: "zammadUserAccessToken",
	}

	constructor(_json: IMitarbeiter) {
		super(_json)
	}
}
