export class Logger {

    static logger = new Logger(Logger)

    constructor(private klass: any) {
        if (this.development) {
            for (const key in console) {
                if (typeof console[key] === "function")
                    this[key] = console[key].bind(window.console, ...this.prepend(key, klass))
            }
        }
    }

    get development() {
        return process.env.NODE_ENV === "development"
    }

    info: (...data: any[]) => void = () => undefined

    trace: (...data: any[]) => void = () => undefined

    log: (...data: any[]) => void = () => undefined

    warn: (...data: any[]) => void = () => undefined

    error: (...data: any[]) => void = () => undefined

    private css(method: string): string {
        switch (method) {
            case "info":
                return "color: blue"
            case "warn":
                return "color: yellow"
            case "error":
                return "color: red"
            case "log":
                return "color: green"
            default:
                return "color: black"
        }
    }

    private prepend(method: string, klass: any): [string, string] {
        const name = klass.constructor?.name
        return [
            `%c[INTRANET ${method.toLocaleUpperCase()}] ${(name ?? klass.toString())}: `,
            this.css(method),
        ]
    }
}