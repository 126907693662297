export class IdRequiredException extends Error {
    constructor() {
        super("Id Required")
    }
}

export class ListItemIsNotCreateableException extends Error {
    constructor(name?: string) {
        super(`Der Liste ${name ? "\"" + name + "\" " : ""}können keine weiteren Elemente hinzugefügt werden`)
    }
}

export class ListItemIsNotUpdateableException extends Error {
    constructor(name?: string) {
        super(`Die Liste ${name ? "\"" + name + "\" " : ""}kann nicht verändert werden`)
    }
}