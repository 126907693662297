import {Model} from "@lib/common/model/Model"
import {Id} from "@lib/common/model/Id"
import {Ticketgruppe} from "@generated/de/lohn24/model/ticketgruppe/Ticketgruppe"
import {Mandant} from "@generated/de/lohn24/model/mandant/Mandant"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"

export class TicketgruppeMapping extends Model<TicketgruppeMapping> {

    ticketgruppe: Id<Ticketgruppe> | null = null
    mandant: Id<Mandant> | null = null
    kooperationspartner: Id<Kooperationspartner> | null = null
    sammelrechnung: Id<Kooperationspartner> | null = null
}

