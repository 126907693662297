import {ComponentRenderer} from "@lib/common/dialog/ComponentRenderer"
import {
    KooperationspartnerEditDialogController,
} from "@intranet/view/kooperationspartner/edit/KooperationspartnerEditDialogController"
import {Kooperationspartner} from "@generated/de/lohn24/model/kooperationspartner/Kooperationspartner"
import KooperationspartnerEditDialogView
    from "@intranet/view/kooperationspartner/edit/KooperationspartnerEditDialogView.vue"
import {Resource} from "@lib/model/Resource"
import {Id} from "@lib/common/model/Id"

export class KooperationspartnerEditDialog extends ComponentRenderer<typeof KooperationspartnerEditDialogView> {

    constructor(controller: KooperationspartnerEditDialogController, done: () => void) {
        super(
            KooperationspartnerEditDialogView,
            {
                controller: controller,
                done,
            },
        )
    }

    static async show(id: Id<Kooperationspartner>) {
        const kooperationspartner = await Resource.kooperationspartner.find(id)
        const controller = new KooperationspartnerEditDialogController(kooperationspartner)
        return new Promise(resolve => {
            new KooperationspartnerEditDialog(controller, () => resolve(null))
        })
    }
}