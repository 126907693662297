import {Plugin} from "vue"
import {Kontakt} from "@generated/de/lohn24/model/kontakt/Kontakt"
import {Resource} from "@lib/model/Resource"
import Api from "@lib/common/axios/Api"
import {BelegBuilder} from "@generated/de/lohn24/model/beleg/builder/BelegBuilder"
import {Validator} from "@lib/common/Validator"
import {MandantBuilder} from "@generated/de/lohn24/model/mandant/MandantBuilder"
import {DateTime} from "luxon"
import {Config} from "@lib/Config"
import {ZammadApi} from "@lib/common/zammad/ZammadApi"
import {UuidService} from "@lib/common/uuid/UuidService"

declare global {
    interface Window {
        isDevelopment(): boolean
    }
}

export const DevPlugin: Plugin = {
    async install() {
        if (process.env.NODE_ENV === "development" || window.location.host != "intranet.lohn24.de") {
            Object.assign(window, {api: Api.Instance(Config.API_BASE_URL)})
            //@ts-expect-error dev
            window.Resource = Object.assign(window.Resource || {}, Resource)
            //@ts-expect-error dev
            window.Validator = Object.assign(window.Validator || {}, Validator)
            //@ts-expect-error dev
            window.model = Object.assign(window.model || {}, {
                Kontakt: Kontakt,
                BelegBuilder: BelegBuilder,
                MandantBuilder: MandantBuilder,
            })
            //@ts-expect-error dev
            window.zammadApi = () => ({zammadApi: new ZammadApi(Config.ZAMMAD_BASE_URL)})
            //@ts-expect-error dev
            window.uuidService = new UuidService()
            //@ts-expect-error dev
            window.DateTime = DateTime
            window.isDevelopment = function () {
                return process.env.NODE_ENV === "development" || window.location.host != "intranet.lohn24.de"
            }
        }
    },
}

export function isDevelopment() {
    return process.env.NODE_ENV === "development" || window.location.host != "intranet.lohn24.de"
}

export function isLocalDevelopment() {
    return process.env.NODE_ENV === "development"
}


