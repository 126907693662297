import {assertIsDefined, assertNotNull, random} from "@lib/common/Functions"

export interface Params {
    id: string,
    show: boolean,
}

export enum ZammadFormSenderType {
    EMAIL_OUT = "email-out",
    PHONE_IN = "phone-in",
    PHONE_OUT = "phone-out",
}

export interface TicketCreateState {
    formSenderType: ZammadFormSenderType,
    customer_id: string | number | null,
    form_id?: string,
    group_id: string,
    owner_id: string,
}

export interface ZammadTaskbar {
    id: number
    params: Params
    key: string,
    callback: string
}

export function buildCallbackPath(baseUrl: string, taskbar: ZammadTaskbar) {
    const pattern = /(?<word>([A-Z][a-z]+|[A-Z]))/g
    const path = Array
        .from(taskbar.callback.matchAll(pattern))
        .map(it => assertNotNull(it.groups)["word"])
        .join("/")
        .toLowerCase()
    return `${baseUrl}/#${path}/id/${taskbar.params.id}`
}

export class ZammadTaskbarBuilder {
    constructor(public state: TicketCreateState, public params?: Params, public key?: string, public callback: string = "TicketCreate", public prio: number = 2) {
        if (!params) {
            const id = random(5).toString()
            this.params = {
                show: true,
                id: id,
            }
        }
        assertIsDefined(this.params)
        this.key = key ? `${key}-${this.params.id}` : `${callback}Screen-${this.params.id}`
        // this.state.form_id = `${this.params!.id}`
    }

}