import {App, Ref, ref, shallowReactive, watch, WatchSource} from "vue"
import {Logger} from "@lib/common/logger/Logger"
import {Plugin} from "@lib/common/vue/plugin/Plugin"

type IntervalSimpleOptions = {
    uniqueName: string;
    methods?: Array<() => Promise<void> | void>;
    interval?: number;
    immediate?: boolean;
};

type IntervalWatcherOptions<T> = {
    uniqueName: string;
    methods?: Array<() => void | Promise<void>>;
    immediate?: boolean;
    interval: number;
    startWithWatcher: {
        source: WatchSource<T> | Array<WatchSource<T> | object>,
        triggerRule: (value: T, oldValue: T) => boolean
    };
};

export type IntervalOptions<T> = IntervalWatcherOptions<T> & IntervalSimpleOptions;

export type IntervalRefs<T> = {
    active: Ref<boolean>;
    loop: Ref<number>;
    options: Ref<IntervalOptions<T>>;
};

export class IntervalPlugin<T> implements Plugin<IntervalOptions<T>> {

    private static intervalRefsMap: Record<string, IntervalRefs<any>> = shallowReactive({})
    private log: Logger

    constructor(user?: Plugin<any>) {
        this.log = new Logger(user ?? this)
    }

    install(app: App, options: IntervalOptions<T>) {

        if (process.env.NODE_ENV === "development") {
            this.log.info(`installs ${options.uniqueName}`)
        }
        if (IntervalPlugin.intervalRefsMap[options.uniqueName]) {
            this.log.warn(`Interval ${options.uniqueName} already registered. Will be overridden.`)
        }
        const intervalRefs = (IntervalPlugin.intervalRefsMap[options.uniqueName] = {
            active: ref<boolean>(true),
            loop: ref<number>(0),
            options: ref(options) as Ref<IntervalOptions<T>>,
        }) as IntervalRefs<T>
        if (intervalRefs.options.value.immediate) {
            this.callMethods(intervalRefs)
        }
        if (intervalRefs.options.value.interval && intervalRefs.options.value.startWithWatcher) {
            const unwatch = watch(intervalRefs.options.value.startWithWatcher.source, (value, oldValue) => {
                if (intervalRefs.options.value.startWithWatcher.triggerRule(value as any, oldValue as any)) {
                    this.callMethods(intervalRefs)
                    this.poll(intervalRefs)
                    unwatch()
                }
            }, {immediate: false, deep: true})
        } else if (intervalRefs.options.value.interval) {
            this.poll(intervalRefs)
        }
    }

    private callMethods<T>(intervalRefs: IntervalRefs<T>) {
        if (intervalRefs.active.value && Array.isArray(intervalRefs.options.value.methods)) {
            intervalRefs.options.value.methods.forEach((method) => {
                typeof method === "function" && method()
            })
        }
    }

    private poll<T>(intervalRefs: IntervalRefs<T>) {
        intervalRefs.loop.value = setTimeout(() => {
            this.callMethods(intervalRefs)
            this.poll(intervalRefs)
        }, intervalRefs.options.value.interval) as unknown as number
    }
}